import { FormikProps } from "formik";
import { GenericVehicleModel } from "../../../Model/TokenModel";
import { PlanFormikInputs } from "./AddPlan";
import { Box, Grid, Icon, Typography, useTheme } from "@mui/material";
import { DoNotDisturbAlt } from "@mui/icons-material";
import NewLabel from "../../../components/NewLabel/NewLabel";
import SliderCard from "./SliderCard";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
  activeRegisteredVehicle: PlanFormikInputs | undefined;
}

const Preview = (props: Props) => {
  const { formik, activeRegisteredVehicle } = props;

  const theme = useTheme();

  const genericVehicle = formik.values.activeGenericVehicle;

  return (
    <>
      {activeRegisteredVehicle && (
        <Grid container spacing={2} sx={{ padding: "10px 0 0 0" }}>
          <Grid item xs={4}>
            {genericVehicle ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <img
                  src={genericVehicle.iconUrl}
                  alt="Vehicle Image"
                  style={{
                    objectFit: "cover",
                    height: "50px",
                    margin: "auto",
                  }}
                />
              </Box>
            ) : (
              <Icon sx={{ width: "100%", margin: "auto" }}>
                <DoNotDisturbAlt />
              </Icon>
            )}
          </Grid>
          <Grid item xs={4}>
            <NewLabel
              Label="Plan Type"
              Value={
                activeRegisteredVehicle.planType
                  ? `${activeRegisteredVehicle.planType
                    .at(0)
                    ?.toUpperCase()}${activeRegisteredVehicle.planType.slice(
                      1
                    )}`
                  : "NA"
              }
            />
          </Grid>
          <Grid item xs={4}>
            <NewLabel
              Label="Schedule Type"
              Value={
                activeRegisteredVehicle.scheduleType === "hour"
                  ? activeRegisteredVehicle.scheduleTypeHourValueDN
                    ? "Shift Basis"
                    : `Every ${activeRegisteredVehicle.scheduleTypeHourValue} Hour`
                  : activeRegisteredVehicle.scheduleType === "day"
                    ? `Every ${activeRegisteredVehicle.scheduleTypeDayValue} Day`
                    : `Every ${activeRegisteredVehicle.scheduleTypeWeekValue} Week`
              }
            />
          </Grid>
          {activeRegisteredVehicle.scheduleType === "hour" && (
            <>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Time"
                  Value={`${formik.values.dayParkingFrom} To ${formik.values.dayParkingTo}`}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Time"
                  Value={`${formik.values.nightParkingFrom} To ${formik.values.nightParkingTo}`}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Actual Amount"
                  Value={
                    activeRegisteredVehicle.dayActualAmount
                      ? `₹ ${activeRegisteredVehicle.dayActualAmount}`
                      : "NA"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Basic Amount"
                  Value={`₹ ${activeRegisteredVehicle.dayBasicAmount}`}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Minimum Minutes"
                  Value={`${activeRegisteredVehicle.dayMinimumMinutes} `}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Actual Amount"
                  Value={
                    activeRegisteredVehicle.nightActualAmount
                      ? `₹ ${activeRegisteredVehicle.nightActualAmount}`
                      : "NA"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Basic Amount"
                  Value={`₹ ${activeRegisteredVehicle.nightBasicAmount}`}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Minimum Minutes"
                  Value={`${activeRegisteredVehicle.nightMinimumMinutes} `}
                />
              </Grid>
            </>
          )}
          {activeRegisteredVehicle.scheduleType !== "hour" && (
            <>
              <Grid item xs={4}>
                <NewLabel
                  Label="Actual Charge"
                  Value={
                    activeRegisteredVehicle.actualAmount
                      ? `₹ ${activeRegisteredVehicle.actualAmount}`
                      : "NA"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Basic Charge"
                  Value={`₹ ${activeRegisteredVehicle.basicAmount}`}
                />
              </Grid>
              {activeRegisteredVehicle.scheduleType === "day" && (
                <Grid item xs={4}>
                  <NewLabel
                    Label="Daily Minimum Hour"
                    Value={`${activeRegisteredVehicle.dailyMinimumHour} Hour`}
                  />
                </Grid>
              )}
              {activeRegisteredVehicle.scheduleType === "week" && (
                <Grid item xs={4}>
                  <NewLabel
                    Label="Daily Minimum Day"
                    Value={`${activeRegisteredVehicle.weeklyMinimumDay} Day`}
                  />
                </Grid>
              )}
              {/* <Grid item xs={4}>
                <NewLabel
                  Label="Day Charge Type"
                  Value={
                    activeRegisteredVehicle.dayChargeType === 1
                      ? "24 Hour Day"
                      : "Day of Year"
                  }
                />
              </Grid> */}
            </>
          )}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                color: theme.palette.primary.main,
                textAlign: "center",
              }}
            >
              Example
            </Typography>
            {activeRegisteredVehicle && (
              <SliderCard
                formik={formik}
                activeRegisteredVehicle={activeRegisteredVehicle}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Preview;
