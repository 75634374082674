import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "./Drawer";
import { resetState, useAppDispatch, useAppSelector } from "../../Redux/Store";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Menu,
  MenuItem,
  Slide,
  Tooltip,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { GetProfileDetails } from "../../Redux/Thunks/ProfileThunk";
import { AccountCircle, Logout, Settings } from "@mui/icons-material";
import theme from "../../Theme/Theme";
import appLogo from "../../assets/app_logo.png";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tabs = {
  entry: { label: "Entry", value: "entry" },
  exit: { label: "Exit", value: "exit" },
};

export default function Navbar() {
  const [openModal, setOpenModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const handleClose = () => setOpenModal(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const activeTab = params.type;

  const { user, permissions } = useAppSelector((state) => state.profile);

  useEffect(() => {
    dispatch(GetProfileDetails());
    const handleKeyDown = (event: any) => {
      if (event.key === "F1") {
        event.preventDefault();
        navigate(`/token/${tabs.entry.value}`);
      }
      if (event.key === "F2") {
        event.preventDefault();
        navigate(`/token/${tabs.exit.value}`);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onClickUserLogOut = () => {
    Cookies.remove("accessToken");
    dispatch(resetState());
    navigate("/");
  };

  const onClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setShowDrawer(!showDrawer);
  };

  const onClickLoco = () => {
    navigate("/dashboard");
  };

  return (
    <AppBar
      position="static"
      style={{
        background: "transparent",
        boxShadow: "none",
        minHeight: "10vh",
      }}
    >
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure want to log out
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={onClickUserLogOut}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Toolbar>
        <Grid container>
          <Grid
            item
            xs={6}
            md={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={onClickMenu}
            >
              <MenuIcon />
            </IconButton>
            {/* <IconButton edge="start" onClick={onClickMenu}> */}
            <Avatar
              src={appLogo}
              sx={{ width: "140px", cursor: "pointer" }}
              variant="square"
              onClick={onClickLoco}
            />
            {/* </IconButton> */}
            {/* <Typography variant="h6" sx={{ color: "primary.main" }}>
              Auto Hive &#178;
            </Typography> */}
          </Grid>
          <Grid
            item
            xs={4}
            display={{ xs: "none", md: "flex" }}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Link
              to="/list"
              style={{
                textDecoration: "none",
                margin: "0 15px",
                color: `${theme.palette.primary.main}`,
              }}
              className={`${
                location.pathname === "/list" ? "active-nav-link" : "nav-link"
              }`}
            >
              List
            </Link>
            {permissions.entry && (
              <Tooltip title="F1">
                <Link
                  to="/token/entry"
                  style={{
                    textDecoration: "none",
                    margin: "0 15px",
                    color: `${theme.palette.primary.main}`,
                  }}
                  className={`${
                    location.pathname === "/token/entry"
                      ? "active-nav-link"
                      : "nav-link"
                  }`}
                >
                  Entry
                </Link>
              </Tooltip>
            )}
            {permissions.exit && (
              <Tooltip title="F2">
                <Link
                  to="/token/exit"
                  style={{
                    textDecoration: "none",
                    margin: "0 15px",
                    color: `${theme.palette.primary.main}`,
                  }}
                  className={`${
                    location.pathname === "/token/exit"
                      ? "active-nav-link"
                      : "nav-link"
                  }`}
                >
                  Exit
                </Link>
              </Tooltip>
            )}
            <Link
              to="/report"
              style={{
                textDecoration: "none",
                margin: "0 15px",
                color: `${theme.palette.primary.main}`,
              }}
              className={`${
                location.pathname === "/report" ? "active-nav-link" : "nav-link"
              }`}
            >
              Report
            </Link>
          </Grid>
          <Grid
            item
            xs={6}
            display={{ xs: "flex", md: "none" }}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Tooltip title="Logout" placement="bottom">
              <IconButton onClick={() => setOpenModal(true)}>
                <Logout color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            display={{ xs: "none", md: "flex" }}
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Tooltip title={user?.firstName || "profile"} placement="bottom">
              <IconButton onClick={() => navigate("/profile")}>
                <AccountCircle
                  sx={{
                    color: `${
                      location.pathname === "/profile"
                        ? "text.primary"
                        : "primary.main"
                    }`,
                  }}
                  fontSize="large"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings" placement="bottom">
              <IconButton onClick={() => navigate("/settings/account")}>
                <Settings
                  fontSize="large"
                  sx={{
                    color: `${
                      location.pathname.includes("/settings")
                        ? "text.primary"
                        : "primary.main"
                    }`,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout" placement="bottom">
              <IconButton onClick={() => setOpenModal(true)}>
                <Logout color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <Drawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        onToggle={onClickMenu}
      />
    </AppBar>
  );
}
