import { convertMinimumDurationToMinutes, getDifferenceInMinutes } from "./utilities/dateTimeConversion";

export const daysCalculation = (startDate: Date, endDate: Date, scheduleDifference: number, dailyMinimumHour: string) => {
    let minimumDuration = convertMinimumDurationToMinutes(dailyMinimumHour);
    // console.log('minimumDuration::', minimumDuration);
    let timeDifference = getDifferenceInMinutes(startDate, endDate);
    // console.log('timeDifference::', timeDifference)
    // Calculate the difference in milliseconds
    const millisecondsDifference = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to days
    let totalDays = Math.floor(millisecondsDifference / (scheduleDifference * 1000 * 60 * 60 * 24));
    let minimumHour = 0;
    const dayBalance = Math.floor(millisecondsDifference % (scheduleDifference * 1000 * 60 * 60 * 24) / (1000 * 60));
    // console.log('dayBalance::', dayBalance);
    if (dayBalance > minimumDuration) {
        totalDays++;
    } else {
        minimumHour++;
    }
    return { totalDays, minimumHour };
}