import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getHeaderToken } from '../utils/header';
import { ApiResponse } from '../utils/ApiResponse';
import { BlockParams, TheftParams } from '../../Model/BlockModal';

const url = process.env.REACT_APP_HIVERAPIURL;

export const AddBlockAndTheft = createAsyncThunk<ApiResponse, TheftParams | BlockParams>('post/blockAndtheft',
    async (params) => {
        try {
            let response = await axios.post(
                `${url}api/AccessControlList/createAccessControl`,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    });
export const UpdateBlockAndTheft = createAsyncThunk<ApiResponse, TheftParams | BlockParams>('post/blockAndtheft',
    async (params) => {
        try {
            let response = await axios.put(
                `${url}api/AccessControlList/UpdateAccessControl`,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    });

export const ListBlockOrTheft = createAsyncThunk(
    "block/list",
    async (details: any) => {
        try {
            let response = await axios.get(
                `${url}api/AccessControlList/GetListAccessControl/${details.type}/${details.parkingLotId}`,
                await getHeaderToken()
            );
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const GetByIdBlockOrTheft = createAsyncThunk(
    "block/getByID",
    async (details: any) => {
        try {
            let response = await axios.get(
                `${url}api/AccessControlList/GetByIdAccessControl/${details.id}/${details.type}/${details.parkingLotId}`,
                await getHeaderToken()
            );
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const DeleteVehicleBlockOrTheft = createAsyncThunk(
    "block/delete",
    async (details:any) => {
        try{
            let response = await axios.delete(
                `${url}api/AccessControlList/DeleteAccessControl/${details.id}/${details.type}/${details.parkingLotId}`,
                await getHeaderToken()
            );
            return response.data;
        }
        catch(error:any){
            return error.response.data;
        }
    }
);