import { useEffect, useState } from "react";
import "./Login.css";
import { Form, Formik } from "formik";
import { LoginModel } from "../../Model/LoginModel";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import {
  AlertColor,
  Avatar,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import Lottie from "react-lottie";
import loginAnimation from "../../assets/Login.json";
import burstExplosionAnimation from "../../assets/BurstExplosion.json";
import Cookies from "js-cookie";
import { LoginUser } from "../../Redux/Thunks/LoginThunk";
import { GetProfileDetails } from "../../Redux/Thunks/ProfileThunk";
import Loading from "../../components/Loading/Loading";
import {
  ToastMessage,
  ToastMessageState,
} from "../../components/ToastMessage/ToastMessage";
import appLogo from "../../assets/app_logo.png";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("* Mobile number is required")
    .min(10, "* Minimum 10 digit required")
    .max(10, "* Can`t be more than 10 digit"),
  password: yup
    .string()
    .required("* Password is required")
    .min(6, "* Minimum 6 digit required"),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let token = Cookies.get("accessToken");
    if (token) {
      navigate("/dashboard");
    }
  });

  const onClickParkingLotRegisterForm = () => {
    navigate("/ParkingLotRegister");
  };

  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  let initialLoginValues: LoginModel = {
    email: "",
    password: "",
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loginAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      flex={1}
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Formik
        initialValues={initialLoginValues}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          let params: any = {
            username: values.email,
            password: values.password,
            grant_type: "password",
            scope: "hiverwebapi",
            client_id: "hiverandroid",
            client_secret: "secret",
          };
          const formBody = Object.keys(params)
            .map(
              (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            )
            .join("&");
          dispatch(LoginUser(formBody)).then(async (response: any) => {
            if (response.meta.requestStatus !== "fulfilled") {
              updateToastMessage(true, "Connection Refused", "error");
              setIsLoading(false);
              return;
            }
            if (
              response &&
              response.payload &&
              response.payload.status === 200
            ) {
              await dispatch(GetProfileDetails());
              setIsLoading(false);
              navigate("/dashboard");
            } else {
              setIsLoading(false);
              updateToastMessage(
                true,
                response.payload.error_description,
                "info"
              );
            }
          });
        }}
        enableReinitialize
      >
        {({ handleChange, handleBlur, values, errors, touched }) => (
          <Form className="form-container">
            <div className="lottie-container">
              <Lottie options={defaultOptions} speed={0.5} />
            </div>
            <div className="input-container">
              <Avatar
                src={appLogo}
                sx={{ width: "200px", height: "60px", margin: "20px auto" }}
                variant="square"
              />
              <Box className="form-input">
                <TextField
                  label="Mobile"
                  variant="outlined"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email ? true : false}
                  helperText={touched.email && errors.email ? errors.email : ""}
                  type="number"
                  fullWidth
                />
              </Box>
              <Box className="form-input">
                <TextField
                  label="Password"
                  variant="outlined"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && errors.password ? true : false}
                  helperText={
                    touched.password && errors.password ? errors.password : ""
                  }
                  type="password"
                  fullWidth
                  sx={{ height: "10%" }}
                />
              </Box>
              <Box className="button-container">
                <Button type="submit" variant="contained">
                  Login
                </Button>
                <Typography>
                  Don't have an account? {"   "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: `${theme.palette.primary.main}`,
                      fontWeight: "bold",
                    }}
                    onClick={() => onClickParkingLotRegisterForm()}
                  >
                     Signup
                  </span>
                </Typography>
              </Box>
            </div>
          </Form>
        )}
      </Formik>
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
        // placement={{ vertical: "top", horizontal: "left" }}
      />
      <Loading openModal={isLoading} />
      <Box
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          width: "30vh",
          height: "20vh",
        }}
      >
        <Lottie
          options={{ animationData: burstExplosionAnimation }}
          speed={0.5}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          width: "30vh",
          height: "20vh",
        }}
      >
        <Lottie
          options={{ animationData: burstExplosionAnimation }}
          speed={0.5}
        />
      </Box>
      <svg
        viewBox="0 0 500 200"
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          zIndex: -1,
          width: "100%",
          bottom: 0,
        }}
      >
        <path
          d="M 0 150 C 215 50 250 200 500 100 L 500 200 L 0 200"
          fill="#164cfa"
          opacity="0.5"
        ></path>
        <path
          d="M 0 150 C 150 50 300 200 500 120 L 500 200 L 0 200"
          fill="rgb(57, 27, 112)"
        ></path>
        <path
          d="M 0 150 C 150 50 330 230 500 150 L 500 200 L 0 200"
          fill="#F54749"
          opacity="0.8"
        ></path>
      </svg>
    </Box>
  );
};

export default Login;
