import {
  Dispatch,
  ReactEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import GridViewIcon from "@mui/icons-material/GridView";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import List from "@mui/material/List";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Typography, useTheme } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Redux/Store";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import "./Drawer.css";
import { EventNote, Token } from "@mui/icons-material";
import BlockTwoToneIcon from "@mui/icons-material/BlockTwoTone";

interface props {
  showDrawer: boolean;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
  onToggle: ReactEventHandler<{}>;
}

export default function Drawer({ showDrawer, onToggle, setShowDrawer }: props) {
  const theme = useTheme();
  const [isActive, setIsActive] = useState("dashboard");

  const { permissions } = useAppSelector((state) => state.profile);
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let pathname = location.pathname.slice(1);
    if (pathname === "token/exit") {
      setIsActive("token/entry");
    } else {
      if (pathname.includes("blocked")) {
        setIsActive("blocked");
      } else if (pathname.includes("settings")) {
        setIsActive("settings");
      } else {
        setIsActive(pathname);
      }
    }
  }, [location]);

  const navigate = (activetab: string) => {
    setIsActive(activetab);
    navigation(`/${activetab}`);
    setShowDrawer(false);
  };

  let pages = [
    { name: "dashboard", key: "Dashboard", icon: <GridViewIcon /> },
    { name: "token/entry", key: "Token", icon: <Token /> },
    // { name: "entry", key: "Entry", icon: <CarRepairIcon /> },
    // { name: "exit", key: "Exit", icon: <DirectionsCarIcon /> },
    { name: "list", key: "List", icon: <FormatListBulletedIcon /> },
    // { name: "permission", key: "Permission", icon: <PersonAddIcon /> },
    // { name: "printReceipt", key: "Print Receipt", icon: <PrintIcon /> },
    { name: "plan", key: "Plan", icon: <EventNote /> },
    { name: "report", key: "Report", icon: <DescriptionIcon /> },
    { name: "agingReport", key: "Aging Report", icon: <PendingActionsIcon /> },
    { name: "blocked", key: "Block List", icon: <BlockTwoToneIcon /> },
    { name: "settings", key: "Settings", icon: <SettingsIcon /> },
    { name: "profile", key: "Profile", icon: <AccountBoxIcon /> },
  ];

  if (!permissions.entry && !permissions.exit) {
    pages = pages.filter((eachPage) => eachPage.name !== "token/entry");
  }

  // if (!permissions.entry) {
  //   pages = pages.filter((eachPage) => eachPage.name !== "entry");
  // }

  // if (!permissions.exit) {
  //   pages = pages.filter((eachPage) => eachPage.name !== "exit");
  // }

  const onNavigate = (pageName: string) => {
    if (pageName === "blocked") {
      navigate("blocked/list");
    } else if (pageName === "settings") {
      navigate("settings/account");
    } else {
      navigate(pageName);
    }
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={"left"}
        open={showDrawer}
        onClose={onToggle}
        onOpen={onToggle}
        PaperProps={{
          sx: { width: "20%" },
        }}
      >
        <List className="item-list">
          <ListItem
            key="Dashboard"
            onClick={() => onNavigate("dashboard")}
            style={{
              backgroundColor:
                isActive === "dashboard" ? theme.palette.primary.main : "",
              color:
                isActive === "dashboard" ? theme.palette.background.paper : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "dashboard" ? theme.palette.background.paper : ""
                }`,
              }}
            >
              <GridViewIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Dashboard"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Token"
            onClick={() => onNavigate("token/entry")}
            style={{
              backgroundColor:
                isActive === "token/entry" ? theme.palette.primary.main : "",
              color:
                isActive === "token/entry"
                  ? theme.palette.background.paper
                  : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "token/entry"
                    ? theme.palette.background.paper
                    : ""
                }`,
              }}
            >
              <Token />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Token"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="List"
            onClick={() => onNavigate("list")}
            style={{
              backgroundColor:
                isActive === "list" ? theme.palette.primary.main : "",
              color: isActive === "list" ? theme.palette.background.paper : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "list" ? theme.palette.background.paper : ""
                }`,
              }}
            >
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"List"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Plan"
            onClick={() => onNavigate("plan")}
            style={{
              backgroundColor:
                isActive.includes("plan") || isActive.includes("schedule")
                  ? theme.palette.primary.main
                  : "",
              color:
                isActive.includes("plan") || isActive.includes("schedule")
                  ? theme.palette.background.paper
                  : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive.includes("plan") || isActive.includes("schedule")
                    ? theme.palette.background.paper
                    : ""
                }`,
              }}
            >
              <EventNote />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Plan"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Report"
            onClick={() => onNavigate("report")}
            style={{
              backgroundColor:
                isActive === "report" ? theme.palette.primary.main : "",
              color:
                isActive === "report" ? theme.palette.background.paper : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "report" ? theme.palette.background.paper : ""
                }`,
              }}
            >
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Report"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Aging Report"
            onClick={() => onNavigate("agingReport")}
            style={{
              backgroundColor:
                isActive === "agingReport" ? theme.palette.primary.main : "",
              color:
                isActive === "agingReport"
                  ? theme.palette.background.paper
                  : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "agingReport"
                    ? theme.palette.background.paper
                    : ""
                }`,
              }}
            >
              <PendingActionsIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"AgingReport"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Block List"
            onClick={() => onNavigate("blocked/list")}
            style={{
              backgroundColor: isActive.includes("blocked")
                ? theme.palette.primary.main
                : "",
              color: isActive.includes("blocked")
                ? theme.palette.background.paper
                : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "blocked" ? theme.palette.background.paper : ""
                }`,
              }}
            >
              <BlockTwoToneIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Block List"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Settings"
            onClick={() => onNavigate("settings/account")}
            style={{
              backgroundColor: isActive.includes("settings")
                ? theme.palette.primary.main
                : "",
              color: isActive.includes("settings")
                ? theme.palette.background.paper
                : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "settings" ? theme.palette.background.paper : ""
                }`,
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Settings"}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            key="Profile"
            onClick={() => onNavigate("profile")}
            style={{
              backgroundColor:
                isActive === "profile" ? theme.palette.primary.main : "",
              color:
                isActive === "profile" ? theme.palette.background.paper : "",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <ListItemIcon
              style={{
                color: `${
                  isActive === "profile" ? theme.palette.background.paper : ""
                }`,
              }}
            >
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>{"Profile"}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </div>
  );
}
