import { Box } from "@mui/material";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import CurrentPlanDetails from "./CurrentPlanDetails";
import CurrentPlansVehicles from "./CurrentPlansVehicles";

interface Props {
  currentPlanDetails: PlanDetailsModel[];
}

const CurrentPlan = (props: Props) => {
  const { currentPlanDetails } = props;

  let mainPlan: PlanDetailsModel | undefined;
  let specialPlan: PlanDetailsModel | undefined;

  if (currentPlanDetails.length > 0) {
    if (currentPlanDetails.length > 1) {
      specialPlan = currentPlanDetails.find(
        (each) => each.plan.isSpecialPlan === true
      );
      mainPlan = currentPlanDetails.find(
        (each) => each.plan.isSpecialPlan === false
      );
    } else {
      mainPlan = currentPlanDetails[0];
    }
  }

  let activePlan = specialPlan ? specialPlan : mainPlan;

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <CurrentPlanDetails currentPlanDetails={currentPlanDetails} />
      <CurrentPlansVehicles activePlan={activePlan} />
    </Box>
  );
};

export default CurrentPlan;
