import { createSlice } from "@reduxjs/toolkit";
import { GetAgingReport, GetReport } from "../Thunks/ReportThunk";
import { GenericVehicleModel, TokenModel } from "../../Model/TokenModel";
import { reportModel } from "../../Model/ReportModel";

interface InitialState {
  isLoading: boolean;
  agingReport: reportModel;
  report: reportModel;
}

let initialState: InitialState = {
  isLoading: false,
  agingReport: {
    tokens: [],
    genericVehicles: [],
  },
  report: {
    tokens: [],
    genericVehicles: [],
  },
};

const reportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAgingReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAgingReport.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.agingReport.tokens =
            action.payload.data === null ? [] : action.payload.data.tokens;
          state.agingReport.genericVehicles = action.payload.data === null ? [] : action.payload.data.genericVehicles;
        }
      })
      .addCase(GetAgingReport.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(GetReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetReport.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.report.tokens =
            action.payload.data === null ? [] : action.payload.data.tokens;
          state.report.genericVehicles = action.payload.data === null ? [] : action.payload.data.genericVehicles;
        }
      })
      .addCase(GetReport.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const reportReducer = reportSlice.reducer;
