import { PushPin } from "@mui/icons-material";
import { Avatar, Box, Card, Grid, Tooltip, Typography } from "@mui/material";
import { FixedPlanTypeModel } from "../../../Model/PlanModel";
import { GenericVehicleModel } from "../../../Model/TokenModel";

interface Props {
  plan: FixedPlanTypeModel;
  vehicleTypeName: string;
  iconUrl: string;
}

const FixedPlanVehicleCard = (props: Props) => {
  const { plan, vehicleTypeName, iconUrl } = props;

  const getScheduleType = (type: string) => {
    let value = "Every ";
    if (type.startsWith("DN")) {
      value = "Day / Night";
    } else if (type.startsWith("H")) {
      value += type.at(1) + " Hour";
    } else if (type.startsWith("D")) {
      value += type.at(1) + " Day";
    } else if (type.startsWith("W")) {
      value += type.at(1) + " Week";
    } else if (type.startsWith("M")) {
      value += type.at(1) + " Month";
    }
    return value;
  };

  return (
    <Grid item xs={12} sm={6} md={12} lg={6}>
      <Card
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "transparent",
          // boxShadow: "none",
          // margin: "auto",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Tooltip title={vehicleTypeName}>
            <img src={iconUrl} alt="" height="50px" />
          </Tooltip>
          {/* <Typography variant="h5">Fixed</Typography> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ margin: "0 0 0 15px" }}
        >
          <Typography variant="caption">
            Schedule :
            <span style={{ fontWeight: 600 }}>
              {getScheduleType(plan.scheduleType)}
            </span>
          </Typography>
          {plan.dayActualAmount && plan.nightActualAmount && (
            <>
              <Typography variant="caption">
                Day Charge :
                <span style={{ fontWeight: 600 }}>
                  &#8377; {plan.dayActualAmount}
                </span>
              </Typography>
              <Typography variant="caption">
                Night Charge :
                <span style={{ fontWeight: 600 }}>
                  &#8377; {plan.nightActualAmount}
                </span>
              </Typography>
            </>
          )}
          {plan.actualAmount && (
            <>
              <Typography variant="caption">
                Actual Charge :
                <span style={{ fontWeight: 600 }}>
                  &#8377; {plan.actualAmount}
                </span>
              </Typography>
              <Typography variant="caption">
                Basic Charge :
                <span style={{ fontWeight: 600 }}>
                  &#8377; {plan.basicAmount}
                </span>
              </Typography>
            </>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Tooltip title="Fixed Plan">
            <Avatar sx={{ backgroundColor: "transparent" }}>
              <PushPin />
            </Avatar>
          </Tooltip>
        </Box>
      </Card>
    </Grid>
  );
};

export default FixedPlanVehicleCard;
