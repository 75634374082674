import { ViewDay } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import formatDate from "../../../utilities/formatDate";
import UpcomingPlanCard from "./UpcomingPlanCard";

interface Props {
  upcomingPlanDetails: PlanDetailsModel[];
}

const UpcomingPlans = (props: Props) => {
  const { upcomingPlanDetails } = props;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        background:
          "linear-gradient(276deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
        flexGrow: 1,
        padding: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ backgroundColor: "transparent" }}>
          <ViewDay fontSize="medium" color="primary" />
        </Avatar>
        <Typography variant="h6" color="primary" sx={{ margin: "0 10px" }}>
          Upcoming Plans
        </Typography>
      </Box>
      {upcomingPlanDetails.length > 0 ? (
        <Grid
          container
          // spacing={2}
          alignItems="flex-start"
          sx={{
            height: "40vh",
            overflow: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {upcomingPlanDetails.map((eachUpcomingPlan, index) => (
            <UpcomingPlanCard
              planDetails={eachUpcomingPlan}
              key={eachUpcomingPlan.plan.planId + index}
            />
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" sx={{ margin: "auto" }}>
          Upcoming Plan Not Available
        </Typography>
      )}
    </Card>
  );
};

export default UpcomingPlans;
