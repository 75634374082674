import { createSlice } from "@reduxjs/toolkit";
import { LoginUser } from "../Thunks/LoginThunk";

let initialState = {
    isLoading: false,
    error: '',
}

const LoginUserSlice = createSlice({
    name: 'Groups',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(LoginUser.fulfilled, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(LoginUser.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(LoginUser.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const loginReducer = LoginUserSlice.reducer;