import {
    Box,
    Chip,
    Divider,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import { DateRange, Today } from "@mui/icons-material";

interface Props {
    formik: FormikProps<PlanFormikInputs>;
}

const FixedPlanWeekInputsWeekEnd = ({ formik }: Props) => {
    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ marginBottom: '10px', marginTop: '18px' }}>
                <Divider />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Week End Details
                </Typography>
                <Divider />
            </Box>
            <Box display="flex" flexDirection="column" flexWrap="wrap">
                <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", margin: "10px 5px" }}
                >
                    Choose a Week
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                    <TextField
                        variant="outlined"
                        label="Week"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DateRange />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: "100%" }}
                        type="number"
                        error={
                            formik.touched.scheduleTypeWeekValueWeekEnd &&
                            Boolean(formik.errors.scheduleTypeWeekValueWeekEnd)
                        }
                        helperText={
                            formik.touched.scheduleTypeWeekValueWeekEnd
                                ? formik.errors.scheduleTypeWeekValueWeekEnd
                                : ""
                        }
                        {...formik.getFieldProps("scheduleTypeWeekValueWeekEnd")}
                    />
                    <TextField
                        variant="outlined"
                        label="Weekly Actual Charge"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">&#8377;</InputAdornment>
                            ),
                        }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.actualAmountWeekEnd && Boolean(formik.errors.actualAmountWeekEnd)
                        }
                        helperText={
                            formik.touched.actualAmountWeekEnd ? formik.errors.actualAmountWeekEnd : ""
                        }
                        {...formik.getFieldProps("actualAmountWeekEnd")}
                    />
                    <TextField
                        variant="outlined"
                        label="Daily basic Charge"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">&#8377;</InputAdornment>
                            ),
                        }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.basicAmountWeekEnd && Boolean(formik.errors.basicAmountWeekEnd)
                        }
                        helperText={
                            formik.touched.basicAmountWeekEnd ? formik.errors.basicAmountWeekEnd : ""
                        }
                        {...formik.getFieldProps("basicAmountWeekEnd")}
                    />
                    <TextField
                        variant="outlined"
                        label="Weekly Minimum Day"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Today />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.weeklyMinimumDayWeekEnd &&
                            Boolean(formik.errors.weeklyMinimumDayWeekEnd)
                        }
                        helperText={
                            formik.touched.weeklyMinimumDayWeekEnd
                                ? formik.errors.weeklyMinimumDayWeekEnd
                                : ""
                        }
                        {...formik.getFieldProps("weeklyMinimumDayWeekEnd")}
                    />
                    {/* <Box display="flex" flexDirection="row" alignItems="center">
            <Chip
              label="24 Hour Day"
              clickable
              color={formik.values.dayChargeType === 1 ? "primary" : "default"}
              onClick={() => formik.setFieldValue("dayChargeType", 1)}
              style={{
                padding: "0 15px",
                margin: "5px 5px",
              }}
            />
            <Chip
              label="Day of year"
              clickable
              color={formik.values.dayChargeType === 2 ? "primary" : "default"}
              onClick={() => formik.setFieldValue("dayChargeType", 2)}
              style={{
                padding: "0 15px",
                margin: "5px 5px",
              }}
            />
          </Box> */}
                </Box>
            </Box>
        </Box>
    );
};

export default FixedPlanWeekInputsWeekEnd;
