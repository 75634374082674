import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { getHeaderToken } from "../utils/header";
const url = process.env.REACT_APP_HIVERAPIURL;

export const ExitDetails = createAsyncThunk(
    "List/exit",
    async (id: any) => {
        try {
            let response = await axios.get(
                `${url}api/Token/get-exit-details/${id}`,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const AllExitDetails = createAsyncThunk(
    "List/getAllExit",
    async () => {
        try {
            let response = await axios.get(
                `${url}api/Token/get-exit-search/`,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const ExitToken = createAsyncThunk(
    "Post/ExitToken",
    async (token: any) => {
        try {
            let response = await axios.post(
                `${url}api/Token/token-exit`,
                token,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const ResendOTP = createAsyncThunk(
    "Post/resendOtp",
    async (token: any) => {
        try {
            let response = await axios.post(
                `${url}api/Token/resendotp`,
                token,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);