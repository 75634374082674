import { Typography } from '@mui/material';
import './NewLabel.css';

interface NewLabelProps {
    Label: string;
    Value: any;
    Varient?: string;
}

function NewLabel(props: NewLabelProps) {
    const { Label, Value, Varient } = props;
    return (
        <div>
            <div className='label'>{Label}</div>
            <div className='value'>{Value}</div>
            {Varient && <Typography className='label-varient' style={{ fontWeight: 'bold' }}>{Varient}</Typography>}
        </div>
    )
}

export default NewLabel
