import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React from "react";
import Subscription from "../../assets/Animation.json";
import Lottie from "react-lottie";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#f94749" : "#308fe8",
  },
}));

const lottie = {
  marginTop: "5px",
  height: "220px",
  width: "100%",
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Subscription,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const DashboardSubscription = () => {
  return (
    <Box>
        <Box sx={lottie}>
          <Lottie options={defaultOptions} />
        </Box>
    </Box>
  );
};

export default DashboardSubscription;
