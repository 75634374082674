import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import TokenEntry from "./TokenEntry";
import Lottie from "react-lottie";
import entryAnimation from "../../assets/Coding.json";
import searchAnimation from "../../assets/Search.json";
import { useAppSelector } from "../../Redux/Store";
import TokenExit from "./TokenExit/TokenExit";
import { useNavigate, useParams } from "react-router-dom";

const tabs = {
  entry: { label: "Entry", value: "entry" },
  exit: { label: "Exit", value: "exit" },
};

const Token = () => {
  const { permissions } = useAppSelector((state) => state.profile);

  const params = useParams();
  const navigate = useNavigate();
  const activeTab = params.type;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      activeTab === tabs.entry.value ? entryAnimation : searchAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box sx={{ minHeight: "90vh", display: "flex", flexDirection: "column" }}>
      <Grid container flexGrow={1}>
        <Grid item md={4.5} display={{ xs: "none", sm: "none", md: "block" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Lottie
              style={{
                height: "90 %",
                width: "90%",
                marginLeft: "-1%",
              }}
              options={defaultOptions}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7.5}>
          <Grid container>
            <Grid item md={0} xs={3} display={{ md: "none" }} />
            <Grid
              md={0}
              xs={6}
              item
              display={{ md: "none" }}
              justifyContent="space-around"
            >
              <Button
                sx={{ width: "80px", height: "39px" }}
                variant={
                  activeTab === tabs.entry.value ? "contained" : "outlined"
                }
                onClick={() => navigate(`/token/${tabs.entry.value}`)}
              >
                Entry
              </Button>
              <Button
                sx={{ width: "82px", height: "39px" }}
                variant={
                  activeTab === tabs.exit.value ? "contained" : "outlined"
                }
                onClick={() => navigate(`/token/${tabs.exit.value}`)}
              >
                Exit
              </Button>
            </Grid>
            <Grid item md={5.5} xs={4} />
          </Grid>
          {permissions.entry && activeTab === tabs.entry.value && (
            <TokenEntry />
          )}
          {permissions.exit && activeTab === tabs.exit.value && <TokenExit />}
        </Grid>
        <Grid item xs={0} md={1} display={{ sm: "none", md: "block" }}></Grid>
      </Grid>
    </Box>
  );
};

export default Token;
