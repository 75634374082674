import { createSlice } from "@reduxjs/toolkit";
import { AccountReduxModal } from "../../Model/Settings/AccountModal";
import { ChangeTokenSettings, CheckUserInLot, Createuser, GetAccountDetails, GetAllUserRole, UpdateReceiptFormat,SaveReceiptFormat, getParkinglotSettingsDetails, getReceiptFormat, sendOtp } from "../Thunks/SettingsThunk";

let initialState: AccountReduxModal = {
    isLoading: false,
    Account: {},
    Roles: [{
        id: '',
        name: ''
    }],
    ParkingLotSettings: [
        {
            id: 0,
            parkingLotId: '',
            settingGroupKey: '',
            settingKey: '',
            settingValue: 0,
            lastUpdatedBy: '',
            lastUpdatedOn: new Date()
        }
    ],
    attachments: [],
    error: '',
}

const settingstSlice = createSlice({
    name: 'List',
    initialState,
    reducers: {
        getAccount: () => {

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAccountDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetAccountDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status) {
                    state.Account = action.payload.data.data;
                }
            })
            .addCase(GetAccountDetails.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(GetAllUserRole.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetAllUserRole.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status) {
                    state.Roles = action.payload.data.data;
                }
            })
            .addCase(GetAllUserRole.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(getParkinglotSettingsDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.status) {
                    state.ParkingLotSettings = action.payload.data.data;
                }
            })
            .addCase(getParkinglotSettingsDetails.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(getParkinglotSettingsDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(CheckUserInLot.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(CheckUserInLot.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(CheckUserInLot.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendOtp.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(sendOtp.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(sendOtp.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(Createuser.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(Createuser.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(Createuser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(ChangeTokenSettings.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(ChangeTokenSettings.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(ChangeTokenSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReceiptFormat.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getReceiptFormat.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(getReceiptFormat.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(UpdateReceiptFormat.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(UpdateReceiptFormat.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(UpdateReceiptFormat.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(SaveReceiptFormat.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(SaveReceiptFormat.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(SaveReceiptFormat.pending, (state) => {
                state.isLoading = true;
            })
    }

})

export const settingsReducer = settingstSlice.reducer;