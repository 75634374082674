import {
    Box,
    Chip,
    Divider,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import {
    ClockIcon,
} from "@mui/x-date-pickers";

interface Props {
    formik: FormikProps<PlanFormikInputs>;
}

const FixedPlanHourInputsWeekEnd = (props: Props) => {
    const { formik } = props;

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ marginBottom: '14px', marginTop: '18px' }}>
                <Divider />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Week End Details
                </Typography>
                <Divider />
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
                <TextField
                    variant="outlined"
                    label="Hour"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ClockIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: "50%" }}
                    type="number"
                    error={
                        formik.touched.scheduleTypeHourValueWeekEnd &&
                        Boolean(formik.errors.scheduleTypeHourValueWeekEnd)
                    }
                    helperText={
                        formik.touched.scheduleTypeHourValueWeekEnd
                            ? formik.errors.scheduleTypeHourValueWeekEnd
                            : ""
                    }
                    {...formik.getFieldProps("scheduleTypeHourValueWeekEnd")}
                />
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    sx={{ padding: "0 10px" }}
                >
                    <Typography variant="h6">OR</Typography>
                    <Chip
                        label="Shift Basis"
                        clickable
                        color={
                            formik.values.scheduleTypeHourValueDNWeekEnd ? "primary" : "default"
                        }
                        onClick={() =>
                            formik.values.scheduleTypeHourValueDNWeekEnd
                                ? formik.setFieldValue("scheduleTypeHourValueDNWeekEnd", false)
                                : formik.setFieldValue("scheduleTypeHourValueDNWeekEnd", true)
                        }
                        style={{
                            padding: "0 10px",
                            margin: "4px 10px",
                        }}
                    />
                </Box>
                <TextField
                    variant="outlined"
                    label="Day Actual Charge"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">&#8377;</InputAdornment>
                        ),
                    }}
                    sx={{ width: "50%" }}
                    type="number"
                    error={
                        formik.touched.dayActualAmountWeekEnd &&
                        Boolean(formik.errors.dayActualAmountWeekEnd)
                    }
                    helperText={
                        formik.touched.dayActualAmountWeekEnd ? formik.errors.dayActualAmountWeekEnd : ""
                    }
                    {...formik.getFieldProps("dayActualAmountWeekEnd")}
                    onChange={(event) => {
                        formik.setFieldValue("dayActualAmountWeekEnd", event.target.value);
                        if (
                            formik.values.nightActualAmountWeekEnd === "" ||
                            formik.values.nightActualAmountWeekEnd === formik.values.dayActualAmountWeekEnd
                        ) {
                            formik.setFieldValue("nightActualAmountWeekEnd", event.target.value);
                        }
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Night Actual Charge"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">&#8377;</InputAdornment>
                        ),
                    }}
                    sx={{ width: "50%" }}
                    type="number"
                    error={
                        formik.touched.nightActualAmountWeekEnd &&
                        Boolean(formik.errors.nightActualAmountWeekEnd)
                    }
                    helperText={
                        formik.touched.nightActualAmountWeekEnd
                            ? formik.errors.nightActualAmountWeekEnd
                            : ""
                    }
                    {...formik.getFieldProps("nightActualAmountWeekEnd")}
                />
                {!formik.values.scheduleTypeHourValueDNWeekEnd && (
                    <TextField
                        variant="outlined"
                        label="Day Minimum Minutes"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.dayMinimumMinutesWeekEnd &&
                            Boolean(formik.errors.dayMinimumMinutesWeekEnd)
                        }
                        helperText={
                            formik.touched.dayMinimumMinutesWeekEnd
                                ? formik.errors.dayMinimumMinutesWeekEnd
                                : ""
                        }
                        {...formik.getFieldProps("dayMinimumMinutesWeekEnd")}
                    />
                )}
                {!formik.values.scheduleTypeHourValueDNWeekEnd && (
                    <TextField
                        variant="outlined"
                        label="Night Minimum Minutes"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.nightMinimumMinutesWeekEnd &&
                            Boolean(formik.errors.nightMinimumMinutesWeekEnd)
                        }
                        helperText={
                            formik.touched.nightMinimumMinutesWeekEnd
                                ? formik.errors.nightMinimumMinutesWeekEnd
                                : ""
                        }
                        {...formik.getFieldProps("nightMinimumMinutesWeekEnd")}
                    />
                )}
                {formik.values.scheduleTypeHourValueDNWeekEnd && (
                    <TextField
                        variant="outlined"
                        label="Day Minimum Hour"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.dayMinimumHourWeekEnd &&
                            Boolean(formik.errors.dayMinimumHourWeekEnd)
                        }
                        helperText={
                            formik.touched.dayMinimumHourWeekEnd ? formik.errors.dayMinimumHourWeekEnd : ""
                        }
                        {...formik.getFieldProps("dayMinimumHourWeekEnd")}
                    />
                )}
                {formik.values.scheduleTypeHourValueDNWeekEnd && (
                    <TextField
                        variant="outlined"
                        label="Night Minimum Hour"
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.nightMinimumHourWeekEnd &&
                            Boolean(formik.errors.nightMinimumHourWeekEnd)
                        }
                        helperText={
                            formik.touched.nightMinimumHourWeekEnd
                                ? formik.errors.nightMinimumHourWeekEnd
                                : ""
                        }
                        {...formik.getFieldProps("nightMinimumHourWeekEnd")}
                    />
                )}
                <TextField
                    variant="outlined"
                    label="Day Basic Charge"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">&#8377;</InputAdornment>
                        ),
                    }}
                    sx={{ width: "50%" }}
                    type="number"
                    error={
                        formik.touched.dayBasicAmountWeekEnd &&
                        Boolean(formik.errors.dayBasicAmountWeekEnd)
                    }
                    helperText={
                        formik.touched.dayBasicAmountWeekEnd ? formik.errors.dayBasicAmountWeekEnd : ""
                    }
                    {...formik.getFieldProps("dayBasicAmountWeekEnd")}
                />
                <TextField
                    variant="outlined"
                    label="Night Basic Charge"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">&#8377;</InputAdornment>
                        ),
                    }}
                    sx={{ width: "50%" }}
                    type="number"
                    error={
                        formik.touched.nightBasicAmountWeekEnd &&
                        Boolean(formik.errors.nightBasicAmountWeekEnd)
                    }
                    helperText={
                        formik.touched.nightBasicAmountWeekEnd
                            ? formik.errors.nightBasicAmountWeekEnd
                            : ""
                    }
                    {...formik.getFieldProps("nightBasicAmountWeekEnd")}
                />
            </Box>
        </Box>
    );
};

export default FixedPlanHourInputsWeekEnd;
