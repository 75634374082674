import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import Logo from "../../assets/app_logo.png";
import { useAppSelector } from "../../Redux/Store";
import dayjs from "dayjs";

const cardTicket = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const cardHeader = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const divider = {
  borderTop: "2px dashed rgb(179, 179, 179)",
  width: "80%",
};

const cir = {
  position: "absolute",
  width: "16px",
  height: "16px",
  borderRadius: "8px",
  top: "calc(33% - 8px)",
  backgroundColor: "rgb(237, 234, 234)",
  left: "-8px",
  boxShadow: "rgb(230, 227, 227) -3px 0px 3px inset",
};

const cir1 = {
  position: "absolute",
  width: "16px",
  height: "16px",
  borderRadius: "8px",
  top: "calc(33% - 8px)",
  backgroundColor: "rgb(237, 234, 234)",
  right: "-8px",
  boxShadow: "rgb(230, 227, 227) 3px 0px 3px inset",
};

const tstyle = {
  display: "flex",
  MozBoxPack: "center",
  justifyContent: "center",
  MozBoxAlign: "center",
  alignItems: "center",
  width: "100%",
  height: "28px",
  zIndex: "1",
  position: "relative",
  overflow: "hidden",
};

const cardTitle = {
  backgroundColor: "#fff",
  boxShadow: "0px 10px 15px 0px rgba(46, 37, 134, 0.24)",
  borderRadius: "20px 1px 20px 1px",
};

const text = {
  textAlign: "center",
  background: `-webkit-linear-gradient(45deg, #ff0015, #ff003c)`,
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
};

const title = {
  background: `-webkit-linear-gradient(45deg, #ff0015, #ff003c)`,
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  fontWeight:"500",
  fontSize:"20px"
};

const EntryTicket = () => {
  const { tokenDetails } = useAppSelector((state) => state.addToken);

  return (
    <Grid>
      <Grid xs={12}>
        <Card sx={cardTitle}>
          <CardContent sx={cardTicket}>
            <img src={Logo} alt="" style={{ width: "130px", height: "45px" }} />
            <Divider orientation="vertical" variant="middle" flexItem></Divider>
            <Typography sx={cardHeader}>
              {tokenDetails ? tokenDetails.parkingLotName : ""}
            </Typography>
          </CardContent>
          <Box sx={tstyle}>
            <Typography sx={cir}></Typography>
            <Typography sx={divider}></Typography>
            <Typography sx={cir1}></Typography>
          </Box>
          <Box sx={text}>
            <Typography sx={title}>Token In</Typography>
          </Box>
          <Box sx={cardTicket}>
            <Box sx={{ padding: "20px" }}>
              <Typography>
                TokenNo : {tokenDetails ? tokenDetails.tokenDisplayNumber : ""}
              </Typography>
              <Typography>
                RegisterNo : {tokenDetails ? tokenDetails.registerNumber : ""}
              </Typography>
              <Typography>
                Date :{" "}
                {tokenDetails
                  ? dayjs(tokenDetails.inTime).format("DD-MM-YYYY")
                  : ""}
              </Typography>
              <Typography>
                In-Time :{" "}
                {tokenDetails
                  ? dayjs(tokenDetails.inTime).format("hh:mm A")
                  : ""}
              </Typography>
            </Box>
            <Typography>
              <Tooltip title={tokenDetails ? tokenDetails.vehicleTypeName : ""}>
                <img src={tokenDetails ? tokenDetails.iconUrl : ""} alt="" />
              </Tooltip>
            </Typography>
          </Box>
          <Box sx={tstyle}>
            <Typography sx={cir}></Typography>
            <Typography sx={divider}></Typography>
            <Typography sx={cir1}></Typography>
          </Box>
          <footer>
            <Typography sx={text}>© Powered by AutoHiveSquare</Typography>
          </footer>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EntryTicket;
