import { Edit, LocalParking, PhoneAndroid, Pin } from "@mui/icons-material";
import {
    AlertColor,
    Avatar,
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Snackbar,
    SxProps,
    ThemeProvider,
    Typography,
    createTheme,
    useTheme,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CSSProperties, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { ExportAgingReport, ExportReport, GetAgingReport, GetReport } from "../../Redux/Thunks/ReportThunk";
import Loading from "../../components/Loading/Loading";
import DataTable from "../../components/DataTable";
import {
    ToastMessageState,
    ToastMessage,
} from "../../components/ToastMessage/ToastMessage";
import { dateProps } from "../../Model/ReportModel";
import utcPlugin from 'dayjs/plugin/utc';
import formatDate from "../../utilities/formatDate";
dayjs.extend(utcPlugin);

interface Style {
    box: SxProps;
    infoCard: CSSProperties;
    radioGroup: CSSProperties;
    radioButton: CSSProperties;
    vehicleCard: CSSProperties;
}

const defaultValues = [
    { label: "Last week", value: "lastWeek" },
    { label: "Last two weeks", value: "lastTwoWeeks" },
    { label: "Last month", value: "lastMonth" },
    { label: "Custom", value: "custom" },
];

const Report = () => {
    const { isLoading, report } = useAppSelector((state) => state.reports);
    const { user } = useAppSelector((state) => state.profile);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [value, setValue] = useState(defaultValues[0].value);
    const initialDateRange = {
        fromDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
            .subtract(1, "week")
            .toDate(),
        toDate: dayjs(new Date(new Date().setHours(23, 59, 59, 0)))
            .toDate()
    }
    const [date, setDate] = useState<dateProps>(initialDateRange);
    const [isFromDatePickerOpen, setIsFromDatePickerOpen] = useState(false);
    const [isToDatePickerOpen, setIsToDatePickerOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<ToastMessageState>({
        showMessage: false,
        message: "",
        alertType: "success",
    });

    const updateToastMessage = (
        showMessage: boolean,
        message: string,
        alertType: AlertColor
    ) => {
        setToastMessage({ showMessage, message, alertType });
    };

    const updateDate = (value: string) => {
        let newDate = initialDateRange;
        switch (value) {
            case "lastWeek":
                newDate = {
                    fromDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
                        .subtract(1, "week")
                        .toDate(),
                    toDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
                        .toDate()
                };
                break;
            case "lastTwoWeeks":
                newDate = {
                    fromDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
                        .subtract(2, "week")
                        .toDate(),
                    toDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0))).toDate()
                };
                break;
            case "lastMonth":
                newDate = {
                    fromDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
                        .subtract(4, "week")
                        .toDate(),
                    toDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0))).toDate()
                };
                break;
            default:
                newDate = {
                    fromDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
                        .subtract(1, "week")
                        .toDate(),
                    toDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
                        .toDate()
                };
                break;
        }
        setDate(newDate);
        return newDate;
    };

    const getReport = async (newDate?: dateProps) => {
        let arg: dateProps;
        if (newDate === undefined) {
            arg = { ...date, toDate: dayjs(newDate).add(1, 'day').subtract(1, 'second').toDate() }
        } else {
            arg = newDate;
        }
        const response = await dispatch(GetReport(arg));
        if (!response.payload) {
            updateToastMessage(true, "Connection Refused", "error");
        }
    };

    const customTheme = createTheme({
        ...theme,
        components: {
            MuiCard: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent",
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent",
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    });

    useEffect(() => {
        getReport();
    }, []);

    const handleSelect = async (event: SelectChangeEvent) => {
        setValue(event.target.value);
        let updatedDate = updateDate(event.target.value);
        if (event.target.value !== "custom") {
            await getReport(updatedDate);
        }
    };

    let vehiclesList: string[] = [];
    report.tokens.forEach((eachToken) => vehiclesList.push(eachToken.registerNumber));

    function countVehicleDuplicates(vehiclesList: string[]): string[] {
        const frequency: { [key: string]: number } = {};

        vehiclesList.forEach((element) => {
            frequency[element] = (frequency[element] || 0) + 1;
        });

        const result: string[] = Array.from(new Set(vehiclesList)).map(
            (element) => {
                if (frequency[element] > 1) {
                    return `${element} (${frequency[element]})`;
                } else {
                    return element;
                }
            }
        );

        return result;
    }

    const onClickExport = async () => {
        await dispatch(ExportReport({ fromDate: date.fromDate, toDate: dayjs(date.toDate).add(1, 'day').subtract(1, 'second').toDate(), userId: user ? user.id : "" }));
    };

    const formattedInDateTime = (props: any) => {
        const inTime = formatDate(props.data.inTime, "DD MMM YYYY hh:mm A ddd")
        return (
            <Box aria-label="Time" title="Time">
                {inTime}
            </Box>
        );
    };

    const formattedOutDateTime = (props: any) => {
        let outTime = '-';
        if (props.data.outTime) {
            outTime = formatDate(props.data.outTime, "DD MMM YYYY hh:mm A ddd")
        }
        return (
            <Box aria-label="Time" title="Time">
                {outTime}
            </Box>
        );
    };

    const vehicleIcon = (props: any) => (
        <Box aria-label="Time" title="Time">
            <img src={props.data.genericVehicleTypeIconUrl} />
        </Box>
    );

    const style: Style = {
        box: {
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            flex: 1,
        },
        infoCard: {
            padding: "10px",
            backgroundColor: "rgba(250, 242, 222,0.5)",
        },
        radioGroup: {
            display: "flex",
            flexDirection: "row",
        },
        radioButton: {
            margin: "0 20px 0 0",
            fontWeight: 500,
        },
        vehicleCard: {
            //   backgroundColor: "lightyellow",
            padding: "10px",
        },
    };

    return (
        <Box sx={style.box}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card style={style.infoCard}>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid md={3} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-label">Date</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        onChange={handleSelect}
                                        label="Date"
                                        value={value}
                                    >
                                        {defaultValues.map((each) => (
                                            <MenuItem value={each.value}>{each.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid md={1}></Grid>
                            <Grid md={5} xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ marginRight: "10px", fontWeight: 400 }}
                                        gutterBottom
                                    >
                                        Report from
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ margin: 0 }}>
                                        {`${dayjs(date.fromDate).format("DD MMM YYYY ddd")}`}
                                    </Typography>
                                    {value === "custom" && (
                                        <IconButton
                                            style={{ marginLeft: "10px", padding: 3 }}
                                            onClick={() => setIsFromDatePickerOpen(true)}
                                        >
                                            <Edit fontSize="medium" color="warning" />
                                        </IconButton>
                                    )}
                                    <Typography>
                                        to
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ margin: 0 }}>
                                        {`${dayjs(date.toDate).format("DD MMM YYYY ddd")}`}
                                    </Typography>
                                    {value === "custom" && (
                                        <IconButton
                                            style={{ marginLeft: "10px", padding: 3 }}
                                            onClick={() => setIsToDatePickerOpen(true)}
                                        >
                                            <Edit fontSize="medium" color="warning" />
                                        </IconButton>
                                    )}
                                </Box>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    sx={{ display: "none" }}
                                    value={dayjs(date.fromDate)}
                                    open={isFromDatePickerOpen}
                                    onClose={() => setIsFromDatePickerOpen(false)}
                                    onChange={(newDate: any) => setDate(prev => ({ ...prev, fromDate: newDate }))}
                                    onAccept={() => getReport()}
                                    maxDate={dayjs(new Date())}
                                />
                                <MobileDatePicker
                                    sx={{ display: "none" }}
                                    value={dayjs(date.fromDate)}
                                    open={isToDatePickerOpen}
                                    onClose={() => setIsToDatePickerOpen(false)}
                                    onChange={(newDate: any) => setDate(prev => ({ ...prev, toDate: newDate }))}
                                    onAccept={() => getReport()}
                                    maxDate={dayjs(new Date())}
                                />
                            </LocalizationProvider>
                            <Grid md={2} xs={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ marginRight: "10px", fontWeight: 400 }}
                                        gutterBottom
                                    >
                                        Total vehicles
                                    </Typography>
                                    <Typography variant="h6">{report.tokens.length}</Typography>
                                </Box>
                            </Grid>
                            <Grid md={1} xs={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onClickExport()}
                                        disabled={report.tokens.length <= 0}
                                    >
                                        Export
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Typography variant="h5">
              {registerNumberList.join(", ")}
            </Typography> */}
                    </Card>
                </Grid>
                <Box
                    sx={{ height: "80vh", width: "100%", padding: "20px 0 20px 20px" }}
                >
                    <DataTable
                        rows={report.tokens}
                        columnDefs={[
                            {
                                headerName: "S.No.",
                                valueGetter: "node.rowIndex + 1",
                                minWidth: 100,
                            },
                            { field: "tokenDisplayNumber", headerName: "Token Number", minWidth: 120, },
                            {
                                field: "genericVehicleTypeIconUrl",
                                headerName: "Vehicle Type",
                                cellRenderer: vehicleIcon,
                                minWidth: 90,
                            },
                            {
                                field: "inTime",
                                headerName: "In Time",
                                cellRenderer: formattedInDateTime,
                                minWidth: 150
                            },
                            {
                                field: "outTime",
                                headerName: "Out Time",
                                cellRenderer: formattedOutDateTime,
                                minWidth: 150
                            },
                            { field: "registerNumber", headerName: "Register Number", minWidth: 150 },
                            { field: "customerMobile", headerName: "Customer Mobile", minWidth: 150 },
                            // { field: "customerName", headerName: "Customer Name", minWidth: 150 },
                        ]}
                    />
                </Box>
                {/* {tokens.length > 0 &&
          tokens.map((eachToken) => (
            <Grid item xs={12} sm={6} md={4} key={eachToken.tokenId}>
              <Card style={style.vehicleCard}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={
                          genericVehicles.length > 0
                            ? genericVehicles.find(
                                (each) =>
                                  each.genericVehicleTypeId ===
                                  eachToken.genericVehicleTypeId
                              )?.iconUrl
                            : undefined
                        }
                        alt="Vehicle Image"
                        variant="square"
                        style={{
                          textAlign: "center",
                          margin: "5px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </ListItemAvatar>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemIcon>
                        <PhoneAndroid />
                      </ListItemIcon>
                      <ListItemText
                        primary={eachToken.customerMobile}
                        sx={{ margin: "0" }}
                      />
                    </ListItem>
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemIcon>
                        <Pin />
                      </ListItemIcon>
                      <ListItemText
                        primary={eachToken.registerNumber}
                        sx={{ margin: "0" }}
                      />
                    </ListItem>
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemIcon>
                        <LocalParking />
                      </ListItemIcon>
                      <ListItemText
                        primary={dayjs(eachToken.inTime).format(
                          "ddd DD MMM YYYY hh:mm A"
                        )}
                        sx={{ margin: "0" }}
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))} */}
            </Grid>
            <Loading openModal={isLoading} />
            <ToastMessage
                showToastMessage={toastMessage.showMessage}
                setToastMessage={setToastMessage}
                message={toastMessage.message}
                alertType={toastMessage.alertType}
            // placement={{ vertical: "top", horizontal: "left" }}
            />
        </Box>
    );
};

export default Report;
