import { BrowserRouter as Router, Routes, Route, HashRouter } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import { ThemeProvider } from "@mui/material";
import theme from "./Theme/Theme";
import List from "./pages/List/List";
import Permission from "./pages/Permission/Permission";
import PrintReceipt from "./pages/PrintReceipt/PrintReceipt";
import AgingReport from "./pages/Report/AgingReport";
import Report from "./pages/Report/Report";
import Profile from "./pages/ProfileDetails/ProfileDetails";
import TokenDetails from "./pages/TokenDetails/TokenDetails";
import Token from "./pages/Token/Token";
import Plan from "./pages/Plan/Plan/Plan";
import AddPlan from "./pages/Plan/AddPlan/AddPlan";
import Theft from "./pages/BlockAndTheft/Theft/Theft";
import Block from "./pages/BlockAndTheft/Block/Block";
import BlockAndTheftList from "./pages/BlockAndTheft/List/List";
import CreateSchedule from "./pages/Schedule/CreateSchedule/CreateSchedule";
import PrivateRoute from "./layouts/PrivateRoute";
import BlockListLayout from "./layouts/BlockAndTheft";
import SettingsLayout from "./layouts/Settings";
import Account from "./pages/Settings/Account/AccountSetting";
import AddUser from "./pages/Settings/AddUser/AddUser";
import TokenSettings from "./pages/Settings/Token/Token";
import Receipt from "./pages/Settings/Receipt/Receipt";
import TicketContainer from "./pages/Ticket/TicketContainer";
import ParkingLotRegisterForm from "./pages/ParkingLotRegister/ParkingLotRegisterForm";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HashRouter>
            {/* <Router> */}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/token/:type"
                element={
                  <PrivateRoute>
                    <Token />
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="/entry"
                element={
                  <PrivateRoute>
                    <AddToken />
                  </PrivateRoute>
                }
              /> */}
              {/* <Route
                path="/exit"
                element={
                  <PrivateRoute>
                    <Exit />
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="list/details"
                element={
                  <PrivateRoute>
                    <TokenDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/list"
                element={
                  <PrivateRoute>
                    <List />
                  </PrivateRoute>
                }
              />
              <Route
                path="permission"
                element={
                  <PrivateRoute>
                    <Permission />
                  </PrivateRoute>
                }
              />
              <Route
                path="printReceipt"
                element={
                  <PrivateRoute>
                    <PrintReceipt />
                  </PrivateRoute>
                }
              />
              <Route
                path="agingReport"
                element={
                  <PrivateRoute>
                    <AgingReport />
                  </PrivateRoute>
                }
              />
              <Route
                path="Report"
                element={
                  <PrivateRoute>
                    <Report />
                  </PrivateRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="plan"
                element={
                  <PrivateRoute>
                    <Plan />
                  </PrivateRoute>
                }
              />
              <Route
                path="plan/add"
                element={
                  <PrivateRoute>
                    <AddPlan />
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="schedule"
                element={
                  <PrivateRoute>
                    <Plan />
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="schedule/add"
                element={
                  <PrivateRoute>
                    <CreateSchedule />
                  </PrivateRoute>
                }
              />
              <Route
                path="blocked/list"
                element={
                  <PrivateRoute>
                    <BlockAndTheftList />
                  </PrivateRoute>
                }
              />
              <Route
                path="blocked/block"
                element={
                  <BlockListLayout>
                    <Block />
                  </BlockListLayout>
                }
              />
              <Route
                path="blocked/theft"
                element={
                  <BlockListLayout>
                    <Theft />
                  </BlockListLayout>
                }
              />
              <Route
                path="settings/account"
                element={
                  <SettingsLayout>
                    <Account />
                  </SettingsLayout>
                }
              />
              <Route
                path="settings/adduser"
                element={
                  <SettingsLayout>
                    <AddUser />
                  </SettingsLayout>
                }
              />
              <Route
                path="settings/token"
                element={
                  <SettingsLayout>
                    <TokenSettings />
                  </SettingsLayout>
                }
              />
              <Route
                path="settings/receipt"
                element={
                  <SettingsLayout>
                    <Receipt />
                  </SettingsLayout>
                }
              />
              <Route path="Tk" element={<TicketContainer />} />
              <Route path="ParkingLotRegister" element={<ParkingLotRegisterForm />} />
              {/* <Route
                path="ParkingLotRegister"
                element={
                  <PrivateRoute>
                    <ParkingLotRegisterForm />
                  </PrivateRoute>
                }
              /> */}
            </Routes>
            {/* </Router> */}
          </HashRouter>
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
