import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

interface Props {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  onClickConfirm: () => void;
  description?: string;
  confirmButtonName?: string;
  cancelButtonName?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = (props: Props) => {
  const {
    dialogOpen,
    setDialogOpen,
    title,
    onClickConfirm,
    description,
    confirmButtonName = "Confirm",
    cancelButtonName = "Cancel",
  } = props;

  const onClickConfirmButton = () => {
    onClickConfirm();
    setDialogOpen(false);
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        {title}
      </DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>{cancelButtonName}</Button>
        <Button onClick={onClickConfirmButton} autoFocus>
          {confirmButtonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
