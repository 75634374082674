import { PlaylistAddCheckCircle } from "@mui/icons-material";
import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import FixedPlanVehicleCard from "./FixedPlanVehicleCard";
import SlabPlanVehicleCard from "./SlabPlanVehicleCard";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import { GenericVehicleModel } from "../../../Model/TokenModel";

interface Props {
  activePlan: PlanDetailsModel | undefined;
}

const CurrentPlansVehicles = (props: Props) => {
  const { activePlan } = props;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        borderRadius: "15px",
        padding: "16px",
        background:
          "linear-gradient(276deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
        overflow: "auto",
        height: "45vh",
        scrollbarWidth: "thin",
      }}
    >
      {activePlan ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ backgroundColor: "transparent" }}>
                <PlaylistAddCheckCircle fontSize="medium" color="primary" />
              </Avatar>
              <Typography variant="h6" color="primary">
                Current Plan Vehicles
              </Typography>
            </Box>
          </Grid>
          {activePlan.fixedPlanTypeVehicles.length > 0 &&
            activePlan.fixedPlanTypeVehicles.map((eachVehicle) => (
              <FixedPlanVehicleCard
                plan={eachVehicle}
                vehicleTypeName={eachVehicle.vehicleTypeName}
                iconUrl={eachVehicle.iconUrl}
              />
            ))}
          {activePlan.slabPlanTypeVehicles.length > 0 &&
            activePlan.slabPlanTypeVehicles.map((eachVehicle) => (
              <SlabPlanVehicleCard
                plan={eachVehicle}
                vehicleTypeName={eachVehicle.vehicleTypeName}
                iconUrl={eachVehicle.iconUrl}
              />
            ))}
        </Grid>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography variant="h6">Current Plan Not Available</Typography>
        </Box>
      )}
    </Card>
  );
};

export default CurrentPlansVehicles;
