import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import Model from "../Model/Model";
import { Box, Button, Typography } from "@mui/material";
import Toggle from "../Togglebutton/Toggle";

interface Props {
  openWebcam: boolean;
  setOpenWebcam: React.Dispatch<React.SetStateAction<boolean>>;
  captureImageBase64: string | null;
  setCaptureImageBase64: React.Dispatch<React.SetStateAction<string | null>>;
}

const ReactWebcam = (props: Props) => {
  const {
    openWebcam,
    setOpenWebcam,
    captureImageBase64,
    setCaptureImageBase64,
  } = props;

  const webcamRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [mirrored, setMirrored] = useState(true);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      setImageSrc(webcamRef.current.getScreenshot());
    }
  }, [webcamRef]);

  return (
    <Model open={openWebcam} setOpen={setOpenWebcam}>
      <Box display="flex" flexDirection="column">
        <Box sx={{ height: 500, width: 700 }}>
          {imageSrc ? (
            <img
              src={imageSrc}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              alt="webcam"
            />
          ) : (
            <Webcam
              height="100%"
              width="100%"
              ref={webcamRef}
              mirrored={mirrored}
              screenshotFormat="image/jpeg"
              screenshotQuality={0.8}
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          position="relative"
          sx={{ marginTop: "10px", right: 0 }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            position="absolute"
          >
            <Typography variant="h6">Mirror</Typography>
            <Toggle value={mirrored} onClick={() => setMirrored(!mirrored)} />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignSelf="center"
            margin="auto"
          >
            {imageSrc ? (
              <>
                <Button
                  variant="contained"
                  sx={{ margin: "0 10px" }}
                  onClick={() => setImageSrc(null)}
                >
                  Retake
                </Button>
                <Button
                  variant="contained"
                  sx={{ margin: "0 10px" }}
                  onClick={() => {
                    setCaptureImageBase64(imageSrc);
                    setImageSrc(null);
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button variant="contained" onClick={capture}>
                Capture
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Model>
  );
};

export default ReactWebcam;
