import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
const baseurl = process.env.REACT_APP_BASEURL;

export const LoginUser = createAsyncThunk(
  "Login/Login",
  async (params: any) => {
    try {
      let response = await axios.post(`${baseurl}connect/token`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response && response.statusText === "OK") {
        let total_minutes = Number(Number(response.data.expires_in) / 60);
        let total_hours = Number(total_minutes / 60);
        let days: any = Number(total_hours / 24);
        Cookies.set("accessToken", response.data.access_token, {
          expires: days,
        });
      }
      return response;
    } catch (error: any) {
      return error.response.data;
    }
  }
);
