import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
import { ApiResponse } from "../utils/ApiResponse";
import { AddScheduleDetailsModel } from "../../Model/ScheduleModel";

const url = process.env.REACT_APP_HIVERAPIURL;

export const AddSchedule = createAsyncThunk<
  ApiResponse,
  AddScheduleDetailsModel
>("Post/AddSchedule", async (schedule) => {
  try {
    const response = await axios.post(
      `${url}api/Schedule/AddSchedule`,
      schedule,
      await getHeaderToken()
    );
    return {
      status: true,
      data: response.data.data,
      resultCode: response.status,
      message: response.data.message,
    };
  } catch (error: any) {
    return {
      status: false,
      data: null,
      resultCode: error.response.status,
      message: error.response.data,
    };
  }
});

export const GetScheduleInitialDetails = createAsyncThunk<ApiResponse>(
  "Get/ScheduleInitialDetails",
  async () => {
    try {
      let response = await axios.get(
        `${url}api/Schedule/GetInitialDetails`,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);
