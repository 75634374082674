import { Box } from "@mui/system";
import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container,
  Grid,
  AlertColor,
} from "@mui/material";
import ParkingLotDetailsReportForm from "./ParkingLotDetailsReportForm";
import CompanyDetailsForm from "./ContactDetailsForm";
import VehicleCapacityForm from "./VehicleCapacityForm";
import RegisterForm from "./RegisterForm";
import { FormikProps, useFormik } from "formik";
import * as yup from "yup";
import { ParkingLotData } from "../../Model/ParkingLotRegister/ParkingLotRegisterModel";
import {
  CreateParkingLotRegister,
  OtpVerification,
  VerifyInvitationOtp,
} from "../../Redux/Thunks/ParkingLotThunk";
import { useAppDispatch } from "../../Redux/Store";
import OTPVerification from "./OTPVerificationForm";
import appBackground from "../../assets/app_background.png";
import {
  ToastMessage,
  ToastMessageState,
} from "../../components/ToastMessage/ToastMessage";
import { useNavigate } from "react-router-dom";
import ParkingLotAppBar from "../../components/ParkingLot/ParkingLotRegisterAppBar";

const background = {
  position: "relative",
  minHeight: "100vh",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundImage: `url(${appBackground})`,
    zIndex: -1,
    opacity: 0.2,
  },
};

export interface GenericVehicleList {
  vehicleCount: number;
  genericVehicleTypeId: string;
  vehicleTypeName: string;
}
export interface ParkingLotRegisterFormikInputs {
  parkingLotName: string;
  parkingLotTypeId: string;
  parkingLotTypeName: string;
  ownerName: string;
  address1: string;
  address2: string;
  city: string;
  stateId: number;
  stateName: string;
  companyStateId: number;
  companyStateName: string;
  pincode: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  contactName: string;
  email: string;
  hasReservation: boolean;
  surfaceStatus: number;
  interchangeable: boolean;
  areaInSquareFeet: number;
  lengthInFeet: number;
  widthInFeet: number;
  profilePictureUrl: string;
  name: string;
  nameType: string;
  companyType: number;
  companyAddress1: string;
  companyAddress2: string;
  companyCity: string;
  companyPincode: string;
  invitation: string;
  genericVehicleList: GenericVehicleList[];
  pinNumber: number;
  OTP: string;
  pages: number;
  parkingLotPhoneNumber: string;
  password: string;
}
const ParkingLotRegisterForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    formik.values.pages = formik.values.pages - 1;
  };

  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik: FormikProps<ParkingLotRegisterFormikInputs> =
    useFormik<ParkingLotRegisterFormikInputs>({
      initialValues: {
        parkingLotName: "",
        parkingLotTypeId: "2a897f57-213c-4e70-944c-20aad9c8687b",
        parkingLotTypeName: "Parking business",
        ownerName: "",
        address1: "",
        address2: "",
        pages: 1,
        city: "",
        stateId: 0,
        stateName: "",
        companyStateId: 0,
        companyStateName: "",
        pincode: "",
        latitude: 0.0,
        longitude: 0.0,
        phoneNumber: "",
        contactName: "",
        email: "",
        hasReservation: false,
        surfaceStatus: 0,
        interchangeable: false,
        areaInSquareFeet: 0,
        lengthInFeet: 0,
        widthInFeet: 0,
        profilePictureUrl: "",
        name: "",
        nameType: "",
        companyType: 0,
        companyAddress1: "",
        companyAddress2: "",
        companyCity: "",
        companyPincode: "",
        // ownerId: "",
        invitation: "",
        genericVehicleList: [],
        pinNumber: 0,
        OTP: "",
        parkingLotPhoneNumber: "",
        password: "",
      },
      validationSchema: yup.object({
        parkingLotName: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        parkingLotTypeName: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        ownerName: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        address1: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        address2: yup.string(),
        city: yup.string(),

        pincode: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        latitude: yup.number(),
        longitude: yup.number(),
        phoneNumber: yup
          .string()
          .matches(/^\d{10}$/, "Mobile number must be 10 digits")
          .when(["pages"], {
            is: (pages: number) => pages === 1,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        parkingLotPhoneNumber: yup
          .string()
          .matches(/^\d{10}$/, "Mobile number must be 10 digits")
          .when(["pages"], {
            is: (pages: number) => pages === 3,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.notRequired(),
          }),
        contactName: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        password: yup.string().when(["pages"], {
          is: (pages: number) => pages === 3,
          then: (schema) =>
            schema
              .min(6, "* Minimum 6 digit required")
              .length(6, "Password must be 6 digits")
              .required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        email: yup.string().email(),
        hasReservation: yup.boolean(),
        surfaceStatus: yup.number(),
        interchangeable: yup.boolean(),
        areaInSquareFeet: yup.number(),
        lengthInFeet: yup.number(),
        widthInFeet: yup.number(),
        profilePictureUrl: yup.string().url(),
        name: yup.string().when(["pages"], {
          is: (pages: number) => pages === 4,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        nameType: yup.string().when(["pages"], {
          is: (pages: number) => pages === 4,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        companyAddress1: yup.string().when(["pages"], {
          is: (pages: number) => pages === 4,
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        companyAddress2: yup.string(),
        companyCity: yup.string(),
        companyPincode: yup.string(),
        companyType: yup.number(),
        // ownerId: yup.string(),
        invitation: yup.string().when(["pages"], {
          is: (pages: number) => pages === 1,
          then: (schema) =>
            schema
              .min(6, "* Minimum 6 digit required")
              .length(6, "Invitation code must be 6 digits")
              .required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
        pinNumber: yup.number(),
        OTP: yup.string().when(["pages"], {
          is: (pages: number) => pages === 2,
          then: (schema) =>
            schema
              .min(4, "* Minimum 4 digit required")
              .length(4, "OTP must be 4 digits")
              .required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      }),
      onSubmit: async (values, { resetForm }) => {
        if (values.pages === 1) {
          let params = {
            phoneNumber: formik.values.phoneNumber,
            invitation: formik.values.invitation,
            OTP: formik.values.OTP,
          };

          dispatch(VerifyInvitationOtp(params))
            .then((data: any) => {
              if (data.payload.status) {
                handleNext();
                values.pages = values.pages + 1;
              } else {
                updateToastMessage(
                  true,
                  data.payload.message.message,
                  "warning"
                );
              }
            })
            .catch((error) => {
              console.error("Error occurred:", error.message);
            });
          return;
        }
        if (values.pages === 2) {
          let params = {
            phoneNumber: formik.values.phoneNumber,
            invitation: formik.values.invitation,
            OTP: formik.values.OTP,
          };

          dispatch(OtpVerification(params))
            .then((data: any) => {
              if (data.payload.status === true) {
                updateToastMessage(true, data.payload.message, "success");
                handleNext();
                values.pages = values.pages + 1;
              } else {
                updateToastMessage(true, data.payload.message, "warning");
              }
            })
            .catch((error) => {
              console.error("Error occurred:", error.message);
            });
          return;
        }

        if (values.pages === 3) {
          handleNext();
          values.pages = values.pages + 1;
          return;
        }

        if (values.pages === 4) {
          handleNext();
          values.pages = values.pages + 1;
          return;
        }

        const filteredGenericVehicle = values.genericVehicleList.filter(
          (vehicle) => Object.keys(vehicle).includes("vehicleCount")
        );

        const parkingLotRegister: ParkingLotData = {
          parkingLotData: {
            parkingLotName: values.parkingLotName,
            parkingLotTypeId: values.parkingLotTypeId,
            parkingLotTypeName: values.parkingLotTypeName,
            ownerName: values.ownerName,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            stateId: values.stateId,
            stateName: values.stateName,
            pincode: values.pincode,
            latitude: values.latitude,
            longitude: values.longitude,
            contactName: values.contactName,
            email: values.email,
            hasReservation: values.hasReservation,
            surfaceStatus: values.surfaceStatus,
            interchangeable: values.interchangeable,
            areaInSquareFeet: values.areaInSquareFeet,
            lengthInFeet: values.lengthInFeet,
            widthInFeet: values.widthInFeet,
            profilePictureUrl: values.profilePictureUrl,
            mobile: values.parkingLotPhoneNumber,
          },
          companyData: {
            name: values.name,
            nameType: values.nameType,
            companyType: values.companyType,
            // ownerId: values.ownerId,
            address1: values.companyAddress1,
            address2: values.companyAddress2,
            city: values.companyCity,
            stateId: values.companyStateId,
            stateName: values.companyStateName,
            pincode: values.companyPincode,
          },
          lotAttachments: [],
          vehicleCapacity: filteredGenericVehicle,
          pinNumber: values.password,
          phoneNumber: values.phoneNumber,
        };
        // console.log("Submitting data:", values, parkingLotRegister);
        await dispatch(CreateParkingLotRegister(parkingLotRegister)).then(
          (data: any) => {
            if (data.payload.status) {
              navigate("/dashboard");
            }
          }
        );
        resetForm();
      },
    });

  return (
    <Box sx={background}>
      <Container>
        <ParkingLotAppBar />
        <Stepper
          sx={{ margin: "20px 0" }}
          activeStep={activeStep}
          alternativeLabel
        >
          <Step key="Invitations">
            <StepLabel>Register User</StepLabel>
          </Step>
          <Step key="OTP Verification">
            <StepLabel>OTP Verification</StepLabel>
          </Step>
          <Step key="ParkingLotDetails">
            <StepLabel>Parking Lot Details</StepLabel>
          </Step>
          <Step key="CompanyDetails">
            <StepLabel>Company Details</StepLabel>
          </Step>
          <Step key="VehicleCapacity">
            <StepLabel>Vehicle Capacity</StepLabel>
          </Step>
        </Stepper>
        <form onSubmit={formik.handleSubmit}>
          {activeStep === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RegisterForm formik={formik} />
              </Grid>
              <ToastMessage
                showToastMessage={toastMessage.showMessage}
                setToastMessage={setToastMessage}
                message={toastMessage.message}
                alertType={toastMessage.alertType}
              />
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OTPVerification formik={formik} />
                <ToastMessage
                  showToastMessage={toastMessage.showMessage}
                  setToastMessage={setToastMessage}
                  message={toastMessage.message}
                  alertType={toastMessage.alertType}
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ParkingLotDetailsReportForm formik={formik} />
              </Grid>
            </Grid>
          )}
          {activeStep === 3 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CompanyDetailsForm formik={formik} />
              </Grid>
            </Grid>
          )}
          {activeStep === 4 && (
            <Grid container spacing={2} marginTop={"20px"}>
              <Grid item xs={12}>
                <VehicleCapacityForm formik={formik} />
              </Grid>
            </Grid>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            {activeStep !== 0 && activeStep !== 2 && (
              <Button
                variant="contained"
                onClick={handleBack}
                sx={{ mt: 3, mr: 1 }}
              >
                Back
              </Button>
            )}
            {activeStep === 0 && (
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 3 }}
              >
                Next
              </Button>
            )}
            {activeStep === 1 && (
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 3 }}
              >
                Next
              </Button>
            )}
            {activeStep === 2 && (
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 3 }}
              >
                Next
              </Button>
            )}
            {activeStep === 3 && (
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 3 }}
              >
                Next
              </Button>
            )}
            {activeStep === 4 ? (
              <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                Submit
              </Button>
            ) : null}
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default ParkingLotRegisterForm;
