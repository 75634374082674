export interface DateRange {
  fromDate: Date;
  toDate: Date;
}

export const getLastWeekDateRange = (): DateRange => {
  const today = new Date();
  const lastWeekStartDate = new Date(today);
  lastWeekStartDate.setDate(today.getDate() - today.getDay() - 7);
  lastWeekStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  const lastWeekEndDate = new Date(today);
  lastWeekEndDate.setDate(today.getDate() - today.getDay() - 1);
  lastWeekEndDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
  return { fromDate: lastWeekStartDate, toDate: lastWeekEndDate };
};

export const getThisWeekDateRange = (): DateRange => {
  const today = new Date();
  const thisWeekStartDate = new Date(today);
  thisWeekStartDate.setDate(today.getDate() - today.getDay());
  thisWeekStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  const thisWeekEndDate = new Date(today);
  thisWeekEndDate.setDate(today.getDate() - today.getDay() + 6);
  thisWeekEndDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
  return { fromDate: thisWeekStartDate, toDate: thisWeekEndDate };
};

export const getThisMonthDateRange = (): DateRange => {
  const today = new Date();
  const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  thisMonthStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  const thisMonthEndDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );
  thisMonthEndDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
  return { fromDate: thisMonthStartDate, toDate: thisMonthEndDate };
};

export const getLastMonthDateRange = (): DateRange => {
  const today = new Date();
  const lastMonthStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  lastMonthStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  lastMonthEndDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
  return { fromDate: lastMonthStartDate, toDate: lastMonthEndDate };
};

export const getYesterdayDateRange = (): DateRange => {
  const today = new Date();
  const yesterdayStartDate = new Date(today);
  yesterdayStartDate.setDate(today.getDate() - 1);
  yesterdayStartDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

  const yesterdayEndDate = new Date(today);
  yesterdayEndDate.setDate(today.getDate() - 1);
  yesterdayEndDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999

  return { fromDate: yesterdayStartDate, toDate: yesterdayEndDate };
};
