import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Container, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { FormikProps } from "formik";
import { ParkingLotRegisterFormikInputs } from "./ParkingLotRegisterForm";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { GetParkingLotType } from "../../Redux/Thunks/ParkingLotThunk";
import Loading from "../../components/Loading/Loading";

interface Props {
  formik: FormikProps<ParkingLotRegisterFormikInputs>;
}

const CompanyDetailsForm = (props: Props) => {
  const { formik } = props;

  const handleStateChange = (state: any) => {
    formik.setFieldValue("companyStateId", state.stateId);
    formik.setFieldValue("companyStateName", state.stateName);
  };

  const handleChange = (event: SelectChangeEvent) => {
    formik.setFieldValue("nameType", event.target.value);
  };

  const [type, setType] = React.useState("");

  const handleCompanyTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
    formik.setFieldValue("companyType", event.target.value);
  };

  const dispatch = useAppDispatch();

  const { parkingLotType, isLoading } = useAppSelector(
    (state) => state.parkinglot
  );

  useEffect(() => {
    dispatch(GetParkingLotType());
  }, []);
  return (
    <Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Company Name"
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              {...formik.getFieldProps("name")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Name Type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Name type"
                value={formik.values.nameType}
                onChange={handleChange}
                error={
                  formik.touched.nameType && Boolean(formik.errors.nameType)
                }
              >
                <MenuItem value={"Self"}>Self</MenuItem>
                <MenuItem value={"Company"}>Company</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address Line 1"
              error={
                formik.touched.companyAddress1 &&
                Boolean(formik.errors.companyAddress1)
              }
              helperText={
                formik.touched.companyAddress1 && formik.errors.companyAddress1
              }
              {...formik.getFieldProps("companyAddress1")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address Line 2"
              error={
                formik.touched.companyAddress2 &&
                Boolean(formik.errors.companyAddress2)
              }
              helperText={
                formik.touched.companyAddress2 && formik.errors.companyAddress2
              }
              {...formik.getFieldProps("companyAddress2")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              error={
                formik.touched.companyCity && Boolean(formik.errors.companyCity)
              }
              helperText={
                formik.touched.companyCity && formik.errors.companyCity
              }
              {...formik.getFieldProps("companyCity")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select state
              </InputLabel>
              <Select
                fullWidth
                labelId="state-select-label"
                id="state-select"
                label="Select state"
                value={formik.values.companyStateName}
              >
                {Object.keys(parkingLotType).length > 0 &&
                  parkingLotType.states.length > 0 &&
                  parkingLotType.states.map((state, index) => (
                    <MenuItem
                      key={index}
                      value={state.stateName}
                      onClick={() => handleStateChange(state)}
                    >
                      {state.stateName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Pincode"
              error={
                formik.touched.companyPincode &&
                Boolean(formik.errors.companyPincode)
              }
              helperText={
                formik.touched.companyPincode && formik.errors.companyPincode
              }
              {...formik.getFieldProps("companyPincode")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">CompanyType</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="CompanyType"
                onChange={handleCompanyTypeChange}
              >
                <MenuItem value={1}>proprietary</MenuItem>
                <MenuItem value={2}>partnership</MenuItem>
                <MenuItem value={3}>pvt ltd companies</MenuItem>
              </Select>
            </FormControl>
            <Loading openModal={isLoading} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyDetailsForm;
