import { AlertColor, Box, Button, Chip, Grid, Popover, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { ToastMessage, ToastMessageState } from '../../../components/ToastMessage/ToastMessage';
import { RolesModal } from '../../../Model/Settings/AccountModal';
import { Createuser } from '../../../Redux/Thunks/SettingsThunk';
import { GetOtpEntity } from '../../../Model/Settings/AddUser';
import TextBox from '../../../components/TextBox/TextBox';
import Loading from '../../../components/Loading/Loading';
import theme from '../../../Theme/Theme';

function GetOtp(props: GetOtpEntity) {

    const { mobile, setOtpSended } = props;
    const { Roles, Account, isLoading } = useAppSelector((state: any) => state.settings);

    const [selectedRole, setSelectedRole] = useState<RolesModal>({
        id: ''
        , name: ''
    });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [toastMessage, setToastMessage] = useState<ToastMessageState>({
        showMessage: false,
        message: "",
        alertType: "success",
    });
    const [supervisor, setSuperVisor] = useState<any>();
    const [entry, setEntry] = useState<any>();
    const [exit, setExit] = useState<any>();
    const [both, setBoth] = useState<any>();
    const [selectedName, setSelectedName] = useState<any>('');

    useEffect(() => {
        setEntry(Roles.find((role: any) => role.name === 'Entry'));
        setExit(Roles.find((role: any) => role.name === 'Exit'));
        setBoth(Roles.find((role: any) => role.name === 'Both'));
        setSuperVisor(Roles.find((role: any) => role.name === 'Supervisor'));
    }, [Roles]);

    const dispatch = useAppDispatch();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (anchorEl === null) {
            setAnchorEl(document.getElementById('chip-anchor'));
        } else {
            setAnchorEl(null);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popOverID = open ? 'simple-popover' : undefined;

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            otp: "",
            pin: ""
        },
        validationSchema: yup.object({
            otp: yup
                .string()
                .min(4, "* Minimum 4 digit required")
                .max(4, '* Can`t be more than 4 digits')
                .matches(/^\d{4}$/, "OTP must be 4 digits")
                .required("* OTP required"),
            pin: yup
                .string()
                .required("* Pin is required")
                .min(6, "* Minimum 6 digit required")
                .max(6, '* Can`t be more than 6 digits')
                .matches(/^\d{6}$/, "Pin number must be 6 digits"),
        }),
        onSubmit: async (values: any, { resetForm }: any) => {
            if (!selectedRole.id) {
                updateToastMessage(true, 'Select role to continue!', "error");
                return;
            }
            let params = {
                FirstName: values.firstName,
                LastName: values.lastName,
                PhoneNumber: mobile,
                IsActive: true,
                OTPNumber: values.otp,
                PinNumber: values.pin,
                RoleId: selectedRole.id,
                ParkingLotId: Account.parkingLotDetails.parkingLotId
            };
            dispatch(Createuser(params)).then((data: any) => {
                if (data.meta.requestStatus !== "fulfilled") {
                    updateToastMessage(true, "Connection Refused", "info");
                    return;
                }
                if (data.payload.status == 204) {
                    updateToastMessage(true, "No data found", "info");
                    return;
                }
                if (data.payload.status) {
                    updateToastMessage(true, data.payload, "error");
                    setOtpSended(false)
                } else {
                    updateToastMessage(true, data.payload, "error");
                }
            })
        },
    });
    const handleSelectedChip = (role: RolesModal) => {
        setSelectedRole(role);
        if (role.id !== '89f6b26a-faff-4e85-91b4-a0be88b060ec') {
            setSelectedName(role.name)
        }
        else {
            setSelectedName('')
        }
        handleClose();
    }

    const updateToastMessage = (
        showMessage: boolean,
        message: string,
        alertType: AlertColor
    ) => {
        setToastMessage({ showMessage, message, alertType });
    };
    return (
        <Grid container  >
            <Grid item md={1} xs={1} />
            <Grid item md={10} xs={11.5}>
                <TextBox
                    type='text'
                    error={Boolean(formik.touched.firstName) && Boolean(formik.errors.firstName)}
                    formik={formik} label='First Name'
                    helpertext={formik.touched.firstName ? String(formik.errors.firstName) : ""}
                    fieldpropsName={'firstName'}
                />
            </Grid>
            <Grid item md={1} />
            <Grid item md={1} />
            <Grid item md={10} xs={11.5}>
                <TextBox
                    type='text'
                    error={Boolean(formik.touched.lastName) && Boolean(formik.errors.lastName)}
                    formik={formik} label='Last Name'
                    helpertext={formik.touched.lastName ? String(formik.errors.lastName) : ""}
                    fieldpropsName={'lastName'}
                />
            </Grid>
            <Grid item md={1} />
            <Grid item md={1} />
            <Grid md={10} display='flex' sx={{ padding: '7px 0px' }}>
                <Typography >
                    Roles
                </Typography>
                <div className='role-chip-container' >
                    <Chip style={{ width: '98px' }} label={supervisor && supervisor.name} onClick={() => handleSelectedChip(supervisor)} color='primary' variant={selectedRole.id && selectedRole.id === supervisor.id ? 'filled' : 'outlined'} />
                    <Chip
                        style={{ width: '98px' }}
                        label={'Employee'}
                        id="chip-anchor"
                        onClick={(e) => handleClick(e)}
                        color='primary'
                        variant={
                            selectedRole.id && selectedRole.id === entry.id ||
                                selectedRole.id && selectedRole.id === exit.id ||
                                selectedRole.id && selectedRole.id === both.id ? 'filled' : 'outlined'
                        }
                    />
                    <Popover
                        id={popOverID}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className='inner-chip-container'>
                            <Chip style={{ width: '80px', margin: '0px 5px' }} label={entry && entry.name} onClick={() => handleSelectedChip(entry)} color='primary' variant={selectedRole.id && selectedRole.id === entry.id ? 'filled' : 'outlined'} />
                            <Chip style={{ width: '80px', margin: '0px 5px' }} label={exit && exit.name} onClick={() => handleSelectedChip(exit)} color='primary' variant={selectedRole.id && selectedRole.id === exit.id ? 'filled' : 'outlined'} />
                            <Chip style={{ width: '80px', margin: '0px 5px' }} label={both && both.name} onClick={() => handleSelectedChip(both)} color='primary' variant={selectedRole.id && selectedRole.id === both.id ? 'filled' : 'outlined'} />
                        </div>
                    </Popover>
                    <Typography color={theme.palette.primary.main}>{selectedName}</Typography>
                </div>
            </Grid>
            <Box sx={{ width: '100%' }}>
                <Grid container spacing={0.5}>
                    <Grid item md={1} xs={0} />
                    <Grid item md={5} xs={5.7}>
                        <TextBox
                            type='text'
                            error={Boolean(formik.touched.otp) && Boolean(formik.errors.otp)}
                            formik={formik} label='OTP'
                            helpertext={formik.touched.otp ? String(formik.errors.otp) : ""}
                            fieldpropsName={'otp'}
                        />
                    </Grid>
                    <Grid item md={5} xs={5.7}>
                        <TextBox
                            type='text'
                            error={Boolean(formik.touched.pin) && Boolean(formik.errors.pin)}
                            formik={formik} label='Pin'
                            helpertext={formik.touched.pin ? String(formik.errors.pin) : ""}
                            fieldpropsName={'pin'}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Grid container>
                    <Grid xs={4} />
                    <Grid item md={3} xs={3} >
                        <Button sx={{
                            height: '40px',
                            width: '100%',
                            marginLeft: '15%',
                            marginTop: '8%'
                        }}
                            variant='contained'
                            onClick={() => formik.handleSubmit()}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <ToastMessage
                showToastMessage={toastMessage.showMessage}
                setToastMessage={setToastMessage}
                message={toastMessage.message}
                alertType={toastMessage.alertType}
            // placement={{ vertical: "top", horizontal: "left" }}
            />
            <Loading openModal={isLoading} />
        </Grid>

    )
}

export default GetOtp
