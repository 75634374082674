import { Box, Chip, Collapse, Divider, Typography, useTheme } from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import FixedPlanDayInputs from "./FixedPlanDayInputs";
import FixedPlanHourInputs from "./FixedPlanHourInputs";
import FixedPlanWeekInputs from "./FixedPlanWeekInputs";
import FixedPlanHourInputsWeekEnd from "./FixedPlanHourInputWeekEnd";
import FixedPlanDayInputsWeekEnd from "./FixedPlanDayInputsWeekEnd";
import FixedPlanWeekInputsWeekEnd from "./FixedPlanWeekInputsWeekEnd";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
}

const FixedPlanInputs = ({ formik }: Props) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column">
      {formik.values.IsWeekdayOrWeekendPlan &&
        <Box sx={{ marginBottom: '5px', marginTop: '4px' }}>
          <Divider />

          <Typography variant="body1" sx={{ fontWeight: "600" }}>
            Week Days Details
          </Typography>
          <Divider />
        </Box>
      }
      <Collapse in={formik.values.scheduleType === "hour"}>
        <FixedPlanHourInputs formik={formik} />
        {formik.values.IsWeekdayOrWeekendPlan && <FixedPlanHourInputsWeekEnd formik={formik} />}
      </Collapse>
      <Collapse in={formik.values.scheduleType === "day"}>
        <FixedPlanDayInputs formik={formik} />
        {formik.values.IsWeekdayOrWeekendPlan && <FixedPlanDayInputsWeekEnd formik={formik} />}
      </Collapse>
      <Collapse in={formik.values.scheduleType === "week"}>
        <FixedPlanWeekInputs formik={formik} />
        {formik.values.IsWeekdayOrWeekendPlan && <FixedPlanWeekInputsWeekEnd formik={formik} />}
      </Collapse>
    </Box>
  );
};

export default FixedPlanInputs;
