import { Box, Button, Card, ImageListItem, Typography } from "@mui/material";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import PlanIcon from "../../../assets/Plan_Icon.png";
import ScheduleIcon from "../../../assets/Schedule_Icon.png";
import PlanAnimation from "../../../assets/Plan_Animation.json";

const CreateButtonsCard = () => {
  const navigate = useNavigate();

  const onClickCreatePlan = () => {
    navigate("/plan/add");
  };

  const onClickCreateSchedule = () => {
    navigate("/schedule/add");
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "15px",
        background:
          "linear-gradient(276deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
        minHeight: "30vh",
        marginBottom: "16px",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="60%"
        margin="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          onClick={onClickCreatePlan}
          style={{
            background:
              "linear-gradient(to right, rgba(132, 135, 3, 1) 30%,  rgba(246, 183, 23, 1) 100%)",
            padding: "15px 40px",
            borderRadius: "0px 58px 0px 58px",
            alignSelf: "stretch",
            margin: "10px",
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <ImageListItem style={{ height: "40px" }}>
              <img src={PlanIcon} alt="Plan" />
            </ImageListItem>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              sx={{ padding: "0 30px" }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  margin: 0,
                }}
              >
                Plan
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  color: "#FFFFFF",
                  margin: 0,
                }}
              >
                Create Price Details
              </Typography>
            </Box>
          </Box>
        </Button>
        <Button
          onClick={onClickCreateSchedule}
          style={{
            background:
              "linear-gradient(to right, rgba(237, 104, 202, 1) 30%,  rgba(231, 106, 211, 1) 100%)",
            padding: "15px 40px",
            borderRadius: "0px 58px 0px 58px",
            alignSelf: "stretch",
            margin: "10px",
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <ImageListItem style={{ height: "40px" }}>
              <img src={ScheduleIcon} alt="Schedule" />
            </ImageListItem>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              sx={{ padding: "0 30px" }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  margin: 0,
                }}
              >
                Schedule
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  color: "#FFFFFF",
                  margin: 0,
                }}
              >
                Get the plan into Action
              </Typography>
            </Box>
          </Box>
        </Button>
      </Box>
    </Card>
  );
};

export default CreateButtonsCard;
