import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import { Today } from "@mui/icons-material";
import { ClockIcon } from "@mui/x-date-pickers";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
}

const FixedPlanDayInputs = ({ formik }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" flexWrap="wrap">
        <Typography
          variant="body1"
          sx={{ fontWeight: "600", margin: "10px 5px" }}
        >
          Choose a Day
        </Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <TextField
            variant="outlined"
            label="Day"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Today />
                </InputAdornment>
              ),
            }}
            sx={{ width: "100%" }}
            type="number"
            error={
              formik.touched.scheduleTypeDayValue &&
              Boolean(formik.errors.scheduleTypeDayValue)
            }
            helperText={
              formik.touched.scheduleTypeDayValue
                ? formik.errors.scheduleTypeDayValue
                : ""
            }
            {...formik.getFieldProps("scheduleTypeDayValue")}
          />
          <TextField
            variant="outlined"
            label="Daily Actual Charge"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&#8377;</InputAdornment>
              ),
            }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.actualAmount && Boolean(formik.errors.actualAmount)
            }
            helperText={
              formik.touched.actualAmount ? formik.errors.actualAmount : ""
            }
            {...formik.getFieldProps("actualAmount")}
          />
          <TextField
            variant="outlined"
            label="Daily basic Charge"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&#8377;</InputAdornment>
              ),
            }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.basicAmount && Boolean(formik.errors.basicAmount)
            }
            helperText={
              formik.touched.basicAmount ? formik.errors.basicAmount : ""
            }
            {...formik.getFieldProps("basicAmount")}
          />
          <TextField
            variant="outlined"
            label="Daily Minimum Hour"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ClockIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.dailyMinimumHour &&
              Boolean(formik.errors.dailyMinimumHour)
            }
            helperText={
              formik.touched.dailyMinimumHour
                ? formik.errors.dailyMinimumHour
                : ""
            }
            {...formik.getFieldProps("dailyMinimumHour")}
          />
          {/* <Box display="flex" flexDirection="row" alignItems="center">
            <Chip
              label="24 Hour Day"
              clickable
              color={formik.values.dayChargeType === 1 ? "primary" : "default"}
              onClick={() => formik.setFieldValue("dayChargeType", 1)}
              style={{
                padding: "0 15px",
                margin: "5px 5px",
              }}
            />
            <Chip
              label="Day of year"
              clickable
              color={formik.values.dayChargeType === 2 ? "primary" : "default"}
              onClick={() => formik.setFieldValue("dayChargeType", 2)}
              style={{
                padding: "0 15px",
                margin: "5px 5px",
              }}
            />
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default FixedPlanDayInputs;
