import { AlertColor, Box, Grid } from "@mui/material";
import PlansCard from "./PlansCard";
import CreateScheduleForm from "./CreateScheduleForm";
import { FormikProps, useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AddScheduleDetailsModel } from "../../../Model/ScheduleModel";
import { ScheduleType } from "./ScheduleType";
import {
  AddSchedule,
  GetScheduleInitialDetails,
} from "../../../Redux/Thunks/ScheduleThunk";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../../../Redux/Store";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";
import Loading from "../../../components/Loading/Loading";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import formatDate from "../../../utilities/formatDate";

export interface ScheduleFormikInputs {
  scheduleName: string;
  scheduleType: string;
  planId: string;
  effectiveDate: dayjs.Dayjs;
  expiryDate: dayjs.Dayjs;
  // Additional Details
  // normalEffectiveDate: Date | null;
  specialMinimumEffectiveDate: Date | null;
  specialMaximumExpiryDate: Date | null;
  // forcedEffectiveDate: Date | null;
  // forcedExpiryDate: Date | null;
  isSpecialScheduleTypeDisabled: boolean;
  plans: PlanDetailsModel[];
  // Validation details
  normalEffectiveDateDisabled: boolean;
  normalEffectiveDateValue: dayjs.Dayjs;
  normalExpiryDateDisabled: boolean;
  normalExpiryDateValue: dayjs.Dayjs;
  specialEffectiveDateDisabled: boolean;
  specialEffectiveDateValue: dayjs.Dayjs;
  specialExpiryDateDisabled: boolean;
  specialExpiryDateValue: dayjs.Dayjs;
  forcedEffectiveDateDisabled: boolean;
  forcedEffectiveDateValue: dayjs.Dayjs;
  forcedExpiryDateDisabled: boolean;
  forcedExpiryDateValue: dayjs.Dayjs;
}

const CreateSchedule = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const locationState = location.state;

  const { isLoading, createScheduleDetails } = useAppSelector(
    (state) => state.schedule
  );

  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const formik: FormikProps<ScheduleFormikInputs> =
    useFormik<ScheduleFormikInputs>({
      initialValues: {
        scheduleName: "",
        scheduleType: "normal",
        planId: "",
        effectiveDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0))).add(
          1,
          "day"
        ),
        expiryDate: dayjs(new Date(new Date().setHours(0, 0, 0, 0))).add(
          1,
          "month"
        ),
        // Additional Details
        // normalEffectiveDate: null,
        specialMinimumEffectiveDate: null,
        specialMaximumExpiryDate: null,
        // forcedEffectiveDate: null,
        // forcedExpiryDate: null,
        isSpecialScheduleTypeDisabled: true,
        plans: [],
        // Validation Details
        normalEffectiveDateDisabled: true,
        normalEffectiveDateValue: dayjs(new Date()),
        normalExpiryDateDisabled: true,
        normalExpiryDateValue: dayjs(new Date()),
        specialEffectiveDateDisabled: true,
        specialEffectiveDateValue: dayjs(new Date()),
        specialExpiryDateDisabled: true,
        specialExpiryDateValue: dayjs(new Date()),
        forcedEffectiveDateDisabled: true,
        forcedEffectiveDateValue: dayjs(new Date()),
        forcedExpiryDateDisabled: true,
        forcedExpiryDateValue: dayjs(new Date()),
      },
      validationSchema: yup.object({
        // scheduleName: yup
        //   .string()
        //   .min(3, "Schedule name must be at least 3 characters")
        //   .required("Required"),
        scheduleType: yup.string().required("Required"),
        planId: yup.string().required("Required"),
      }),
      onSubmit: async (values) => {
        const schedule: AddScheduleDetailsModel = {
          scheduleName: values.scheduleName,
          scheduleType:
            values.scheduleType === "normal"
              ? ScheduleType.normal
              : values.scheduleType === "special"
                ? ScheduleType.special
                : ScheduleType.forced,
          effectiveDate:
            values.scheduleType === "forced"
              ? new Date()
              : values.effectiveDate.toDate(),
          expiryDate: values.expiryDate
            .add(1, "day")
            .subtract(1, "second")
            .toDate(),
          assignedDate: new Date(),
          planId: values.planId,
        };
        const response = await dispatch(AddSchedule(schedule));
        const createScheduleResult = unwrapResult(response);
        if (createScheduleResult.status) {
          updateToastMessage(true, createScheduleResult.message, "success");
          navigate("/plan");
        } else {
          updateToastMessage(true, createScheduleResult.message, "info");
        }
      },
    });

  const getScheduleInitialDetails = async () => {
    const response = await dispatch(GetScheduleInitialDetails());
    if (!response.payload) {
      updateToastMessage(true, "Connection Refused", "error");
    }
  };
  useEffect(() => {
    getScheduleInitialDetails();
  }, []);

  useEffect(() => {
    const {
      normalEffectiveDate,
      specialMinimumEffectiveDate,
      specialMaximumExpiryDate,
      forcedEffectiveDate,
      forcedExpiryDate,
      unassignedPlans,
    } = createScheduleDetails;
    formik.setValues({
      ...formik.values,
      scheduleType: "normal",
      // normalEffectiveDate,
      specialMinimumEffectiveDate,
      specialMaximumExpiryDate,
      // forcedEffectiveDate,
      // forcedExpiryDate,
      normalEffectiveDateDisabled: normalEffectiveDate !== null,
      normalEffectiveDateValue:
        normalEffectiveDate !== null
          ? dayjs(formatDate(normalEffectiveDate))
          : dayjs(new Date(new Date().setHours(0, 0, 0, 0))).add(1, "day"),
      normalExpiryDateDisabled: false,
      normalExpiryDateValue:
        normalEffectiveDate !== null
          ? dayjs(formatDate(normalEffectiveDate))
            .add(1, "month")
            .subtract(1, "day")
          : dayjs(new Date(new Date().setHours(0, 0, 0, 0))).add(1, "month"),
      specialEffectiveDateDisabled: false,
      specialEffectiveDateValue: dayjs(
        new Date(new Date().setHours(0, 0, 0, 0))
      ),
      specialExpiryDateDisabled: false,
      specialExpiryDateValue: dayjs(new Date(new Date().setHours(0, 0, 0, 0))),
      forcedEffectiveDateDisabled: true,
      forcedEffectiveDateValue:
        forcedEffectiveDate !== null
          ? dayjs(formatDate(forcedEffectiveDate))
          : dayjs(new Date(new Date().setHours(0, 0, 0, 0))),
      forcedExpiryDateDisabled: forcedExpiryDate !== null,
      forcedExpiryDateValue:
        forcedExpiryDate !== null
          ? dayjs(
            formatDate(
              new Date(new Date(forcedExpiryDate).setHours(0, 0, 0, 0))
            )
          )
          : dayjs(new Date(new Date().setHours(0, 0, 0, 0))),
      plans: unassignedPlans,
    });
    if (specialMinimumEffectiveDate === null) {
      formik.setFieldValue("isSpecialScheduleTypeDisabled", true);
    } else {
      formik.setFieldValue("isSpecialScheduleTypeDisabled", false);
    }
  }, [createScheduleDetails]);

  useEffect(() => {
    if (locationState && locationState.planId) {
      formik.setFieldValue("planId", locationState.planId);
    }
  }, [locationState]);

  useEffect(() => {
    if (formik.touched.planId && formik.errors.planId) {
      updateToastMessage(true, "Choose a Plan to Assign Schedule", "info");
    }
  }, [formik.errors.planId]);

  return (
    <Box display="flex" flexDirection="column" sx={{ minHeight: "90vh" }}>
      <form
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container flexGrow={1}>
          <Grid
            item
            xs={12}
            md={6}
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <PlansCard formik={formik} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <CreateScheduleForm formik={formik} />
          </Grid>
        </Grid>
      </form>
      <Loading openModal={isLoading} />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      />
    </Box>
  );
};

export default CreateSchedule;
