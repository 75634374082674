import PrivateRoute from "./PrivateRoute";
import { layoutProps } from "../Model/LayoutModel";
import Settings from "../pages/Settings/Settings";

export default function SettingsLayout({ children }: layoutProps) {
    return (
        <PrivateRoute>
            <Settings>
                {children}
            </Settings>
        </PrivateRoute>
    )
}