import React from "react";
import {
  Alert,
  Box,
  IconButton,
  Link,
  ListItemIcon,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const contact = {
  borderRadius: "20px",
  background: "transparent",
  boxShadow: "0px 10px 15px 0px rgba(46, 37, 134, 0.24)",
  outline: "1px solid #ccc",
  padding: "10px",
  marginTop: "15px",
};

const parkingLotDetails = {
  color: "#282f79",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 500,
  marginTop: "10px",
};

const header = {
  display: "flex",
  justifyContent: "space-between",
};

const DashboardContact = () => {
  const phoneNumber = "+918925904559";
  const message = "Hello, this is a pre-filled message!";

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  return (
    <Box sx={contact}>
      <Typography sx={parkingLotDetails}>
        Contact us,
        <hr></hr>
      </Typography>
      <Typography variant="h5">
        For any issues and enquiries Please Contact us,
      </Typography>
      <Box sx={header}>
        <Typography variant="h5">
          <IconButton style={{ color: "red" }}>
            <EmailIcon></EmailIcon>
          </IconButton>
          <Link href="mailto:info@pmsquaresoft.com" color="inherit">
            Send Email
          </Link>
        </Typography>
        <Typography variant="h5">
          <IconButton style={{ color: "green" }}>
            <WhatsAppIcon></WhatsAppIcon>
          </IconButton>
          <Link
            href={whatsappLink}
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open WhatsApp
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardContact;
