import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
const url = process.env.REACT_APP_HIVERAPIURL;

export const ListInOrOut = createAsyncThunk(
    "List/InOrOut",
    async (id: any) => {
        try {
            let response = await axios.get(
                `${url}api/Token/getInOrOut/${id}`,
                await getHeaderToken()
            );
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const ListAttachments = createAsyncThunk(
    "List/attachmenst",
    async (id: any) => {
        try {
            let response = await axios.get(
                `${url}api/Token/get-token-attachments/${id}`,
                await getHeaderToken()
            );
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    }
);
