import {
  AlertColor,
  Box,
  Button,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import blockAnimation from "../../../assets/block3.json";
import * as yup from "yup";

import { useFormik } from "formik";
import TextBox from "../../../components/TextBox/TextBox";
import Lottie from "react-lottie";
import { useAppDispatch, useAppSelector } from "../../../Redux/Store";
import { TheftParams, TheftInitialValues } from "../../../Model/BlockModal";
import {
  AddBlockAndTheft,
  GetByIdBlockOrTheft,
  UpdateBlockAndTheft,
} from "../../../Redux/Thunks/Block";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";
import { useSearchParams } from "react-router-dom";

function Theft() {
  const { parkingLot }: any = useAppSelector((state) => state.profile);
  const { block }: any = useAppSelector((state: any) => state.block);

  const [checked, setChecked] = useState(false);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const [searchParams] = useSearchParams();

  let id = searchParams.get("id");
  let type = searchParams.get("type");
  let parkingLotId = searchParams.get("parkingLotId");

  const containerRef = useRef<HTMLElement>(null);

  const dispatch = useAppDispatch();

  const handleChange = () => {
    setChecked(true);
  };
  useEffect(() => {
    handleChange();
  }, []);

  useEffect(() => {
    if (parkingLotId != null) {
      let params = {
        id: id,
        type: type,
        parkingLotId: parkingLotId,
      };
      dispatch(GetByIdBlockOrTheft(params));
    }
  }, [parkingLotId]);

  useEffect(() => {
    if (Object.keys(block).length > 0) {
      formiktheft.setFieldValue("registerNumber", block.registerNumber);
      formiktheft.setFieldValue("stationMobile", block.stationMobile);
      formiktheft.setFieldValue("stationName", block.stationName);
      formiktheft.setFieldValue("description", block.description);
    }
  }, [block]);

  const defaultOptions = {
    loop: true,
    // autoplay: true,
    animationData: blockAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const initialValues: TheftInitialValues = {
    registerNumber:
      id && Object.keys(block).length > 0 ? block.registerNumber : "",
    stationMobile: "",
    stationName: "",
    description: "",
  };

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const formiktheft = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      registerNumber: yup
        .string()
        .matches(
          /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
          "Invalid Vehicle Number - XX00XX0000"
        )
        .transform((value: string) => (value ? value.toUpperCase() : value))
        .required("Required"),
      stationMobile: yup
        .string()
        .matches(/^\d{10}$/, "Mobile number must be 10 digits")
        .transform((value: string) => (value ? value.toUpperCase() : value))
        .required("Required"),
    }),
    onSubmit: async (values: TheftInitialValues, { resetForm }: any) => {
      let params: TheftParams = {
        id: id && Object.keys(block).length > 0 ? block.id : null,
        registerNumber: values.registerNumber.toUpperCase(),
        StationName: values.stationName,
        stationMobile: values.stationMobile,
        description: values.description,
        parkingLotId: parkingLot ? parkingLot.parkingLotId : null,
        type: 2,
      };

      if (id != null) {
        dispatch(UpdateBlockAndTheft(params)).then((data: any) => {
          if (data.meta.requestStatus !== "fulfilled") {
            updateToastMessage(true, "Connection Refused", "info");
            return;
          }
          if (data.payload.data.status == 204) {
            updateToastMessage(true, "No data found", "info");
            return;
          }
          if (data.payload.status) {
            updateToastMessage(true, data.payload.data.message, "success");
            resetForm();
            formiktheft.setFieldValue("registerNumber", "");
            id = null;
            type = null;
            parkingLotId = null;
          } else {
            updateToastMessage(true, data.payload.message, "error");
          }
        });
        return;
      }
      dispatch(AddBlockAndTheft(params)).then((data: any) => {
        if (data.meta.requestStatus !== "fulfilled") {
          updateToastMessage(true, "Connection Refused", "info");
          return;
        }
        if (data.payload.data.status == 204) {
          updateToastMessage(true, "No data found", "info");
          return;
        }
        if (data.payload.data.status) {
          updateToastMessage(true, data.payload.data.message, "success");
          resetForm()
        } else {
          updateToastMessage(true, data.payload.message, "error");
        }
      });
    },
  });

  const vehicleNo = (
    <Box sx={{ width: "100%" }}>
      <TextBox
        type="text"
        error={
          Boolean(formiktheft.touched.registerNumber) &&
          Boolean(formiktheft.errors.registerNumber)
        }
        formik={formiktheft}
        label="Vehicle Number"
        helpertext={
          formiktheft.touched.registerNumber
            ? String(formiktheft.errors.registerNumber)
            : ""
        }
        fieldpropsName={"registerNumber"}
        upperCase={true}
      />
    </Box>
  );

  const stationName = (
    <Box sx={{ width: "100%" }}>
      <TextBox
        type="text"
        error={
          Boolean(formiktheft.touched.stationName) &&
          Boolean(formiktheft.errors.stationName)
        }
        formik={formiktheft}
        label="Station Name"
        helpertext={
          formiktheft.touched.stationName
            ? String(formiktheft.errors.stationName)
            : ""
        }
        fieldpropsName={"stationName"}
      />
    </Box>
  );

  const mobile = (
    <Box sx={{ width: "100%" }}>
      <TextBox
        type="text"
        error={
          Boolean(formiktheft.touched.stationMobile) &&
          Boolean(formiktheft.errors.stationMobile)
        }
        formik={formiktheft}
        label="Station Mobile Number"
        helpertext={
          formiktheft.touched.stationMobile
            ? String(formiktheft.errors.stationMobile)
            : ""
        }
        fieldpropsName={"stationMobile"}
      />
    </Box>
  );

  const description = (
    <Box sx={{ width: "100%" }}>
      <TextBox
        type="text"
        error={
          Boolean(formiktheft.touched.description) &&
          Boolean(formiktheft.errors.description)
        }
        formik={formiktheft}
        label="Description"
        helpertext={
          formiktheft.touched.description
            ? String(formiktheft.errors.description)
            : ""
        }
        fieldpropsName={"description"}
      />
    </Box>
  );
  const buttons = (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid xs={4} />
        <Grid item md={3} xs={3}>
          <Button
            sx={{
              height: "40px",
              width: "100%",
              marginLeft: "15%",
              marginTop: "8%",
            }}
            variant="contained"
            onClick={() => formiktheft.handleSubmit()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
  const heading = (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4">{id ? "Update " : "Add to "}Theft</Typography>
    </Box>
  );
  const wholeJsx = (
    <Grid container gap={2} alignItems="center">
      <Grid item md={0.4} />
      <Grid item xs={0} md={3} display={{ xs: "none", sm: "none", md: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Lottie
            style={{
              height: "110%",
              width: "110%",
              margin: "0 auto",
            }}
            options={defaultOptions}
          />
        </Box>
      </Grid>
      <Grid item md={0.8} />
      <Grid item xs={11.6} md={7}>
        <Box
          sx={{ p: 2, height: "100%", alignItems: "center" }}
          ref={containerRef}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{ p: 2, height: 60, overflow: "hidden" }}
                ref={containerRef}
              >
                <Slide
                  in={checked}
                  container={containerRef.current}
                  direction="up"
                  timeout={900}
                >
                  {heading}
                </Slide>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {/* <Slide in={checked} container={containerRef.current} direction='left' timeout={700} > */}
              {vehicleNo}
              {/* </Slide> */}
              {/* <Slide in={checked} container={containerRef.current} direction='right' timeout={1250}> */}
              {mobile}
              {/* </Slide> */}
              {/* <Slide in={checked} container={containerRef.current} direction='left' timeout={700} > */}
              {stationName}
              {/* </Slide> */}
              {/* <Slide in={checked} container={containerRef.current} direction='left' timeout={1750} > */}
              {description}
              {/* </Slide>
                                <Slide in={checked} container={containerRef.current} direction='right' timeout={2100} > */}
              {buttons}
              {/* </Slide> */}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      // placement={{ vertical: "top", horizontal: "left" }}
      />
    </Grid>
  );

  return (
    <Slide
      in={checked}
      container={containerRef.current}
      direction="right"
      timeout={500}
    >
      {wholeJsx}
    </Slide>
  );
}

export default Theft;
