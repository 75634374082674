import { Box, Container, Grid, TextField, Chip, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormikProps } from "formik";
import { ParkingLotRegisterFormikInputs } from "./ParkingLotRegisterForm";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { GetGenericVehicle } from "../../Redux/Thunks/ParkingLotThunk";
import Loading from "../../components/Loading/Loading";

interface Props {
  formik: FormikProps<ParkingLotRegisterFormikInputs>;
}

const VehicleCapacityForm = (props: Props) => {
  const { formik } = props;
  const { parkingLotType, isLoading } = useAppSelector(
    (state) => state.parkinglot
  );

  const [newGetGenericVehicle, setNewGetGenericVehicle]: any = useState();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewGetGenericVehicle(parkingLotType.genericVehicleTypes);
  }, [parkingLotType.genericVehicleTypes]);

  useEffect(() => {
    dispatch(GetGenericVehicle());
  }, []);

  const handleChange = (value: string, genericVehicleTypeId: string) => {
    const genericVehicle = newGetGenericVehicle.map((vehicle: any) => {
      if (vehicle.genericVehicleTypeId === genericVehicleTypeId) {
        return { ...vehicle, vehicleCount: value };
      }
      return vehicle;
    });
    setNewGetGenericVehicle(genericVehicle);
    formik.setFieldValue("genericVehicleList", genericVehicle);
  };

  return (
    <Box>
      <Container>
        <Grid container spacing={2}>
          {Object.keys(parkingLotType).length > 0 &&
            parkingLotType.genericVehicleTypes.length > 0 &&
            parkingLotType.genericVehicleTypes.map((vehicle, index) => {
              return (
                <Grid xs={12} sm={6} key={vehicle.genericVehicleTypeId}>
                  <Box>
                    <Grid container>
                      <Grid xs={12} md={2}>
                        <Avatar sx={{ width: "50px", height: "40px" }}>
                          {index + 1}
                        </Avatar>
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Chip
                          label={vehicle.vehicleTypeName}
                          clickable
                          color={"primary"}
                        />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <Box width={"220px"} padding={"15px"}>
                          <TextField
                            sx={{ marginTop: "-35px" }}
                            fullWidth
                            label={"Vehicle Capacity Count"}
                            variant="standard"
                            onChange={(event) =>
                              handleChange(
                                event.target.value,
                                vehicle.genericVehicleTypeId
                              )
                            }
                            value={vehicle.vehicleCount}
                          />
                        </Box>
                        <Loading openModal={isLoading} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Box>
  );
};

export default VehicleCapacityForm;
