import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
import { ApiResponse } from "../utils/ApiResponse";
import { AddTokenModel, customerAutoCompleteModel, getCustomerAutoCompleteModel } from "../../Model/TokenModel";
const url = process.env.REACT_APP_HIVERAPIURL;


export const GetAddTokenInitialDetails = createAsyncThunk<ApiResponse>(
  "Get/InitialDetails",
  async () => {
    try {
      let response = await axios.get(
        `${url}api/Token/GetInitialDetails`,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);

export const CreateToken = createAsyncThunk<ApiResponse, AddTokenModel>(
  "Post/AddToken",
  async (token) => {
    try {
      let response = await axios.post(
        `${url}api/Token/Add`,
        token,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);

export const customerAutoComplete = createAsyncThunk<ApiResponse, getCustomerAutoCompleteModel>(
  'Get/customerAutoComplete',
  async (params) => {
    try {
      let response = await axios.post(`${url}api/Token/PreviousCustomerDetails`, params, await getHeaderToken());
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    }
    catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  });

export const getTicketStatus = createAsyncThunk<ApiResponse, string>(
  'Get/ticketStatus',
  async (props) => {
    try {
      let response = await axios.get(`${url}api/Token/getTicket/${props}`);
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    }
    catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  })
