import {
    Box,
    Chip,
    Divider,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import { Today } from "@mui/icons-material";
import { ClockIcon } from "@mui/x-date-pickers";

interface Props {
    formik: FormikProps<PlanFormikInputs>;
}

const FixedPlanDayInputsWeekEnd = ({ formik }: Props) => {
    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ marginBottom: '10px', marginTop: '18px' }}>
                <Divider />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                    Week End Details
                </Typography>
                <Divider />
            </Box>
            <Box display="flex" flexDirection="column" flexWrap="wrap">
                <Typography
                    variant="body1"
                    sx={{ fontWeight: "600", margin: "10px 5px" }}
                >
                    Choose a Day
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                    <TextField
                        variant="outlined"
                        label="Day"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Today />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: "100%" }}
                        type="number"
                        error={
                            formik.touched.scheduleTypeDayWeekEndValue &&
                            Boolean(formik.errors.scheduleTypeDayWeekEndValue)
                        }
                        helperText={
                            formik.touched.scheduleTypeDayWeekEndValue
                                ? formik.errors.scheduleTypeDayWeekEndValue
                                : ""
                        }
                        {...formik.getFieldProps("scheduleTypeDayWeekEndValue")} />

                    <TextField
                        variant="outlined"
                        label="Daily Actual Charge"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">&#8377;</InputAdornment>
                            ),
                        }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.actualAmountWeekEnd && Boolean(formik.errors.actualAmountWeekEnd)
                        }
                        helperText={
                            formik.touched.actualAmountWeekEnd ? formik.errors.actualAmountWeekEnd : ""
                        }
                        {...formik.getFieldProps("actualAmountWeekEnd")}
                    />
                    <TextField
                        variant="outlined"
                        label="Daily basic Charge"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">&#8377;</InputAdornment>
                            ),
                        }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.basicAmountWeekEnd && Boolean(formik.errors.basicAmountWeekEnd)
                        }
                        helperText={
                            formik.touched.basicAmountWeekEnd ? formik.errors.basicAmountWeekEnd : ""
                        }
                        {...formik.getFieldProps("basicAmountWeekEnd")}
                    />
                    <TextField
                        variant="outlined"
                        label="Daily Minimum Hour"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ClockIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: "50%" }}
                        type="number"
                        error={
                            formik.touched.dailyMinimumHourWeekEnd &&
                            Boolean(formik.errors.dailyMinimumHourWeekEnd)
                        }
                        helperText={
                            formik.touched.dailyMinimumHourWeekEnd
                                ? formik.errors.dailyMinimumHourWeekEnd
                                : ""
                        }
                        {...formik.getFieldProps("dailyMinimumHourWeekEnd")}
                    />
                    {/* <Box display="flex" flexDirection="row" alignItems="center">
            <Chip
              label="24 Hour Day"
              clickable
              color={formik.values.dayChargeType === 1 ? "primary" : "default"}
              onClick={() => formik.setFieldValue("dayChargeType", 1)}
              style={{
                padding: "0 15px",
                margin: "5px 5px",
              }}
            />
            <Chip
              label="Day of year"
              clickable
              color={formik.values.dayChargeType === 2 ? "primary" : "default"}
              onClick={() => formik.setFieldValue("dayChargeType", 2)}
              style={{
                padding: "0 15px",
                margin: "5px 5px",
              }}
            />
          </Box> */}
                </Box>
            </Box>
        </Box>
    );
};

export default FixedPlanDayInputsWeekEnd;
