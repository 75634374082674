import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { layoutProps } from "../../Model/LayoutModel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BlockedContainer({ children }: layoutProps) {
  const [checked, setChecked] = useState(false);
  const [isBlockSelected, setIsBlockSelected] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = () => {
    setChecked(true);
  };

  useEffect(() => {
    handleChange();
  }, []);

  const topLeftButtons = (
    <Box sx={{ width: "100%" }}>
      <Button
        sx={{
          height: "40px",
          width: "106px",
          marginLeft: "15%",
          marginTop: "8%",
        }}
        variant={
          location.pathname === "/blocked/block" ? "contained" : "outlined"
        }
        onClick={() => navigate("/blocked/block")}
      >
        Block
      </Button>
    </Box>
  );

  const topRightButtons = (
    <Box sx={{ width: "100%" }}>
      <Button
        sx={{
          height: "40px",
          width: "106px",
          marginLeft: "15%",
          marginTop: "8%",
        }}
        variant={
          location.pathname === "/blocked/theft" ? "contained" : "outlined"
        }
        onClick={() => navigate("/blocked/theft")}
      >
        Theft
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: 2,
      }}
    >
      <Grid container gap={2} alignItems="center">
        <Grid item md={4.5} padding={{ md: '0px 0px 0px 50px' }}>
          <Tooltip title="Back" placement="bottom">
            <IconButton onClick={() => navigate("/blocked/list")}>
              <ArrowBackIcon color="primary" fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box sx={{ p: 2, width: "100%" }}>
            <Grid container padding="0px 35px">
              <Grid item xs={6}>
                {/* <Slide in={checked} container={containerRef.current} direction='left' timeout={1400}> */}
                {topLeftButtons}
                {/* </Slide> */}
              </Grid>
              <Grid item xs={6}>
                {/* <Slide in={checked} container={containerRef.current} direction='right' timeout={1400}> */}
                {topRightButtons}
                {/* </Slide> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={2} />
        {children}
      </Grid>
    </Box>
  );
}
