import React, { useEffect, useRef, useState } from "react";
import "./TokenExit.css";
import {
  AlertColor,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogTitle,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppDispatch, useAppSelector } from "../../../Redux/Store";
import {
  AllExitDetails,
  ExitDetails,
  ExitToken,
  ResendOTP,
} from "../../../Redux/Thunks/ExitThunk";
import Loading from "../../../components/Loading/Loading";
import NewLabel from "../../../components/NewLabel/NewLabel";
import theme from "../../../Theme/Theme";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import Label from "../../../components/Label/Label";
import dayjs from "dayjs";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { Preview, Print } from "@mui/icons-material";
import { deleteExitData } from "../../../Redux/Slices/ExitSlice";
import { TokenPrintModel } from "../../../Model/TokenModel";
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import OutTokenContent from "../../../components/TokenContent/OutTokenContent";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";
import Edit from "@mui/icons-material/Edit";
import SearchBar from "../../../components/SearchBar/SearchBar";
import formatDate from "../../../utilities/formatDate";
import { ParkinglotSettingsModal, constSettingValues } from "../../../Model/Settings/TokenModal";
import {
  GetAccountDetails,
  getParkinglotSettingsDetails,
} from "../../../Redux/Thunks/SettingsThunk";
import { GetProfileDetails } from "../../../Redux/Thunks/ProfileThunk";
import { AmountCalculation } from "../../../utilities/AmountCalculation/amountCalculation";
import { getDuration, getMinuteDifference } from "../../../utilities/getDuration";
import WarningDialog from "../../../components/Dialog/AlertDialog";
import { unwrapResult } from "@reduxjs/toolkit";
import { SearchValueModel } from "../../../Model/SearchBarModel";
import { IOSSwitch } from "../../../components/Togglebutton/Toggle";
import { getDifferenceInMinutes } from "../../../utilities/AmountCalculation/utilities/dateTimeConversion";

const style = {
  position: "absolute" as "absolute",
  alignItems: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
};

function TokenExit() {
  const amountCalculation = new AmountCalculation();


  const { allExit, exit, isLoading }: any = useAppSelector(
    (state: any) => state.exitReducer
  );

  const { parkingLot, user }: any = useAppSelector(
    (state: any) => state.profile
  );

  const { Account, ParkingLotSettings } = useAppSelector(
    (state: any) => state.settings
  );

  const tokenInPayment = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_key_Token_in_payment
  ) : null;

  const outReceipt = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_Key_Token_out_receipt
  ) : null;

  const enterOtp = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_Key_Token_in_otp
  ) : null;

  const tokenOutPayment = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_Key_Token_out_payment
  ) : null;

  const graceInterval = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_key_Token_grace_interval
  ) : null;

  const graceActive = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_key_Token_grace_active
  ) : null;

  const graceOrActual = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find(
    (element: any) => element.settingKey === constSettingValues.settings_key_Token_grace_both_in_same_button
  ) : null;

  const helmetHour = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
    element.settingKey === constSettingValues.settings_key_Helmet_hour) : null;

  const helmetAmount = ParkingLotSettings.length > 0 ? ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
    element.settingKey === constSettingValues.settings_key_Helmet_amount) : null;

  const dispatch = useAppDispatch();
  const [autosearchData, setAutosearchData] = useState([]);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });
  const [isSearchExist, setSearchExit] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [isAmountEditOpen, setAmountEditOpen] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedChip, setSelectedChip] = useState("cash");
  const [checkBox, setCheckBox] = useState(true);
  const [helmetCheckBox, setHelmetCheckBox] = useState(false);
  const [timeGap, setTimeGap] = useState<string>("");
  const [startPrint, setStartPrint] = useState(false);
  const [amountToCollect, setAmountToCollect] = useState<any>();
  const [tempAmountTextBox, setTempAmountTextBox] = useState(0);
  const { permissions } = useAppSelector((state) => state.profile);
  const printRef = useRef<HTMLDivElement>(null);
  const [otpText, setOtpText] = useState<any>("");
  const [printTokenData, setPrintTokenData] = useState({} as TokenPrintModel);
  const [openAlert, setOpenAlert] = useState(false);
  const [helmetAmountValue, setHelmetAmountValue] = useState<any>();
  const [remainingTime, setRemainingTime] = useState(60);
  const [searchValue, setSearchValue] = useState<SearchValueModel>({
    key: "",
    title: "",
    value: "",
    tokenNo: "",
  });
  const [totalAmountDetails, setTotalAmountDetails] = useState({
    graceAmount: 0,
    totalAmount: 0
  });
  const [isWithinSettingsGrace, setWithinSettingsGrace] = useState(false);

  useEffect(() => {
    if (
      user &&
      Object.keys(user).length > 0 &&
      parkingLot &&
      Object.keys(parkingLot).length > 0
    ) {
      let AccountParams = {
        UserName: user?.userName,
        ParkingLotId: parkingLot?.parkingLotId,
      };
      dispatch(GetAccountDetails(AccountParams));
    }
    dispatch(AllExitDetails());
    dispatch(GetProfileDetails());
  }, []);

  useEffect(() => {
    setTempAmountTextBox(totalAmountDetails.totalAmount);
  }, [totalAmountDetails.totalAmount]);

  useEffect(() => {
    setCheckBox(outReceipt && outReceipt.settingValue === 1);
  }, [ParkingLotSettings]);

  useEffect(() => {
    if (remainingTime !== 0) {
      const timer = setTimeout(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      handleRefresh();
    }
  }, [remainingTime]);

  useEffect(() => {
    if (exit.planType == 1) {
      calculateAmount(false)
      setTotalAmountDetails({
        graceAmount: calculateAmount(true),
        totalAmount: calculateAmount(false)
      });
    }
    else {
      calculateSlabPlan()
    }
  }, [dateValue, exit.tokenId]);

  useEffect(() => {
    if (Account && Account.parkingLotDetails) {
      let apiQuery = "parkingLotId=" + Account.parkingLotDetails.parkingLotId;
      dispatch(getParkinglotSettingsDetails(apiQuery));
    }
  }, [Account]);

  useEffect(() => {
    setAutosearchData(allExit);
  }, [allExit]);

  useEffect(() => {
    if (exit && Object.keys(exit).length > 0 && graceInterval) {
      const diffMilliseconds = Math.abs(new Date(exit.inTime).getTime() - dateValue.getTime());
      const hours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
      const isWithinGracePeriod = hours > 0 ? (hours % Number(graceInterval.settingValue)) == 0 : false;
      setWithinSettingsGrace(isWithinGracePeriod)
    }
  }, [dateValue, graceInterval]);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleEditExitTime = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const handleSearch = (data: any) => {
    dispatch(ExitDetails(data.tokenNo)).then((res: any) => {
      if (res.meta.requestStatus !== "fulfilled") {
        updateToastMessage(true, "Connection Refused", "info");
        return;
      }
      if (res.payload.status == 204) {
        updateToastMessage(true, "No data found", "info");
        return;
      }
      if (res.payload.status) {
        setSearchExit(true);
        const result = unwrapResult(res);
        if (
          result.data.data !== null &&
          result.data.data.alertMessage !== null
        ) {
          setOpenAlert(true);
        }
      } else {
        updateToastMessage(true, res.payload.message, "error");
      }
    });
  };

  const calculateAmount = (gracePayment: boolean) => {
    const newDateValue =
      exit && Object.keys(exit).length > 0
        ? new Date(formatDate(exit.inTime))
        : new Date();
    const minuteDifference = getMinuteDifference(newDateValue, dateValue);

    let outDate = gracePayment ? new Date(new Date(dateValue).setMinutes(dateValue.getMinutes() - (minuteDifference + 1))) : new Date(dateValue);
    let params = {
      planType: exit.planType,
      inTime: new Date(formatDate(exit.inTime)),
      outTime: new Date(formatDate(outDate)),
      dayParkingFrom: exit.planDetails.dayParkingFrom,
      dayParkingTo: exit.planDetails.dayParkingTo,
      nightParkingFrom: exit.planDetails.nightParkingFrom,
      nightParkingTo: exit.planDetails.nightParkingTo,
      scheduleType: exit.planTypeDetails.scheduleType,
      basicAmount: exit.planTypeDetails.basicAmount,
      actualAmount: exit.planTypeDetails.actualAmount,
      dayBasicAmount: exit.planTypeDetails.dayBasicAmount,
      dayActualAmount: exit.planTypeDetails.dayActualAmount,
      nightBasicAmount: exit.planTypeDetails.nightBasicAmount,
      nightActualAmount: exit.planTypeDetails.nightActualAmount,
      dayMinimumHour: exit.planTypeDetails.dayMinimumHour,
      nightMinimumHour: exit.planTypeDetails.nightMinimumHour,
      dayMinimumMinutes: exit.planTypeDetails.dayMinimumMinutes,
      nightMinimumMinutes: exit.planTypeDetails.nightMinimumMinutes,
      dailyMinimumHour: exit.planTypeDetails.dailyMinimumHour,
      weeklyMinimumDay: exit.planTypeDetails.weeklyMinimumDay,
      dayChargeType: exit.planTypeDetails.dayChargeType,
    };
    let calculatedAmount =
      amountCalculation.fixedPlanCalculation(params).totalAmount;
    let duration = getDuration(newDateValue, dateValue);
    setTimeGap(duration);
    return calculatedAmount;
  };

  const handleRefresh = () => {
    setRemainingTime(60);
    setDateValue(prev => {
      const newDate = new Date(prev);
      newDate.setMinutes(prev.getMinutes() + 1);
      return newDate;
    });
  };

  const handleMakePayment = (gracePayment: boolean) => {
    let totalAmount = totalAmountDetails.totalAmount;
    if (gracePayment) {
      totalAmount = calculateAmount(gracePayment);
    }
    if (enterOtp && enterOtp.settingValue && exit && exit.otp) {
      if (exit.otp !== otpText) {
        updateToastMessage(true, "Otp not matched", "error");
        return;
      }
    }
    let exitToken = {
      TokenId: exit.tokenId,
      OutTime: dateValue,
      ActualOutTime: new Date(),
      AmountToCollect: amountToCollect,
      CollectedAmount: gracePayment ? totalAmount : totalAmountDetails.totalAmount,
      TokenNumber: exit.tokenNumber,
      PaymentMode:
        selectedChip == "cash"
          ? 1
          : selectedChip == "credit"
            ? 2
            : selectedChip == "gPay"
              ? 3
              : 4,
    };
    dispatch(ExitToken(exitToken)).then((res: any) => {
      if (res.meta.requestStatus !== "fulfilled") {
        updateToastMessage(true, "Connection Refused", "info");
        return;
      }
      if (res.payload.status == 204) {
        updateToastMessage(true, "No data found", "info");
        return;
      }
      if (res.payload.status) {
        setSearchValue({
          key: "",
          title: "",
          value: "",
          tokenNo: "",
        });
        setSearchExit(true);
        updateToastMessage(true, res.payload.data.message, "success");
        dispatch(AllExitDetails());
      } else {
        updateToastMessage(true, res.payload.data.message, "error");
      }
    });
    let printTokenData: TokenPrintModel = {
      time: exit.inTime.toString(),
      inTime: formatDate(exit.inTime),
      outTime: new Date(dateValue).toString(),
      token: exit.tokenDisplayNumber,
      regNo: exit.registerNumber.toUpperCase(),
      tokenId: exit.tokenId,
      amount: totalAmount,
      vehicleType: exit.vehicleTypeName,
      helmetAmount: helmetCheckBox ? String(helmetAmountValue) : null
    };
    setPrintTokenData(printTokenData);
    if (checkBox) {
      setStartPrint(true);
    }

    dispatch(deleteExitData());
  };

  const calculateSlabPlan = () => {
    const newDateValue =
      exit && Object.keys(exit).length > 0
        ? new Date(formatDate(exit.inTime))
        : new Date();
    let duration = getDuration(newDateValue, dateValue);
    setTimeGap(duration);
    const timeDifference = Math.abs(
      newDateValue.getTime() - dateValue.getTime()
    );

    if (exit && Object.keys(exit).length > 0) {
      let totalMinutes = Math.floor(timeDifference / (1000 * 60));
      let allTypeformulas = [0, 60, 60 * 24, 60 * 24 * 7, 60 * 24 * 7 * 30];
      let orderedSlabTypes = [...exit.slabPlanDetails];
      orderedSlabTypes.sort((a: any, b: any) => a.planStart - b.planStart);
      let planEndType =
        orderedSlabTypes[exit.slabPlanDetails.length - 1].endType;
      let planStartType =
        exit.slabPlanDetails[exit.slabPlanDetails.length - 1].startType;
      if (
        Number(orderedSlabTypes[orderedSlabTypes.length - 1].planEnd) <
        totalMinutes
      ) {
        let totalSpendTime = Math.floor(
          totalMinutes / allTypeformulas[planEndType - 1]
        );
        let totalAmount =
          totalSpendTime *
          Number(orderedSlabTypes[orderedSlabTypes.length - 1].charges);
        if (totalMinutes % allTypeformulas[planEndType - 1])
          totalAmount += Number(
            orderedSlabTypes[orderedSlabTypes.length - 1].charges
          );
        // setAmountTextBox(
        //   exit
        //     ? exit.collectedAmount
        //       ? Number(exit.collectedAmount) - totalAmount
        //       : totalAmount
        //     : totalAmount
        // );
        setAmountToCollect(totalAmount);
        return;
      } else {
        orderedSlabTypes.forEach((type) => {
          if (
            totalMinutes >= Number(type.planStart) &&
            totalMinutes <= Number(type.planEnd)
          ) {
            // setAmountTextBox(
            //   exit
            //     ? exit.collectedAmount
            //       ? Number(exit.collectedAmount) - Number(type.charges)
            //       : Number(type.charges)
            //     : Number(type.charges)
            // );
            setAmountToCollect(Number(type.charges));
          }
        });
      }
    }
  };


  const handleResendOtp = () => {
    let param = {
      TokenId: exit && exit.tokenNumber,
    };
    dispatch(ResendOTP(param)).then((res) => {
      if (res.meta.requestStatus !== "fulfilled") {
        updateToastMessage(true, "Connection Refused", "info");
        return;
      }
      if (res.payload.status == 204) {
        updateToastMessage(true, "No data found", "info");
        return;
      }
      if (res.payload.data.status) {
        updateToastMessage(true, "Successfully sent OTP", "success");
      } else {
        updateToastMessage(true, res.payload.message, "error");
      }
    });
  };

  const handleDateChange = (date: Date) => {
    setDateValue(new Date(date));
  };

  const handleAmountChange: () => void = () => {
    if (tempAmountTextBox > 0) {
      setTotalAmountDetails(prev => (
        { ...prev, totalAmount: tempAmountTextBox }
      ))
      setAmountEditOpen(false);
    }
  };

  const handleCloseModal = () => setAmountEditOpen(false);

  const handleHelmetClick = () => {
    if (exit && Object.keys(exit).length > 0 && helmetAmount) {
      const diffMilliseconds = Math.abs(new Date(exit.inTime).getTime() - dateValue.getTime());
      const hours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
      let amount = Math.trunc(hours / Number(helmetHour.settingValue)) * Number(helmetAmount.settingValue);
      setHelmetAmountValue(amount);
      setTotalAmountDetails(prev => {
        return {
          graceAmount: helmetCheckBox ? prev.graceAmount - amount : prev.graceAmount + amount,
          totalAmount: helmetCheckBox ? prev.totalAmount - amount : prev.totalAmount + amount
        }
      });
    }
    setHelmetCheckBox(!helmetCheckBox);
  }

  return (
    <div className="exit-container">
      <Modal
        open={isAmountEditOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            type="number"
            value={tempAmountTextBox}
            onChange={(eve: any) => setTempAmountTextBox(eve.target.value)}
          />
          <IconButton color="success" onClick={handleAmountChange}>
            <DoneTwoToneIcon />
          </IconButton>
          <IconButton color="error" onClick={() => setAmountEditOpen(false)}>
            <ClearTwoToneIcon />
          </IconButton>
        </Box>
      </Modal>
      <div>
        <Grid container marginBottom="3%">
          <Grid xs={10} md={6}>
            <SearchBar
              handleSearch={handleSearch}
              searchData={autosearchData}
              setSearchExit={setSearchExit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Grid>
          <Grid md={6}></Grid>
        </Grid>

        {Object.keys(exit).length > 0 && (
          <div>
            <Grid container marginBottom="1.5%">
              <Grid xs={12} md={4}>
                <Card id="transparent" elevation={0} className="first-child">
                  {tokenOutPayment && tokenOutPayment.settingValue ? (
                    <Grid container alignItems={'center'}>
                      <Grid>
                        <Typography
                          variant="h2"
                          onClick={() => setAmountEditOpen(true)}
                        >
                          <CurrencyRupeeIcon />
                          <span style={{ color: theme.palette.primary.main }}>
                            {totalAmountDetails.totalAmount}
                          </span>
                        </Typography>
                      </Grid>
                      {/* <div className='info-icon'>
                          <InfoTwoToneIcon />
                        </div> */}
                      <Grid>
                        <IconButton onClick={() => setAmountEditOpen(true)}>
                          <Edit color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Card>
              </Grid>
              <Grid xs={12} md={6}>
                <Typography sx={{ color: "text.primary" }}>
                  Total Parked Time
                </Typography>
                {/* <NewLabel
                Label={''}
                Value={`${timeGap.days} Days, ${timeGap.hours} Hours,${timeGap.minutes} Minutes ${timeGap.seconds} Seconds`}
              /> */}
                <Typography
                  variant="h6"
                  style={{ color: theme.palette.primary.main }}
                >
                  {timeGap}
                </Typography>
              </Grid>
              <Grid xs={6} md={2}>
                <Tooltip title={exit.vehicleTypeName}>
                  <img className="vehicle-icon" src={exit.iconUrl} />
                </Tooltip>
              </Grid>
            </Grid>
            <Card id="transparent" className="exit-wrapper" elevation={0}>
              <Grid container marginBottom="1.5%">
                <Grid xs={6} md={4}>
                  <NewLabel
                    Label={"Name"}
                    Value={
                      exit.customerName ? exit.customerName : "Virtual Customer"
                    }
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <NewLabel
                    Label={"Mobile Number"}
                    Value={exit.customerMobile}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <NewLabel
                    Label={"Token Number"}
                    Value={exit.tokenDisplayNumber}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card id="transparent" className="exit-wrapper" elevation={0}>
              <Grid container marginBottom="1.5%">
                <Grid md={4}>
                  <NewLabel
                    Label={"In Time"}
                    Value={formatDate(exit.inTime, "ddd MMM DD YYYY hh:mm A")}
                  />
                </Grid>
                <Grid md={4} xs={12}>
                  {permissions.editOutTime && (
                    <Label
                      onClick={handleEditExitTime}
                      icon={
                        tokenOutPayment && tokenOutPayment.settingValue
                          ? true
                          : false
                      }
                      Label="Token Exit Time"
                      Value={dayjs(dateValue).format("ddd MMM DD YYYY hh:mm A")}
                    />
                  )}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      sx={{ display: "none" }}
                      value={dayjs(dateValue)}
                      minDateTime={dayjs(formatDate(exit.inTime))}
                      open={isDatePickerOpen}
                      onClose={() => setDatePickerOpen(false)}
                      onChange={(date: any) => handleDateChange(date)}
                    />
                  </LocalizationProvider>
                </Grid>
                {enterOtp && enterOtp.settingValue ? (
                  <Grid
                    xs={12}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-required"
                      label="Otp"
                      value={otpText}
                      onChange={(e) => setOtpText(e.target.value)}
                    />
                    <Chip
                      sx={{ width: "90px", marginRight: "10px" }}
                      color="primary"
                      onClick={() => handleResendOtp()}
                      variant={"filled"}
                      label="Resend"
                    />
                    {/* <Button
                    sx={{ width: "160px" }}
                    onClick={handleMakePayment}
                    className="exit-make-payment"
                    variant="contained"
                  >
                    Resend Otp
                  </Button> */}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Card>
            <Card id="transparent" className="exit-wrapper" elevation={0}>
              <Grid container marginBottom="1.5%">
                <Grid
                  xs={6}
                  md={
                    tokenOutPayment &&
                      tokenOutPayment.settingValue &&
                      tokenInPayment.settingValue
                      ? 3
                      : 4
                  }
                >
                  <Card id="transparent" elevation={0} className="second-child">
                    <NewLabel
                      Label={"Register Number"}
                      Value={exit.registerNumber}
                    />
                  </Card>
                </Grid>
                <Grid xs={12} md={5.2}>
                  <div>
                    {tokenOutPayment && tokenOutPayment.settingValue
                      ? "Payment options"
                      : "Collected Amount - Entry"}
                  </div>
                  {tokenOutPayment && tokenOutPayment.settingValue ? (
                    <div className="pay-chip-container">
                      <Chip
                        onClick={() => setSelectedChip("cash")}
                        sx={{ width: "90px" }}
                        color="primary"
                        label="Cash"
                        variant={
                          selectedChip === "cash" ? "filled" : "outlined"
                        }
                      />
                      <Chip
                        sx={{ width: "90px" }}
                        color="primary"
                        onClick={() => setSelectedChip("credit")}
                        variant={
                          selectedChip === "credit" ? "filled" : "outlined"
                        }
                        label="Credit"
                      />
                      <Chip
                        sx={{ width: "90px" }}
                        color="primary"
                        onClick={() => setSelectedChip("upi")}
                        variant={selectedChip === "upi" ? "filled" : "outlined"}
                        label="Upi"
                      />
                    </div>
                  ) : (
                    <Typography
                      variant="h6"
                      style={{ color: theme.palette.primary.main }}
                    >
                      {exit.collectedAmount !== null
                        ? `₹ ${exit.collectedAmount}`
                        : "₹ 0"}
                    </Typography>
                  )}
                </Grid>

                {tokenOutPayment &&
                  tokenOutPayment.settingValue &&
                  tokenInPayment.settingValue ? (
                  <Grid xs={12} md={3}>
                    <div>Collected Amount - Entry</div>
                    <Typography
                      variant="h6"
                      style={{ color: theme.palette.primary.main }}
                    >
                      {exit.collectedAmount !== null
                        ? `₹ ${exit.collectedAmount}`
                        : "₹ 0"}
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Card>
            <Grid container>
              {/* <Grid xs={12} md={7}>
                <Grid container sx={{ alignItems: "Center" }}>
                  <WarningTwoToneIcon
                    color="warning"
                    style={{ height: "55px", width: "55px" }}
                  />
                  This screen will automatically be invalid after {remainingTime} seconds
                </Grid>
              </Grid> */}
            </Grid>
            <Grid container>
              <Grid md={3} xs={3} display={'flex'} alignItems={'center'}>
                {/* <Chip
                  label="Print Receipt"
                  icon={<Print />}
                  clickable
                  color={checkBox ? "primary" : "default"}
                  onClick={() => setCheckBox(!checkBox)}
                  style={{
                    padding: "15px",
                    marginRight: "10px",
                    fontSize: "15px",
                    fontWeight: "500",
                    height: "40px",
                  }}
                /> */}
                <IconButton color={checkBox ? "primary" : "default"} onClick={() => setCheckBox(!checkBox)}>
                  <Print />
                </IconButton>
                <FormGroup onClick={() => setCheckBox(!checkBox)}>
                  <IOSSwitch sx={{ m: 1 }} checked={checkBox} />
                </FormGroup>
                {helmetAmount && helmetAmount.settingValue > 0 && helmetHour && helmetHour.settingValue > 0 &&
                  <>
                    <IconButton color={helmetCheckBox ? "primary" : "default"} onClick={() => setHelmetCheckBox(!helmetCheckBox)}>
                      <SportsMotorsportsIcon />
                    </IconButton>
                    <FormGroup onClick={() => { handleHelmetClick() }}>
                      <IOSSwitch sx={{ m: 1 }} checked={helmetCheckBox} />
                    </FormGroup>
                  </>}
              </Grid>
              <Grid md={1} xs={3} > </Grid>
              {graceActive &&
                graceActive.settingValue && !graceOrActual.settingValue ?
                <Grid md={4} xs={9} flexDirection={'column'} justifyContent={'center'} >
                  <Button
                    onClick={() => { handleMakePayment(true) }}
                    className="exit-make-payment"
                    variant="contained"
                    sx={{ borderRadius: '25px' }}
                  >
                    {` Collect - ₹ ${totalAmountDetails.graceAmount}`}
                  </Button>
                  <Typography color='primary'>
                    * Grace Amount
                  </Typography>
                </Grid>
                : <Grid md={4} xs={9} flexDirection={'column'} justifyContent={'center'} />}
              {graceOrActual && graceActive &&
                graceOrActual.settingValue && isWithinSettingsGrace ?
                <Grid md={4} xs={9} flexDirection={'column'} justifyContent={'center'} >
                  <Button
                    onClick={() => { handleMakePayment(true) }}
                    className="exit-make-payment"
                    variant="contained"
                    sx={{ borderRadius: '25px' }}
                  >
                    {` Collect - ₹ ${totalAmountDetails.graceAmount}`}
                  </Button>
                  <Typography color='primary'>
                    * Grace Amount
                  </Typography>
                </Grid>
                : <Grid md={3} xs={12} flexDirection={'column'} justifyContent={'center'}>
                  <Button
                    // sx={{
                    //   width:
                    //     tokenOutPayment && tokenOutPayment.settingValue
                    //       ? "160px"
                    //       : "130px",
                    // }}
                    sx={{ borderRadius: '25px' }}
                    onClick={() => { handleMakePayment(false) }}
                    className="exit-make-payment"
                    variant="contained"
                  >
                    {tokenOutPayment && tokenOutPayment.settingValue
                      ? `Collect - ₹ ${totalAmountDetails.totalAmount}`
                      : "Token Out"}
                  </Button>
                  <Typography color='primary'>
                    * Total Amount
                  </Typography>
                </Grid>}
              <Grid md={3}></Grid>
            </Grid>
          </div>
        )}
      </div>
      <Box sx={{ display: "none" }}>
        {startPrint && (
          <OutTokenContent
            printRef={printRef}
            printData={printTokenData}
            isPrint={true}
            setStartPrint={setStartPrint}
          />
        )}
      </Box>
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      // placement={{ vertical: "top", horizontal: "left" }}
      />
      <WarningDialog
        openAlert={openAlert}
        handleAlertClose={handleAlertClose}
        message={exit !== null ? exit.alertMessage : ""}
      />
      <Loading openModal={isLoading} />
    </div>
  );
}

export default TokenExit;
