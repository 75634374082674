import { Box } from "@mui/system";
import {
  Container,
  Grid,
  TextField,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import { FormikProps } from "formik";
import { ParkingLotRegisterFormikInputs } from "./ParkingLotRegisterForm";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { GetParkingLotType } from "../../Redux/Thunks/ParkingLotThunk";
import Loading from "../../components/Loading/Loading";

interface Props {
  formik: FormikProps<ParkingLotRegisterFormikInputs>;
}

const ParkingLotDetailsReportForm = (props: Props) => {
  const handleStateChange = (state: any) => {
    formik.setFieldValue("stateId", state.stateId);
    formik.setFieldValue("stateName", state.stateName);
  };
  const handleParkingLotTypeChange = (state: any) => {
    formik.setFieldValue("parkingLotTypeId", state.parkingLotTypeId);
    formik.setFieldValue("parkingLotTypeName", state.parkingLotType);
  };
  const dispatch = useAppDispatch();

  const { parkingLotType, isLoading } = useAppSelector(
    (state) => state.parkinglot
  );

  useEffect(() => {
    dispatch(GetParkingLotType());
  }, []);

  const { formik } = props;
  return (
    <Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Parking Lot Name"
              error={
                formik.touched.parkingLotName &&
                Boolean(formik.errors.parkingLotName)
              }
              helperText={
                formik.touched.parkingLotName && formik.errors.parkingLotName
              }
              {...formik.getFieldProps("parkingLotName")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                ParkingLotTypes
              </InputLabel>
              <Select
                fullWidth
                labelId="state-select-label"
                id="state-select"
                label="ParkingLotTypes"
                value={formik.values.parkingLotTypeName}
                error={
                  formik.touched.parkingLotTypeName &&
                  Boolean(formik.errors.parkingLotTypeName)
                }
              >
                {Object.keys(parkingLotType).length > 0 &&
                  parkingLotType.parkingLotTypes.length > 0 &&
                  parkingLotType.parkingLotTypes.map((types, index) => (
                    <MenuItem
                      key={index}
                      value={types.parkingLotType}
                      onClick={() => handleParkingLotTypeChange(types)}
                    >
                      {types.parkingLotType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Owner Name"
              error={
                formik.touched.ownerName && Boolean(formik.errors.ownerName)
              }
              helperText={formik.touched.ownerName && formik.errors.ownerName}
              {...formik.getFieldProps("ownerName")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Address Line 1"
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
              {...formik.getFieldProps("address1")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address Line 2"
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
              {...formik.getFieldProps("address2")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              {...formik.getFieldProps("city")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select state
              </InputLabel>
              <Select
                fullWidth
                labelId="state-select-label"
                id="state-select"
                label="Select state"
                value={formik.values.stateName}
              >
                {Object.keys(parkingLotType).length > 0 &&
                  parkingLotType.states.length > 0 &&
                  parkingLotType.states.map((state, index) => (
                    <MenuItem
                      key={index}
                      value={state.stateName}
                      onClick={() => handleStateChange(state)}
                    >
                      {state.stateName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Pincode"
              error={formik.touched.pincode && Boolean(formik.errors.pincode)}
              helperText={formik.touched.pincode && formik.errors.pincode}
              {...formik.getFieldProps("pincode")}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Latitude"
                error={
                  parkingLotDetails.touched.latitude &&
                  Boolean(parkingLotDetails.errors.latitude)
                }
                helperText={
                  parkingLotDetails.touched.latitude &&
                  parkingLotDetails.errors.latitude
                }
                {...parkingLotDetails.getFieldProps("latitude")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Longitude"
                error={
                  parkingLotDetails.touched.longitude &&
                  Boolean(parkingLotDetails.errors.longitude)
                }
                helperText={
                  parkingLotDetails.touched.longitude &&
                  parkingLotDetails.errors.longitude
                }
                {...parkingLotDetails.getFieldProps("longitude")}
              />
            </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="PhoneNumber"
              error={
                formik.touched.parkingLotPhoneNumber &&
                Boolean(formik.errors.parkingLotPhoneNumber)
              }
              helperText={
                formik.touched.parkingLotPhoneNumber &&
                formik.errors.parkingLotPhoneNumber
              }
              {...formik.getFieldProps("parkingLotPhoneNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact Name"
              error={
                formik.touched.contactName && Boolean(formik.errors.contactName)
              }
              helperText={
                formik.touched.contactName && formik.errors.contactName
              }
              {...formik.getFieldProps("contactName")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              {...formik.getFieldProps("email")}
            />
            <Chip
              label="HasReservation"
              clickable
              color={formik.values.hasReservation ? "primary" : "default"}
              onClick={() =>
                formik.values.hasReservation
                  ? formik.setFieldValue("hasReservation", false)
                  : formik.setFieldValue("hasReservation", true)
              }
              style={{
                padding: "0 10px",
                margin: "4px 10px",
              }}
            />
            <Chip
              label="Interchangeable"
              clickable
              color={formik.values.interchangeable ? "primary" : "default"}
              onClick={() =>
                formik.values.interchangeable
                  ? formik.setFieldValue("interchangeable", false)
                  : formik.setFieldValue("interchangeable", true)
              }
              style={{
                padding: "0 10px",
                margin: "4px 10px",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Surface Status"
              error={
                formik.touched.surfaceStatus &&
                Boolean(formik.errors.surfaceStatus)
              }
              helperText={
                formik.touched.surfaceStatus && formik.errors.surfaceStatus
              }
              {...formik.getFieldProps("surfaceStatus")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Area In Square Feet"
              error={
                formik.touched.areaInSquareFeet &&
                Boolean(formik.errors.areaInSquareFeet)
              }
              helperText={
                formik.touched.areaInSquareFeet &&
                formik.errors.areaInSquareFeet
              }
              {...formik.getFieldProps("areaInSquareFeet")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Length In Feet"
              error={
                formik.touched.lengthInFeet &&
                Boolean(formik.errors.lengthInFeet)
              }
              helperText={
                formik.touched.lengthInFeet && formik.errors.lengthInFeet
              }
              {...formik.getFieldProps("lengthInFeet")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Width In Feet"
              error={
                formik.touched.widthInFeet && Boolean(formik.errors.widthInFeet)
              }
              helperText={
                formik.touched.widthInFeet && formik.errors.widthInFeet
              }
              {...formik.getFieldProps("widthInFeet")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Profile Picture URL"
              error={
                formik.touched.profilePictureUrl &&
                Boolean(formik.errors.profilePictureUrl)
              }
              helperText={
                formik.touched.profilePictureUrl &&
                formik.errors.profilePictureUrl
              }
              {...formik.getFieldProps("profilePictureUrl")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              {...formik.getFieldProps("password")}
            />
            <Loading openModal={isLoading} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ParkingLotDetailsReportForm;
