import { createSlice } from "@reduxjs/toolkit";
import { GetDashboardDetails } from "../Thunks/DashboardThunk";
import { LotDailyReportSummary } from "../../Model/DashboardReportModel";

interface InitialState {
  isLoading: boolean;
  dashboardReport: LotDailyReportSummary;
  error: string
}

let dashboardReport: LotDailyReportSummary = {
  companyId: "",
  parkingLotId: "",
  openingBalance: 0,
  closingBalance: 0,
  notes: "",
  totalParkedCount: 0,
  totalParkedIn: 0,
  totalParkedOut: 0,
  totalPaymentReceived: 0,
  totalPaymentReturned: 0,
  totalPaymentPending: 0,
  lastThreeDays: [],
  todayReport : null,
  notificationReport : null,
};

let initialState: InitialState = {
  isLoading: false,
  dashboardReport:dashboardReport,
  error: '',
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetDashboardDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetDashboardDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (
          action.payload.status &&
          action.payload.data !== null
        ) {
          state.dashboardReport = action.payload.data;
        }
      })
      .addCase(GetDashboardDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const dashBoardReducer = dashboardSlice.reducer;
