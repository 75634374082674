import Lottie from 'react-lottie';
import * as animationData from '../../assets/loading.json'
import { Dialog } from '@mui/material';
import theme from '../../Theme/Theme';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const contentStyle = {
    backgroundColor: theme.palette.primary.contrastText,
    border: 'none',
    padding: '20px',
    height: '100px',
    width: '200px'
};

function Loading(props: any) {
    const { openModal } = props;
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Dialog
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={modalStyle}
        >
            <div style={contentStyle}>
                <Lottie options={defaultOptions} />
            </div>
        </Dialog>
    )
}

export default Loading
