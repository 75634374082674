import { PricingPlan } from "./Model/amountCalculationModels";
import { convertDateTo12Hour, convertHourNumberToDate, convertMinimumDurationToMinutes, convertTimeTo24Hour, getDifferenceInMinutes, getMinutesFromTimeString, getDayTimeDifferenceInMilliSeconds, getNightTimeDifferenceInMilliSeconds } from "./utilities/dateTimeConversion";


export const dayNightCalculation = (entryTime: Date, exitTime: Date, pricingPlan: PricingPlan, dayMinimumHour: string, nightMinimumHour: string) => {

    let daySessions = 0;
    let nightSessions = 0;
    let currentTime = entryTime;
    let currentHourMain = currentTime.getHours();
    let currentMinutesMain = currentTime.getMinutes();
    let currentHourAndMinutes = getMinutesFromTimeString(currentHourMain, currentMinutesMain)
    let planStartHoursAndMinutesMain = getMinutesFromTimeString(pricingPlan.day.startHour, pricingPlan.day.startMinutes);
    let planEndHoursAndMinutesMain = getMinutesFromTimeString(pricingPlan.day.endHour, pricingPlan.day.endMinutes);
    let isDayTimeMain = currentHourAndMinutes >= planStartHoursAndMinutesMain && currentHourAndMinutes < planEndHoursAndMinutesMain;
    let formattedDayMinimumHours = convertMinimumDurationToMinutes(dayMinimumHour);
    let formattedNightMinimumHours = convertMinimumDurationToMinutes(nightMinimumHour);
    let dayMinimumDuration = 0;
    let nightMinimumDuration = 0;

    //checks the current shift
    if (isDayTimeMain) {
        let differenceInMinutes = getDifferenceInMinutes(currentTime, convertHourNumberToDate(currentTime, pricingPlan.day.endHour, pricingPlan.day.endMinutes));
        let differenceInEntryAndExit = getDifferenceInMinutes(currentTime, exitTime);
        let currentInHours: string | number = convertDateTo12Hour(entryTime);
        let currentInMins = convertTimeTo24Hour(currentInHours).minutes;
        currentInHours = convertTimeTo24Hour(currentInHours).hours;
        let timeDifference = getDayTimeDifferenceInMilliSeconds(pricingPlan.day.endHour, pricingPlan.day.endMinutes, currentInHours, currentInMins);
        currentTime = new Date(currentTime.getTime() + (timeDifference));
        if (differenceInMinutes > formattedDayMinimumHours && differenceInEntryAndExit > formattedDayMinimumHours) {
            daySessions++;
        } else {
            dayMinimumDuration++;
        }
    } else {
        let differenceInMinutes = getDifferenceInMinutes(currentTime, convertHourNumberToDate(currentTime, pricingPlan.night.endHour, pricingPlan.night.endMinutes));
        let differenceInEntryAndExit = getDifferenceInMinutes(currentTime, exitTime);
        let currentInTime: string | number = convertDateTo12Hour(entryTime);
        let currentInTimeInMins = convertTimeTo24Hour(currentInTime).minutes;
        currentInTime = convertTimeTo24Hour(currentInTime).hours;
        let timeDifference = getDayTimeDifferenceInMilliSeconds(pricingPlan.night.endHour, pricingPlan.night.endMinutes, currentInTime, currentInTimeInMins);
        currentTime = new Date(currentTime.getTime() + (timeDifference));
        if (differenceInMinutes > formattedNightMinimumHours && differenceInEntryAndExit > formattedNightMinimumHours) {
            nightSessions++;
        } else {
            nightMinimumDuration++;
        }
    }

    while (currentTime < exitTime) {
        let differenceInMinutes = getDifferenceInMinutes(currentTime, exitTime);
        let currentHour = currentTime.getHours();
        let currentMinutes = currentTime.getMinutes();
        let currentHourAndMinutes = getMinutesFromTimeString(currentHour, currentMinutes)
        let planStartHoursAndMinutes = getMinutesFromTimeString(pricingPlan.day.startHour, pricingPlan.day.startMinutes);
        let planEndHoursAndMinutes = getMinutesFromTimeString(pricingPlan.day.endHour, pricingPlan.day.endMinutes);
        let isDayTime = currentHourAndMinutes >= planStartHoursAndMinutes && currentHourAndMinutes < planEndHoursAndMinutes;
        // let isDayTime = currentHour >= pricingPlan.day.startHour && currentHour < pricingPlan.day.endHour;

        if (differenceInMinutes >= (isDayTime ? formattedDayMinimumHours : formattedNightMinimumHours)) {
            if (isDayTime) {
                daySessions++;
            } else {
                nightSessions++;
            }
        } else {
            if (isDayTime) {
                dayMinimumDuration++;
            } else {
                nightMinimumDuration++;
            }
        }

        let dayTimeDifference = getDayTimeDifferenceInMilliSeconds(pricingPlan.day.endHour, pricingPlan.day.endMinutes, currentHour, currentMinutes);
        let nightTimeDifference = getNightTimeDifferenceInMilliSeconds(pricingPlan.night.endHour, pricingPlan.night.endMinutes, currentHour, currentMinutes);

        // Increment current time by time difference after checking shift for the next iteration
        currentTime = isDayTime
            ? new Date(currentTime.getTime() + (dayTimeDifference))
            : new Date(currentTime.getTime() + (nightTimeDifference))
    }
    // console.log('daySessions:', daySessions, 'nightSessions:', nightSessions, 'dayMinimumDuration:', dayMinimumDuration, 'nightMinimumDuration:', nightMinimumDuration);
    return { daySessions, nightSessions, dayMinimumDuration, nightMinimumDuration };
}
