import { AlertColor, Box, Button, Card, Chip, Grid, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react';
import adduserAnimation from '../../../assets/adduser.json'
import { AddUserModel } from '../../../Model/Settings/AddUser';
import * as yup from "yup";
import { Formik, useFormik } from 'formik';
import './AddUser.css';
import Lottie from 'react-lottie';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { CheckUserInLot, GetAllUserRole, sendOtp } from '../../../Redux/Thunks/SettingsThunk';
import { ToastMessage, ToastMessageState } from '../../../components/ToastMessage/ToastMessage';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import GetOtp from './GetOtp';
import TextBox from '../../../components/TextBox/TextBox';
import Loading from '../../../components/Loading/Loading';

function AddUser() {
    const { Account, isLoading } = useAppSelector((state: any) => state.settings);
    const [isValidNumber, setIsValidNumber] = useState(false);
    const [optSended, setOtpSended] = useState(false)
    const [toastMessage, setToastMessage] = useState<ToastMessageState>({
        showMessage: false,
        message: "",
        alertType: "success",
    });

    const dispatch = useAppDispatch();



    useEffect(() => {
        dispatch(GetAllUserRole()).then((res: any) => {
            if (res.meta.requestStatus !== "fulfilled") {
                updateToastMessage(true, "Connection Refused", "info");
                return;
            }
            if (res.payload.status == 204) {
                updateToastMessage(true, "No data found", "info");
                return;
            }
            if (!res.payload.status) {
                if (res.payload.message != 'null')
                    updateToastMessage(true, res.payload.message, "error");
            }
        });
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: adduserAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const formik = useFormik({
        initialValues: {
            mobile: "",
        },
        validationSchema: yup.object({
            mobile: yup
                .string()
                .matches(/^\d{10}$/, "Mobile number must be 10 digits")
                .required("Required")
        }),
        onSubmit: async (values: any, { resetForm }: any) => {
            let query = 'mobileNumber=' + values.mobile + '&parkingLotId=' + Account.parkingLotDetails.parkingLotId;
            dispatch(CheckUserInLot(query)).then((data) => {
                if (data.payload == "User Not found") {
                    setIsValidNumber(true);
                    let params = {
                        MobileNumber: values.mobile,
                        ParkingLotId: Account.parkingLotDetails.parkingLotId
                    }
                    dispatch(sendOtp(params)).then((data) => {
                        if (data.meta.requestStatus !== "fulfilled") {
                            updateToastMessage(true, "Connection Refused", "info");
                            return;
                        }
                        if (data.payload.status == 204) {
                            updateToastMessage(true, "No data found", "info");
                            return;
                        }
                        if (data.payload.status) {
                            setOtpSended(true);
                        } else {
                            updateToastMessage(true, data.payload.message, "error");
                        }
                    })
                    return;
                }
                if (data.meta.requestStatus !== "fulfilled") {
                    updateToastMessage(true, "Connection Refused", "info");
                    return;
                }
                if (data.payload.status == 204) {
                    updateToastMessage(true, "No data found", "info");
                    return;
                }
                if (data.payload.status) {
                    updateToastMessage(true, "User already exist", "error");
                } else {
                    updateToastMessage(true, data.payload.message, "error");
                }
            })
        },
    });

    const updateToastMessage = (
        showMessage: boolean,
        message: string,
        alertType: AlertColor
    ) => {
        setToastMessage({ showMessage, message, alertType });
    };

    return (
        <Box className='add-user-container' >
            <Grid container spacing={2} alignItems='center' justifyContent='center'>
                <Grid item xs={12} md={6} columnGap={2}>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {!optSended ?
                                <Grid container  >
                                    <Grid item md={1} xs={1} />
                                    <Grid item md={9} xs={8}>
                                        <TextBox
                                            type='number'
                                            error={Boolean(formik.touched.mobile) && Boolean(formik.errors.mobile)}
                                            formik={formik} label='Mobile Number'
                                            helpertext={formik.touched.mobile ? String(formik.errors.mobile) : ""}
                                            fieldpropsName={'mobile'}
                                        />
                                    </Grid>
                                    <Grid item md={1} xs={2} height={{ xs: '55px' }} width={{ xs: '45px' }} >
                                        <Button sx={{
                                            height: '100%',
                                            width: '100%',
                                            marginLeft: '15%',
                                            marginTop: '8%'
                                        }}
                                            variant='contained'
                                            onClick={() => formik.handleSubmit()}
                                        >
                                            <CheckCircleTwoToneIcon sx={{ height: '100%', width: '100%' }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                :
                                <GetOtp mobile={formik.values.mobile} setOtpSended={setOtpSended} />
                            }
                        </Box>
                    </form>
                </Grid>
                <Grid item xs={12} md={6} display={{ xs: "flex", md: "flex" }} >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <Lottie
                            style={{
                                height: "80%",
                                width: "80%",
                                marginLeft: "8%",
                            }}
                            options={defaultOptions}
                        />
                    </Box>
                </Grid>
            </Grid>
            <ToastMessage
                showToastMessage={toastMessage.showMessage}
                setToastMessage={setToastMessage}
                message={toastMessage.message}
                alertType={toastMessage.alertType}
            // placement={{ vertical: "top", horizontal: "left" }}
            />
            <Loading openModal={isLoading} />
        </Box>
    )
}

export default AddUser
