import { Box } from "@mui/material";
import { Container, Grid, TextField } from "@mui/material";
import React from "react";
import { FormikProps } from "formik";
import { ParkingLotRegisterFormikInputs } from "./ParkingLotRegisterForm";
import Loading from "../../components/Loading/Loading";
import { useAppSelector } from "../../Redux/Store";

interface Props {
  formik: FormikProps<ParkingLotRegisterFormikInputs>;
}

const OTPVerification = (props: Props) => {
  const { isLoading } = useAppSelector((state) => state.parkinglot);
  const { formik } = props;

  return (
    <Box>
      <Container>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="OTP"
                error={formik.touched.OTP && Boolean(formik.errors.OTP)}
                helperText={formik.touched.OTP && formik.errors.OTP}
                {...formik.getFieldProps("OTP")}
              />
            </Grid>
            <Loading openModal={isLoading} />
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default OTPVerification;
