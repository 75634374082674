import { AlertColor, Box, Button, Card, Grid, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from "yup";
import TextBox from '../../../components/TextBox/TextBox';
import './Receipt.css';
import InTokenContent from '../../../components/TokenContent/InTokenContent';
import { inTokenPropsModel } from '../../../Model/TokenContentModel';
import { TokenPrintModel } from '../../../Model/TokenModel';
import theme from '../../../Theme/Theme';
import OutTokenContent from '../../../components/TokenContent/OutTokenContent';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { format } from 'path';
import { SaveReceiptFormat, UpdateReceiptFormat } from '../../../Redux/Thunks/SettingsThunk';
import Loading from '../../../components/Loading/Loading';
import { AnyARecord } from 'dns';
import { ToastMessage, ToastMessageState } from '../../../components/ToastMessage/ToastMessage';
import { GetProfileDetails } from '../../../Redux/Thunks/ProfileThunk';

function Receipt() {
    const printRef = useRef<HTMLDivElement>(null);

    const { receiptFormat, parkingLot, } = useAppSelector((state: any) => state.profile);
    const { isLoading } = useAppSelector((state: any) => state.settings);
    const receiptInData = receiptFormat.find((item: any) => item.typeNumber === 1);
    const receiptOutData = receiptFormat.find((item: any) => item.typeNumber === 2);
    const [toastMessage, setToastMessage] = useState<ToastMessageState>({
        showMessage: false,
        message: "",
        alertType: "success",
    });

    const [startPrint, setStartPrint] = useState(false);
    const [showIn, setShowIn] = useState(true);

    const dispatch = useAppDispatch();

    useEffect(() => {
        formikIn.setFieldValue('title', receiptInData ? receiptInData.title : '');
        formikIn.setFieldValue('subTitle', receiptInData ? receiptInData.subtitle : '');
        formikIn.setFieldValue('footer', receiptInData ? receiptInData.footer : '');
        formikOut.setFieldValue('outTitle', receiptOutData ? receiptOutData.title : '');
        formikOut.setFieldValue('outSubTitle', receiptOutData ? receiptOutData.subtitle : '');
        formikOut.setFieldValue('outFooter', receiptOutData ? receiptOutData.footer : '');
    }, [receiptFormat])

    useEffect(() => {
        dispatch(GetProfileDetails());
    }, [])

    const updateToastMessage = (
        showMessage: boolean,
        message: string,
        alertType: AlertColor
    ) => {
        setToastMessage({ showMessage, message, alertType });
    };

    const formikIn = useFormik({
        initialValues: {
            title: '',
            subTitle: '',
            footer: '',
        },
        validationSchema: yup.object({
            title: yup
                .string()
                .required("Required")
        }),
        onSubmit: async (values: any, { resetForm }: any) => {
            let params = {
                parkingLotId: parkingLot?.parkingLotId,
                inOrOut: receiptFormat.length > 0 && receiptFormat[0].typeNumber,
                title: values.title,
                subTitle: values.subTitle,
                footer: values.footer
            }
            let createParam = { ...params, inOrOut: showIn ? 1 : 2 };
            receiptFormat.length > 1 ? dispatch(UpdateReceiptFormat(params)).then((data: any) => {
                if (data.meta.requestStatus !== "fulfilled") {
                    updateToastMessage(true, "Connection Refused", "info");
                    return;
                }
                if (data.payload.status == 200) {
                    updateToastMessage(true, data.payload.data.message, "info");
                    dispatch(GetProfileDetails());
                    return;
                }
                if (data.payload.status) {
                    updateToastMessage(true, data.payload, "error");
                } else {
                    updateToastMessage(true, data.payload, "error");
                }
            }) :
                dispatch(SaveReceiptFormat(createParam)).then((data: any) => {
                    if (data.meta.requestStatus !== "fulfilled") {
                        updateToastMessage(true, "Connection Refused", "info");
                        return;
                    }
                    if (data.payload.status == 200) {
                        updateToastMessage(true, data.payload.data.message, "info");
                        dispatch(GetProfileDetails());
                        return;
                    }
                    if (data.payload.status) {
                        updateToastMessage(true, data.payload, "error");
                    } else {
                        updateToastMessage(true, data.payload, "error");
                    }
                })
        }
    })
    const inTokenData: TokenPrintModel = {
        inTime: new Date().toString(),
        outTime: new Date().toString(),
        regNo: "XX00XX0000",
        time: new Date().toString(),
        token: 'Token Number here',
        tokenId: "Token Id here",
        title: formikIn.values.title,
        subTitle: formikIn.values.subTitle,
        footer: formikIn.values.footer
    }

    const formikOut = useFormik({
        initialValues: {
            outTitle: '',
            outSubTitle: '',
            outFooter: '',
        },
        validationSchema: yup.object({
            outTitle: yup
                .string()
                .required("Required")
        }),
        onSubmit: async (values: any, { resetForm }: any) => {
            let params = {
                parkingLotId: parkingLot?.parkingLotId,
                inOrOut: receiptFormat.length > 1 && receiptFormat[1].typeNumber,
                title: values.outTitle,
                subTitle: values.outSubTitle,
                footer: values.outFooter
            }
            let createParam = { ...params, inOrOut: showIn ? 1 : 2 };
            receiptFormat.length > 1 ? dispatch(UpdateReceiptFormat(params)).then((data: any) => {
                if (data.meta.requestStatus !== "fulfilled") {
                    updateToastMessage(true, "Connection Refused", "info");
                    return;
                }
                if (data.payload.status == 200) {
                    updateToastMessage(true, data.payload.data.message, "info");
                    dispatch(GetProfileDetails());
                    return;
                }
                if (data.payload.status) {
                    updateToastMessage(true, data.payload, "error");
                } else {
                    updateToastMessage(true, data.payload, "error");
                }
            }) :
                dispatch(SaveReceiptFormat(createParam)).then((data: any) => {
                    if (data.meta.requestStatus !== "fulfilled") {
                        updateToastMessage(true, "Connection Refused", "info");
                        return;
                    }
                    if (data.payload.status == 200) {
                        updateToastMessage(true, data.payload.data.message, "info");
                        dispatch(GetProfileDetails());
                        return;
                    }
                    if (data.payload.status) {
                        updateToastMessage(true, data.payload, "error");
                    } else {
                        updateToastMessage(true, data.payload, "error");
                    }
                })
        }
    })

    const outTokenData: TokenPrintModel = {
        inTime: new Date().toString(),
        outTime: new Date().toString(),
        regNo: "XX00XX0000",
        time: new Date().toString(),
        token: 'Token Number here',
        tokenId: "Token Id here",
        title: formikOut.values.outTitle,
        subTitle: formikOut.values.outSubTitle,
        footer: formikOut.values.outFooter
    }

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Grid container >
                <Grid container gap={1} marginBottom='3%'>
                    <Grid container >
                        <Grid item md={4} xs={4} />
                        <Grid md={2.5} xs={4} item display='flex' justifyContent='space-around'>
                            <Button
                                sx={{ width: '80px', height: '39px' }}
                                variant={showIn ? 'contained' : 'outlined'}
                                onClick={() => setShowIn(true)}
                            >
                                In
                            </Button>
                            <Button
                                sx={{ width: '82px', height: '39px' }}
                                variant={!showIn ? 'contained' : 'outlined'}
                                onClick={() => setShowIn(false)}
                            >
                                Out
                            </Button>
                        </Grid>
                        <Grid item md={5.5} xs={4} />
                    </Grid>
                </Grid>
                {showIn ?
                    <Grid container gap={1} >
                        <Grid item xs={11.6} md={7}>
                            <form>
                                <TextBox
                                    type='text'
                                    error={Boolean(formikIn.touched.title) && Boolean(formikIn.errors.title)}
                                    formik={formikIn} label='Receipt Title'
                                    helpertext={formikIn.touched.title ? String(formikIn.errors.title) : ""}
                                    fieldpropsName={'title'}
                                />
                                <TextBox
                                    type='text'
                                    error={Boolean(formikIn.touched.subTitle) && Boolean(formikIn.errors.subTitle)}
                                    formik={formikIn} label='Sub Title'
                                    helpertext={formikIn.touched.subTitle ? String(formikIn.errors.subTitle) : ""}
                                    fieldpropsName={'subTitle'}
                                />
                                <TextBox
                                    type='text'
                                    error={Boolean(formikIn.touched.footer) && Boolean(formikIn.errors.footer)}
                                    formik={formikIn} label='Footer'
                                    helpertext={formikIn.touched.footer ? String(formikIn.errors.footer) : ""}
                                    fieldpropsName={'footer'}
                                />
                                <Box sx={{ width: '100%' }}>
                                    <Grid container>
                                        <Grid xs={4} />
                                        <Grid item md={3} xs={3} >
                                            <Button sx={{
                                                height: '40px',
                                                width: '100%',
                                                marginLeft: '15%',
                                                marginTop: '8%',
                                            }}
                                                variant='contained'
                                                onClick={() => formikIn.handleSubmit()}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item md={4.7} height={{ md: '78%', xs: 'auto' }} xs={12} display={{ xs: 'flex', md: 'flex' }} >
                            <Card style={{ height: '78%', width: '80%', marginLeft: '10%' }} elevation={1} sx={{ backgroundColor: theme.palette.secondary.light, padding: '36px 0px 10px 0px' }}>
                                <Grid container justifyContent='center' marginTop='-13%' >
                                    <Grid md={10} sx={{ backgroundColor: theme.palette.secondary.light, padding: '36px 0px 0px 10px' }}>
                                        <Typography sx={{ marginBottom: '4%', textAlign: 'center', marginTop: '-4%', }}>In Token</Typography>
                                        <InTokenContent setStartPrint={setStartPrint} printRef={printRef} isPrint={false} printData={inTokenData} />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    :
                    <Grid container gap={1}>
                        <Grid item xs={11.6} md={7}>
                            <form>
                                <TextBox
                                    type='text'
                                    error={Boolean(formikOut.touched.outTitle) && Boolean(formikOut.errors.outTitle)}
                                    formik={formikOut} label='Receipt Title'
                                    helpertext={formikOut.touched.outTitle ? String(formikOut.errors.outTitle) : ""}
                                    fieldpropsName={'outTitle'}
                                />
                                <TextBox
                                    type='text'
                                    error={Boolean(formikOut.touched.outSubTitle) && Boolean(formikOut.errors.outSubTitle)}
                                    formik={formikOut} label='Sub Title'
                                    helpertext={formikOut.touched.outSubTitle ? String(formikOut.errors.outSubTitle) : ""}
                                    fieldpropsName={'outSubTitle'}
                                />
                                <TextBox
                                    type='text'
                                    error={Boolean(formikOut.touched.outFooter) && Boolean(formikOut.errors.outFooter)}
                                    formik={formikOut} label='Footer'
                                    helpertext={formikOut.touched.outFooter ? String(formikOut.errors.outFooter) : ""}
                                    fieldpropsName={'outFooter'}
                                />
                                <Box sx={{ width: '100%' }}>
                                    <Grid container>
                                        <Grid xs={4} />
                                        <Grid item md={3} xs={3} >
                                            <Button sx={{
                                                height: '40px',
                                                width: '100%',
                                                marginLeft: '15%',
                                                marginTop: '8%'
                                            }}
                                                variant='contained'
                                                onClick={() => formikOut.handleSubmit()}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item md={4.7} xs={12} height={{ md: '78%', xs: 'auto' }} display={{ xs: 'flex', md: 'flex' }} sx={{ marginBottom: '2% ' }} >
                            <Card style={{ height: '100%', width: '80%', marginLeft: '10%' }} elevation={1} sx={{ backgroundColor: theme.palette.secondary.light, padding: '36px 0px 10px 0px' }}>
                                <Grid container justifyContent='center' marginTop='-13%' >
                                    <Grid md={10} sx={{ backgroundColor: theme.palette.secondary.light, padding: '36px 0px 0px 10px' }}>
                                        <Typography sx={{ marginBottom: '4%', textAlign: 'center', marginTop: '-4%', }}>Out Token</Typography>
                                        <OutTokenContent setStartPrint={setStartPrint} printRef={printRef} isPrint={false} printData={outTokenData} />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <ToastMessage
                showToastMessage={toastMessage.showMessage}
                setToastMessage={setToastMessage}
                message={toastMessage.message}
                alertType={toastMessage.alertType}
            // placement={{ vertical: "top", horizontal: "left" }}
            />
            <Loading openModal={isLoading} />
        </Box>
    )
}

export default Receipt
