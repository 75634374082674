import * as React from "react";
import Box from "@mui/material/Box";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import dayjs, { Dayjs } from "dayjs";
import { Typography, useTheme } from "@mui/material";
import duration from "dayjs/plugin/duration";
import Slider from "./Slider";
import { AmountCalculation } from "../../../utilities/AmountCalculation/amountCalculation";
import { planDetailsModel } from "../../../utilities/AmountCalculation/Model/amountCalculationModels";
import { useEffect } from "react";

dayjs.extend(duration);
interface Props {
  formik: FormikProps<PlanFormikInputs>;
  activeRegisteredVehicle: PlanFormikInputs;
}

interface Mark {
  value: number;
  label: string;
}

const SliderCard: React.FC<Props> = (props: Props) => {
  const { formik, activeRegisteredVehicle } = props;
  const theme = useTheme();
  const amountCalculation = new AmountCalculation();

  const [chargeAmount, setChargeAmount] = React.useState(0);

  let max = 1;
  let divider = 1;
  if (formik.values.scheduleType === "hour") {
    max = 4320; // Three day in minutes
    divider = 1440; // One day in minutes
  } else if (formik.values.scheduleType === "day") {
    max = 10080; // One week in minutes
    divider = 1440; // One day in minutes
  } else {
    max = 40320; // One month in minutes
    divider = 10080; // One week in minutes
  }

  let marks: Mark[] = [
    {
      label: formik.values.scheduleType !== "week" ? "Day 0" : "Week 0",
      value: 0,
    },
  ];
  for (let i = 1; i <= Math.floor(max / divider); i++) {
    let value;
    if (formik.values.scheduleType === "hour") {
      value = { label: `Day ${i}`, value: i * divider };
    } else if (formik.values.scheduleType === "day") {
      value = { label: `Day ${i}`, value: i * divider };
    } else {
      value = { label: `Week ${i}`, value: i * divider };
    }
    marks.push(value);
  }

  const startDateTime = dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
    .add(formik.values.exampleParkInValue, "minutes")
    .toDate();
  const startDateTimeStr = dayjs(startDateTime).format(
    "ddd DD MMM YYYY hh:mm:ss A"
  );

  const endDateTime = dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
    .add(formik.values.exampleParkOutValue, "minutes")
    .toDate();
  const endDateTimeStr = dayjs(endDateTime).format(
    "ddd DD MMM YYYY hh:mm:ss A"
  );

  const getDuration = () => {
    const difference = dayjs.duration(
      dayjs(endDateTimeStr).diff(dayjs(startDateTimeStr))
    );
    let durationParts = [];
    const weeks = difference.weeks();
    const days = weeks > 0 ? difference.days() % 7 : difference.days();
    const hours = difference.hours();
    const minutes = difference.minutes();
    if (weeks > 0) {
      durationParts.push(`${weeks} Week${weeks > 1 ? "s" : ""}`);
    }
    if (days > 0) {
      durationParts.push(`${days} Day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      durationParts.push(`${hours} Hour${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      durationParts.push(`${minutes} Minute${minutes > 1 ? "s" : ""}`);
    }
    return durationParts.join(" ");
  };

  if (max < formik.values.exampleParkInValue) {
    formik.setFieldValue("exampleParkInValue", 0);
  }

  if (max < formik.values.exampleParkOutValue) {
    formik.setFieldValue("exampleParkOutValue", max);
  }

  const getScheduleType = (scheduleType: string): string => {
    if (scheduleType === "hour") {
      if (formik.values.scheduleTypeHourValueDN) {
        return "DN";
      } else {
        return `H${formik.values.scheduleTypeHourValue}`;
      }
    } else if (scheduleType === "day") {
      return `D${formik.values.scheduleTypeDayValue}`;
    } else {
      return `W${formik.values.scheduleTypeWeekValue}`;
    }
  };

  const livePreviewCalculationParams: planDetailsModel = {
    inTime: startDateTime,
    outTime: endDateTime,
    dayParkingFrom: formik.values.dayParkingFrom,
    dayParkingTo: formik.values.dayParkingTo,
    nightParkingFrom: formik.values.nightParkingFrom,
    nightParkingTo: formik.values.nightParkingTo,
    scheduleType: getScheduleType(formik.values.scheduleType),
    basicAmount: formik.values.basicAmount,
    actualAmount:
      formik.values.actualAmount === "" ? null : formik.values.actualAmount,
    dayBasicAmount: formik.values.dayBasicAmount,
    dayActualAmount:
      formik.values.dayActualAmount === "" ? 0 : formik.values.dayActualAmount,
    nightBasicAmount: formik.values.nightBasicAmount,
    nightActualAmount:
      formik.values.nightActualAmount === ""
        ? 0
        : formik.values.nightActualAmount,
    dayMinimumHour: `H${formik.values.dayMinimumHour}`,
    nightMinimumHour: `H${formik.values.nightMinimumHour}`,
    dayMinimumMinutes: `M${formik.values.dayMinimumMinutes}`,
    nightMinimumMinutes: `M${formik.values.nightMinimumMinutes}`,
    dailyMinimumHour: `H${formik.values.dailyMinimumHour}`,
    weeklyMinimumDay: `D${formik.values.weeklyMinimumDay}`,
    dayChargeType: formik.values.dayChargeType,
  };

  const previewCalculationParams: planDetailsModel = {
    inTime: startDateTime,
    outTime: endDateTime,
    dayParkingFrom: formik.values.dayParkingFrom,
    dayParkingTo: formik.values.dayParkingTo,
    nightParkingFrom: formik.values.nightParkingFrom,
    nightParkingTo: formik.values.nightParkingTo,
    scheduleType: getScheduleType(activeRegisteredVehicle.scheduleType),
    basicAmount: activeRegisteredVehicle.basicAmount,
    actualAmount:
      activeRegisteredVehicle.actualAmount === ""
        ? null
        : activeRegisteredVehicle.actualAmount,
    dayBasicAmount: activeRegisteredVehicle.dayBasicAmount,
    dayActualAmount:
      activeRegisteredVehicle.dayActualAmount === ""
        ? 0
        : activeRegisteredVehicle.dayActualAmount,
    nightBasicAmount: activeRegisteredVehicle.nightBasicAmount,
    nightActualAmount:
      activeRegisteredVehicle.nightActualAmount === ""
        ? 0
        : activeRegisteredVehicle.nightActualAmount,
    dayMinimumHour: `H${activeRegisteredVehicle.dayMinimumHour}`,
    nightMinimumHour: `H${activeRegisteredVehicle.nightMinimumHour}`,
    dayMinimumMinutes: `M${activeRegisteredVehicle.dayMinimumMinutes}`,
    nightMinimumMinutes: `M${activeRegisteredVehicle.nightMinimumMinutes}`,
    dailyMinimumHour: `H${activeRegisteredVehicle.dailyMinimumHour}`,
    weeklyMinimumDay: `D${activeRegisteredVehicle.weeklyMinimumDay}`,
    dayChargeType: activeRegisteredVehicle.dayChargeType,
  };

  const getChargeAmount = () => {
    let result = 0;
    if (formik.values.livePreview && formik.values.planType === "fixed") {
      result = amountCalculation.fixedPlanCalculation(
        livePreviewCalculationParams
      ).totalAmount;
    } else if (
      !formik.values.livePreview &&
      formik.values.planType === "fixed"
    ) {
      result = amountCalculation.fixedPlanCalculation(previewCalculationParams).totalAmount;
    }
    return result;
  };

  useEffect(() => {
    setChargeAmount(getChargeAmount);
  }, [formik.values]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Slider
          startValue={formik.values.exampleParkInValue}
          endValue={formik.values.exampleParkOutValue}
          min={0}
          max={max}
          onChangeSliderValue={(startValue: number, endValue: number) => {
            formik.setFieldValue("exampleParkInValue", startValue);
            formik.setFieldValue("exampleParkOutValue", endValue);
          }}
          marks={marks}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ margin: "10px 0 0 0" }}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" sx={{ margin: "0 0 10px 0" }}>
            Parking In Time :
            <span style={{ fontWeight: 600 }}>{startDateTimeStr}</span>
          </Typography>
          <Typography variant="h5" sx={{ margin: "0 0 10px 0" }}>
            Parking Out Time :
            <span style={{ fontWeight: 600 }}>{endDateTimeStr}</span>
          </Typography>
          <Typography variant="h5" sx={{ margin: "0 0 10px 0" }}>
            Parking Duration :
            <span style={{ fontWeight: 600 }}>{getDuration()}</span>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "normal",
            }}
          >
            Charge Amount :
            <span style={{ fontWeight: 600 }}>&#8377; {chargeAmount}</span>
          </Typography>
          {/* {formik.values.planType === "fixed" &&
          formik.values.scheduleType === "hour" ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                Day Charge: {"100"}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                Night Charge: {"150"}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                Total Charge: {"250"}
              </Typography>
            </>
          ) : formik.values.scheduleType === "day" ? (
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              Total Amount: {"300"}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              Total Amount: {"400"}
            </Typography>
          )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default SliderCard;
