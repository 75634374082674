import { Box, Card } from "@mui/material";
import CreateButtonsCard from "./CreateButtonsCard";
import UpcomingPlans from "./UpcomingPlans";
import { PlanDetailsModel } from "../../../Model/PlanModel";

interface Props {
  upcomingPlanDetails: PlanDetailsModel[];
}

const OtherPlans = (props: Props) => {
  const { upcomingPlanDetails } = props;

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <CreateButtonsCard />
      <UpcomingPlans upcomingPlanDetails={upcomingPlanDetails} />
    </Box>
  );
};

export default OtherPlans;
