import { Insights, PushPin } from "@mui/icons-material";
import { Avatar, Box, Card, Grid, Tooltip, Typography } from "@mui/material";
import { SlabPlanTypeModel } from "../../../Model/PlanModel";
import { GenericVehicleModel } from "../../../Model/TokenModel";

interface Props {
  plan: SlabPlanTypeModel;
  vehicleTypeName: string;
  iconUrl: string;
}

const SlabPlanVehicleCard = (props: Props) => {
  const { plan, vehicleTypeName, iconUrl } = props;

  const getScheduleType = (
    minutes: number | undefined,
    type: number | undefined
  ) => {
    let value = "";
    switch (type) {
      case 1:
        value = minutes + " Minute";
        break;
      case 2:
        value = Number(minutes) / 60 + " Hour";
        break;
      case 3:
        value = Number(minutes) / 1140 + " Day";
        break;
      case 4:
        value = Number(minutes) / 10080 + " Week";
        break;
      case 5:
        value = Number(minutes) / 302400 + " Month";
        break;

      default:
        value = "Invalid";
        break;
    }
    return value;
  };

  return (
    <Grid item xs={12} sm={6} md={12} lg={6}>
      <Card
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "transparent",
          // boxShadow: "none",
          // margin: "auto",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Tooltip title={vehicleTypeName}>
            <img src={iconUrl} alt="" height="50px" />
          </Tooltip>
          {/* <Typography variant="h5">Fixed</Typography> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ margin: "0 0 0 15px" }}
        >
          <Typography variant="caption">
            Schedule :
            <span style={{ fontWeight: 600 }}>{`${getScheduleType(
              plan.planStart,
              plan.startType
            )} to ${getScheduleType(plan.planEnd, plan.endType)}`}</span>
          </Typography>
          {/* <Typography variant="caption">
            Minimum Charge :
            <span style={{ fontWeight: 600 }}>
              &#8377; {plan.minimumCharge}
            </span>
          </Typography> */}
          <Typography variant="caption">
            Charge :
            <span style={{ fontWeight: 600 }}>&#8377; {plan.charges}</span>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Tooltip title="Slab Plan">
            <Avatar sx={{ backgroundColor: "transparent" }}>
              <Insights />
            </Avatar>
          </Tooltip>
        </Box>
      </Card>
    </Grid>
  );
};

export default SlabPlanVehicleCard;
