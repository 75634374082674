import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Collapse,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import EmojiTransportationRoundedIcon from "@mui/icons-material/EmojiTransportationRounded";
import { BarChart } from "@mui/x-charts/BarChart";
import { GetDashboardDetails } from "../../Redux/Thunks/DashboardThunk";
import dayjs from "dayjs";
import DashboardSubscription from "./DashboardSubscription";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Lottie from "react-lottie";
import Animation from "../../assets/subscription.json";
import Loading from "../../components/Loading/Loading";
import DashboardContact from "./DashboardContact";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import DashboardDetail from "./DashboardDetail";
import { ParkingLotVehicleModel } from "../../Model/DashboardReportModel";
import DashboardCurrentStatus from "./DashboardCurrentStatus";
import {
  getLastMonthDateRange,
  getLastWeekDateRange,
  getThisMonthDateRange,
  getThisWeekDateRange,
  getYesterdayDateRange,
} from "../../utilities/appUtils";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const amount = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "7px",
  marginTop: "15px",
  backgroundColor: "transparent",
  outline: "1px solid #ccc",
  borderRadius: "15px",
  padding: "12px",
  boxShadow: "0px 10px 15px 0px rgba(46, 37, 134, 0.24)",
};

const inOut = {
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  borderRadius: "15px",
  background: "transparent",
  boxShadow: "0px 2px 2px 0px rgba(46, 37, 134, 0.24)",
  padding: "5px",
  marginTop: "10px",
};

const parkingLotDetails = {
  color: "#282f79",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 500,
};

const contact = {
  borderRadius: "20px",
  background: "transparent",
  boxShadow: "0px 10px 15px 0px rgba(46, 37, 134, 0.24)",
  outline: "1px solid #ccc",
  padding: "10px",
  marginTop: "5px",
  cursor: "pointer",
};

const top = {
  marginTop: "10px",
};

const alertError = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "red",
  outline: "1px solid red",
  borderRadius: "20px",
  marginTop: "10px",
  padding: "4px 13px",
  textTransform: "capitalize",
};
const alertWarning = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#e77818",
  outline: "1px solid #e77818",
  borderRadius: "20px",
  marginTop: "10px",
  padding: "4px 13px",
  textTransform: "capitalize",
};
const alertSuccess = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#10a600",
  outline: "1px solid #10a600",
  borderRadius: "20px",
  marginTop: "10px",
  padding: "4px 13px",
  textTransform: "capitalize",
};

const lottie = {
  marginTop: "10px",
  height: "200px",
  width: "100%",
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface chart {
  inData: number[];
  outData: number[];
  reportDates: string[];
}

const Dashboard = () => {
  const { parkingLot, user } = useAppSelector((state) => state.profile);
  const { dashboardReport, isLoading } = useAppSelector(
    (state) => state.dashboard
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const date = new Date();
  const sunday = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() - 7
  );

  const [dateRange, setDateRange] = useState({
    startDate: getThisWeekDateRange().fromDate,
    endDate: getThisWeekDateRange().toDate,
  });
  const [selectedDateRange, setSelectedDateRange] = useState("thisWeek");
  const [chartData, setChartData] = useState<chart>({
    inData: [0, 0, 0],
    outData: [0, 0, 0],
    reportDates: [] as string[],
  });

  useEffect(() => {
    if (parkingLot) {
      getParkingLotData();
    }
  }, [dateRange, parkingLot]);

  const getParkingLotData = async () => {
    let props = {
      ParkingLotId: parkingLot?.parkingLotId,
      StartDate: dateRange.startDate,
      EndDate: dateRange.endDate,
    };
    const response = await dispatch(GetDashboardDetails(props));
  };

  useEffect(() => {
    if (dashboardReport.lastThreeDays.length > 0) {
      const inData = dashboardReport.lastThreeDays.map((item) => {
        return item.totalParkedIn;
      });
      const outData = dashboardReport.lastThreeDays.map((item) => {
        return item.totalParkedOut;
      });
      const dates = dashboardReport.lastThreeDays.map((item: any) => {
        return dayjs(item.reportDate).format("DD-MM-YYYY");
      });
      setChartData({
        inData: inData as unknown as number[],
        outData: outData as unknown as number[],
        reportDates: dates,
      });
    }
  }, [dashboardReport.lastThreeDays]);

  const handleChangeDate = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    setSelectedDateRange(selectedValue);
    switch (selectedValue) {
      case "yesterday":
        startDate = getYesterdayDateRange().fromDate;
        endDate = getYesterdayDateRange().toDate;
        break;
      case "thisWeek":
        startDate = getThisWeekDateRange().fromDate;
        endDate = getThisWeekDateRange().toDate;
        break;
      case "lastWeek":
        startDate = getLastWeekDateRange().fromDate;
        endDate = getLastWeekDateRange().toDate;
        break;
      case "thisMonth":
        startDate = getThisMonthDateRange().fromDate;
        endDate = getThisMonthDateRange().toDate;
        break;
      case "lastMonth":
        startDate = getLastMonthDateRange().fromDate;
        endDate = getLastMonthDateRange().toDate;
        break;
      default:
        startDate = new Date();
        endDate = new Date();
    }
    setDateRange({ startDate, endDate });
  };

  const onClickNotificationCard = () => {
    navigate("/agingReport");
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Loading openModal={isLoading} />
        <Grid container>
          <Grid xs={12} md={8}>
            <Item>
              <DashboardCurrentStatus />
              <Box sx={amount}>
                <Grid container>
                  <Grid xs={12} md={5}>
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Filter Days
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedDateRange}
                          label="Days Of Parking"
                          onChange={handleChangeDate}
                        >
                          {/* <MenuItem value="yesterday">Yesterday</MenuItem> */}
                          <MenuItem value="thisWeek">This Week</MenuItem>
                          <MenuItem value="lastWeek">Last Week</MenuItem>
                          <MenuItem value="thisMonth">This Month</MenuItem>
                          <MenuItem value="lastMonth">Last Month</MenuItem>
                        </Select>
                      </FormControl>
                      <Box>
                        <Box>
                          <Typography variant="h5">
                            From :{" "}
                            {dateRange.startDate
                              ? dateRange.startDate.toLocaleDateString()
                              : ""}{" "}
                            To :{" "}
                            {dateRange.endDate
                              ? dateRange.endDate.toLocaleDateString()
                              : ""}
                          </Typography>
                        </Box>
                        <Box sx={top}>
                          <Grid container>
                            <Grid xs={12} md={12}>
                              <Box sx={inOut}>
                                <EmojiTransportationRoundedIcon></EmojiTransportationRoundedIcon>
                                <Typography variant="h5">
                                  IN{" "}
                                  <span
                                    style={{
                                      verticalAlign: "super",
                                      fontSize: "16px",
                                      color: "#f01911",
                                    }}
                                  >
                                    {dashboardReport?.totalParkedIn === null
                                      ? 0
                                      : dashboardReport.totalParkedIn}
                                  </span>{" "}
                                </Typography>
                                <Typography variant="h5">
                                  OUT{" "}
                                  <span
                                    style={{
                                      verticalAlign: "super",
                                      fontSize: "16px",
                                      color: "#f01911",
                                    }}
                                  >
                                    {dashboardReport?.totalParkedOut === null
                                      ? 0
                                      : dashboardReport.totalParkedOut}
                                  </span>{" "}
                                </Typography>
                                <Typography variant="h5">
                                  ₹{dashboardReport?.totalPaymentReceived}
                                </Typography>
                              </Box>
                              <Box sx={lottie}>
                                <Lottie options={defaultOptions} />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <Box>
                      <BarChart
                        height={290}
                        series={[
                          {
                            data: chartData.inData,
                            color: "#ff7000",
                            label: "In",
                          },
                          {
                            data: chartData.outData,
                            color: "#f7e152",
                            label: "Out",
                          },
                        ]}
                        xAxis={[
                          {
                            data: chartData.reportDates,
                            scaleType: "band",
                          },
                        ]}
                        margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
          <Grid xs={12} md={4}>
            <Item>
              <Box>
                <Box>
                  <DashboardSubscription />
                </Box>
                <Box sx={contact} onClick={onClickNotificationCard}>
                  <Typography sx={parkingLotDetails}>
                    Parking lot notification history
                    <Box>
                      {dashboardReport.notificationReport && (
                        <Box
                          sx={
                            dashboardReport.notificationReport
                              .eightDaysAgoVehicleCount > 0
                              ? alertWarning
                              : alertSuccess
                          }
                        >
                          <WarningAmberIcon />
                          <Typography>
                            {`${dashboardReport.notificationReport.eightDaysAgoVehicleCount} vehicles parked for over eight days`}
                          </Typography>
                        </Box>
                      )}
                      {dashboardReport.notificationReport && (
                        <Box
                          sx={
                            dashboardReport.notificationReport
                              .twentyDaysAgoVehicleCount > 0
                              ? alertError
                              : alertSuccess
                          }
                        >
                          <WarningAmberIcon />
                          <Typography>
                            {`${dashboardReport.notificationReport.twentyDaysAgoVehicleCount} vehicles parked for over twenty days`}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Typography>
                </Box>
                <Box>
                  <DashboardContact />
                </Box>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default Dashboard;
