import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Slide, TextField, Typography } from '@mui/material';
import TokenSettingsCard from '../../../components/TokenSettingsCard/TokenSettingsCard';
import theme from '../../../Theme/Theme';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { ChangeTokenSettings, getParkinglotSettingsDetails } from '../../../Redux/Thunks/SettingsThunk';
import { ParkinglotSettingsModal, constSettingValues } from '../../../Model/Settings/TokenModal';
import { TransitionProps } from '@mui/material/transitions';
import Loading from '../../../components/Loading/Loading';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TokenSettings() {

    const { Account, ParkingLotSettings, isLoading } = useAppSelector((state: any) => state.settings);
    const dispatch: any = useAppDispatch();

    const [tokenInPayment, setTokenInPayment] = useState<any>(null);
    const [tokenOutPayment, setTokenOutPayment] = useState<any>(null);
    const [ignoreMobile, setIgnoreMobile] = useState<any>(null);
    const [ignoreVnumber, setIgnoreVnumber] = useState<any>(null);
    const [inReceipt, setInReceipt] = useState<any>(null);
    const [outReceipt, setOutReceipt] = useState<any>(null);
    const [graceActive, setGraceActive] = useState<any>(null);
    const [graceBothInSame, setGraceBothInSame] = useState<any>(null);
    const [graceInterval, setGraceInterval] = useState<any>(null);
    const [graceTimeGap, setGraceTimeGap] = useState('0');
    const [helmetHourValue, setHelmetHourValue] = useState('0');
    const [helmetAmountValue, setHelmetAmountValue] = useState('0');
    const [helmetHour, setHelmetHour] = useState<any>(null);
    const [helmetAmount, setHelmetAmount] = useState<any>(null);
    const [enterOtp, setEntryOtp] = useState<any>(null);
    const [currentSetting, setCurrentSetting] = useState<ParkinglotSettingsModal>(
        {
            id: 0,
            parkingLotId: '',
            settingGroupKey: '',
            settingKey: '',
            settingValue: 0,
            lastUpdatedBy: '',
            lastUpdatedOn: new Date()
        }
    );
    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {
        let apiQuery = '';
        if (Account && Account.parkingLotDetails) {
            apiQuery = 'parkingLotId=' + Account.parkingLotDetails.parkingLotId;
        }
        getTokenSettings();
        getParkinglotSettingsDetails(apiQuery);
    }, [Account?.parkingLotDetails?.parkingLotId]);
    useEffect(() => {
        if (graceInterval && Object.keys(graceInterval).length > 0) {
            setGraceTimeGap(graceInterval.settingValue)
        }
        if (helmetHour && Object.keys(helmetHour).length > 0) {
            setHelmetHourValue(helmetHour.settingValue)
            setHelmetAmountValue(helmetAmount.settingValue)
        }
    }, [graceInterval]);

    useEffect(() => {
        setTokenInPayment(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Token_in_payment));
        setTokenOutPayment(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_Key_Token_out_payment));
        setIgnoreMobile(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_Key_Token_in_dummy_mobile));
        setIgnoreVnumber(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_Key_Token_in_dummy_register_number));
        setInReceipt(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Token_in_receipt));
        setOutReceipt(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_Key_Token_out_receipt));
        setEntryOtp(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_Key_Token_in_otp));
        setGraceInterval(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Token_grace_interval));
        setGraceActive(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Token_grace_active));
        setGraceBothInSame(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Token_grace_both_in_same_button));
        setHelmetHour(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Helmet_hour));
        setHelmetAmount(ParkingLotSettings[0].Id !== 0 && ParkingLotSettings.find((element: ParkinglotSettingsModal) =>
            element.settingKey === constSettingValues.settings_key_Helmet_amount));
    }, [ParkingLotSettings])

    const handleClose = () => setOpenModal(false);

    const handleDialogAgree = async () => {
        const param = [{
            "Id": currentSetting.id,
            "ParkingLotId": currentSetting.parkingLotId,
            "SettingGroupKey": currentSetting.settingGroupKey,
            "SettingKey": currentSetting.settingKey,
            "SettingValue": currentSetting.settingValue === 0 ? 1 : 0
        }]
        if (currentSetting.settingKey === 'token_otp' && currentSetting.settingValue === 0) {
            const ignoreMobileParam = [{
                "Id": graceActive.id,
                "ParkingLotId": ignoreMobile.parkingLotId,
                "SettingGroupKey": ignoreMobile.settingGroupKey,
                "SettingKey": ignoreMobile.settingKey,
                "SettingValue": 0
            }]
            await dispatch(ChangeTokenSettings(ignoreMobileParam));
        }
        if (currentSetting.settingKey === "grace_active" && currentSetting.settingValue === 1) {
            const graceBothInSameParam = [{
                "Id": graceBothInSame.id,
                "ParkingLotId": graceBothInSame.parkingLotId,
                "SettingGroupKey": graceBothInSame.settingGroupKey,
                "SettingKey": graceBothInSame.settingKey,
                "SettingValue": 0
            }]
            await dispatch(ChangeTokenSettings(graceBothInSameParam));
        }
        if (currentSetting.settingKey === 'grace_both_in_same_button' && currentSetting.settingValue === 0) {
            const graceActiveParam = [{
                "Id": graceActive.id,
                "ParkingLotId": graceActive.parkingLotId,
                "SettingGroupKey": graceActive.settingGroupKey,
                "SettingKey": graceActive.settingKey,
                "SettingValue": 1
            }]
            await dispatch(ChangeTokenSettings(graceActiveParam));
        }
        await dispatch(ChangeTokenSettings(param));
        getTokenSettings();
        handleClose();
    }

    const getTokenSettings = () => {
        if (Account && Account.parkingLotDetails) {
            let apiQuery = 'parkingLotId=' + Account.parkingLotDetails.parkingLotId;
            dispatch(getParkinglotSettingsDetails(apiQuery));
        }
    }

    const handleToggle = (setting: ParkinglotSettingsModal) => {
        setCurrentSetting(setting);
        setOpenModal(true);
    }

    const handleGraceTime = (data: any, value: string) => {
        const param = [{
            "Id": data.id,
            "ParkingLotId": data.parkingLotId,
            "SettingGroupKey": data.settingGroupKey,
            "SettingKey": data.settingKey,
            "SettingValue": Number(value)
        }]
        dispatch(ChangeTokenSettings(param));
    }

    return (
        <Box sx={{ margin: "10px" }}>
            <Dialog
                open={openModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {currentSetting.settingKey === constSettingValues.settings_Key_Token_in_dummy_mobile && enterOtp.settingValue === 1 ? `Cannot enable 'Ignore Mobile Number' setting while OTP is enabled` : `Are you sure want to change this setting`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{currentSetting.settingKey === constSettingValues.settings_Key_Token_in_dummy_mobile && enterOtp.settingValue === 1 ? `Ok` : `Cancel`}</Button>
                    {currentSetting.settingKey === constSettingValues.settings_Key_Token_in_dummy_mobile && enterOtp.settingValue === 1 ? <></> : <Button onClick={handleDialogAgree}>Agree</Button>}
                </DialogActions>
            </Dialog>
            <Grid container gap={1}>
                <Grid xs={12} md={12}>
                    <div className='avoidable-details' style={{ backgroundColor: theme.palette.background.paper }}>
                        <Typography color={theme.palette.primary.main}>Ignorable options</Typography>
                    </div>
                </Grid>
                {
                    ignoreMobile &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(ignoreMobile)} content='Ignore customer mobile number while entry' value={ignoreMobile.settingValue === 1 ? true : false} />
                    </Grid>
                }
                {
                    ignoreVnumber &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(ignoreVnumber)} content='Ignore customer register number while entry' value={ignoreVnumber.settingValue === 1 ? true : false} />
                    </Grid>
                }
                <Grid xs={12} md={12}>
                    <div className='avoidable-details' style={{ backgroundColor: theme.palette.background.paper }}>
                        <Typography color={theme.palette.primary.main}>Payment options</Typography>
                    </div>
                </Grid>
                {
                    tokenInPayment &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(tokenInPayment)} content='Collect amount while entry' value={tokenInPayment.settingValue === 1 ? true : false} />
                    </Grid>}
                {
                    tokenOutPayment &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(tokenOutPayment)} content='Collect amount while exit' value={tokenOutPayment.settingValue === 1 ? true : false} />
                    </Grid>
                }

                <Grid xs={12} md={12}>
                    <div className='avoidable-details' style={{ backgroundColor: theme.palette.background.paper }}>
                        <Typography color={theme.palette.primary.main}>Receipt options</Typography>
                    </div>
                </Grid>
                {
                    inReceipt &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(inReceipt)} content='Token In' value={inReceipt.settingValue === 1 ? true : false} />
                    </Grid>
                }
                {
                    outReceipt &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(outReceipt)} content='Token Out' value={outReceipt.settingValue === 1 ? true : false} />
                    </Grid>
                }
                <Grid xs={12} md={12}>
                    <div className='avoidable-details' style={{ backgroundColor: theme.palette.background.paper }}>
                        <Typography color={theme.palette.primary.main}>Token out grace time</Typography>
                    </div>
                </Grid>
                {
                    graceActive &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(graceActive)} content='Enable grace time' value={graceActive.settingValue === 1 ? true : false} />
                    </Grid>
                }
                {
                    graceBothInSame &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(graceBothInSame)} content='Show either grace or actual amount' value={graceBothInSame.settingValue === 1 ? true : false} />
                    </Grid>
                }
                <Grid item xs={12} md={5.7}>
                    <Grid container  >
                        <Grid item md={10.4} xs={10}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleGraceTime(graceInterval, graceTimeGap);
                            }}>
                                <TextField
                                    type='number'
                                    value={graceTimeGap}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Grace Interval"
                                    variant="outlined"
                                    onChange={(eve) => setGraceTimeGap(eve.target.value)}
                                />
                            </form>
                        </Grid>
                        <Grid item md={1} xs={2} height={{ xs: '55px' }} width={{ xs: '45px' }} >
                            <Button sx={{
                                height: '100%',
                                width: '100%',
                                marginLeft: '15%',
                                marginTop: '8%'
                            }}
                                variant='contained'
                                disabled={graceInterval ? graceInterval.settingValue == graceTimeGap : false}
                                onClick={() => handleGraceTime(graceInterval, graceTimeGap)}
                            >
                                <CheckCircleTwoToneIcon sx={{ height: '100%', width: '100%' }} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={12}>
                    <div className='avoidable-details' style={{ backgroundColor: theme.palette.background.paper }}>
                        <Typography color={theme.palette.primary.main}>Helmet Settings</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={5.7}>
                    <Grid container  >
                        <Grid item md={10.4} xs={10}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleGraceTime(helmetHour, helmetHourValue);
                            }}>
                                <TextField
                                    type='number'
                                    value={helmetHourValue}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Hours"
                                    variant="outlined"
                                    onChange={(eve) => setHelmetHourValue(eve.target.value)}
                                />
                            </form>
                        </Grid>
                        <Grid item md={1} xs={2} height={{ xs: '55px' }} width={{ xs: '45px' }} >
                            <Button sx={{
                                height: '100%',
                                width: '100%',
                                marginLeft: '15%',
                                marginTop: '8%'
                            }}
                                variant='contained'
                                disabled={helmetHour ? helmetHour.settingValue == helmetHourValue : false}
                                onClick={() => handleGraceTime(helmetHour, helmetHourValue)}
                            >
                                <CheckCircleTwoToneIcon sx={{ height: '100%', width: '100%' }} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5.7}>
                    <Grid container  >
                        <Grid item md={10.4} xs={10}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleGraceTime(helmetAmount, helmetAmountValue);
                            }}>
                                <TextField
                                    type='number'
                                    value={helmetAmountValue}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Amount"
                                    variant="outlined"
                                    onChange={(eve) => setHelmetAmountValue(eve.target.value)}
                                />
                            </form>
                        </Grid>
                        <Grid item md={1} xs={2} height={{ xs: '55px' }} width={{ xs: '45px' }} >
                            <Button sx={{
                                height: '100%',
                                width: '100%',
                                marginLeft: '15%',
                                marginTop: '8%'
                            }}
                                disabled={helmetAmount ? helmetAmount.settingValue == helmetAmountValue : false}
                                variant='contained'
                                onClick={() => handleGraceTime(helmetAmount, helmetAmountValue)}
                            >
                                <CheckCircleTwoToneIcon sx={{ height: '100%', width: '100%' }} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={12}>
                    <div className='avoidable-details' style={{ backgroundColor: theme.palette.background.paper }}>
                        <Typography color={theme.palette.primary.main}>Otp options</Typography>
                    </div>
                </Grid>
                {
                    enterOtp &&
                    <Grid item xs={12} md={5.7}>
                        <TokenSettingsCard onClick={() => handleToggle(enterOtp)} content='OTP In' value={enterOtp.settingValue === 1 ? true : false} />
                    </Grid>
                }
            </Grid>
            <Loading openModal={isLoading} />
        </Box >
    )
}

export default TokenSettings
