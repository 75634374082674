import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import {
  Avatar,
  Grid,
  Slide,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import appLogo from "../../assets/app_logo.png";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ParkingLotAppBar() {
  const navigate = useNavigate();

  const onClickLoco = () => {
    navigate("/");
  };

  return (
    <AppBar
      position="static"
      style={{
        background: "transparent",
        boxShadow: "none",
        minHeight: "10vh",
      }}
    >
      <Toolbar>
        <Grid container>
          <Grid
            item
            xs={6}
            md={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Avatar
              src={appLogo}
              sx={{ width: "140px", cursor: "pointer" }}
              variant="square"
              onClick={onClickLoco}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
