import { Box, Grid, ListItemIcon, Typography, Tooltip } from "@mui/material";
import { ParkingLotVehicleModel } from "../../Model/DashboardReportModel";

const list = {
  display: "flex",
  alignItems: "center",
};

const lot = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  color: "#f94749",
};

interface dashboardDetailsProps {
  vehicleDetails: ParkingLotVehicleModel;
}
const DashboardDetail = (props: dashboardDetailsProps) => {
  let { vehicleDetails } = props;

  return (
    <Box>
      <Box>
        <Grid container>
          <Grid xs={4} md={4}>
            <Box sx={list}>
              <ListItemIcon>
                <Tooltip title={vehicleDetails.vehicleTypeName}>
                  <img src={vehicleDetails.iconUrl} />
                </Tooltip>
              </ListItemIcon>
              <Typography sx={lot}>
                {vehicleDetails.inVehicleCount === null
                  ? 0
                  : vehicleDetails.inVehicleCount}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4} md={4}>
            <Box>
              <Box sx={list}>
                <Typography sx={lot}>
                  {vehicleDetails.outVehicleCount === null
                    ? 0
                    : vehicleDetails.outVehicleCount}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={3} md={3}>
            <Box sx={list}>
              <Typography sx={lot}>
                ₹
                {vehicleDetails.totalPaymentReceived === null
                  ? 0
                  : vehicleDetails.totalPaymentReceived}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardDetail;
