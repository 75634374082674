import { createSlice } from "@reduxjs/toolkit";
import { GetProfileDetails } from "../Thunks/ProfileThunk";
import {
  CompanyModel,
  ParkingLotModel,
  UserModel,
  RolePermission,
} from "../../Model/ProfileModel";
import { Permissions } from "../../Model/Permissions";
import { ReceiptFormatEntity } from "../../Model/ReceiptFormatModel";

interface HasPermissions {
  entry: boolean;
  exit: boolean;
  editInTime: boolean;
  editOutTime: boolean;
  otpOverride: boolean;
}

interface InitialState {
  isLoading: boolean;
  user: UserModel | null;
  parkingLot: ParkingLotModel | null;
  company: CompanyModel | null;
  rolePermission: RolePermission | null;
  permissions: HasPermissions;
  receiptFormat: ReceiptFormatEntity[];
}

let initialState: InitialState = {
  isLoading: false,
  user: null,
  parkingLot: null,
  company: null,
  rolePermission: null,
  permissions: {
    entry: false,
    exit: false,
    editInTime: false,
    editOutTime: false,
    otpOverride: false,
  },
  receiptFormat: []
};

const updatePermissions = (state: InitialState) => {
  state.rolePermission?.permissions.forEach((eachPermission) => {
    switch (eachPermission.permissionType) {
      case Permissions.Entry:
        state.permissions = { ...state.permissions, entry: true };
        break;
      case Permissions.Exit:
        state.permissions = { ...state.permissions, exit: true };
        break;
      case Permissions.Edit_In_Time:
        state.permissions = { ...state.permissions, editInTime: true };
        break;
      case Permissions.Edit_Out_Time:
        state.permissions = { ...state.permissions, editOutTime: true };
        break;
      case Permissions.OTP_Override:
        state.permissions = { ...state.permissions, otpOverride: true };
        break;
      default:
        break;
    }
  });
  return state;
};

const profileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetProfileDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetProfileDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.user = action.payload.data.user;
          state.parkingLot = action.payload.data.parkingLot;
          state.company = action.payload.data.company;
          state.rolePermission = action.payload.data.rolePermission;
          state.receiptFormat = action.payload.data.receiptFormat;
          state = updatePermissions(state);
        }
      })
      .addCase(GetProfileDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const profileReducer = profileSlice.reducer;
