import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import { AlertColor, Typography } from "@mui/material";
import { useState } from "react";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface Props {
  formik: FormikProps<PlanFormikInputs>;
  registeredVehicles: PlanFormikInputs[];
  activeRegisteredVehicle: PlanFormikInputs | undefined;
  setActiveRegisteredVehicle: React.Dispatch<
    React.SetStateAction<PlanFormikInputs | undefined>
  >;
}

const ToggleSwitch = (props: Props) => {
  const {
    formik,
    registeredVehicles,
    activeRegisteredVehicle,
    setActiveRegisteredVehicle,
  } = props;

  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  return (
    <>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            defaultChecked
            checked={formik.values.livePreview}
            onChange={(event, checked) => {
              formik.setFieldValue("livePreview", checked);
              if (checked) {
                formik.setFieldValue("selectedRegisteredVehicleId", 0);
                setActiveRegisteredVehicle(undefined);
              } else {
                if (registeredVehicles.length > 0) {
                  setActiveRegisteredVehicle(registeredVehicles[0]);
                  formik.setFieldValue(
                    "selectedRegisteredVehicleId",
                    registeredVehicles[0].id
                  );
                } else {
                  formik.setFieldValue("livePreview", true);
                  updateToastMessage(
                    true,
                    "Registered generic vehicles not available",
                    "info"
                  );
                }
              }
            }}
          />
        }
        // label={<Typography variant="h5">Live</Typography>}
        label={<Typography variant="h5"></Typography>}
      />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      />
    </>
  );
};
export default ToggleSwitch;
