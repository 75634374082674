import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { GenericVehicleModel } from "../../../Model/TokenModel";
import { FormikProps } from "formik";
import FixedPlan from "./FixedPlan";
import { PlanFormikInputs } from "./AddPlan";
import SlabPlan from "./SlabPlan";
import ToggleSwitch from "./Switch";

interface Props {
  genericVehicles: GenericVehicleModel[];
  formik: FormikProps<PlanFormikInputs>;
  registeredVehicles: PlanFormikInputs[];
  activeRegisteredVehicle: PlanFormikInputs | undefined;
  setActiveRegisteredVehicle: React.Dispatch<
    React.SetStateAction<PlanFormikInputs | undefined>
  >;
}

const AddPlanForm = (props: Props) => {
  const {
    genericVehicles,
    formik,
    registeredVehicles,
    activeRegisteredVehicle,
    setActiveRegisteredVehicle,
  } = props;

  const theme = useTheme();

  const onChangeVehicleList = (
    event: SelectChangeEvent<typeof formik.values.selectedVehiclesList>
  ) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(
      "selectedVehiclesList",
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
      <Card
        sx={{
          borderRadius: "15px",
          background:
            "linear-gradient(84deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
          height: "100%",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box display="flex" flexDirection="column" padding={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <FormControl
              sx={{ width: "80%", overflow: "auto", scrollbarWidth: "thin" }}
            >
              <InputLabel id="genericVehicleType">
                Generic Vehicle Type
              </InputLabel>
              <Select
                labelId="genericVehicleType"
                id="genericVehicleTypeId"
                multiple
                value={formik.values.selectedVehiclesList}
                label="Generic Vehicle Type"
                onChange={onChangeVehicleList}
                renderValue={(selected) => (
                  <Typography variant="caption">
                    {selected
                      .map((value) => {
                        const selectedVehicle = genericVehicles.find(
                          (vehicle) => vehicle.genericVehicleTypeId === value
                        );
                        return selectedVehicle
                          ? selectedVehicle.vehicleTypeName
                          : null;
                      })
                      .filter((name) => name !== null) // Remove null values
                      .join(", ")}
                  </Typography>
                )}
              >
                {genericVehicles &&
                  genericVehicles.length > 0 &&
                  genericVehicles.map((eachVehicle) => (
                    <MenuItem
                      key={eachVehicle.genericVehicleTypeId}
                      value={eachVehicle.genericVehicleTypeId}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">
                        {eachVehicle.vehicleTypeName}
                      </Typography>
                      <Box style={{ height: "30px" }}>
                        <img
                          src={eachVehicle.iconUrl}
                          style={{ height: "100%" }}
                          alt="Schedule"
                        />
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
              {formik.errors.selectedVehiclesList && (
                <FormHelperText sx={{ color: theme.palette.error.main }}>
                  {formik.errors.selectedVehiclesList}
                </FormHelperText>
              )}
            </FormControl>
            <ToggleSwitch
              formik={formik}
              registeredVehicles={registeredVehicles}
              activeRegisteredVehicle={activeRegisteredVehicle}
              setActiveRegisteredVehicle={setActiveRegisteredVehicle}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Plan Type :
              </Typography>
              <Box display="flex" flexDirection="row">
                <Chip
                  label="Fixed"
                  clickable
                  color={
                    formik.values.planType === "fixed" ? "primary" : "default"
                  }
                  onClick={() => formik.setFieldValue("planType", "fixed")}
                  style={{
                    padding: "0 10px",
                    margin: "4px",
                  }}
                />
                <Chip
                  label="Slab"
                  clickable
                  disabled
                  color={
                    formik.values.planType === "slab" ? "primary" : "default"
                  }
                  onClick={() => formik.setFieldValue("planType", "slab")}
                  style={{
                    padding: "0 10px",
                    margin: "4px",
                  }}
                />
              </Box>
            </Box>
            {formik.values.planType === "fixed" && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Schedule Type :
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <Chip
                    label="Hour"
                    clickable
                    color={
                      formik.values.scheduleType === "hour"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => formik.setFieldValue("scheduleType", "hour")}
                    style={{
                      padding: "0 10px",
                      margin: "4px",
                    }}
                  />
                  <Chip
                    label="Day"
                    clickable
                    color={
                      formik.values.scheduleType === "day"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => formik.setFieldValue("scheduleType", "day")}
                    style={{
                      padding: "0 10px",
                      margin: "4px",
                    }}
                  />
                  <Chip
                    label="Week"
                    clickable
                    color={
                      formik.values.scheduleType === "week"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => formik.setFieldValue("scheduleType", "week")}
                    style={{
                      padding: "0 10px",
                      margin: "4px",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {formik.values.planType === "fixed" && <FixedPlan formik={formik} />}
          {formik.values.planType === "slab" && <SlabPlan formik={formik} />}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="flex-end"
          alignSelf="flex-end"
          flexGrow={1}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={() => formik.setFieldValue("action", "addVehicle")}
            sx={{ margin: "15px" }}
          >
            Add Vehicle
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default AddPlanForm;
