import { FormikProps } from "formik";
import { GenericVehicleModel } from "../../../Model/TokenModel";
import { PlanFormikInputs } from "./AddPlan";
import { Box, Divider, Grid, Icon, Typography, useTheme } from "@mui/material";
import { DoNotDisturbAlt } from "@mui/icons-material";
import NewLabel from "../../../components/NewLabel/NewLabel";
import SliderCard from "./SliderCard";
import SliderCardWeekEnd from "./SliderCardWeekEnd";

interface Props {
    formik: FormikProps<PlanFormikInputs>;
    liveGenericVehicles: GenericVehicleModel[];
}

const WeekendLivePreview = (props: Props) => {
    const { liveGenericVehicles, formik } = props;
    const theme = useTheme();

    return (
        <Grid container spacing={2} sx={{ padding: "10px 0 0 0" }}>
            <Grid container>
                <Grid xs={12}>
                    <Box sx={{ marginBottom: '14px', marginTop: '18px' }}>
                        <Divider />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                            Week End Preview
                        </Typography>
                        <Divider />
                    </Box>
                </Grid>
            </Grid>
            {/* <Grid item xs={4}>
                {liveGenericVehicles.length > 0 ? (
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                        sx={{ width: "100%" }}
                    >
                        {liveGenericVehicles.map((eachVehicle) => (
                            <img
                                key={eachVehicle.genericVehicleTypeId}
                                src={eachVehicle.iconUrl}
                                alt="Vehicle Image"
                                style={{
                                    objectFit: "cover",
                                    height: "50px",
                                    margin: "auto",
                                }}
                            />
                        ))}
                    </Box>
                ) : (
                    <Icon sx={{ width: "100%", margin: "auto" }}>
                        <DoNotDisturbAlt />
                    </Icon>
                )}
            </Grid> */}
            {/* <Grid item xs={4}>
                <NewLabel
                    Label="Plan Type"
                    Value={
                        formik.values.planType
                            ? `${formik.values.planType
                                .at(0)
                                ?.toUpperCase()}${formik.values.planType.slice(1)}`
                            : "NA"
                    }
                />
            </Grid> */}
            {/* <Grid item xs={4}>
                <NewLabel
                    Label="Schedule Type"
                    Value={
                        formik.values.scheduleType === "hour"
                            ? formik.values.scheduleTypeHourValueDN
                                ? "Shift Basis"
                                : `Every ${formik.values.scheduleTypeHourValue} Hour`
                            : formik.values.scheduleType === "day"
                                ? `Every ${formik.values.scheduleTypeDayValue} Day`
                                : `Every ${formik.values.scheduleTypeWeekValue} Week`
                    }
                />
            </Grid> */}
            {formik.values.scheduleType === "hour" && (
                <>
                    {/* <Grid item xs={4}>
                        <NewLabel
                            Label="Day Time"
                            Value={`${formik.values.dayParkingFrom} To ${formik.values.dayParkingTo}`}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Night Time"
                            Value={`${formik.values.nightParkingFrom} To ${formik.values.nightParkingTo}`}
                        />
                    </Grid> */}
                    {/* <Grid item xs={4}></Grid> */}
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Day Actual Amount"
                            Value={
                                formik.values.dayActualAmountWeekEnd
                                    ? `₹ ${formik.values.dayActualAmountWeekEnd}`
                                    : "NA"
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Day Basic Amount"
                            Value={`₹ ${formik.values.dayBasicAmountWeekEnd}`}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Day Minimum Minutes"
                            Value={`${formik.values.dayMinimumMinutesWeekEnd} `}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Night Actual Amount"
                            Value={
                                formik.values.nightActualAmountWeekEnd
                                    ? `₹ ${formik.values.nightActualAmountWeekEnd}`
                                    : "NA"
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Night Basic Amount"
                            Value={`₹ ${formik.values.nightBasicAmountWeekEnd}`}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Night Minimum Minutes"
                            Value={`${formik.values.nightMinimumMinutesWeekEnd} `}
                        />
                    </Grid>
                </>
            )}
            {formik.values.scheduleType !== "hour" && (
                <>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Actual Charge"
                            Value={
                                formik.values.actualAmountWeekEnd
                                    ? `₹ ${formik.values.actualAmountWeekEnd}`
                                    : "NA"
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NewLabel
                            Label="Basic Charge"
                            Value={`₹ ${formik.values.basicAmountWeekEnd}`}
                        />
                    </Grid>
                    {formik.values.scheduleType === "day" && (
                        <Grid item xs={4}>
                            <NewLabel
                                Label="Daily Minimum Hour"
                                Value={`${formik.values.dailyMinimumHourWeekEnd} Hour`}
                            />
                        </Grid>
                    )}
                    {formik.values.scheduleType === "week" && (
                        <Grid item xs={4}>
                            <NewLabel
                                Label="Daily Minimum Day"
                                Value={`${formik.values.weeklyMinimumDayWeekEnd} Day`}
                            />
                        </Grid>
                    )}
                    {/* <Grid item xs={4}>
            <NewLabel
              Label="Day Charge Type"
              Value={
                formik.values.dayChargeType === 1
                  ? "24 Hour Day"
                  : "Day of Year"
              }
            />
          </Grid> */}
                </>
            )}
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: "600",
                        color: theme.palette.primary.main,
                        textAlign: "center",
                    }}
                >
                    Example
                </Typography>
                <SliderCardWeekEnd formik={formik} activeRegisteredVehicle={formik.values} />
            </Grid>
        </Grid>
    );
};

export default WeekendLivePreview;
