import { createTheme } from "@mui/material/styles";
import { hover } from "@testing-library/user-event/dist/hover";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F54749",
      light: "#fffbf2",
    },
    secondary: {
      main: "#FFEECA",
      light: "#ffffff"
    },
    background: {
      default: "#733e35",
      paper: "#faf2de",
    },
    text: {
      primary: "#282F79",
      secondary: "#9BA8BB",
    },
    info: {
      main: "#f2f7ff",
      light: "#fc004c",
      dark: "#eb4034",
      contrastText: "#120f0f",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 15,
    fontWeightBold: "bolder",
    h1: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.0rem",
      fontWeight: 500,
      color: "#000000",
      margin: "10px",
    },
    h4: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#282f79",
    },
    h6: {
      fontSize: "1.2rem",
      fontWeight: 600,
      color: "#282f79",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      margin: "5px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#f76b6d",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          // backgroundColor: "#f7faff",
        },
      },
    },
  },
});
export default theme;
