import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import EntryTicket from "./InTicket";
import ExitTicket from "./ExitTicket";
import appBackground from "../../assets/app_background.png";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import Loading from "../../components/Loading/Loading";
import { useSearchParams } from "react-router-dom";
import { getTicketStatus } from "../../Redux/Thunks/AddTokenThunk";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const background = {
  position: "relative",
  minHeight: "100vh",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundImage: `url(${appBackground})`,
    zIndex: -1,
    opacity: 0.2,
  },
};

const TicketContainer = () => {
  const { isLoading, tokenDetails } = useAppSelector((state) => state.addToken);

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  let urlId = searchParams.get("t");

  useEffect(() => {
    if (urlId !== null) {
      dispatch(getTicketStatus(urlId));
    }
  }, [urlId]);

  return (
    <Box sx={[style, background]}>
      {tokenDetails && tokenDetails.status === 1 ? (
        <EntryTicket />
      ) : (
        <ExitTicket />
      )}
      <Loading openModal={isLoading} />
    </Box>
  );
};

export default TicketContainer;
