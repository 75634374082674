import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
import { ApiResponse } from "../utils/ApiResponse";
import { dateProps } from "../../Model/ReportModel";

const url = process.env.REACT_APP_HIVERAPIURL;

export const GetAgingReport = createAsyncThunk<ApiResponse, Date>(
  "Get/agingReport",
  async (date) => {
    try {
      let response = await axios.get(
        `${url}api/Token/AgingReport/${date.toISOString()}`,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);

export const GetReport = createAsyncThunk<ApiResponse, dateProps>(
  "Get/Report",
  async ({ fromDate, toDate }) => {
    try {
      let response = await axios.get(
        `${url}api/Token/Report/${fromDate.toISOString()}/${toDate.toISOString()}`,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);

export const ExportAgingReport = createAsyncThunk<
  void,
  { date: Date; userId: string }
>("Get/agingExport", async ({ date, userId }) => {
  try {
    const downloadUrl = `${url}api/Token/ExportAgingReport/${date.toISOString()}/${userId}`;
    window.location.href = downloadUrl;
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
});

export const ExportReport = createAsyncThunk<
  void,
  { fromDate: Date; toDate: Date; userId: string; }
>("Get/Export", async ({ fromDate, toDate, userId }) => {
  try {
    const downloadUrl = `${url}api/Token/ExportReport/${fromDate.toISOString()}/${toDate.toISOString()}/${userId}`;
    window.location.href = downloadUrl;
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
});
