import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
import {
  ParkingLotData,
  ParkingLotInvitationModel,
} from "../../Model/ParkingLotRegister/ParkingLotRegisterModel";
import { ApiResponse } from "../utils/ApiResponse";
import { VehicleCapacityModel } from "../../Model/ParkingLotRegister/VehicleCapacityModel";
import {
  ParkingLotTypeViewModel,
  parkingLotSetUpModel,
} from "../../Model/ParkingLotRegister/ParkingLotSetup";
import Cookies from "js-cookie";

const url = process.env.REACT_APP_HIVERAPIURL;

export const GetGenericVehicle = createAsyncThunk(
  "parkingLot/getGenericVehicle",
  async () => {
    try {
      const response = await axios.get<VehicleCapacityModel[]>(
        `${url}api/lotCapacity/genericVehicleList`
      );
      return response.data;
    } catch (error: any) {
      throw Error("Error fetching generic vehicles: " + error);
    }
  }
);

export const CreateParkingLotRegister = createAsyncThunk<
  ApiResponse,
  ParkingLotData
>("Post/ParkingLotRegister", async (params) => {
  try {
    const response = await axios.post(
      `${url}api/account/ParkingLotRegister`,
      params
    );
    if (response.data.authToken !== undefined) {
      let tokenObj = JSON.parse(response.data.authToken);
      let total_minutes = Number(Number(tokenObj.expires_in) / 60);
      let total_hours = Number(total_minutes / 60);
      let days: any = Number(total_hours / 24);
      Cookies.set("accessToken", tokenObj.access_token, {
        expires: days,
      });
    }
    return {
      status: true,
      data: response.data.data,
      resultCode: response.status,
      message: response.data.message,
    };
  } catch (error: any) {
    return {
      status: false,
      data: null,
      resultCode: error.response.status,
      message: error.response.data,
    };
  }
});

export const OtpVerification = createAsyncThunk<
  ApiResponse,
  ParkingLotInvitationModel
>("post/OTPConfirmAndUserRegister", async (params) => {
  try {
    const response = await axios.post(
      `${url}api/account/OTPConfirmAndUserRegister`,
      params
    );
    return {
      status: true,
      data: response.data.data,
      resultCode: response.status,
      message: response.data.message,
    };
  } catch (error: any) {
    return {
      status: false,
      data: null,
      resultCode: error.response.status,
      message: error.response.data,
    };
  }
});

export const VerifyInvitationOtp = createAsyncThunk<
  ApiResponse,
  ParkingLotInvitationModel
>("Post/ParkingLotInvitation", async (plan) => {
  try {
    const response = await axios.post(
      `${url}api/account/ParkingLotInvitation`,
      plan
    );
    return {
      status: true,
      data: response.data.data,
      resultCode: response.status,
      message: response.data.message,
    };
  } catch (error: any) {
    return {
      status: false,
      data: null,
      resultCode: error.response.status,
      message: error.response.data,
    };
  }
});

export const GetParkingLotType = createAsyncThunk(
  "parkingLot/getParkingSetup",
  async () => {
    try {
      const response = await axios.get(
        `${url}api/ParkingLotRegisterSetup/ParkingLotSetup`
      );
      return response.data;
    } catch (error: any) {
      throw Error("Error fetching get parking lot type: " + error);
    }
  }
);
