import { AlertColor, Box, Grid } from "@mui/material";
import AddPlanForm from "./AddPlanForm";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../Redux/Store";
import {
  CreatePlan,
  GetPlanInitialDetails,
} from "../../../Redux/Thunks/PlanThunk";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";
import Loading from "../../../components/Loading/Loading";
import { FormikProps, useFormik } from "formik";
import * as yup from "yup";
import { GenericVehicleModel } from "../../../Model/TokenModel";
import {
  AddPlanDetailsModel,
  FixedPlanTypeModel,
  PlanModel,
} from "../../../Model/PlanModel";
import { unwrapResult } from "@reduxjs/toolkit";
import PreviewCard from "./PreviewCard";
import PlanCard from "./PlanCard";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { RequestPlanTypes } from "../../../utilities/Constants/planConstants";

export interface PlanFormikInputs {
  planName: string;
  genericVehicleTypeId: string;
  selectedVehiclesList: string[];
  planType: string;
  scheduleType: string;
  dayParkingFrom: string;
  dayParkingTo: string;
  nightParkingFrom: string;
  nightParkingTo: string;
  scheduleTypeHourValue: number;
  scheduleTypeHourValueWeekEnd: number;
  scheduleTypeHourValueDN: boolean;
  scheduleTypeHourValueDNWeekEnd: boolean;
  scheduleTypeDayValue: number;
  scheduleTypeDayWeekEndValue: number;
  scheduleTypeWeekValue: number;
  scheduleTypeWeekValueWeekEnd: number;
  WeekDays: number[];
  IsWeekdayOrWeekendPlan: boolean;
  basicAmount: number;
  basicAmountWeekEnd: number;
  actualAmount: number | "";
  actualAmountWeekEnd: number | "";
  dayBasicAmount: number;
  dayBasicAmountWeekEnd?: number;
  dayActualAmount: number | "";
  dayActualAmountWeekEnd?: number | "";
  nightBasicAmount: number;
  nightBasicAmountWeekEnd?: number;
  nightActualAmount: number | "";
  nightActualAmountWeekEnd?: number | "";
  dayMinimumMinutes: number;
  dayMinimumMinutesWeekEnd?: number;
  nightMinimumMinutes: number;
  nightMinimumMinutesWeekEnd?: number;
  dayMinimumHour: number;
  dayMinimumHourWeekEnd?: number;
  nightMinimumHour: number;
  nightMinimumHourWeekEnd?: number;
  dayChargeType: number;
  dailyMinimumHour: number;
  dailyMinimumHourWeekEnd: number;
  weeklyMinimumDay: number;
  weeklyMinimumDayWeekEnd: number;
  //Additional Details
  action: string;
  id: number;
  livePreview: boolean;
  selectedRegisteredVehicleId: number;
  activeGenericVehicle: GenericVehicleModel | undefined;
  exampleParkInValue: number;
  exampleParkInValueWeekEnd: number;
  exampleParkOutValue: number;
  exampleParkOutValueWeekEnd: number;
}

const AddPlan = () => {
  const { isLoading, genericVehicles, lastPlan } = useAppSelector(
    (state) => state.plan
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showDayNightTime, setShowDayNightTime] = useState(false);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });
  const [registeredVehicles, setRegisteredVehicles] = useState<
    PlanFormikInputs[]
  >([]);
  const [activeRegisteredVehicle, setActiveRegisteredVehicle] = useState<
    PlanFormikInputs | undefined
  >(undefined);
  const [focusedFieldName, setFocusedFieldName] = useState<string | null>(null);

  const formik: FormikProps<PlanFormikInputs> = useFormik<PlanFormikInputs>({
    initialValues: {
      planName: "",
      genericVehicleTypeId: "",
      selectedVehiclesList: [],
      planType: "fixed",
      IsWeekdayOrWeekendPlan: false,
      WeekDays: [1, 5],
      scheduleType: "hour",
      dayParkingFrom: "",
      dayParkingTo: "",
      nightParkingFrom: "",
      nightParkingTo: "",
      scheduleTypeHourValue: 1,
      scheduleTypeHourValueWeekEnd: 1,
      scheduleTypeHourValueDN: false,
      scheduleTypeHourValueDNWeekEnd: false,
      scheduleTypeDayValue: 1,
      scheduleTypeDayWeekEndValue: 1,
      scheduleTypeWeekValue: 1,
      scheduleTypeWeekValueWeekEnd: 1,
      basicAmount: 0,
      basicAmountWeekEnd: 0,
      actualAmount: "",
      actualAmountWeekEnd: "",
      dayBasicAmount: 0,
      dayBasicAmountWeekEnd: 0,
      dayActualAmount: "",
      dayActualAmountWeekEnd: "",
      nightBasicAmount: 0,
      nightBasicAmountWeekEnd: 0,
      nightActualAmount: "",
      nightActualAmountWeekEnd: "",
      dayMinimumMinutes: 15,
      dayMinimumMinutesWeekEnd: 15,
      nightMinimumMinutes: 15,
      nightMinimumMinutesWeekEnd: 15,
      dayMinimumHour: 1,
      dayMinimumHourWeekEnd: 1,
      nightMinimumHour: 1,
      nightMinimumHourWeekEnd: 1,
      dayChargeType: 1,
      dailyMinimumHour: 1,
      dailyMinimumHourWeekEnd: 1,
      weeklyMinimumDay: 1,
      weeklyMinimumDayWeekEnd: 1,
      //Additional Details
      action: "addVehicle",
      id: 0,
      livePreview: true,
      selectedRegisteredVehicleId: 0,
      activeGenericVehicle: undefined,
      exampleParkInValue: 0,
      exampleParkInValueWeekEnd: 0,
      exampleParkOutValue: 1440,
      exampleParkOutValueWeekEnd: 1440,
    },
    validationSchema: yup.object({
      planName: yup.string().when("action", {
        is: (value: string) => value === "addPlan" || value === "addPlanAndSchedule",
        then: (schema) =>
          schema
            .min(3, "Plan name must be at least 3 characters")
            .required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      genericVehicleTypeId: yup.string(),
      selectedVehiclesList: yup.array().when("action", {
        is: (action: string) => action === "addVehicle",
        then: (schema) =>
          schema
            .of(yup.string().required("Required"))
            .required("Choose generic vehicle type")
            .min(1, "Choose generic vehicle type"),
        otherwise: (schema) => schema.notRequired(),
      }),
      planType: yup.string().when("action", {
        is: (action: string) => action === "addVehicle",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      scheduleType: yup.string().when(["action", "planType"], {
        is: (action: string, planType: string) =>
          action === "addVehicle" && planType === "fixed",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      dayParkingFrom: yup
        .string()
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      dayParkingTo: yup.string().when(["action", "planType", "scheduleType"], {
        is: (action: string, planType: string, scheduleType: string) =>
          action === "addVehicle" &&
          planType === "fixed" &&
          scheduleType === "hour",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      nightParkingFrom: yup
        .string()
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      nightParkingTo: yup
        .string()
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      scheduleTypeHourValue: yup
        .number()
        .min(1, "Hour must be between 1 and 24")
        .max(24, "Hour must be between 1 and 24")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      scheduleTypeDayValue: yup
        .number()
        .min(1, "Day must be between 1 and 30")
        .max(30, "Day must be between 1 and 30")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "day",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      scheduleTypeWeekValue: yup
        .number()
        .min(1, "Week must be between 1 and 4")
        .max(4, "Week must be between 1 and 4")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "week",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      basicAmount: yup.number().when(["action", "planType", "scheduleType"], {
        is: (action: string, planType: string, scheduleType: string) =>
          action === "addVehicle" &&
          planType === "fixed" &&
          scheduleType !== "hour",
        then: (schema) =>
          schema
            .min(0, "Amount should be a positive value")
            .required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      actualAmount: yup.number().when(["action", "planType", "scheduleType"], {
        is: (action: string, planType: string, scheduleType: string) =>
          action === "addVehicle" &&
          planType === "fixed" &&
          scheduleType !== "hour",
        then: (schema) =>
          schema
            .min(1, "Amount should be a positive value")
            .required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      dayBasicAmount: yup
        .number()
        .min(0, "Amount should be a positive value")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      dayActualAmount: yup
        .number()
        .min(1, "Amount should be a positive value")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      nightBasicAmount: yup
        .number()
        .min(0, "Amount should be a positive value")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      nightActualAmount: yup
        .number()
        .min(1, "Amount should be a positive value")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      dayMinimumMinutes: yup
        .number()
        .min(1, "Minutes must be between 1 and 59")
        .max(59, "Minutes must be between 1 and 59")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      nightMinimumMinutes: yup
        .number()
        .min(1, "Minutes must be between 1 and 59")
        .max(59, "Minutes must be between 1 and 59")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "hour",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      dayMinimumHour: yup
        .number()
        .min(0, "Hour must be between 0 and 23")
        .max(23, "Hour must be between 0 and 23"),
      // .when(["action","planType", "scheduleType"], {
      //   is: (action: string, planType: string, scheduleType: string) =>
      //      action === "addVehicle" &&planType === "fixed" && scheduleType === "hour",
      //   then: (schema) => schema.required("Required"),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
      nightMinimumHour: yup
        .number()
        .min(0, "Hour must be between 0 and 23")
        .max(23, "Hour must be between 0 and 23"),
      // .when(["action","planType", "scheduleType"], {
      //   is: (action: string, planType: string, scheduleType: string) =>
      //      action === "addVehicle" &&planType === "fixed" && scheduleType === "hour",
      //   then: (schema) => schema.required("Required"),
      //   otherwise: (schema) => schema.notRequired(),
      // }),nightMinimumMinutes: yup
      dailyMinimumHour: yup
        .number()
        .min(0, "Hour must be between 0 and 23")
        .max(23, "Hour must be between 0 and 23")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "day",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      weeklyMinimumDay: yup
        .number()
        .min(0, "Hour must be between 0 and 6")
        .max(6, "Hour must be between 0 and 6")
        .when(["action", "planType", "scheduleType"], {
          is: (action: string, planType: string, scheduleType: string) =>
            action === "addVehicle" &&
            planType === "fixed" &&
            scheduleType === "week",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      const outputArray = Array.from(
        { length: values.WeekDays[1] - values.WeekDays[0] + 1 },
        (_, index) => index + values.WeekDays[0]
      );
      formik.setFieldValue("WeekDays", outputArray);
      if (values.action === "addVehicle") {
        let id = values.id;
        let vehicleList: PlanFormikInputs[] = [];
        for (const eachVehicleId of values.selectedVehiclesList) {
          id += 1;
          vehicleList.push({
            ...values,
            genericVehicleTypeId: eachVehicleId,
            id,
          });
        }
        setRegisteredVehicles((preState) => [...preState, ...vehicleList]);
        resetForm({
          values: {
            ...formik.initialValues,
            id,
            livePreview: values.livePreview,
            action: values.action,
            planName: values.planName,
            dayParkingFrom: values.dayParkingFrom,
            dayParkingTo: values.dayParkingTo,
            nightParkingFrom: values.nightParkingFrom,
            nightParkingTo: values.nightParkingTo,
            IsWeekdayOrWeekendPlan: values.IsWeekdayOrWeekendPlan,
          },
        });
        formik.setTouched({}, false);
      } else {
        if (registeredVehicles.length <= 0) {
          return updateToastMessage(
            true,
            "Registered vehicles are not available",
            "info"
          );
        }
        const getScheduleTypeActualValue = (details: PlanFormikInputs) => {
          if (details.scheduleType === "hour") {
            return details.scheduleTypeHourValueDN
              ? "DN"
              : `H${details.scheduleTypeHourValue}`;
          } else if (details.scheduleType === "day") {
            return `D${details.scheduleTypeDayValue}`;
          } else if (details.scheduleType === "week") {
            return `W${details.scheduleTypeWeekValue}`;
          } else {
            return "";
          }
        };
        const planData: any = {
          planName: values.planName,
          dayParkingFrom: values.dayParkingFrom,
          dayParkingTo: values.dayParkingTo,
          nightParkingFrom: values.nightParkingFrom,
          nightParkingTo: values.nightParkingTo,
          weekDays: [1, 2, 3, 4, 5],
          IsWeekdayOrWeekendPlan: values.IsWeekdayOrWeekendPlan,
        };
        const planDetails: AddPlanDetailsModel = {
          plan: planData,
          fixedPlanTypeVehicles: [],
          slabPlanTypeVehicles: [],
        };
        for (const eachVehicle of registeredVehicles) {
          if (eachVehicle.planType === "fixed") {
            const fixedPlanVehicle: Partial<FixedPlanTypeModel> = {
              genericVehicleTypeId: eachVehicle.genericVehicleTypeId,
              scheduleType: getScheduleTypeActualValue(eachVehicle),
              basicAmount:
                eachVehicle.scheduleType !== "hour"
                  ? eachVehicle.basicAmount
                  : undefined,
              actualAmount:
                eachVehicle.scheduleType !== "hour" &&
                  eachVehicle.actualAmount !== ""
                  ? eachVehicle.actualAmount
                  : undefined,
              dayBasicAmount:
                eachVehicle.scheduleType === "hour"
                  ? eachVehicle.dayBasicAmount
                  : undefined,
              dayActualAmount:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.dayActualAmount !== ""
                  ? eachVehicle.dayActualAmount
                  : undefined,
              nightBasicAmount:
                eachVehicle.scheduleType === "hour"
                  ? eachVehicle.nightBasicAmount
                  : undefined,
              nightActualAmount:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.nightActualAmount !== ""
                  ? eachVehicle.nightActualAmount
                  : undefined,
              dayMinimumHour:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.scheduleTypeHourValueDN
                  ? `H${eachVehicle.dailyMinimumHour}`
                  : undefined,
              nightMinimumHour:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.scheduleTypeHourValueDN
                  ? `H${eachVehicle.nightMinimumHour}`
                  : undefined,
              dayChargeType:
                eachVehicle.scheduleType !== "hour"
                  ? eachVehicle.dayChargeType
                  : undefined,
              dayMinimumMinutes:
                eachVehicle.scheduleType === "hour" &&
                  !eachVehicle.scheduleTypeHourValueDN
                  ? `M${eachVehicle.dayMinimumMinutes}`
                  : undefined,
              nightMinimumMinutes:
                eachVehicle.scheduleType === "hour" &&
                  !eachVehicle.scheduleTypeHourValueDN
                  ? `M${eachVehicle.nightMinimumMinutes}`
                  : undefined,
              dailyMinimumHour:
                eachVehicle.scheduleType === "day"
                  ? `H${eachVehicle.dailyMinimumHour}`
                  : undefined,
              weeklyMinimumDay:
                eachVehicle.scheduleType === "week"
                  ? `D${eachVehicle.weeklyMinimumDay}`
                  : undefined,
              planType: values.IsWeekdayOrWeekendPlan
                ? RequestPlanTypes.weekDayPlan
                : RequestPlanTypes.normalPlan,
            };
            const fixedPlanVehicleWeekEnd: Partial<FixedPlanTypeModel> = {
              genericVehicleTypeId: eachVehicle.genericVehicleTypeId,
              scheduleType: getScheduleTypeActualValue(eachVehicle),
              basicAmount:
                eachVehicle.scheduleType !== "hour"
                  ? eachVehicle.basicAmount
                  : undefined,
              actualAmount:
                eachVehicle.scheduleType !== "hour" &&
                  eachVehicle.actualAmountWeekEnd !== ""
                  ? eachVehicle.actualAmountWeekEnd
                  : undefined,
              dayBasicAmount:
                eachVehicle.scheduleType === "hour"
                  ? eachVehicle.dayBasicAmountWeekEnd
                  : undefined,
              dayActualAmount:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.dayActualAmountWeekEnd !== ""
                  ? eachVehicle.dayActualAmountWeekEnd
                  : undefined,
              nightBasicAmount:
                eachVehicle.scheduleType === "hour"
                  ? eachVehicle.nightBasicAmountWeekEnd
                  : undefined,
              nightActualAmount:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.nightActualAmountWeekEnd !== ""
                  ? eachVehicle.nightActualAmountWeekEnd
                  : undefined,
              dayMinimumHour:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.scheduleTypeHourValueDNWeekEnd
                  ? `H${eachVehicle.dayMinimumHourWeekEnd}`
                  : undefined,
              nightMinimumHour:
                eachVehicle.scheduleType === "hour" &&
                  eachVehicle.scheduleTypeHourValueDNWeekEnd
                  ? `H${eachVehicle.nightMinimumHourWeekEnd}`
                  : undefined,
              dayChargeType:
                eachVehicle.scheduleType !== "hour"
                  ? eachVehicle.dayChargeType
                  : undefined,
              dayMinimumMinutes:
                eachVehicle.scheduleType === "hour" &&
                  !eachVehicle.scheduleTypeHourValueDNWeekEnd
                  ? `M${eachVehicle.dayMinimumMinutesWeekEnd}`
                  : undefined,
              nightMinimumMinutes:
                eachVehicle.scheduleType === "hour" &&
                  !eachVehicle.scheduleTypeHourValueDNWeekEnd
                  ? `M${eachVehicle.nightMinimumMinutesWeekEnd}`
                  : undefined,
              dailyMinimumHour:
                eachVehicle.scheduleType === "day"
                  ? `H${eachVehicle.dailyMinimumHourWeekEnd}`
                  : undefined,
              weeklyMinimumDay:
                eachVehicle.scheduleType === "week"
                  ? `D${eachVehicle.weeklyMinimumDayWeekEnd}`
                  : undefined,
              planType: RequestPlanTypes.weekEndPlan,
            };
            planDetails.fixedPlanTypeVehicles.push(fixedPlanVehicle);
            if (values.IsWeekdayOrWeekendPlan)
              planDetails.fixedPlanTypeVehicles.push(fixedPlanVehicleWeekEnd);
          }
        }
        const response = await dispatch(CreatePlan(planDetails));
        const createPlanResult = unwrapResult(response);
        if (createPlanResult.status) {
          resetForm({
            values: {
              ...formik.initialValues,
              planName: "",
              dayParkingFrom: values.dayParkingFrom,
              dayParkingTo: values.dayParkingTo,
              nightParkingFrom: values.nightParkingFrom,
              nightParkingTo: values.nightParkingTo,
            },
          });
          formik.setTouched({}, false);
          filteredGenericVehicles = genericVehicles;
          setRegisteredVehicles([]);
          setActiveRegisteredVehicle(undefined);
          updateToastMessage(true, createPlanResult.message, "success");
          if (values.action === "addPlan") {
            navigate(-1);
          } else if (values.action === "addPlanAndSchedule") {
            navigate("/schedule/add", {
              state: { planId: createPlanResult.data.planId },
            });
          }
        } else {
          updateToastMessage(true, createPlanResult.message, "info");
        }
      }
    },
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  let filteredGenericVehicles: GenericVehicleModel[] = [];
  if (registeredVehicles.length > 0) {
    filteredGenericVehicles = genericVehicles.filter((eachVehicle) =>
      registeredVehicles.every(
        (eachRegisteredVehicle) =>
          eachVehicle.genericVehicleTypeId !==
          eachRegisteredVehicle.genericVehicleTypeId &&
          eachRegisteredVehicle.planType === "fixed"
      )
    );
  } else {
    filteredGenericVehicles = [...genericVehicles];
  }

  const getPlanInitialDetails = async () => {
    const response = await dispatch(GetPlanInitialDetails());
    if (!response.payload) {
      updateToastMessage(true, "Connection Refused", "error");
    }
  };

  useEffect(() => {
    getPlanInitialDetails();
  }, []);

  useEffect(() => {
    if (lastPlan) {
      if (
        lastPlan.dayParkingFrom &&
        lastPlan.dayParkingTo &&
        lastPlan.nightParkingFrom &&
        lastPlan.nightParkingTo
      ) {
        setShowDayNightTime(false);
        formik.setValues({
          ...formik.values,
          dayParkingFrom: lastPlan.dayParkingFrom,
          dayParkingTo: lastPlan.dayParkingTo,
          nightParkingFrom: lastPlan.nightParkingFrom,
          nightParkingTo: lastPlan.nightParkingTo,
        });
      } else {
        setShowDayNightTime(true);
        formik.setValues({
          ...formik.values,
          dayParkingFrom: "06:00 AM",
          dayParkingTo: "06:00 PM",
          nightParkingFrom: "06:00 PM",
          nightParkingTo: "06:00 AM",
        });
      }
    } else {
      setShowDayNightTime(true);
      formik.setValues({
        ...formik.values,
        dayParkingFrom: "06:00 AM",
        dayParkingTo: "06:00 PM",
        nightParkingFrom: "06:00 PM",
        nightParkingTo: "06:00 AM",
      });
    }
  }, [lastPlan]);

  useEffect(() => {
    if (registeredVehicles.length > 0) {
      const activeRegisteredVehicle = registeredVehicles.find(
        (each) => each.id === formik.values.selectedRegisteredVehicleId
      );
      activeRegisteredVehicle &&
        setActiveRegisteredVehicle(activeRegisteredVehicle);
    }
  }, [formik.values.selectedRegisteredVehicleId]);

  useEffect(() => {
    if (genericVehicles.length > 0 && activeRegisteredVehicle !== undefined) {
      const activeGenericVehicle = genericVehicles.find(
        (each) =>
          each.genericVehicleTypeId ===
          activeRegisteredVehicle?.genericVehicleTypeId
      );
      activeGenericVehicle &&
        formik.setFieldValue("activeGenericVehicle", activeGenericVehicle);
    }
  }, [activeRegisteredVehicle]);

  useEffect(() => {
    if (genericVehicles.length > 0) {
      formik.setFieldValue("selectedVehiclesList", [
        genericVehicles[0].genericVehicleTypeId,
      ]);
    }
  }, [genericVehicles]);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      const target = event.target as HTMLInputElement;
      if (target.form && target.name) {
        setFocusedFieldName(target.name);
      } else {
        setFocusedFieldName(null);
      }
    };
    document.addEventListener("focusin", handleFocus);

    return () => {
      document.removeEventListener("focusin", handleFocus);
    };
  }, []);

  useEffect(() => {
    if (focusedFieldName !== null && !formik.values.livePreview) {
      formik.setFieldValue("livePreview", true);
      formik.setFieldValue("selectedRegisteredVehicleId", 0);
    }
  }, [focusedFieldName]);

  useEffect(() => {
    if (!formik.values.livePreview) {
      formik.setFieldValue("livePreview", true);
    }
  }, [
    formik.values.selectedVehiclesList,
    formik.values.planType,
    formik.values.scheduleType,
    formik.values.dayChargeType,
  ]);

  return (
    <Box display="flex" flexDirection="column" sx={{ minHeight: "90vh" }}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Grid container spacing={2} flexGrow={1} padding={2}>
          <Grid
            item
            xs={12}
            md={6}
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <PlanCard
              formik={formik}
              showDayNightTime={showDayNightTime}
              setShowDayNightTime={setShowDayNightTime}
            />
            <AddPlanForm
              genericVehicles={filteredGenericVehicles}
              formik={formik}
              registeredVehicles={registeredVehicles}
              activeRegisteredVehicle={activeRegisteredVehicle}
              setActiveRegisteredVehicle={setActiveRegisteredVehicle}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <PreviewCard
              formik={formik}
              genericVehicles={genericVehicles}
              registeredVehicles={registeredVehicles}
              setRegisteredVehicles={setRegisteredVehicles}
              activeRegisteredVehicle={activeRegisteredVehicle}
              setActiveRegisteredVehicle={setActiveRegisteredVehicle}
            />
          </Grid>
        </Grid>
      </form>
      <Loading openModal={isLoading} />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      />
    </Box>
  );
};

export default AddPlan;
