import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import { Today } from "@mui/icons-material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
}

const SlabPlan = ({ formik }: Props) => {
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");

  const handleTimeChange = (newValue: Dayjs | null) => {
    formik.setFieldValue(selectedInput, newValue?.format("hh:mm A") || "");
    setOpenTimePicker(false);
  };

  const onClickTimeInput = (field: string) => {
    setSelectedInput(field);
    setOpenTimePicker(true);
  };

  return (
    <Box display="flex" flexDirection="column">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" flexDirection="column" flexWrap="wrap">
          <Typography
            variant="body1"
            sx={{ fontWeight: "600", margin: "10px 5px" }}
          >
            Slab No : {""}
          </Typography>
          <Box display="flex" flexDirection="row" flexWrap="wrap"></Box>
          <TextField
            variant="outlined"
            label="Day Parking From"
            InputLabelProps={{ shrink: true }}
            onClick={() => onClickTimeInput("dayParkingFrom")}
            value={formik.values.dayParkingFrom}
            sx={{ width: "50%" }}
          />
        </Box>
        {openTimePicker && (
          <MobileTimePicker
            open={true}
            value={dayjs(new Date())}
            onChange={handleTimeChange}
            onClose={() => setOpenTimePicker(false)}
            sx={{ display: "none" }}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default SlabPlan;
