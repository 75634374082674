import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import theme from '../../../Theme/Theme';
import { useAppSelector } from '../../../Redux/Store';
import NewLabel from '../../../components/NewLabel/NewLabel';
import Loading from '../../../components/Loading/Loading';

function Account() {
    const { Account, isLoading } = useAppSelector((state: any) => state.settings);

    const issValidDate = (date: any) => {
        return date !== '0001-01-01T00:00:00' && new Date(date).toString() !== 'Invalid Date';
    }
    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
            }}
            className='settings-content'
            style={{ backgroundColor: 'transparent' }}>
            <div className='details-container'>
                <Card style={{ backgroundColor: theme.palette.primary.light, height: 'auto' }} elevation={1} className='lot-container'>
                    <Card style={{ backgroundColor: theme.palette.primary.light, width: 'auto' }} elevation={0} className='setting-contents-heading'>
                        <Typography style={{
                            color: theme.palette.primary.main,
                        }} className='card-header'>
                            {Account ? Account.parkingLotDetails ? Account.parkingLotDetails.parkingLotName : '' : ''}
                        </Typography>
                    </Card>
                    <div className='border' />
                    <Grid flexWrap={{ xs: 'wrap' }} width={{ xs: '100%' }} className='card-content'>
                        <NewLabel Label='Admin Mobile' Value={Account ? Account.parkingLotDetails ? Account.parkingLotDetails.mobile : '' : ''} />
                        <NewLabel Label='Account Number' Value={Account ? Account.customerDetails ? Account.customerDetails.accountNo : '' : ''} />
                        <NewLabel Label='Contact Person Name' Value={
                            Account ? Account.customerDetails ? `${Account.customerDetails.firstName} ${Account.customerDetails.lastName ? Account.customerDetails.lastName : ''}` : '' : ''} />
                        <NewLabel Label='Contact person Mail' Value={Account ? Account.customerDetails ? Account.customerDetails.email : '' : ''} />
                    </Grid>
                </Card>
                <Card style={{ backgroundColor: theme.palette.primary.light, height: 'auto' }} elevation={1} className='lot-container'>
                    <Card style={{ backgroundColor: theme.palette.primary.light, width: 'auto' }} elevation={0} className='setting-contents-heading'>
                        <Typography style={{
                            color: theme.palette.primary.main,
                        }} className='card-header'>
                            Availability of lot
                        </Typography>
                    </Card>
                    <div className='border' />
                    <Grid flexWrap={{ xs: 'wrap' }} width={{ xs: '100%' }} className='card-content'>
                        <NewLabel Label='Reservation' Value={
                            Account ? Account.parkingLotDetails ?
                                Account.parkingLotDetails.hasReservation ? 'Available' :
                                    'Not Available' : 'Not Available' : 'Not Available'
                        } />
                        <NewLabel Label='Surface Status' Value={
                            Account ? Account.parkingLotDetails ?
                                Account.parkingLotDetails.surfaceStatus ? Account.parkingLotDetails.surfaceStatus :
                                    'Not yet configured' : 'Not yet configured' : 'Not yet configured'} />
                        <NewLabel Label='Intercharge' Value={
                            Account ? Account.parkingLotDetails ? Account.parkingLotDetails.interchangeable ?
                                Account.parkingLotDetails.interchangeable : 'Not yet configured' :
                                'Not yet configured' : 'Not available'} />
                        <NewLabel Label='L*W in square feet' Value={Account ? Account.parkingLotDetails ? Account.parkingLotDetails.areaInSquareFeet ?
                            Account.parkingLotDetails.areaInSquareFeet : 'not yet provided' :
                            'not yet provided' : ' not yet provided'} />
                    </Grid>
                </Card>
                <Card style={{ backgroundColor: theme.palette.primary.light }} elevation={1} className='lot-container'>
                    <Card style={{ backgroundColor: theme.palette.primary.light }} elevation={0} className='setting-contents-heading'>
                        <Typography style={{
                            color: theme.palette.primary.main,
                        }} className='card-header'>
                            Subscription detail
                        </Typography>
                    </Card>
                    <div className='border' />
                    <Grid flexWrap={{ xs: 'wrap' }} gap={{ xs: '1', md: '0' }} width={{ xs: '100%' }} className='card-content'>
                        <NewLabel Label='Activity since' Value={Account ? issValidDate(Account.activitySince) ? Account.activitySince : '' : ''} />
                        <NewLabel Label='Balance  credit' Value={Account ? Account.subscriptionAmount : ''} />
                        <NewLabel Label='Last updated on ' Value={Account ? issValidDate(Account.lastUpdated) ? Account.lastUpdated : '' : ''} />
                        <div className='subscription-detail'></div>
                    </Grid>
                </Card>
                <Card style={{ backgroundColor: theme.palette.primary.light }} elevation={1} className='lot-container'>
                    <Card style={{ backgroundColor: theme.palette.primary.light }} elevation={0} className='setting-contents-heading'>
                        <Typography style={{
                            color: theme.palette.primary.main,
                        }} className='card-header'>
                            Address
                        </Typography>
                    </Card>
                    <div className='border' />
                    <Grid flexDirection={{ xs: 'column', md: 'row' }} className='card-content'>
                        <div className='address-location'>
                            <LocationOnTwoToneIcon />
                            <NewLabel Label='' Value={Account ? Account.parkingLotDetails ? Account.parkingLotDetails.address1 : '' : ''} />
                        </div>
                        {/* <div className='address-location'>
                            <LocationOnTwoToneIcon />

                            <NewLabel
                                Label=''
                                Value={`Lat: ${Account && Account.parkingLotDetails ? Account.parkingLotDetails.latitude : ''}, Long: ${Account && Account.parkingLotDetails ? Account.parkingLotDetails.longitude : ''}`}
                            />
                        </div> */}
                    </Grid>
                </Card>
            </div>
            <div className='attachment-container'>
                <div className='attachment-card'>
                    <Card style={{ backgroundColor: theme.palette.primary.light }} className='attachment-dispaly'>
                        <div className='logo-add'>
                            <Card style={{ backgroundColor: theme.palette.primary.light, width: '100%' }} elevation={0} className='setting-contents-heading'>
                                <Typography style={{
                                    color: theme.palette.primary.main,
                                }} className='card-header'>
                                    Attachments
                                </Typography>
                            </Card>
                            <div className='border' />
                        </div>
                    </Card>
                    <Card style={{ backgroundColor: theme.palette.primary.light }} className='add-print-receipt-logo'>
                        <div className='logo-add'>
                            <Card style={{ backgroundColor: theme.palette.primary.light, width: '100%' }} elevation={0} className='setting-contents-heading'>
                                <Typography style={{
                                    color: theme.palette.primary.main,
                                }} className='card-header'>
                                    Print Receipt Logo
                                </Typography>
                            </Card>
                            <div style={{ marginTop: '-2px' }} className='second-border' />
                            <div className='camera-container'>
                                <div className='camera-icon-container'>
                                    <CameraAltTwoToneIcon style={{ height: '42px', width: '42px' }} />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <Loading openModal={isLoading} />
        </Grid>
    )
}

export default Account
