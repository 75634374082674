import { createSlice } from "@reduxjs/toolkit";
import {
  GetAddTokenInitialDetails,
  CreateToken,
  customerAutoComplete,
  getTicketStatus,
} from "../Thunks/AddTokenThunk";
import {
  GenericVehicleModel,
  TokenModel,
  customerAutoCompleteModel,
} from "../../Model/TokenModel";

interface TokenInfo {
  genericVehicleTypes: GenericVehicleModel[];
  tokenNumber: string;
  tokenDisplayNumber: string;
}

interface InitialState {
  isLoading: boolean;
  tokenInfo: TokenInfo | null;
  tokenDetails: TokenModel | null;
  customerInfo: customerAutoCompleteModel | null;
}

let initialState: InitialState = {
  isLoading: false,
  tokenInfo: null,
  tokenDetails: null,
  customerInfo: null,
};

const createTokenSlice = createSlice({
  name: "Groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAddTokenInitialDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAddTokenInitialDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.tokenInfo = action.payload.data;
        }
      })
      .addCase(GetAddTokenInitialDetails.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(CreateToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateToken.fulfilled, (state) => {
        state.customerInfo = null;
        state.isLoading = false;
      })
      .addCase(CreateToken.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getTicketStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTicketStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.tokenDetails = action.payload.data;
        }
      })
      .addCase(getTicketStatus.rejected, (state) => {
        state.isLoading = false;
      });
    builder.addCase(customerAutoComplete.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data !== null) {
        state.customerInfo = action.payload.data;
      } else if (action.payload.status && action.payload.data == null) {
        state.customerInfo = {
          customerMobile: "",
          registerNumber: "",
          genericVehicleTypeId: "",
          message: "",
          alertMessage: "",
        };
      }
    });
  },
});

export const createTokenReducer = createTokenSlice.reducer;
