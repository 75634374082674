import {
  AlertColor,
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  Person,
  PhoneAndroid,
  Mail,
  Portrait,
  AccountCircle,
  Apartment,
  LocalParking,
  VerifiedUser,
  LocationOn,
} from "@mui/icons-material";
import {
  SxProps,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import { useAppSelector, useAppDispatch } from "../../Redux/Store";
import { useState, useEffect, CSSProperties } from "react";
import { GetProfileDetails } from "../../Redux/Thunks/ProfileThunk";
import {
  ToastMessageState,
  ToastMessage,
} from "../../components/ToastMessage/ToastMessage";

interface Style {
  title: SxProps;
  card: CSSProperties;
  label: SxProps;
  value: SxProps;
}

const Profile = () => {
  const { user, parkingLot, company, rolePermission, permissions } =
    useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const getProfile = async () => {
    const response = await dispatch(GetProfileDetails());
    if (!response.payload) {
      updateToastMessage(true, "Connection Refused", "error");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const customTheme = createTheme({
    ...theme,
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const style: Style = {
    title: {
      fontSize: "25px",
      fontWeight: "bold",
      fontFamily: "sans-serif",
    },
    label: {
      fontSize: "18px",
      fontWeight: 500,
      color: "black",
      fontFamily: "sans-serif",
    },
    value: {
      fontSize: "18px",
      fontWeight: 500,
      color: "black",
      fontFamily: "sans-serif",
    },
    card: {
      padding: "10px",
    },
  };

  let permissionsName = "";
  rolePermission?.permissions.forEach(
    (each, index) =>
      (permissionsName +=
        each.permissionType +
        `${index === rolePermission.permissions.length - 1 ? "" : ", "}`)
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        flex: 1,
      }}
    >
      <ThemeProvider theme={customTheme}>
        <Grid container spacing={2} flex={1}>
          <Grid item md={4} flex={1}>
            <Card style={style.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Avatar
                    src={user?.profilePictureUrl}
                    alt="Profile Image"
                    style={{
                      textAlign: "center",
                      margin: "20px auto",
                      width: "120px",
                      height: "120px",
                    }}
                  />
                  <Typography
                    variant="h4"
                    align="center"
                    sx={style.title}
                    gutterBottom
                  >
                    Personal Information
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <List
                    sx={{
                      width: "100%",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItem>
                      <ListItemIcon>
                        <Person />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          user?.firstName &&
                          `${user.firstName || "NA"} ${user.lastName || ""}`
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <PhoneAndroid />
                      </ListItemIcon>
                      <ListItemText
                        primary={user?.phoneNumber ? user.phoneNumber : "NA"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Mail />
                      </ListItemIcon>
                      <ListItemText primary={user?.email ? user.email : "NA"} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <AccountCircle />
                      </ListItemIcon>
                      <ListItemText
                        primary={user?.userName ? user.userName : "NA"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Portrait />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          rolePermission?.roleName
                            ? rolePermission?.roleName
                            : "NA"
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <VerifiedUser />
                      </ListItemIcon>
                      <ListItemText primary={permissionsName} />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item md={8} flex={1}>
            <Grid container spacing={2} direction="column" flex={1}>
              <Grid item>
                <Card style={style.card}>
                  <Typography
                    variant="h4"
                    textAlign="center"
                    sx={style.title}
                    gutterBottom
                  >
                    Company
                  </Typography>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <List
                        sx={{
                          width: "100%",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItem>
                          <ListItemIcon>
                            <Apartment />
                          </ListItemIcon>
                          <ListItemText
                            primary={company?.name ? company?.name : "NA"}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              company?.address1 ? company?.address1 : "NA"
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item>
                <Card style={style.card}>
                  <Typography
                    variant="h4"
                    textAlign="center"
                    sx={style.title}
                    gutterBottom
                  >
                    Parking Lot
                  </Typography>
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <List
                        sx={{
                          width: "100%",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        <ListItem>
                          <ListItemIcon>
                            <LocalParking />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              parkingLot?.parkingLotName
                                ? parkingLot.parkingLotName
                                : "NA"
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Person />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              parkingLot?.contactName
                                ? parkingLot.contactName
                                : "NA"
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <PhoneAndroid />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              parkingLot?.mobile ? parkingLot.mobile : "NA"
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Mail />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              parkingLot?.email ? parkingLot.email : "NA"
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              parkingLot?.address1 ? parkingLot.address1 : "NA"
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ToastMessage
          showToastMessage={toastMessage.showMessage}
          setToastMessage={setToastMessage}
          message={toastMessage.message}
          alertType={toastMessage.alertType}
          // placement={{ vertical: "top", horizontal: "left" }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default Profile;
