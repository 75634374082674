import { durationTypes } from "./constants";

export const convertTimeTo24Hour = (timeString: string): { hours: number; minutes: number } => {
    // Split the time string into hours, minutes, and AM/PM
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    // Handle the case for 12:00 AM (midnight) and 12:00 PM (noon)
    if (hours === 12) {
        if (period === "AM" || period === "am") {
            return { hours: 0, minutes };
        } else {
            return { hours: 12, minutes };
        }
    }

    // Convert hours to 24-hour format
    let convertedHours = hours;
    if (period === "PM" || period === "pm") {
        convertedHours += 12;
    }

    return { hours: convertedHours, minutes };
}

export const convertDateTo12Hour = (dateTime: Date): string => {
    //convert date time format to HH:MM  AM format
    return dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
}

export const convertMinimumDurationToMinutes = (duration: string): number => {
    //converts minimun duration to minutes ex: H1 to 60
    let durationInNum = duration.slice(1);
    if (duration.includes(durationTypes.minutes)) {
        return Number(durationInNum)
    }
    if (duration.includes(durationTypes.hours)) {
        let hours = Number(durationInNum);
        return hours * 60;
    }
    if (duration.includes(durationTypes.days)) {
        let days = Number(durationInNum);
        return days * 1440;
    } else {
        return 0;
    }
}

export const getDifferenceInMinutes = (inTime: Date, outTime: Date) => {
    //returns time difference in minutes
    const diffMilliseconds = Math.abs(inTime.getTime() - outTime.getTime());
    const minutes = Math.floor(diffMilliseconds / (1000 * 60));
    return minutes;
}

export const convertHourNumberToDate = (currentDate: Date, hour: number, minute: number): Date => {
    //adds hour and minute to date
    let newDate = new Date(currentDate);
    newDate.setHours(hour);
    newDate.setMinutes(minute);
    return newDate;
}

export const getMinutesFromTimeString = (hour: number, minutes: number): number => {
    return (hour * 60) + minutes;
}

export const getDayTimeDifferenceInMilliSeconds = (hour1: number, minute1: number, hour2: number, minute2: number) => {
    let date1 = new Date();
    date1.setHours(hour1);
    date1.setMinutes(minute1);
    let date2 = new Date();
    date2.setHours(hour2);
    date2.setMinutes(minute2);
    let difference = Math.abs(date1.getTime() - date2.getTime());
    return difference;
}

export const getNightTimeDifferenceInMilliSeconds = (hour1: number, minute1: number, hour2: number, minute2: number) => {
    let date1 = new Date();
    date1.setHours(hour2);
    date1.setMinutes(minute2);
    let date2 = new Date();
    date2.setHours(hour1);
    date2.setMinutes(minute1);
    let incrementDate = date2.getDate() + 1
    date2.setDate(incrementDate);
    let difference = Math.abs(date1.getTime() - date2.getTime());
    return difference;
}