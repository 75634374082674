import {
  AlertColor,
  Box,
  Card,
  Grid,
  ImageListItem,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { ScheduleFormikInputs } from "./CreateSchedule";
import { useNavigate } from "react-router-dom";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import dayjs from "dayjs";
import { Search } from "@mui/icons-material";
import PlanIcon from "../../../assets/Plan_Icon.png";
import "./CreateSchedule.css";

interface Props {
  formik: FormikProps<ScheduleFormikInputs>;
}

const PlansCard = (props: Props) => {
  const { formik } = props;
  const theme = useTheme();

  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [filteredPlans, setFilteredPlans] = useState(formik.values.plans);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const selectedPlan: PlanDetailsModel | undefined = formik.values.plans.find(
    (each) => each.plan.planId === formik.values.planId
  );

  useEffect(() => {
    const value = formik.values.plans.filter((eachPlan) =>
      eachPlan.plan.planName.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredPlans(value);
  }, [formik.values.plans, searchInput]);

  const onClickCard = (planDetails: PlanDetailsModel) => {
    formik.values.planId === planDetails.plan.planId
      ? formik.setFieldValue("planId", "")
      : formik.setFieldValue("planId", planDetails.plan.planId);
    formik.setFieldValue("scheduleName", planDetails.plan.planName);
  };

  const onClickCreatePlan = () => {
    navigate("/plan/add");
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        margin: "16px 8px 16px 16px",
        padding: "20px",
        borderRadius: "15px",
        background:
          "linear-gradient(276deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
      }}
    >
      {/* <Autocomplete
        sx={{ width: "99%" }}
        options={plans}
        freeSolo
        getOptionLabel={(plans) =>
          typeof plans !== "string" ? plans.plan.planName : ""
        }
        onInputChange={(event, value) =>
          formik.setFieldValue("planName", value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Plan Name"
            placeholder="Ex: April Plan"
            variant="outlined"
            error={formik.touched.planName && Boolean(formik.errors.planName)}
            helperText={formik.touched.planName ? formik.errors.planName : ""}
            {...formik.getFieldProps("planName")}
            sx={{ margin: "0px" }}
          />
        )}
      /> */}
      <Grid container spacing={2} flexGrow={1}>
        <Grid item xs={12}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              // margin: "10px 0",
              borderRadius: "15px",
              backgroundColor: "transparent",
              minHeight: "140px",
            }}
          >
            {selectedPlan && formik.values.planId !== "" ? (
              <>
                <Typography
                  sx={{ fontSize: 14, margin: 0 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Selected Plan
                </Typography>
                <Typography variant="h6" component="div">
                  {selectedPlan.plan.planName}
                </Typography>
              </>
            ) : (
              <Typography
                sx={{ fontSize: 14, margin: 0 }}
                color="text.secondary"
                gutterBottom
              >
                Selected Plan
              </Typography>
            )}
            {!selectedPlan && formik.values.planId === "" && (
              <>
                <Button
                  onClick={onClickCreatePlan}
                  style={{
                    background:
                      "linear-gradient(to right, rgba(132, 135, 3, 1) 30%,  rgba(246, 183, 23, 1) 100%)",
                    padding: "15px 40px",
                    borderRadius: "0px 58px 0px 58px",
                    alignSelf: "center",
                    margin: "10px",
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <ImageListItem style={{ height: "40px" }}>
                      <img src={PlanIcon} alt="Plan" />
                    </ImageListItem>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      sx={{ padding: "0 30px" }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "20px",
                          fontWeight: 600,
                          color: "#FFFFFF",
                          margin: 0,
                        }}
                      >
                        Plan
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          color: "#FFFFFF",
                          margin: 0,
                        }}
                      >
                        Create Price Details
                      </Typography>
                    </Box>
                  </Box>
                </Button>
                <Typography
                  variant="h5"
                  component="div"
                  alignSelf="center"
                  textAlign="center"
                  margin="auto"
                >
                  OR Select a Plan
                </Typography>
              </>
            )}
            {!selectedPlan && formik.values.planId !== "" && (
              <Typography
                variant="h5"
                component="div"
                alignSelf="center"
                textAlign="center"
                margin="auto"
              >
                Plan Not Available
              </Typography>
            )}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
            >
              {selectedPlan &&
                selectedPlan.fixedPlanTypeVehicles.map((each) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{ minWidth: "70px" }}
                    key={each.genericVehicleTypeId}
                  >
                    <img src={each.iconUrl} alt={"Icon"} loading="lazy" />
                    <Typography variant="caption">Fixed</Typography>
                  </Box>
                ))}
              {selectedPlan &&
                selectedPlan.slabPlanTypeVehicles.map((each) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{ minWidth: "70px" }}
                    key={each.genericVehicleTypeId}
                  >
                    <img src={each.iconUrl} alt={"Icon"} loading="lazy" />
                    <Typography variant="caption">Slab</Typography>
                  </Box>
                ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "10px" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "50%" }}
            >
              <Typography
                variant="h6"
                sx={{
                  borderBottomColor: theme.palette.primary.main,
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  marginLeft: "10px",
                }}
              >
                Plans
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: "15px",
                  width: "15px",
                  margin: "0 15px",
                  padding: "10px 10px 12px 10px",
                  borderRadius: "10px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
              >
                {filteredPlans.length}
              </Box>
            </Box>
            <TextField
              variant="outlined"
              label="Search"
              placeholder="Search"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "50%" }}
              value={searchInput}
              onChange={(event) => setSearchInput(event.target.value)}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          flexGrow={1}
          sx={{ height: "42vh", overflow: "auto", scrollbarWidth: "thin" }}
        >
          <Grid container spacing={2}>
            {filteredPlans.map((eachPlan) => (
              <Grid item xs={12} md={6} key={eachPlan.plan.planId}>
                <Card
                  onClick={() => onClickCard(eachPlan)}
                  className="schedule-plan-card"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    // margin: "10px 0",
                    borderRadius: "15px",
                    backgroundColor: `${selectedPlan?.plan.planId === eachPlan.plan.planId
                      ? theme.palette.secondary.main
                      : "transparent"
                      }`,
                    height: "70px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      marginBottom="5px"
                      dangerouslySetInnerHTML={{
                        __html: eachPlan.plan.planName.replace(
                          new RegExp(searchInput, "gi"),
                          (match) => `<mark>${match}</mark>`
                        ),
                      }}
                    />
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "600", marginBottom: "5px" }}
                    >
                      <span style={{ fontWeight: "normal", fontSize: "10px" }}>
                        Created On :{" "}
                      </span>
                      {dayjs(eachPlan.plan.createdOn).format("DD/MM/YY")}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    overflow="hidden"
                  >
                    {eachPlan.fixedPlanTypeVehicles.map((each) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        sx={{ minWidth: "70px" }}
                        key={each.genericVehicleTypeId}
                      >
                        <img src={each.iconUrl} alt={"Icon"} loading="lazy" />
                      </Box>
                    ))}
                    {eachPlan.slabPlanTypeVehicles.map((each) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        sx={{ minWidth: "70px" }}
                        key={each.genericVehicleTypeId}
                      >
                        <img src={each.iconUrl} alt={"Icon"} loading="lazy" />
                      </Box>
                    ))}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      />
    </Card>
  );
};

export default PlansCard;
