import { Box } from "@mui/material";
import Cookies from "js-cookie";
import Navbar from "../components/AppBar/NavBar";
import { Navigate } from "react-router-dom";
import appBackground from "../assets/app_background.png";
import { layoutProps } from "../Model/LayoutModel";

export default function PrivateRoute({ children }: layoutProps) {
    const isAuthenticated = Cookies.get("accessToken");
    return isAuthenticated ? (
        <Box
            sx={{
                position: "relative",
                minHeight: "100vh",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                    backgroundImage: `url(${appBackground})`,
                    zIndex: -1,
                    opacity: 0.1,
                },
            }}
        >
            <Navbar />
            {children}
        </Box>
    ) : (
        <Navigate to="/" replace />
    );
}