import { createSlice } from "@reduxjs/toolkit";
import { ListAttachments, ListInOrOut } from "../Thunks/ListThunk";

let initialState = {
  isLoading: false,
  List: [],
  attachments: [],
  error: "",
};

const ListSlice = createSlice({
  name: "List",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ListInOrOut.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status) {
        state.List = action.payload.data;
      } else {
        state.List = [];
      }
    });
    builder.addCase(ListInOrOut.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ListInOrOut.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(ListAttachments.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status) {
        state.attachments = action.payload.data;
      }
    });
    builder.addCase(ListAttachments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ListAttachments.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const listReducer = ListSlice.reducer;
