import {
  AlertColor,
  Avatar,
  Box,
  Button,
  Chip,
  FormGroup,
  Grid,
  IconButton,
  Slide,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../Redux/Store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState, useEffect, useRef } from "react";
import {
  CreateToken,
  GetAddTokenInitialDetails,
  customerAutoComplete,
} from "../../Redux/Thunks/AddTokenThunk";
import Loading from "../../components/Loading/Loading";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  AddTokenAttachmentModel,
  AddTokenModel,
  TokenPrintModel,
} from "../../Model/TokenModel";
import {
  Print,
  AccessTime,
  Refresh,
  AddAPhoto,
  ArrowForwardIos,
  ArrowBackIos,
} from "@mui/icons-material";
import dayjs from "dayjs";
import Edit from "@mui/icons-material/Edit";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InTokenContent from "../../components/TokenContent/InTokenContent";
import {
  ToastMessage,
  ToastMessageState,
} from "../../components/ToastMessage/ToastMessage";
import {
  GetAccountDetails,
  getParkinglotSettingsDetails,
} from "../../Redux/Thunks/SettingsThunk";
import { constSettingValues } from "../../Model/Settings/TokenModal";
import TextBox from "../../components/TextBox/TextBox";
import { GetProfileDetails } from "../../Redux/Thunks/ProfileThunk";
import WarningDialog from "../../components/Dialog/AlertDialog";
import ReactWebcam from "../../components/ReactWebcam/ReactWebcam";
import CustomModel from "../../components/Model/Model";
import { getImageFormatName } from "../../utilities/getImageFormatName";
import { IOSSwitch } from "../../components/Togglebutton/Toggle";

interface TokenDefaultDetails {
  type: string;
  inTime: Date;
  genericVehicleTypeId: string | null;
  printReceipt: boolean;
  OTPorSMS: boolean;
}

const TokenEntry = () => {
  const { tokenInfo, isLoading, customerInfo } = useAppSelector(
    (state) => state.addToken
  );
  const { Account, ParkingLotSettings } = useAppSelector(
    (state: any) => state.settings
  );
  const { permissions, user, parkingLot } = useAppSelector(
    (state) => state.profile
  );
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLElement>(null);
  const printRef = useRef<HTMLDivElement>(null);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const tokenDefaultDetails: TokenDefaultDetails = {
    type: "walkIn",
    inTime: new Date(),
    OTPorSMS: true,
    printReceipt: true,
    genericVehicleTypeId: null,
  };

  const [tokenDetails, setTokenDetails] = useState(tokenDefaultDetails);
  const [attachments, setAttachments] = useState<AddTokenAttachmentModel[]>([]);
  const [checked, setChecked] = useState(false);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });
  const [isDateTimePickerOpen, setDateTimePickerOpen] = useState(false);
  const [printTokenData, setPrintTokenData] = useState({} as TokenPrintModel);
  const [startPrint, setStartPrint] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [inReceipt, setInReceipt] = useState<any>(null);
  const [enterOtp, setEntryOtp] = useState<any>(null);
  const [tokenInPayment, setTokenInPayment] = useState<any>(null);
  const [ignoreMobile, setIgnoreMobile] = useState<any>(null);
  const [ignoreVnumber, setIgnoreVnumber] = useState<any>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openWebcam, setOpenWebcam] = useState(false);
  const [captureImageBase64, setCaptureImageBase64] = useState<string | null>(
    null
  );
  const [openImageView, setOpenImageView] = useState(false);
  const [activeCapturedImage, setActiveCapturedImage] = useState<
    string | undefined
  >(undefined);
  const [isFocused, setIsFocused] = useState(true);

  console.log('tokenDetails::', tokenDetails);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (tokenInfo && tokenInfo.genericVehicleTypes.length > 0) {
        tokenInfo.genericVehicleTypes.map((item: any, index: number) => {
          if (event.key === `F${index + 3}`) {
            event.preventDefault();
            updateTokenDetails(
              "genericVehicleTypeId",
              item.genericVehicleTypeId
            );
          }
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (tokenInfo && tokenInfo.genericVehicleTypes.length > 0 && tokenDetails.genericVehicleTypeId === null) {
      updateTokenDetails(
        "genericVehicleTypeId",
        tokenInfo.genericVehicleTypes[0].genericVehicleTypeId
      )
    }
  }, [tokenInfo]);

  const handleChange = () => {
    setChecked(true);
  };

  useEffect(() => {
    if (
      user &&
      Object.keys(user).length > 0 &&
      parkingLot &&
      Object.keys(parkingLot).length > 0
    ) {
      let AccountParams = {
        UserName: user?.userName,
        ParkingLotId: parkingLot?.parkingLotId,
      };
      dispatch(GetAccountDetails(AccountParams)).then((data: any) => { });
    }

    getInitialDetails();
    dispatch(GetProfileDetails());
    handleChange();
  }, []);

  useEffect(() => {
    if (Account && Account.parkingLotDetails) {
      let apiQuery = "parkingLotId=" + Account.parkingLotDetails.parkingLotId;
      dispatch(getParkinglotSettingsDetails(apiQuery));
    }
  }, [Account]);
  useEffect(() => {
    setTokenInPayment(
      ParkingLotSettings[0].Id !== 0 &&
      ParkingLotSettings.find(
        (element: any) =>
          element.settingKey ===
          constSettingValues.settings_key_Token_in_payment
      )
    );

    setIgnoreMobile(
      ParkingLotSettings[0].Id !== 0 &&
      ParkingLotSettings.find(
        (element: any) =>
          element.settingKey ===
          constSettingValues.settings_Key_Token_in_dummy_mobile
      )
    );
    setIgnoreVnumber(
      ParkingLotSettings[0].Id !== 0 &&
      ParkingLotSettings.find(
        (element: any) =>
          element.settingKey ===
          constSettingValues.settings_Key_Token_in_dummy_register_number
      )
    );
    setInReceipt(
      ParkingLotSettings[0].Id !== 0 &&
      ParkingLotSettings.find(
        (element: any) =>
          element.settingKey ===
          constSettingValues.settings_key_Token_in_receipt
      )
    );
    setEntryOtp(
      ParkingLotSettings[0].Id !== 0 &&
      ParkingLotSettings.find(
        (element: any) =>
          element.settingKey === constSettingValues.settings_Key_Token_in_otp
      )
    );

    setTokenDetails({
      ...tokenDetails,
      printReceipt: inReceipt && inReceipt.settingValue === 1,
      OTPorSMS: enterOtp && enterOtp.settingValue === 1,
    });
  }, [ParkingLotSettings]);

  useEffect(() => {
    if (inReceipt)
      setTokenDetails({
        ...tokenDetails,
        printReceipt: inReceipt.settingValue === 1,
      });
  }, [inReceipt]);

  useEffect(() => {
    if (captureImageBase64) {
      const newAttachment: AddTokenAttachmentModel = {
        name: getImageFormatName(),
        imageBase64: captureImageBase64,
      };
      setAttachments((preState) => [...preState, newAttachment]);
      setCaptureImageBase64(null);
      setOpenWebcam(false);
    }
  }, [captureImageBase64]);

  useEffect(() => {
    const inputElement = textFieldRef.current;
    const focusTimeout = setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
        setIsFocused(true);
      }
    }, 2000);

    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      inputElement.addEventListener('blur', handleBlur);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
        inputElement.removeEventListener('blur', handleBlur);
      }
      clearTimeout(focusTimeout);
    };
  }, [])


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  const customTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: "black",
          },
        },
      },
    },
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const getInitialDetails = async () => {
    setDisableSubmit(true);
    const response = await dispatch(GetAddTokenInitialDetails());
    if (response.payload) {
      const result = unwrapResult(response);
      if (!result.status) {
        updateToastMessage(true, result.message, "info");
      }
    } else {
      updateToastMessage(true, "Connection Refused", "error");
    }
    setDisableSubmit(false);
  };

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      registerNumber: "TN",
      visitorName: "",
      amount: 0,
    },
    validationSchema: yup.object({
      mobileNumber:
        ignoreMobile && ignoreMobile.settingValue
          ? yup.string().matches(/^\d{10}$/, "Mobile number must be 10 digits")
          : yup
            .string()
            .matches(/^\d{10}$/, "Mobile number must be 10 digits")
            .required("Required"),
      registerNumber:
        ignoreVnumber && ignoreVnumber.settingValue
          ? yup
            .string()
            .matches(
              /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
              "Invalid Vehicle Number - XX00XX0000"
            )
            .transform((value) => (value ? value.toUpperCase() : value))
          : yup
            .string()
            .matches(
              /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
              "Invalid Vehicle Number - XX00XX0000"
            )
            .transform((value) => (value ? value.toUpperCase() : value))
            .required("Required"),
      visitorName: yup.string(),
      amount: yup.number(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setDisableSubmit(true);
      if (tokenDetails.genericVehicleTypeId === null) {
        updateToastMessage(true, "Select vehicle type", "warning");
        setDisableSubmit(false);
        return;
      }
      const token: AddTokenModel = {
        genericVehicleTypeId: tokenDetails.genericVehicleTypeId
          ? tokenDetails.genericVehicleTypeId
          : "",
        mobileNumber: values.mobileNumber === "" ? null : values.mobileNumber,
        registerNumber:
          values.registerNumber === ""
            ? null
            : values.registerNumber.toUpperCase(),
        visitorName: values.visitorName === "" ? null : values.visitorName,
        inTime: tokenDetails.inTime,
        isSubscription: tokenDetails.type === "walkIn" ? false : true,
        subscriptionId: tokenDetails.type === "walkIn" ? null : "", //id temp
        tokenAttachments: [],
        username: null,
        amount: values.amount,
        sendOtp: tokenDetails.OTPorSMS,
      };
      const response: any = await dispatch(CreateToken(token));
      if (response.payload) {
        const result = unwrapResult(response);
        if (result.status) {
          let printTokenData: TokenPrintModel = {
            time: tokenDetails.inTime.toString(),
            inTime: new Date().toString(),
            outTime: new Date().toString(),
            token: tokenInfo!.tokenDisplayNumber,
            regNo:
              values.registerNumber === ""
                ? ""
                : values.registerNumber.toUpperCase(),
            tokenId: result.data.tokenId,
          };
          setPrintTokenData(printTokenData);
          setTokenDetails({
            ...tokenDefaultDetails,
            printReceipt: inReceipt && inReceipt.settingValue === 1,
          });
          if (tokenDetails.printReceipt) {
            setStartPrint(true);
          }
          updateToastMessage(true, result.message, "success");
          resetForm();
          await getInitialDetails();
        } else {
          updateToastMessage(true, result.message, "info");
        }
      } else {
        updateToastMessage(true, "Connection Refused", "error");
      }
      setDisableSubmit(false);
    },
  });

  const updateTokenDetails = (key: string, value: any) => {
    if (
      key === "genericVehicleTypeId" &&
      tokenDetails.genericVehicleTypeId === value
    ) {
      setTokenDetails((preInfo) => ({
        ...preInfo,
        genericVehicleTypeId: null,
      }));
    } else {
      setTokenDetails((preInfo) => ({
        ...preInfo,
        [key]: value,
      }));
    }
  };

  //Auto Complete when entering Mobile or Register Number

  useEffect(() => {
    customerDetailsAutoCompleteByMobile();
  }, [formik.values.mobileNumber]);

  useEffect(() => {
    customerDetailsAutoCompleteByReg();
  }, [formik.values.registerNumber]);

  const customerDetailsAutoCompleteByMobile = async () => {
    let regNoPattern = /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/;
    if (
      formik.values.mobileNumber.length === 10 &&
      !regNoPattern.test(formik.values.registerNumber)
    ) {
      let params = {
        MobileNumber: formik.values.mobileNumber,
        RegisterNumber: null,
      };
      await dispatch(customerAutoComplete(params));
    }
  };

  const customerDetailsAutoCompleteByReg = async () => {
    let regNoPattern = /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/;
    if (
      !regNoPattern.test(formik.values.registerNumber) ||
      formik.values.registerNumber.length === 0
    ) {
      formik.setFieldValue("mobileNumber", "");
      // updateTokenDetails("genericVehicleTypeId", null);
    }
    if (
      regNoPattern.test(formik.values.registerNumber) &&
      formik.values.mobileNumber.length !== 10
    ) {
      let params = {
        MobileNumber: null,
        RegisterNumber: formik.values.registerNumber,
      };
      dispatch(customerAutoComplete(params)).then((res) => {
        let result = unwrapResult(res);
        if (result.data !== null && result.data.alertMessage !== null) {
          setOpenAlert(true);
        }
      });
    }
  };

  useEffect(() => {
    if (customerInfo !== null) {
      if (customerInfo.customerMobile !== null) {
        if (
          customerInfo.customerMobile.length === 10 &&
          customerInfo.customerMobile !== "0000000000"
        ) {
          formik.setFieldValue("mobileNumber", customerInfo.customerMobile);
        }
      }
      if (customerInfo.registerNumber !== null) {
        if (
          customerInfo.registerNumber !== "" &&
          customerInfo.registerNumber !== "XX00XX0000"
        ) {
          formik.setFieldValue("registerNumber", customerInfo.registerNumber);
        }
      }
      // if (customerInfo.genericVehicleTypeId !== null) {
      //   if (customerInfo.genericVehicleTypeId !== "") {
      //     updateTokenDetails(
      //       "genericVehicleTypeId",
      //       customerInfo.genericVehicleTypeId
      //     );
      //   }
      // }
    }
  }, [customerInfo?.registerNumber, customerInfo?.customerMobile]);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const wholeContent = (
    <Box sx={{ margin: "10px 15px 10px 10px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container rowGap={1}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Token No : {tokenInfo?.tokenDisplayNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Register Number"
              variant="outlined"
              fullWidth
              error={
                formik.touched.registerNumber && !isFocused &&
                Boolean(formik.errors.registerNumber)
              }
              helperText={
                formik.touched.registerNumber && !isFocused
                  ? formik.errors.registerNumber
                  : ""
              }
              {...formik.getFieldProps("registerNumber")}
              onChange={(e) => {
                const uppercaseValue = e.target.value.toUpperCase();
                formik.setFieldValue("registerNumber", uppercaseValue);
              }}
              inputRef={textFieldRef}
              autoFocus
            />
            {customerInfo !== null &&
              customerInfo.message !== null &&
              formik.values.registerNumber.length === 10 ? (
              <span>
                <Typography color="primary"> {customerInfo.message}</Typography>
              </span>
            ) : (
              <span></span>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mobile Number"
              variant="outlined"
              fullWidth
              error={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
              helperText={
                formik.touched.mobileNumber ? formik.errors.mobileNumber : ""
              }
              {...formik.getFieldProps("mobileNumber")}
            />
          </Grid>
          <Grid item xs={12}>
            {tokenInfo &&
              tokenInfo.genericVehicleTypes &&
              tokenInfo.genericVehicleTypes.length > 0 &&
              tokenInfo.genericVehicleTypes.map(
                (eachVehicle: any, index: number) => (
                  <Tooltip title={`F${index + 3}`}>
                    <Chip
                      key={eachVehicle.genericVehicleTypeId}
                      label={eachVehicle.vehicleTypeName}
                      avatar={
                        <Avatar
                          alt="Custom Icon"
                          src={eachVehicle.iconUrl}
                          style={{ width: "30px", height: "30px" }}
                        />
                      }
                      clickable
                      color={
                        tokenDetails.genericVehicleTypeId ===
                          eachVehicle.genericVehicleTypeId
                          ? "primary"
                          : "default"
                      }
                      onClick={() =>
                        updateTokenDetails(
                          "genericVehicleTypeId",
                          eachVehicle.genericVehicleTypeId
                        )
                      }
                      style={{
                        padding: "15px 5px",
                        margin: "5px 10px",
                        fontSize: "15px",
                        fontWeight: "500",
                        height: "40px",
                      }}
                    />
                  </Tooltip>
                )
              )}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton disabled>
              <AccessTime style={{ color: "black" }} />
            </IconButton>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6" align="center">
                {dayjs(tokenDetails.inTime).format("ddd DD MMM YYYY hh:mm A")}
              </Typography>
              <IconButton
                onClick={() => updateTokenDetails("inTime", new Date())}
              >
                <Refresh color="primary" />
              </IconButton>
            </Box>
            <IconButton
              onClick={() => setDateTimePickerOpen(true)}
              disabled={!permissions.editInTime}
            >
              <Edit color="primary" />
            </IconButton>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={customTheme}>
                <MobileDateTimePicker
                  sx={{ display: "none" }}
                  value={dayjs(tokenDetails.inTime)}
                  open={isDateTimePickerOpen}
                  onClose={() => setDateTimePickerOpen(false)}
                  onAccept={(date: any) => updateTokenDetails("inTime", date)}
                  maxDateTime={dayjs(new Date())}
                />
              </ThemeProvider>
            </LocalizationProvider>
          </Grid>
          <Grid container gap={1}>
            <Grid
              item
              xs={12}
              md={tokenInPayment && tokenInPayment.settingValue ? 5.9 : 12}
            >
              <TextField
                label="Visitor Name"
                variant="outlined"
                fullWidth
                error={
                  formik.touched.visitorName &&
                  Boolean(formik.errors.visitorName)
                }
                helperText={
                  formik.touched.visitorName ? formik.errors.visitorName : ""
                }
                {...formik.getFieldProps("visitorName")}
              />
            </Grid>
            {tokenInPayment && tokenInPayment.settingValue ? (
              <Grid item xs={12} md={5.9}>
                <TextBox
                  label="Amount"
                  helpertext={
                    formik.touched.amount ? String(formik.errors.amount) : ""
                  }
                  formik={formik}
                  fieldpropsName="amount"
                  error={
                    Boolean(formik.touched.amount) &&
                    Boolean(formik.errors.amount)
                  }
                />
              </Grid>
            ) : (
              ""
            )}
            {/* <TextField
              label="Visitor Name"
              variant="outlined"
              fullWidth
              error={
                formik.touched.visitorName && Boolean(formik.errors.visitorName)
              }
              helperText={
                formik.touched.visitorName ? formik.errors.visitorName : ""
              }
              {...formik.getFieldProps("visitorName")}
            /> */}
          </Grid>
          {/* <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <IconButton
              onClick={() => setOpenWebcam(true)}
              disabled={attachments.length >= 5}
            >
              <AddAPhoto
                fontSize="large"
                color={attachments.length >= 5 ? undefined : "primary"}
              />
            </IconButton>
            <Box flex={1} display="flex" flexDirection="row">
              {attachments.map((eachAttachment) => (
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    margin: "0 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setActiveCapturedImage(eachAttachment.imageBase64);
                    setOpenImageView(true);
                  }}
                >
                  <Avatar
                    src={eachAttachment.imageBase64}
                    // style={{ width: "60px", height: "40px" }}
                    variant="rounded"
                  />
                </Box>
              ))}
            </Box>
          </Grid> */}
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            sx={{ margin: "0 10px" }}
          >
            {/* <Chip
              label="Print Receipt"
              icon={<Print />}
              clickable
              color={tokenDetails.printReceipt ? "primary" : "default"}
              onClick={() =>
                updateTokenDetails("printReceipt", !tokenDetails.printReceipt)
              }
              style={{
                padding: "15px",
                marginRight: "10px",
                fontSize: "15px",
                fontWeight: "500",
                height: "40px",
              }}
            /> */}
            <IconButton color={tokenDetails.printReceipt ? "primary" : "default"} onClick={() => updateTokenDetails("printReceipt", !tokenDetails.printReceipt)}>
              <Print />
            </IconButton>
            <FormGroup onClick={() => updateTokenDetails("printReceipt", !tokenDetails.printReceipt)}>
              <IOSSwitch sx={{ m: 1 }} checked={tokenDetails.printReceipt} />
            </FormGroup>
            {/* <Chip
              label="Send otp"
              icon={<MessageIcon />}
              clickable
              color={tokenDetails.OTPorSMS ? "primary" : "default"}
              onClick={() =>
                updateTokenDetails("OTPorSMS", !tokenDetails.OTPorSMS)
              }
              style={{
                padding: "15px",
                marginRight: "10px",
                fontSize: "15px",
                fontWeight: "500",
                height: "40px",
              }}
            /> */}
            {/* <FormControlLabel
              checked={tokenDetails.OTPorSMS}
              control={
                <Checkbox
                  icon={<Key />}
                  checkedIcon={<Key color="primary" />}
                />
              }
              onChange={() =>
                updateTokenDetails("OTPorSMS", !tokenDetails.OTPorSMS)
              }
              label="OTP / SMS"
              labelPlacement="end"
              disabled
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            style={{ margin: "0 10px" }}
          >
            <Box
              sx={{ width: "50%" }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  formik.resetForm();
                  setTokenDetails({
                    ...tokenDefaultDetails,
                    printReceipt: inReceipt && inReceipt.settingValue === 1,
                    OTPorSMS: enterOtp && enterOtp.settingValue === 1,
                  });
                }}
                style={{
                  margin: "0 20px",
                  backgroundColor: `${theme.palette.background.default}`,
                }}
              >
                Clear
              </Button>
              <Button
                disabled={disableSubmit}
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  margin: "0 20px",
                }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Loading openModal={isLoading} />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      // placement={{ vertical: "top", horizontal: "left" }}
      />
      <Box sx={{ display: "none" }}>
        {startPrint && (
          <InTokenContent
            printRef={printRef}
            printData={printTokenData}
            isPrint={true}
            setStartPrint={setStartPrint}
          />
        )}
      </Box>
      <WarningDialog
        openAlert={openAlert}
        handleAlertClose={handleAlertClose}
        message={customerInfo !== null ? customerInfo.alertMessage : ""}
      />
      <ReactWebcam
        openWebcam={openWebcam}
        setOpenWebcam={setOpenWebcam}
        captureImageBase64={captureImageBase64}
        setCaptureImageBase64={setCaptureImageBase64}
      />
      <CustomModel open={openImageView} setOpen={setOpenImageView}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            sx={{ height: 500, width: 700 }}
          >
            <img
              src={activeCapturedImage}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              alt="Captured Image"
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignSelf="center"
            sx={{ margin: "10px auto" }}
          >
            {/* <IconButton>
              <ArrowBackIos />
            </IconButton> */}
            <Button
              variant="contained"
              sx={{ margin: "0 10px" }}
              onClick={() => setOpenImageView(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ margin: "0 10px" }}
              onClick={() => {
                setAttachments((preState) =>
                  preState.filter(
                    (each) => each.imageBase64 !== activeCapturedImage
                  )
                );
                setOpenImageView(false);
              }}
            >
              Delete
            </Button>
            {/* <IconButton>
              <ArrowForwardIos />
            </IconButton> */}
          </Box>
        </Box>
      </CustomModel>
    </Box>
  );

  return (
    <Slide
      in={checked}
      container={containerRef.current}
      direction="left"
      timeout={500}
    >
      {wholeContent}
    </Slide>
  );
};

export default TokenEntry;
