import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import theme from "../../../Theme/Theme";
import LivePreview from "./LivePreview";
import { GenericVehicleModel } from "../../../Model/TokenModel";
import Preview from "./Preview";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Delete, Edit } from "@mui/icons-material";
import AlertDialog from "../../../components/Dialog/Dialog";
import { SetStateAction, useState } from "react";
import WeekendLivePreview from "./WeekendLivePreview";
import WeekendPreview from "./WeekendPreview";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
  genericVehicles: GenericVehicleModel[];
  registeredVehicles: PlanFormikInputs[];
  setRegisteredVehicles: React.Dispatch<
    React.SetStateAction<PlanFormikInputs[]>
  >;
  activeRegisteredVehicle: PlanFormikInputs | undefined;
  setActiveRegisteredVehicle: React.Dispatch<
    React.SetStateAction<PlanFormikInputs | undefined>
  >;
}

const PreviewCard = (props: Props) => {
  const {
    formik,
    genericVehicles,
    registeredVehicles,
    setRegisteredVehicles,
    activeRegisteredVehicle,
    setActiveRegisteredVehicle,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogName, setDialogName] = useState("edit");

  const liveGenericVehicles =
    genericVehicles.length > 0
      ? genericVehicles.filter((each) =>
        formik.values.selectedVehiclesList.includes(each.genericVehicleTypeId)
      )
      : [];

  const onClickEditButton = () => {
    setDialogOpen(true);
    setDialogName("edit");
  };

  const onClickConfirmEdit = () => {
    if (activeRegisteredVehicle) {
      setRegisteredVehicles((preState) =>
        preState.filter(
          (eachItem) =>
            eachItem.id !== formik.values.selectedRegisteredVehicleId
        )
      );
      formik.setValues({
        ...activeRegisteredVehicle,
        selectedVehiclesList: [activeRegisteredVehicle.genericVehicleTypeId],
        id: formik.values.selectedRegisteredVehicleId - 1,
        livePreview: true,
        planName: formik.values.planName,
        dayParkingFrom: formik.values.dayParkingFrom,
        dayParkingTo: formik.values.dayParkingTo,
        nightParkingFrom: formik.values.nightParkingFrom,
        nightParkingTo: formik.values.nightParkingTo,
        selectedRegisteredVehicleId: 0,
        activeGenericVehicle: undefined,
      });
      setActiveRegisteredVehicle(undefined);
    }
  };

  const onClickDeleteButton = () => {
    setDialogOpen(true);
    setDialogName("delete");
  };

  const onClickConfirmDelete = () => {
    setRegisteredVehicles((preState) =>
      preState.filter(
        (eachItem) => eachItem.id !== formik.values.selectedRegisteredVehicleId
      )
    );
    formik.setFieldValue("selectedRegisteredVehicleId", 0);
    formik.setFieldValue("activeGenericVehicle", undefined);
    formik.setFieldValue("livePreview", true);
    setActiveRegisteredVehicle(undefined);
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(84deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
            height: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex" flexDirection="column" flexGrow={1} padding={2}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="genericVehicles">
                  Registered Generic Vehicles
                </InputLabel>
                <Select
                  labelId="genericVehicles"
                  value={
                    formik.values.selectedRegisteredVehicleId === 0
                      ? ""
                      : formik.values.selectedRegisteredVehicleId
                  }
                  label="Registered Generic Vehicles"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "selectedRegisteredVehicleId",
                      Number(event.target.value)
                    );
                    formik.setFieldValue("livePreview", false);
                  }}
                >
                  {registeredVehicles.map((eachVehicle) => {
                    const vehicleTypeName = genericVehicles.find(
                      (eachItem) =>
                        eachItem.genericVehicleTypeId ===
                        eachVehicle.genericVehicleTypeId
                    )?.vehicleTypeName;
                    if (vehicleTypeName) {
                      return (
                        <MenuItem key={eachVehicle.id} value={eachVehicle.id}>
                          {vehicleTypeName}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={0} value={0}>
                          Registered Generic Not Available
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
              {!formik.values.livePreview &&
                formik.values.selectedRegisteredVehicleId > 0 && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <IconButton onClick={onClickEditButton}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={onClickDeleteButton}>
                      <Delete />
                    </IconButton>
                  </Box>
                )}
            </Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                color: theme.palette.primary.main,
                textAlign: "center",
              }}
            >
              {formik.values.livePreview ? "Live Preview" : "Preview"}
            </Typography>
            {genericVehicles.length > 0 && (
              <>
                <Collapse in={formik.values.livePreview}>
                  <LivePreview
                    formik={formik}
                    liveGenericVehicles={liveGenericVehicles}
                  />
                  {
                    formik.values.IsWeekdayOrWeekendPlan
                    &&
                    <WeekendLivePreview
                      formik={formik}
                      liveGenericVehicles={liveGenericVehicles}
                    />
                  }
                </Collapse>
                <Collapse in={!formik.values.livePreview}>
                  <Preview
                    formik={formik}
                    activeRegisteredVehicle={activeRegisteredVehicle}
                  />
                  {
                    formik.values.IsWeekdayOrWeekendPlan &&
                    <WeekendPreview
                      formik={formik}
                      activeRegisteredVehicle={activeRegisteredVehicle}
                    />
                  }
                </Collapse>
              </>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="flex-end"
            alignSelf="flex-end"
            flexGrow={1}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => formik.setFieldValue("action", "addPlan")}
              sx={{ margin: "15px" }}
            >
              Create
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={() =>
                formik.setFieldValue("action", "addPlanAndSchedule")
              }
              sx={{ margin: "15px" }}
            >
              Create & Schedule
            </Button>
          </Box>
        </Card>
      </LocalizationProvider>
      <AlertDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        title={`Are you sure you want to ${dialogName === "edit" ? "edit" : "delete"
          } a registered generic vehicle?`}
        onClickConfirm={
          dialogName === "edit" ? onClickConfirmEdit : onClickConfirmDelete
        }
        confirmButtonName={dialogName === "edit" ? "Edit" : "Delete"}
      />
    </Box>
  );
};

export default PreviewCard;
