import { createSlice } from "@reduxjs/toolkit";
import { CreateParkingLotRegister, GetGenericVehicle, GetParkingLotType, OtpVerification, VerifyInvitationOtp } from "../Thunks/ParkingLotThunk";
import { ParkingLotData } from "../../Model/ParkingLotRegister/ParkingLotRegisterModel";
import { parkingLotSetUpModel } from "../../Model/ParkingLotRegister/ParkingLotSetup";

interface InitialState {
  isLoading: boolean;
  parkingLotType:parkingLotSetUpModel;
  // getGenericVehicle: VehicleCapacityModel[];
  parkingLotData: ParkingLotData | null;
  error: string;
}

let initialState: InitialState = {
  parkingLotType:{}as parkingLotSetUpModel,
  // getGenericVehicle: [],
  parkingLotData: null,
  isLoading: false,
  error: "",
};

const ParkingLotSlice = createSlice({
  name: "genericVehicle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(CreateParkingLotRegister.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(CreateParkingLotRegister.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateParkingLotRegister.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(VerifyInvitationOtp.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(VerifyInvitationOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(VerifyInvitationOtp.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(OtpVerification.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(OtpVerification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(OtpVerification.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(GetParkingLotType.fulfilled, (state, action:any) => {
      state.isLoading = false;
      state.parkingLotType = action.payload.data;
    });
    builder.addCase(GetParkingLotType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetParkingLotType.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const parkingLotReducer = ParkingLotSlice.reducer;
