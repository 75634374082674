import React from 'react'
import './Label.css'
import { Grid, IconButton, Typography } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

interface LabelProps {
    Label: string;
    Value: any;
    icon: boolean;
    onClick?: () => void
}

function Label(props: LabelProps) {
    const { Label, Value, icon, onClick } = props;
    return (
        <Grid container>
            <Grid display={'flex'} alignItems={'center'}>
                <div >{Label}</div>
                {icon && <IconButton /*sx={{ marginTop: '-16px', flex: '0.3' }}*/ aria-label="Edit" onClick={onClick}>
                    <ModeEditIcon color='primary' />
                </IconButton>}
            </Grid>
            <Grid style={{ fontWeight: 'bold' }} >{Value}</Grid>
        </Grid>
    )
}

export default Label
