import { Card, Typography } from '@mui/material'
import React from 'react'
import Toggle from '../Togglebutton/Toggle'
import theme from '../../Theme/Theme'

interface TokenSetting {
    content: string;
    value: boolean;
    onClick: () => void
}

function TokenSettingsCard(props: TokenSetting) {
    const { content, value, onClick } = props;
    return (
        <Card style={{
            backgroundColor: 'transparent',
            padding: '8px 11px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '1%',
            marginBottom: '1%'
        }}>
            <Typography
                style={{ marginLeft: '2%' }}
            >{content}
            </Typography>  <Toggle value={value} onClick={onClick} />
        </Card>
    )
}

export default TokenSettingsCard
