import { FormikProps } from "formik";
import { GenericVehicleModel } from "../../../Model/TokenModel";
import { PlanFormikInputs } from "./AddPlan";
import { Box, Grid, Icon, Typography, useTheme } from "@mui/material";
import { DoNotDisturbAlt } from "@mui/icons-material";
import NewLabel from "../../../components/NewLabel/NewLabel";
import SliderCard from "./SliderCard";
import SliderCardWeekEnd from "./SliderCardWeekEnd";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
  activeRegisteredVehicle: PlanFormikInputs | undefined;
}

const WeekendPreview = (props: Props) => {
  const { formik, activeRegisteredVehicle } = props;

  const theme = useTheme();

  const genericVehicle = formik.values.activeGenericVehicle;

  return (
    <>
      {activeRegisteredVehicle && (
        <Grid container spacing={2} sx={{ padding: "10px 0 0 0" }}>
          {activeRegisteredVehicle.scheduleType === "hour" && (
            <>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Actual Amount"
                  Value={
                    activeRegisteredVehicle.dayActualAmountWeekEnd
                      ? `₹ ${activeRegisteredVehicle.dayActualAmountWeekEnd}`
                      : "NA"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Basic Amount"
                  Value={`₹ ${activeRegisteredVehicle.dayBasicAmountWeekEnd}`}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Day Minimum Minutes"
                  Value={`${activeRegisteredVehicle.dayMinimumMinutesWeekEnd} `}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Actual Amount"
                  Value={
                    activeRegisteredVehicle.nightActualAmountWeekEnd
                      ? `₹ ${activeRegisteredVehicle.nightActualAmountWeekEnd}`
                      : "NA"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Basic Amount"
                  Value={`₹ ${activeRegisteredVehicle.nightBasicAmountWeekEnd}`}
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Night Minimum Minutes"
                  Value={`${activeRegisteredVehicle.nightMinimumMinutesWeekEnd} `}
                />
              </Grid>
            </>
          )}
          {activeRegisteredVehicle.scheduleType !== "hour" && (
            <>
              <Grid item xs={4}>
                <NewLabel
                  Label="Actual Charge"
                  Value={
                    activeRegisteredVehicle.actualAmountWeekEnd
                      ? `₹ ${activeRegisteredVehicle.actualAmountWeekEnd}`
                      : "NA"
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <NewLabel
                  Label="Basic Charge"
                  Value={`₹ ${activeRegisteredVehicle.basicAmountWeekEnd}`}
                />
              </Grid>
              {activeRegisteredVehicle.scheduleType === "day" && (
                <Grid item xs={4}>
                  <NewLabel
                    Label="Daily Minimum Hour"
                    Value={`${activeRegisteredVehicle.dailyMinimumHourWeekEnd} Hour`}
                  />
                </Grid>
              )}
              {activeRegisteredVehicle.scheduleType === "week" && (
                <Grid item xs={4}>
                  <NewLabel
                    Label="Daily Minimum Day"
                    Value={`${activeRegisteredVehicle.weeklyMinimumDayWeekEnd} Day`}
                  />
                </Grid>
              )}
              {/* <Grid item xs={4}>
                <NewLabel
                  Label="Day Charge Type"
                  Value={
                    activeRegisteredVehicle.dayChargeType === 1
                      ? "24 Hour Day"
                      : "Day of Year"
                  }
                />
              </Grid> */}
            </>
          )}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                color: theme.palette.primary.main,
                textAlign: "center",
              }}
            >
              Example
            </Typography>
            {activeRegisteredVehicle && (
              <SliderCardWeekEnd formik={formik} activeRegisteredVehicle={activeRegisteredVehicle} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WeekendPreview;
