import { createSlice } from "@reduxjs/toolkit";
import {
  AddBlockAndTheft,
  DeleteVehicleBlockOrTheft,
  GetByIdBlockOrTheft,
  ListBlockOrTheft,
} from "../Thunks/Block";

let initialState = {
  isLoading: false,
  block: {},
  blockList: [],
  error: "",
};

const BlockSlice = createSlice({
  name: "Block",
  initialState,
  reducers: {
    ClearBlock : (state) =>{
      state.block = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(AddBlockAndTheft.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(AddBlockAndTheft.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(AddBlockAndTheft.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(ListBlockOrTheft.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status) {
        state.blockList = action.payload.data;
      } else {
        state.blockList = [];
      }
    });
    builder.addCase(ListBlockOrTheft.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ListBlockOrTheft.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(GetByIdBlockOrTheft.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status) {
        state.block = action.payload.data;
      }
    });
    builder.addCase(GetByIdBlockOrTheft.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GetByIdBlockOrTheft.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(DeleteVehicleBlockOrTheft.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status) {
        state.block = action.payload.data;
      }
    });
    builder.addCase(DeleteVehicleBlockOrTheft.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(DeleteVehicleBlockOrTheft.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {ClearBlock} = BlockSlice.actions;

export const blockReducer = BlockSlice.reducer;
