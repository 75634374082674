export class PlanTypes {
    static fixed = 1;
    static slab = 2;
}

export class ScheduleTypes {
    static dayNight = 'DN';
    static hour = 'H';
    static day = 'D';
    static week = 'W';
}

export class durationTypes {
    static minutes = 'M';
    static hours = 'H';
    static days = 'D';
}