import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
const url = process.env.REACT_APP_HIVERAPIURL;

export const GetAccountDetails = createAsyncThunk(
    "settings/account",
    async (accDetails: any) => {
        try {
            let response = await axios.post(
                `${url}api/Setting/account`,
                accDetails,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const GetAllUserRole = createAsyncThunk(
    "settings/userRole",
    async () => {
        try {
            let response = await axios.get(
                `${url}api/User/all-roles`,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const CheckUserInLot = createAsyncThunk(
    "settings/CheckUserInLot",
    async (query: string) => {
        try {
            let response = await axios.get(
                `${url}api/User/FindUserInLot?${query}`,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const sendOtp = createAsyncThunk(
    "settings/sendOtp",
    async (params: any) => {
        try {
            let response = await axios.post(
                `${url}api/User/NewUserRegisterOTP`,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);
export const Createuser = createAsyncThunk(
    "settings/createUser",
    async (params: any) => {
        try {
            let response = await axios.post(
                `${url}api/User/CreateUserWithConfirmOTP`,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const getParkinglotSettingsDetails = createAsyncThunk(
    "settings/ParkingLot",
    async (query: string) => {
        try {
            let response = await axios.get(
                `${url}api/Setting/token-setting?${query}`,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const ChangeTokenSettings = createAsyncThunk(
    "settings/ChangeTokenSettings",
    async (params: any) => {
        try {
            let response = await axios.post(
                `${url}api/Setting/token-setting`,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const getReceiptFormat = createAsyncThunk(
    "settings/ReceiptFormat",
    async (query: string) => {
        try {
            let response = await axios.get(
                `${url}api/Setting/token-setting?${query}`,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);

export const UpdateReceiptFormat = createAsyncThunk(
    "settings/updateReceipt",
    async (params: any) => {
        try {
            let response = await axios.put(
                `${url}api/Setting/update-receiptformat `,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);
export const SaveReceiptFormat = createAsyncThunk(
    "settings/saveReceipt",
    async (params: any) => {
        try {
            let response = await axios.post(
                `${url}api/Setting/save-receiptformat `,
                params,
                await getHeaderToken()
            );
            return response;
        } catch (error: any) {
            return error.response.data;
        }
    }
);