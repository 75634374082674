import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
import { ApiResponse } from "../utils/ApiResponse";

const url = process.env.REACT_APP_HIVERAPIURL;

interface props {
  ParkingLotId: string | undefined;
  StartDate: Date;
  EndDate: Date;
}

export const GetDashboardDetails = createAsyncThunk<ApiResponse, props>(
  "Get/DashboardDetails",
  async (props) => {
    try {
      const response = await axios.post(
        `${url}api/DashboardV1/ParkingLotReportWeb`,
        props,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);
