import { Box, Card, Collapse, Grid, IconButton, Slider, Switch, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import {
  LocalizationProvider,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Toggle from "../../../components/Togglebutton/Toggle";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
  showDayNightTime: boolean;
  setShowDayNightTime: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Mark {
  value: number;
  label: string;
}

const PlanCard = (props: Props) => {
  const { formik, showDayNightTime, setShowDayNightTime } = props;

  const [openDayFromTimePicker, setOpenDayFromTimePicker] = useState(false);
  const [openDayToTimePicker, setOpenDayToTimePicker] = useState(false);
  const [openNightFromTimePicker, setOpenNightFromTimePicker] = useState(false);
  const [openNightToTimePicker, setOpenNightToTimePicker] = useState(false);
  const marks: Mark[] = [
    { value: 1, label: "Mon" },
    { value: 2, label: "Tue" },
    { value: 3, label: "Wed" },
    { value: 4, label: "Thu" },
    { value: 5, label: "Fri" },
    { value: 6, label: "Sat" },
    { value: 7, label: "Sun" }
  ]

  const handleChange = (event: Event, newValue: number | number[]) => {
    formik.setFieldValue("WeekDays", newValue as number[]);
  };

  const handleToggle = () => {
    if (!formik.values.IsWeekdayOrWeekendPlan)
      setShowDayNightTime(true);
    formik.setFieldValue("IsWeekdayOrWeekendPlan", !formik.values.IsWeekdayOrWeekendPlan);
  }

  const handleDayFromTimeChange = (newValue: Dayjs | null) => {
    formik.setFieldValue("dayParkingFrom", newValue?.format("hh:mm A") || "");
    formik.setFieldValue("nightParkingTo", newValue?.format("hh:mm A") || "");
  };

  const handleDayToTimeChange = (newValue: Dayjs | null) => {
    formik.setFieldValue("nightParkingFrom", newValue?.format("hh:mm A") || "");
    formik.setFieldValue("dayParkingTo", newValue?.format("hh:mm A") || "");
  };

  const handleNightFromTimeChange = (newValue: Dayjs | null) => {
    formik.setFieldValue("nightParkingFrom", newValue?.format("hh:mm A") || "");
    formik.setFieldValue("dayParkingTo", newValue?.format("hh:mm A") || "");
  };

  const handleNightToTimeChange = (newValue: Dayjs | null) => {
    formik.setFieldValue("dayParkingFrom", newValue?.format("hh:mm A") || "");
    formik.setFieldValue("nightParkingTo", newValue?.format("hh:mm A") || "");
  };

  const dayTime = dayjs(formik.values.dayParkingFrom, "hh:mm A");
  const nightTime = dayjs(formik.values.nightParkingFrom, "hh:mm A");

  return (
    <Box display="flex" flexDirection="column" sx={{ marginBottom: "16px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(84deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
            height: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex" flexDirection="column" padding={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TextField
                label="Plan Name"
                placeholder="Ex: April Month Plan"
                variant="outlined"
                error={
                  formik.touched.planName && Boolean(formik.errors.planName)
                }
                helperText={
                  formik.touched.planName ? formik.errors.planName : ""
                }
                {...formik.getFieldProps("planName")}
                sx={{ width: "50%" }}
              />
              < Typography>
                Week Days And Week End
              </Typography> <Toggle
                value={formik.values.IsWeekdayOrWeekendPlan}
                onClick={handleToggle}
              />
              <IconButton
                onClick={() => setShowDayNightTime((preState) => !preState)}
              >
                {showDayNightTime ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Box>
            <Collapse in={showDayNightTime}>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                <Grid md={12} xs={12}>
                  <Collapse in={formik.values.IsWeekdayOrWeekendPlan}>
                    <Box sx={{ transition: "height 0.5s ease-in-out" }} display="flex" padding="10px 20px 20px 20px" flexDirection="column" alignItems="center">
                      <Box>
                        <Typography variant="body1" sx={{ fontWeight: "600", justifyContent: 'start' }}>
                          Select Week Days
                        </Typography>
                      </Box>
                      <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={formik.values.WeekDays}
                        step={1}
                        min={1}
                        max={7}
                        marks={marks}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Collapse>
                </Grid>
                <TimePicker
                  label="Day Parking From"
                  value={dayTime}
                  onChange={handleDayFromTimeChange}
                  open={openDayFromTimePicker}
                  onOpen={() => setOpenDayFromTimePicker(true)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onClose={() => setOpenDayFromTimePicker(false)}
                  onAccept={() => setOpenDayFromTimePicker(false)}
                  onViewChange={() => setOpenDayFromTimePicker(true)}
                  sx={{ width: "50%" }}
                />
                <TimePicker
                  label="Day Parking To"
                  value={nightTime}
                  onChange={handleDayToTimeChange}
                  open={openDayToTimePicker}
                  onOpen={() => setOpenDayToTimePicker(true)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onClose={() => setOpenDayToTimePicker(false)}
                  onAccept={() => setOpenDayToTimePicker(false)}
                  onViewChange={() => setOpenDayToTimePicker(true)}
                  sx={{ width: "50%" }}
                />
                <TimePicker
                  label="Night Parking From"
                  value={nightTime}
                  onChange={handleNightFromTimeChange}
                  open={openNightFromTimePicker}
                  onOpen={() => setOpenNightFromTimePicker(true)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onClose={() => setOpenNightFromTimePicker(false)}
                  onAccept={() => setOpenNightFromTimePicker(false)}
                  sx={{ width: "50%" }}
                />
                <TimePicker
                  label="Night Parking T0"
                  value={dayTime}
                  onChange={handleNightToTimeChange}
                  open={openNightToTimePicker}
                  onOpen={() => setOpenNightToTimePicker(true)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  onClose={() => setOpenNightToTimePicker(false)}
                  onAccept={() => setOpenNightToTimePicker(false)}
                  sx={{ width: "50%" }}
                />
              </Box>
            </Collapse>
          </Box>
        </Card>
      </LocalizationProvider>
    </Box>
  );
};

export default PlanCard;
