import { createSlice } from "@reduxjs/toolkit";
import { AllExitDetails, ExitDetails, ExitToken, ResendOTP } from "../Thunks/ExitThunk";

interface InitialState {
    isLoading: boolean;
    exit: any;
    allExit: any[]
    error: string
}

let initialState: InitialState = {
    isLoading: false,
    exit: {},
    allExit: [],
    error: '',
}

const ExitSlice = createSlice({
    name: 'Exit',
    initialState,
    reducers: {
        deleteExitData: (state) => {
            state.exit = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ExitDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload.status == 200) {
                state.exit = action.payload.data.data
            }
            else {
                state.exit = []
            }
        })
        builder.addCase(ExitDetails.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(ExitDetails.rejected, (state) => {
            state.isLoading = false;
        })

        builder.addCase(AllExitDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload.status == 200) {
                state.allExit = action.payload.data.data
            }
            else {
                state.allExit = [];
            }
        })
        builder.addCase(ExitToken.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(ExitToken.rejected, (state) => {
            state.isLoading = false;
        })

        builder.addCase(ExitToken.fulfilled, (state, action) => {
            state.exit = {};
            state.isLoading = false;
        })
        builder.addCase(AllExitDetails.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(AllExitDetails.rejected, (state) => {
            state.isLoading = false;
            state.allExit = [];
        })
        builder.addCase(ResendOTP.fulfilled, (state) => {
            state.isLoading = false;
        })
        builder.addCase(ResendOTP.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(ResendOTP.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { deleteExitData } = ExitSlice.actions;
export const ExitReducer = ExitSlice.reducer;