import { createSlice } from "@reduxjs/toolkit";
import {
  CreatePlan,
  GetPlansDetails,
  GetPlanInitialDetails,
} from "../Thunks/PlanThunk";
import { GenericVehicleModel } from "../../Model/TokenModel";
import { PlanDetailsModel, PlanModel } from "../../Model/PlanModel";

interface InitialState {
  isLoading: boolean;
  genericVehicles: GenericVehicleModel[];
  lastPlan: PlanModel | null | undefined;
  currentPlanDetails: PlanDetailsModel[];
  upcomingPlanDetails: PlanDetailsModel[];
  otherPlanDetails: PlanDetailsModel[];
}

let initialState: InitialState = {
  isLoading: false,
  genericVehicles: [],
  lastPlan: null,
  currentPlanDetails: [],
  upcomingPlanDetails: [],
  otherPlanDetails: [],
};

const planSlice = createSlice({
  name: "Plan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetPlansDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetPlansDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          state.currentPlanDetails = action.payload.data.currentPlanDetails;
          state.upcomingPlanDetails = action.payload.data.upcommingPlanDetails;
          state.otherPlanDetails = action.payload.data.otherPlanDetails;
        }
      })
      .addCase(GetPlansDetails.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(GetPlanInitialDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetPlanInitialDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.status) {
          if (action.payload.data) {
            state.genericVehicles = action.payload.data.genericVehicleTypes;
            state.lastPlan = action.payload.data.lastPlan;
          } else {
            state.genericVehicles = [];
            state.lastPlan = undefined;
          }
        }
      })
      .addCase(GetPlanInitialDetails.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(CreatePlan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreatePlan.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(CreatePlan.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const planReducer = planSlice.reducer;
