import PrivateRoute from "./PrivateRoute";
import BlockAndTheftList from '../pages/BlockAndTheft/BlockedContainer';
import { layoutProps } from "../Model/LayoutModel";

export default function BlockListLayout({ children }: layoutProps) {
    return (
        <PrivateRoute>
            <BlockAndTheftList>
                {children}
            </BlockAndTheftList>
        </PrivateRoute>
    )
}