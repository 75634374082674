import { Edit, LocalParking, PhoneAndroid, Pin } from "@mui/icons-material";
import {
  AlertColor,
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Snackbar,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CSSProperties, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { ExportAgingReport, GetAgingReport } from "../../Redux/Thunks/ReportThunk";
import Loading from "../../components/Loading/Loading";
import DataTable from "../../components/DataTable";
import {
  ToastMessageState,
  ToastMessage,
} from "../../components/ToastMessage/ToastMessage";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface Style {
  box: SxProps;
  infoCard: CSSProperties;
  radioGroup: CSSProperties;
  radioButton: CSSProperties;
  vehicleCard: CSSProperties;
}

const defaultValues = [
  { label: "Last one week before", value: "lastOneWeekBefore" },
  { label: "Last two weeks before", value: "lastTwoWeeksBefore" },
  { label: "Last one month before", value: "lastOneMonthBefore" },
  { label: "Last three months before", value: "lastThreeMonthsBefore" },
  { label: "Last six months before", value: "lastSixMonthsBefore" },
  { label: "Custom", value: "custom" },
];

const AgingReport = () => {
  const { isLoading, agingReport } = useAppSelector((state) => state.reports);
  const { user } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [value, setValue] = useState(defaultValues[0].value);
  const initialDate = dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
    .subtract(1, "week")
    .toDate();
  const [date, setDate] = useState(initialDate);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const updateDate = (value: string) => {
    let newDate = dayjs(new Date(new Date().setHours(0, 0, 0, 0)));
    switch (value) {
      case "lastOneWeekBefore":
        newDate = newDate.subtract(1, "week");
        break;
      case "lastTwoWeeksBefore":
        newDate = newDate.subtract(2, "week");
        break;
      case "lastOneMonthBefore":
        newDate = newDate.subtract(1, "months");
        break;
      case "lastThreeMonthsBefore":
        newDate = newDate.subtract(3, "months");
        break;
      case "lastSixMonthsBefore":
        newDate = newDate.subtract(6, "months");
        break;
      default:
        newDate = dayjs(date);
        break;
    }
    setDate(newDate.toDate());
    return newDate.toDate();
  };

  const getReport = async (newDate?: Date) => {
    let arg: Date;
    if (newDate === undefined) {
      arg = dayjs(date).add(1, 'day').subtract(1, 'second').toDate()
    } else {
      arg = newDate;
    }
    const response = await dispatch(GetAgingReport(arg));
    if (!response.payload) {
      updateToastMessage(true, "Connection Refused", "error");
    }
  };

  const customTheme = createTheme({
    ...theme,
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  useEffect(() => {
    getReport();
  }, []);

  const onClickRadioButton = async (value: string) => {
    setValue(value);
    updateDate(value);
    if (value !== "custom") {
      await getReport();
    }
  };

  const handleSelect = async (event: SelectChangeEvent) => {
    setValue(event.target.value);
    let updatedDate = updateDate(event.target.value);
    if (event.target.value !== "custom") {
      await getReport(updatedDate);
    } else {
      setDatePickerOpen(true);
    }
  };

  let vehiclesList: string[] = [];
  agingReport.tokens.forEach((eachToken) => vehiclesList.push(eachToken.registerNumber));

  function countVehicleDuplicates(vehiclesList: string[]): string[] {
    const frequency: { [key: string]: number } = {};

    vehiclesList.forEach((element) => {
      frequency[element] = (frequency[element] || 0) + 1;
    });

    const result: string[] = Array.from(new Set(vehiclesList)).map(
      (element) => {
        if (frequency[element] > 1) {
          return `${element} (${frequency[element]})`;
        } else {
          return element;
        }
      }
    );

    return result;
  }
  const registerNumberList: string[] = countVehicleDuplicates(vehiclesList);

  const onClickExport = async () => {
    await dispatch(ExportAgingReport({ date: dayjs(date).add(1, 'day').subtract(1, 'second').toDate(), userId: user ? user.id : "" }));
  };

  const formattedDateTime = (props: any) => {
    const inTime = dayjs(new Date(props.data.inTime)).format(
      "DD MMM YYYY hh:mm A ddd"
    );
    return (
      <Box aria-label="Time" title="Time">
        {inTime}
      </Box>
    );
  };

  const vehicleIcon = (props: any) => (
    <Box aria-label="Time" title="Time">
      <img src={props.data.genericVehicleTypeIconUrl} />
    </Box>
  );

  const style: Style = {
    box: {
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      flex: 1,
    },
    infoCard: {
      padding: "10px",
      backgroundColor: "rgba(250, 242, 222,0.5)",
    },
    radioGroup: {
      display: "flex",
      flexDirection: "row",
    },
    radioButton: {
      margin: "0 20px 0 0",
      fontWeight: 500,
    },
    vehicleCard: {
      //   backgroundColor: "lightyellow",
      padding: "10px",
    },
  };

  return (
    <Box sx={style.box}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card style={style.infoCard}>
            {/* <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={defaultValues[0].value}
              name="radio-buttons-group"
              style={style.radioGroup}
            >
              {defaultValues.map((each) => (
                <FormControlLabel
                  key={each.value}
                  value={each.value}
                  control={<Radio />}
                  label={each.label}
                  onClick={() => onClickRadioButton(each.value)}
                  sx={style.radioButton}
                />
              ))}
            </RadioGroup> */}
            <Grid container sx={{ alignItems: "center" }}>
              <Grid md={3} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Date</InputLabel>
                  <Select
                    labelId="select-label"
                    onChange={handleSelect}
                    label="Date"
                    value={value}
                  >
                    {defaultValues.map((each) => (
                      <MenuItem value={each.value}>{each.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid md={1}></Grid>
              <Grid md={4} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ marginRight: "10px", fontWeight: 400 }}
                    gutterBottom
                  >
                    Parked vehicles before
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ margin: 0 }}>
                    {`${dayjs(date).format("DD MMM YYYY ddd")}`}
                  </Typography>
                  {value === "custom" && (
                    <IconButton
                      style={{ marginLeft: "10px", padding: 3 }}
                      onClick={() => setDatePickerOpen(true)}
                    >
                      <Edit fontSize="medium" color="warning" />
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  sx={{ display: "none" }}
                  value={dayjs(date)}
                  open={isDatePickerOpen}
                  onClose={() => setDatePickerOpen(false)}
                  onChange={(newDate: any) => setDate(new Date(newDate))}
                  onAccept={() => getReport()}
                  maxDate={dayjs(new Date())}
                />
              </LocalizationProvider>
              <Grid md={3} xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ marginRight: "10px", fontWeight: 400 }}
                    gutterBottom
                  >
                    Total vehicles
                  </Typography>
                  <Typography variant="h6">{agingReport.tokens.length}</Typography>
                </Box>
              </Grid>
              <Grid md={1} xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickExport()}
                    disabled={agingReport.tokens.length <= 0}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {/* <Typography variant="h5">
              {registerNumberList.join(", ")}
            </Typography> */}
          </Card>
        </Grid>
        <Box
          sx={{ height: "80vh", width: "100%", padding: "20px 0 20px 20px" }}
        >
          <DataTable
            rows={agingReport.tokens}
            columnDefs={[
              {
                headerName: "S.No.",
                valueGetter: "node.rowIndex + 1",
                minWidth: 70,
              },
              { field: "tokenDisplayNumber", headerName: "Token Number", minWidth: 110, },
              {
                field: "genericVehicleTypeIconUrl",
                headerName: "Vehicle Type",
                cellRenderer: vehicleIcon,
                maxWidth: 150,
                minWidth: 70,
              },
              {
                field: "inTime",
                headerName: "In Time",
                cellRenderer: formattedDateTime,
                minWidth: 150,
              },
              { field: "registerNumber", headerName: "Register Number", minWidth: 150, },
              { field: "customerMobile", headerName: "Customer Mobile", minWidth: 150, },
              // { field: "customerName", headerName: "Customer Name", minWidth: 150, },
            ]}
          />
        </Box>
        {/* {tokens.length > 0 &&
          tokens.map((eachToken) => (
            <Grid item xs={12} sm={6} md={4} key={eachToken.tokenId}>
              <Card style={style.vehicleCard}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={
                          genericVehicles.length > 0
                            ? genericVehicles.find(
                                (each) =>
                                  each.genericVehicleTypeId ===
                                  eachToken.genericVehicleTypeId
                              )?.iconUrl
                            : undefined
                        }
                        alt="Vehicle Image"
                        variant="square"
                        style={{
                          textAlign: "center",
                          margin: "5px",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </ListItemAvatar>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemIcon>
                        <PhoneAndroid />
                      </ListItemIcon>
                      <ListItemText
                        primary={eachToken.customerMobile}
                        sx={{ margin: "0" }}
                      />
                    </ListItem>
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemIcon>
                        <Pin />
                      </ListItemIcon>
                      <ListItemText
                        primary={eachToken.registerNumber}
                        sx={{ margin: "0" }}
                      />
                    </ListItem>
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemIcon>
                        <LocalParking />
                      </ListItemIcon>
                      <ListItemText
                        primary={dayjs(eachToken.inTime).format(
                          "ddd DD MMM YYYY hh:mm A"
                        )}
                        sx={{ margin: "0" }}
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))} */}
      </Grid>
      <Loading openModal={isLoading} />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      // placement={{ vertical: "top", horizontal: "left" }}
      />
    </Box>
  );
};

export default AgingReport;
