import * as React from "react";
import Slider, { SliderOwnProps } from "@mui/material/Slider";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import dayjs from "dayjs";
import { Typography, useTheme } from "@mui/material";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

interface Marks {
  value: number;
  label: string;
}

interface Props {
  startValue: number;
  endValue: number;
  min: number;
  max: number;
  onChangeSliderValue: (startValue: number, endValue: number) => void;
  marks: Marks[];
}

const PreviewSlider: React.FC<Props> = (props) => {
  const { startValue, endValue, min, max, onChangeSliderValue, marks } = props;

  return (
    <Slider
      aria-label="Custom marks"
      value={[startValue, endValue]}
      valueLabelFormat={(value) => (
        <Typography>
          {dayjs(new Date(new Date().setHours(0, 0, 0, 0)))
            .add(value, "minutes")
            .format("DD MMM YY hh:mm A")}
        </Typography>
      )}
      step={1}
      valueLabelDisplay="auto"
      marks={marks}
      min={min}
      max={max}
      onChange={(event, value, activeThumb) => {
        if (Array.isArray(value)) {
          onChangeSliderValue(value[0], value[1]);
        }
      }}
      sx={{ width: "80%" }}
    />
  );
};

export default PreviewSlider;
