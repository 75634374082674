import { Modal, Box, Card } from '@mui/material';
import { useAppSelector } from '../../Redux/Store';
import './TokenDetails.css';
import NewLabel from '../../components/NewLabel/NewLabel';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import formatDate from '../../utilities/formatDate';
import { getDuration } from '../../utilities/getDuration';
dayjs.extend(utcPlugin);

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    height: '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function TokenDetails(props: any) {
    const { open, handleDetailsClose } = props;
    const [timeGap, setTimeGap]: any = useState('');
    let { exit } = useAppSelector(
        (state: any) => state.exitReducer
    );

    useEffect(() => {
        if (Object.keys(exit).length > 0) {
            let duration = getDuration(new Date(formatDate(exit.actualInTime)), new Date(exit.actualOutTime ? formatDate(exit.actualOutTime) : new Date()));
            setTimeGap(duration);
        }
    }, [exit])

    return (
        <Modal
            open={open}
            onClose={handleDetailsClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className='detail-card-container'>
                    <Card sx={{ padding: '5px 8px', width: '48%' }} elevation={0}>
                        <NewLabel Label={'Register Number'} Value={exit.registerNumber} />
                    </Card>
                    <Card sx={{ padding: '5px', width: '46%' }} elevation={0}>
                        <img alt='vehicle icon'
                            src={exit.iconUrl}
                        />
                    </Card>
                </div>
                <div className='detail-card-container'>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Mobile Number'} Value={exit.customerMobile} />
                    </Card>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Token Number'} Value={exit.tokenDisplayNumber} />
                    </Card>
                </div>
                <div className='detail-card-container'>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Amount'} Value={exit.collectedAmount ? exit.collectedAmount : 'N/A'} />
                    </Card>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Name'} Value={exit.customerName ? exit.customerName : 'Virtual Customer'} />
                    </Card>
                </div>
                <div className='detail-card-container'>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'In Time'} Value={formatDate(exit.actualInTime, 'ddd DD MMM YYYY hh:mm A')} />
                    </Card>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Out Time'} Value={exit.actualOutTime ? formatDate(exit.actualOutTime, 'ddd DD MMM YYYY hh:mm A') : 'Vehicle still in'} />
                    </Card>
                </div>
                <div className='detail-card-container'>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Vehicle Status'} Value={exit.status === 1 ? 'In' : 'Out'} />
                    </Card>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Plan Type'} Value={exit.planType === 1 ? 'Fixed Plan' : 'Slab Plan'} />
                    </Card>
                </div>
                <div className='detail-card-container'>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Token Type'} Value={exit.tokenType === 1 ? 'Quick Token' : 'Advanced Token'} />
                    </Card>
                    <Card sx={{ padding: '5px 8px', width: '46%' }} elevation={0}>
                        <NewLabel Label={'Parking Lot'} Value={exit.parkingLotName} />
                    </Card>
                </div>
                <div className='detail-last-card'>
                    <NewLabel Label={''} Value={timeGap} />
                </div>
            </Box>
        </Modal>
    )
}

export default TokenDetails;
