import {
  PlaylistAddCheckCircle,
  Grade,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import formatDate from "../../../utilities/formatDate";

interface Props {
  currentPlanDetails: PlanDetailsModel[];
}

const CurrentPlanDetails = (props: Props) => {
  const { currentPlanDetails } = props;

  const [showMainPlanDetails, setMainPlanDetails] = useState(false);

  let mainPlan: PlanDetailsModel | undefined;
  let specialPlan: PlanDetailsModel | undefined;

  if (currentPlanDetails.length > 0) {
    if (currentPlanDetails.length > 1) {
      specialPlan = currentPlanDetails.find(
        (each) => each.plan.isSpecialPlan === true
      );
      mainPlan = currentPlanDetails.find(
        (each) => each.plan.isSpecialPlan === false
      );
    } else {
      mainPlan = currentPlanDetails[0];
    }
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        marginBottom: "16px",
        background:
          "linear-gradient(276deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
        minHeight: "30vh",
      }}
    >
      {currentPlanDetails.length > 0 ? (
        <>
          {specialPlan && (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              margin="16px 16px 8px 16px"
              key={specialPlan.plan.planId}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Avatar sx={{ backgroundColor: "transparent" }}>
                  <PlaylistAddCheckCircle fontSize="large" color="primary" />
                </Avatar>
                <Typography
                  variant="h4"
                  color="primary"
                  sx={{ margin: "0 10px", flexGrow: 1 }}
                >
                  {specialPlan.plan.planName}
                </Typography>
                <Tooltip title="Special Plan">
                  <Avatar sx={{ backgroundColor: "transparent" }}>
                    <Grade fontSize="large" color="warning" />
                  </Avatar>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ margin: "5px 10px", fontWeight: "600" }}
                >
                  Assigned:{" "}
                  {currentPlanDetails &&
                    formatDate(
                      specialPlan.plan.assignedDate,
                      "ddd DD MMM YYYY"
                    )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ margin: "5px 10px", fontWeight: "600" }}
                >
                  Effective:{" "}
                  {formatDate(
                    specialPlan.plan.effectiveDate,
                    "ddd DD MMM YYYY"
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ margin: "5px 10px", fontWeight: "600" }}
                >
                  Expiry:{" "}
                  {formatDate(specialPlan.plan.expiryDate, "ddd DD MMM YYYY")}
                </Typography>
              </Box>
            </Box>
          )}
          {mainPlan && (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              margin={`${specialPlan ? "8px 16px 16px 16px" : "16px"}`}
              key={mainPlan.plan.planId}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: `${specialPlan ? "0px" : "10px"}`,
                }}
              >
                <Avatar sx={{ backgroundColor: "transparent" }}>
                  <PlaylistAddCheckCircle
                    fontSize={specialPlan ? "medium" : "large"}
                    color="primary"
                  />
                </Avatar>
                <Typography
                  variant={specialPlan ? "h6" : "h4"}
                  color="primary"
                  sx={{ margin: "0 10px", flexGrow: 1 }}
                >
                  {mainPlan.plan.planName}
                </Typography>
                {specialPlan && (
                  <IconButton
                    onClick={() => setMainPlanDetails((preState) => !preState)}
                  >
                    {showMainPlanDetails ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )}
              </Box>
              <Collapse in={specialPlan ? showMainPlanDetails : true}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ margin: "5px 10px", fontWeight: "600" }}
                  >
                    Assigned:{" "}
                    {currentPlanDetails &&
                      formatDate(mainPlan.plan.assignedDate, "ddd DD MMM YYYY")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ margin: "5px 10px", fontWeight: "600" }}
                  >
                    Effective:{" "}
                    {formatDate(mainPlan.plan.effectiveDate, "ddd DD MMM YYYY")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ margin: "5px 10px", fontWeight: "600" }}
                  >
                    Expiry:{" "}
                    {formatDate(mainPlan.plan.expiryDate, "ddd DD MMM YYYY")}
                  </Typography>
                </Box>
              </Collapse>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Typography variant="h6">Current Plan Not Available</Typography>
        </Box>
      )}
    </Card>
  );
};

export default CurrentPlanDetails;
