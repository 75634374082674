import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store";
import { ListInOrOut } from "../../Redux/Thunks/ListThunk";
import DataTable from "../../components/DataTable";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  Button,
  AlertColor,
  Tooltip,
  Fab,
} from "@mui/material";
import Loading from "../../components/Loading/Loading";
import { AllExitDetails, ExitDetails } from "../../Redux/Thunks/ExitThunk";
import "./List.css";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InTokenContent from "../../components/TokenContent/InTokenContent";
import { TokenPrintModel } from "../../Model/TokenModel";
import OutTokenContent from "../../components/TokenContent/OutTokenContent";
import TokenDetails from "../TokenDetails/TokenDetails";
import {
  ToastMessageState,
  ToastMessage,
} from "../../components/ToastMessage/ToastMessage";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SearchBar from "../../components/SearchBar/SearchBar";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import formatDate from "../../utilities/formatDate";
import theme from "../../Theme/Theme";
import { SearchValueModel } from "../../Model/SearchBarModel";

dayjs.extend(utcPlugin);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const List = () => {
  const listDetails: any = useAppSelector((state) => state.listReducer.List);
  const { attachments, isLoading } = useAppSelector(
    (state) => state.listReducer
  );
  let { allExit, exit }: any = useAppSelector(
    (state: any) => state.exitReducer
  );

  const dispatch = useAppDispatch();

  const printRef = useRef<HTMLDivElement>(null);

  const [details, setDetails] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState("In");
  const [openModal, setOpenModal] = useState(false);
  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [printTokenData, setPrintTokenData] = useState<TokenPrintModel>(
    {} as TokenPrintModel
  );
  const [isSearchExist, setSearchExit] = useState(false);
  const [startPrint, setStartPrint] = useState(false);
  const [searchValue, setSearchValue] = useState<SearchValueModel>({
    key: "",
    title: "",
    value: "",
    tokenNo: "",
  });

  useEffect(() => {
    dispatch(ListInOrOut(1));
    dispatch(AllExitDetails());
  }, []);

  // useEffect(() => {
  //     setAutosearchData(allExit);
  // }, [allExit, currentTab])

  useEffect(() => {
    setDetails(getTokenDetails(listDetails));
  }, [listDetails, currentTab]);

  useEffect(() => {
    if (isSearchExist) setDetails(getTokenDetails([exit]));
    else setDetails(getTokenDetails(listDetails));
  }, [isSearchExist]);

  const handleDetailsClose = () => setDetailsOpen(false);

  const handleCloseModal = () => setOpenModal(false);

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const handleSearch = (data: any) => {
    dispatch(ExitDetails(data.tokenNo)).then((res: any) => {
      if (res.meta.requestStatus !== "fulfilled") {
        updateToastMessage(true, "Connection Refused", "error");
        return;
      }
      if (res.payload.status === 204) {
        updateToastMessage(true, "No data found", "info");
        return;
      }
      if (res.payload.status) {
        setSearchExit(true);
        // navigation('/exit?token=' + data.tokenNo)
      } else {
        updateToastMessage(true, res.payload.message, "info");
      }
    });
  };

  const getTokenDetails = (details: any) => {
    let filteredDetails = details.map((list: any) => {
      return {
        token: list.tokenDisplayNumber,
        tokenNumber: list.tokenNumber,
        tokenId: list.tokenId,
        time: formatDate(currentTab === "In" ? list.inTime : list.outTime),
        name: list.customerName,
        mobile: list.customerMobile,
        regNo: list.registerNumber,
        sync: true,
        attachment: list.attachments ? true : false,
        vechicleIcon: list.iconUrl,
        inTime: formatDate(list.inTime),
        outTime: formatDate(list.outTime),
        amount: list.collectedAmount,
        vehicleType: list.vehicleTypeName,
      };
    });
    return filteredDetails;
  };

  const handleRowClick = async (data: any) => {
    await dispatch(ExitDetails(data.data.tokenNumber));
    setDetailsOpen(true);
  };

  // const AttachmentRenderer = (props: any) => {
  //     let attachment = props.data.attachment;
  //     return (
  //         attachment && <Box>
  //             <Tooltip title="Attachment" placement='bottom-start'>
  //                 <IconButton onClick={() => handleAttachmentClick(props.data.tokenId)} color={attachment ? 'success' : 'default'} aria-label="Sync">
  //                     <AttachmentIcon fontSize='medium' />
  //                 </IconButton>
  //             </Tooltip>
  //         </Box>
  //     );
  // };

  // const handleAttachmentClick = (tokenId: string) => {
  //     dispatch(ListAttachments(tokenId)).then((response) => {
  //         if (response.meta.requestStatus !== "fulfilled") {
  //             updateToastMessage(true, "Connection Refused", "error");
  //             return;
  //         }
  //         setOpenModal(true);
  //     })
  // };

  // const SyncRenderer = (props: any) => {
  //     let synced = props.data.sync;
  //     return (
  //         <div onClick={() => handleRowClick(props)}>
  //             <Box>
  //                 <Tooltip title="Sync" placement='bottom-start'>
  //                     <IconButton color={synced ? 'success' : 'default'} aria-label="Sync">
  //                         <CloudDoneIcon />
  //                     </IconButton>
  //                 </Tooltip>
  //             </Box>
  //         </div>
  //     );
  // };

  const NameRenderer = (props: any) => {
    let name = props.data.name;
    return (
      <div onClick={() => handleRowClick(props)}>
        <Tooltip title="Name" placement="bottom-start">
          <Box>{name ? name : "Virtual customer"}</Box>
        </Tooltip>
      </div>
    );
  };

  const VechicleRender = (props: any) => {
    return (
      <div onClick={() => handleRowClick(props)}>
        <Tooltip title="Type" placement="bottom-start">
          <Box>
            <img alt="vehicle icon" src={props.data.vechicleIcon} />
          </Box>
        </Tooltip>
      </div>
    );
  };

  const DateRenderer = (props: any) => {
    let date = new Date(props.data.time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return (
      <div onClick={() => handleRowClick(props)}>
        <Tooltip title="Time" placement="bottom-start">
          <Box>
            {new Date(props.data.time).toDateString() + " "}
            {hours + " "}
            {minutes + " "}
            {seconds}
          </Box>
        </Tooltip>
      </div>
    );
  };

  const TokenRenderer = (props: any) => {
    return (
      <div onClick={() => handleRowClick(props)}>
        <Tooltip title="Token" placement="bottom-start">
          <Box>{props.data.token}</Box>
        </Tooltip>
      </div>
    );
  };

  const MobileRenderer = (props: any) => {
    return (
      <div onClick={() => handleRowClick(props)}>
        <Tooltip title="Mobile" placement="bottom-start">
          <Box>{props.data.mobile}</Box>
        </Tooltip>
      </div>
    );
  };

  const RegiterNumberRenderer = (props: any) => {
    return (
      <div onClick={() => handleRowClick(props)}>
        <Tooltip title="Register Number" placement="bottom-start">
          <Box>{props.data.regNo}</Box>
        </Tooltip>
      </div>
    );
  };

  const ActionsRenderer = (props: any) => {
    return (
      <div>
        <Box aria-label="Print Token">
          <Tooltip title="Print Token" placement="bottom-start">
            <IconButton onClick={() => onPrint(props.data)}>
              <LocalPrintshopIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Preview Token" placement="bottom-start">
            <IconButton onClick={() => onPreview(props.data)}>
              <RemoveRedEyeIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    );
  };

  const paymentRenderer = (props: typeof details) => {
    return (
      <div>
        <Box>
          {props.data.amount > 0 ? (
            <Tooltip title={`₹${props.data.amount}`} placement="left">
              <IconButton color={"success"}>
                <VerifiedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton color={"primary"}>
              <CancelIcon />
            </IconButton>
          )}
        </Box>
      </div>
    );
  };

  const onPreview = (data: TokenPrintModel) => {
    setStartPrint(false);
    setPrintTokenData(data);
    setOpenPreviewModal(true);
  };

  const onPrint = (data: TokenPrintModel) => {
    setPrintTokenData((prevData) => {
      return data;
    });
    setStartPrint(true);
    setOpenPreviewModal(false);
  };

  const handlePrintClose = () => {
    setOpenPreviewModal(false);
  };

  const handleOnDrag = (e: React.DragEvent, widgetType: string) => {
    e.dataTransfer.setData("widgetType", widgetType);
  };

  const toggleTab = async () => {
    if (currentTab !== "In") {
      setCurrentTab("In");
      await dispatch(ListInOrOut(1)).then((response) => {
        if (response.meta.requestStatus !== "fulfilled") {
          updateToastMessage(true, "Connection Refused", "error");
          return;
        }
      });
    } else {
      setCurrentTab("Out");
      await dispatch(ListInOrOut(2)).then((response) => {
        if (response.meta.requestStatus !== "fulfilled") {
          updateToastMessage(true, "Connection Refused", "error");
          return;
        }
      });
    }
  };

  const columnDefs: ColDef[] = [
    {
      field: "vechicleType",
      headerName: "Vechicle",
      cellRenderer: VechicleRender,
      minWidth: 75,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: ActionsRenderer,
      minWidth: 105,
    },
    {
      field: "token",
      headerName: "Token",
      minWidth: 104,
      cellRenderer: TokenRenderer,
    },
    {
      field: "time",
      headerName: `${currentTab} Time`,
      minWidth: 170,
      cellRenderer: DateRenderer,
    },
    {
      field: "name",
      headerName: "Name",
      cellRenderer: NameRenderer,
      minWidth: 150,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      cellRenderer: MobileRenderer,
      minWidth: 105,
    },
    {
      field: "regNo",
      headerName: "Register No",
      cellRenderer: RegiterNumberRenderer,
      minWidth: 105,
    },
    // { field: "attachment", headerName: "Attachment", cellRenderer: AttachmentRenderer, maxWidth: 124 },
    // { field: "sync", headerName: "Sync", cellRenderer: SyncRenderer, maxWidth: 90 },
    {
      field: "amount",
      headerName: "Payment Status",
      cellRenderer: paymentRenderer,
      minWidth: 90,
    },
  ];

  return (
    <div style={{ height: "80vh", margin: "10px" }}>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {attachments &&
              attachments.map((attachment: any) => {
                return <div>{attachment.name}</div>;
              })}
            ;
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <div>
            {currentTab === "In" ? (
              <InTokenContent
                printRef={null}
                printData={printTokenData}
                isPrint={false}
                setStartPrint={setStartPrint}
              />
            ) : (
              <OutTokenContent
                printRef={null}
                printData={printTokenData}
                isPrint={false}
                setStartPrint={setStartPrint}
              />
            )}
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                sx={{ marginRight: "10px" }}
                onClick={() => onPrint(printTokenData)}
              >
                Print
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrintClose}
              >
                Close
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
      {isDetailsOpen && (
        <TokenDetails
          handleDetailsClose={handleDetailsClose}
          open={isDetailsOpen}
        />
      )}
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid
          xs={4}
          md={4}
          justifyContent="start"
          display="flex"
          alignItems="center"
        >
          <Typography
            style={{
              height: "40px",
              width: currentTab === "In" ? "52px" : "77px",
            }}
            color={theme.palette.text.primary}
            variant="h4"
          >
            {currentTab === "In" ? "In" : "Out"}
          </Typography>
          <Grid marginLeft={{ xs: "18px", md: "0px" }} />
          <Box onClick={() => toggleTab()}>
            <Fab
              color="primary"
            >
              <SwapHorizIcon />
            </Fab>
          </Box>
          <Typography
            style={{ marginLeft: "20px", height: "40px" }}
            color={theme.palette.text.secondary}
            variant="h4"
          >
            {currentTab === "In" ? "Out" : "In"}
          </Typography>
        </Grid>
        <Grid xs={10} md={4}>
          <SearchBar
            handleSearch={handleSearch}
            searchData={listDetails}
            setSearchExit={setSearchExit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Grid>
        <Grid xs={0} md={4} />
        <Loading openModal={isLoading} />
      </Grid>
      <DataTable rows={details} columnDefs={columnDefs} />
      {/* <Box sx={{ position: 'fixed', bottom: 0, right: 16 }} onClick={() => toggleTab()}>
                <Fab color="primary" className="fixed-speed-dial">
                    <SwapHorizIcon />
                </Fab>
            </Box> */}
      <Loading openModal={isLoading} />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      // placement={{ vertical: "top", horizontal: "left" }}
      />
      <Box sx={{ display: "none" }}>
        {currentTab === "In" && startPrint && (
          <InTokenContent
            printRef={printRef}
            printData={printTokenData}
            isPrint={true}
            setStartPrint={setStartPrint}
          />
        )}
        {currentTab === "Out" && startPrint && (
          <OutTokenContent
            printRef={printRef}
            printData={printTokenData}
            isPrint={true}
            setStartPrint={setStartPrint}
          />
        )}
      </Box>
    </div>
  );
};

export default List;
