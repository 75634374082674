import {
  Box,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAppSelector } from "../../Redux/Store";
import EmojiTransportationRoundedIcon from "@mui/icons-material/EmojiTransportationRounded";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ParkingLotVehicleModel } from "../../Model/DashboardReportModel";
import DashboardDetail from "./DashboardDetail";

const header = {
  display: "flex",
  justifyContent: "space-between",
};

const text = {
  background: `-webkit-linear-gradient(45deg, #ffcc00, #ff6699)`,
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  fontWeight: 700,
};

const todayStatus = {
  borderRadius: "30px",
  background: "transparent",
  padding: "4px 23px",
  boxShadow: "0px 10px 15px 0px rgba(46, 37, 134, 0.24)",
  outline: "1px solid #ccc",
};

const label = {
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  color: "#282f79",
  marginLeft: "15px",
  borderRadius: "30px",
};

const style = {
  padding: "10px",
};

const list = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const parkingLotDetails = {
  color: "#282f79",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 500,
};

const lot = {
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  color: "#f94749",
};

const details = {
  marginTop: "20px",
};

const icon = {
  width: "32px",
  height: "32px",
  flexShrink: 0,
};

const DashboardCurrentStatus = () => {
  const { parkingLot, user } = useAppSelector((state) => state.profile);
  const { dashboardReport, isLoading }: any = useAppSelector(
    (state) => state.dashboard
  );

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <Box>
        <Box sx={header}>
          <Typography sx={text}>
            Hi there {user?.firstName} {user?.lastName}!
          </Typography>
          <Typography variant="h6" sx={text}>
            {parkingLot?.parkingLotName}
          </Typography>
        </Box>
        <Box>
          <Box sx={todayStatus}>
            <Typography sx={label}> Today Status</Typography>
            <Grid container sx={style}>
              <Grid xs={4} md={4}>
                <Box sx={list}>
                  <Box>
                    <ListItemIcon>
                      <EmojiTransportationRoundedIcon
                        sx={icon}
                      ></EmojiTransportationRoundedIcon>
                    </ListItemIcon>
                  </Box>
                  <Box>
                    <Box sx={parkingLotDetails}>
                      <Typography>IN</Typography>
                      <Typography sx={lot}>
                        {dashboardReport?.todayReport?.totalParkedIn === null
                          ? 0
                          : dashboardReport.todayReport?.totalParkedIn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={4} md={4}>
                <Box sx={list}>
                  <Box>
                    <Box sx={parkingLotDetails}>
                      <Typography>Out</Typography>
                      <Typography sx={lot}>
                        {dashboardReport?.todayReport?.totalParkedOut === null
                          ? 0
                          : dashboardReport.todayReport?.totalParkedOut}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={3} md={3}>
                <Box sx={list}>
                  <Box>
                    <Box sx={parkingLotDetails}>
                      <Typography>Amount</Typography>
                      <Typography sx={lot}>
                        ₹
                        {dashboardReport.todayReport?.totalPaymentReceived ===
                        null
                          ? 0
                          : dashboardReport.todayReport?.totalPaymentReceived}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                xs={1}
                md={1}
                display="flex"
                justifyContent="end"
                alignItems="baseline"
              >
                {dashboardReport?.todayReport?.todayDetails?.length > 0 && (
                  <IconButton onClick={handleToggle} sx={details}>
                    <Tooltip title="Details">
                      {open ? <ArrowDropUpIcon /> : <ArrowDropDownSharpIcon />}
                    </Tooltip>
                  </IconButton>
                )}
              </Grid>
              <Grid xs={12}>
                <Collapse in={open}>
                  {dashboardReport &&
                    dashboardReport.todayReport &&
                    dashboardReport?.todayReport?.todayDetails?.length > 0 &&
                    dashboardReport.todayReport.todayDetails.map(
                      (details: ParkingLotVehicleModel) => (
                        <Grid md={12}>
                          <DashboardDetail vehicleDetails={details} />
                        </Grid>
                      )
                    )}
                </Collapse>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCurrentStatus;
