import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface dialogProps {
    openAlert: boolean;
    handleAlertClose: () => void;
    message: string;
}

const WarningDialog = ({ openAlert, handleAlertClose, message: alertMessage }: dialogProps) => {
    return <Dialog
        open={openAlert}
        keepMounted
        onClose={handleAlertClose}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>
            <Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '80px' }} >
                {<ErrorOutlineIcon fontSize='large' sx={{ color: 'primary.main' }} />}
            </Grid>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {alertMessage}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={handleAlertClose}>Ok</Button>
        </DialogActions>
    </Dialog>
}

export default WarningDialog;