import { Box } from "@mui/material";
import { Container, Grid, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { ParkingLotRegisterFormikInputs } from "./ParkingLotRegisterForm";
import { useAppSelector } from "../../Redux/Store";
import Loading from "../../components/Loading/Loading";

interface Props {
  formik: FormikProps<ParkingLotRegisterFormikInputs>;
}

const RegisterForm = (props: Props) => {
  const { formik } = props;
  const { isLoading } = useAppSelector((state) => state.parkinglot);

  return (
    <Box>
      <Container>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                {...formik.getFieldProps("phoneNumber")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Invitation Code"
                error={
                  formik.touched.invitation && Boolean(formik.errors.invitation)
                }
                helperText={
                  formik.touched.invitation && formik.errors.invitation
                }
                {...formik.getFieldProps("invitation")}
              />
              <Loading openModal={isLoading} />
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default RegisterForm;
