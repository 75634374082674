import React, { useEffect } from 'react';
import './TokenContent.css';
import dayjs from 'dayjs';
import { useAppSelector } from '../../Redux/Store';
import { useReactToPrint } from 'react-to-print';
import { outTokenPropsModel } from '../../Model/TokenContentModel';
import { getDuration } from '../../utilities/getDuration';

const OutTokenContent = ({ printRef, printData, isPrint, setStartPrint }: outTokenPropsModel) => {

    const { receiptFormat } = useAppSelector((state) => state.profile);
    const receiptData = receiptFormat.find(item => item.typeNumber === 2);

    const formattedInDate = dayjs(printData.inTime).format("DD-MM-YYYY");
    const formattedOutDate = dayjs(printData.outTime).format("DD-MM-YYYY");
    const inTime = dayjs(printData.inTime).format("hh:mm A");
    const outTime = dayjs(printData.outTime).format("hh:mm A");

    const duration = getDuration(new Date(printData.inTime), new Date(printData.outTime));



    useEffect(() => {
        if (Object.keys(printData).length !== 0 && isPrint) {
            setStartPrint(false);
            onHandlePrint();
        }
    }, [printData.tokenId])

    const onHandlePrint = useReactToPrint({
        content: () => printRef!.current,
    });

    return (
        <div className="print-wrapper txt-center" ref={printRef} >
            <div className="border-bottom">
                <div className="mv-5">
                    {printData.title ? printData.title : receiptData?.title}
                </div>
            </div>
            {receiptData?.subtitle || printData.subTitle && <div className="border-bottom">
                <div className="mv-5">
                    {printData.subTitle ? printData.subTitle : receiptData?.subtitle}
                </div>
            </div>}
            <div className="border-bottom">
                <div className="mv-5">
                    <div>
                        TOKEN OUT
                    </div>
                </div>
            </div>
            <div className="border-bottom">
                <div className='mv-5'>
                    <div>
                        {printData.regNo}
                    </div>
                </div>
            </div>
            <div className='border-bottom'>
                <div className='mv-5'>
                    <div>
                        Token No : {printData.token}
                    </div>
                    <div>
                        Vehicle : {printData.vehicleType}
                    </div>
                </div>
            </div>
            <div>
                <div className='out-details'>
                    <div>
                        In Time : {`${formattedInDate} ${inTime}`}
                    </div>
                    <div>
                        Out Time : {`${formattedOutDate} ${outTime}`}
                    </div>
                    <div>
                        Duration : {duration}
                    </div>
                    <div className='mv-5'>
                        Amount : {printData.amount !== null ? printData.amount : `N/A`}
                    </div>
                </div>
            </div>
            <div className="mv-5">
                {printData.footer ? printData.footer : receiptData?.footer}
            </div>
            <div className="mv-5">
                Powered by AutoHiveSquare
            </div>
        </div>
    );
}

export default OutTokenContent;