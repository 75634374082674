import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { PlanFormikInputs } from "./AddPlan";
import dayjs, { Dayjs } from "dayjs";
import {
  ClockIcon,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";

interface Props {
  formik: FormikProps<PlanFormikInputs>;
}

const FixedPlanHourInputs = (props: Props) => {
  const { formik } = props;

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="body1"
        sx={{ fontWeight: "600", margin: "10px 5px" }}
      >
        Choose an Hour or Day/Night Schedule
      </Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <TextField
          variant="outlined"
          label="Hour"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ClockIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: "50%" }}
          type="number"
          error={
            formik.touched.scheduleTypeHourValue &&
            Boolean(formik.errors.scheduleTypeHourValue)
          }
          helperText={
            formik.touched.scheduleTypeHourValue
              ? formik.errors.scheduleTypeHourValue
              : ""
          }
          {...formik.getFieldProps("scheduleTypeHourValue")}
        />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ padding: "0 10px" }}
        >
          <Typography variant="h6">OR</Typography>
          <Chip
            label="Shift Basis"
            clickable
            color={
              formik.values.scheduleTypeHourValueDN ? "primary" : "default"
            }
            onClick={() =>
              formik.values.scheduleTypeHourValueDN
                ? formik.setFieldValue("scheduleTypeHourValueDN", false)
                : formik.setFieldValue("scheduleTypeHourValueDN", true)
            }
            style={{
              padding: "0 10px",
              margin: "4px 10px",
            }}
          />
        </Box>
        <TextField
          variant="outlined"
          label="Day Actual Charge"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">&#8377;</InputAdornment>
            ),
          }}
          sx={{ width: "50%" }}
          type="number"
          error={
            formik.touched.dayActualAmount &&
            Boolean(formik.errors.dayActualAmount)
          }
          helperText={
            formik.touched.dayActualAmount ? formik.errors.dayActualAmount : ""
          }
          {...formik.getFieldProps("dayActualAmount")}
          onChange={(event) => {
            formik.setFieldValue("dayActualAmount", event.target.value);
            if (
              formik.values.nightActualAmount === "" ||
              formik.values.nightActualAmount === formik.values.dayActualAmount
            ) {
              formik.setFieldValue("nightActualAmount", event.target.value);
            }
          }}
        />
        <TextField
          variant="outlined"
          label="Night Actual Charge"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">&#8377;</InputAdornment>
            ),
          }}
          sx={{ width: "50%" }}
          type="number"
          error={
            formik.touched.nightActualAmount &&
            Boolean(formik.errors.nightActualAmount)
          }
          helperText={
            formik.touched.nightActualAmount
              ? formik.errors.nightActualAmount
              : ""
          }
          {...formik.getFieldProps("nightActualAmount")}
        />
        {!formik.values.scheduleTypeHourValueDN && (
          <TextField
            variant="outlined"
            label="Day Minimum Minutes"
            InputLabelProps={{ shrink: true }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.dayMinimumMinutes &&
              Boolean(formik.errors.dayMinimumMinutes)
            }
            helperText={
              formik.touched.dayMinimumMinutes
                ? formik.errors.dayMinimumMinutes
                : ""
            }
            {...formik.getFieldProps("dayMinimumMinutes")}
          />
        )}
        {!formik.values.scheduleTypeHourValueDN && (
          <TextField
            variant="outlined"
            label="Night Minimum Minutes"
            InputLabelProps={{ shrink: true }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.nightMinimumMinutes &&
              Boolean(formik.errors.nightMinimumMinutes)
            }
            helperText={
              formik.touched.nightMinimumMinutes
                ? formik.errors.nightMinimumMinutes
                : ""
            }
            {...formik.getFieldProps("nightMinimumMinutes")}
          />
        )}
        {formik.values.scheduleTypeHourValueDN && (
          <TextField
            variant="outlined"
            label="Day Minimum Hour"
            InputLabelProps={{ shrink: true }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.dayMinimumHour &&
              Boolean(formik.errors.dayMinimumHour)
            }
            helperText={
              formik.touched.dayMinimumHour ? formik.errors.dayMinimumHour : ""
            }
            {...formik.getFieldProps("dayMinimumHour")}
          />
        )}
        {formik.values.scheduleTypeHourValueDN && (
          <TextField
            variant="outlined"
            label="Night Minimum Hour"
            InputLabelProps={{ shrink: true }}
            sx={{ width: "50%" }}
            type="number"
            error={
              formik.touched.nightMinimumHour &&
              Boolean(formik.errors.nightMinimumHour)
            }
            helperText={
              formik.touched.nightMinimumHour
                ? formik.errors.nightMinimumHour
                : ""
            }
            {...formik.getFieldProps("nightMinimumHour")}
          />
        )}
        <TextField
          variant="outlined"
          label="Day Basic Charge"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">&#8377;</InputAdornment>
            ),
          }}
          sx={{ width: "50%" }}
          type="number"
          error={
            formik.touched.dayBasicAmount &&
            Boolean(formik.errors.dayBasicAmount)
          }
          helperText={
            formik.touched.dayBasicAmount ? formik.errors.dayBasicAmount : ""
          }
          {...formik.getFieldProps("dayBasicAmount")}
          onChange={(event) => {
            formik.setFieldValue("dayBasicAmount", event.target.value);
            if (
              formik.values.nightBasicAmount === 0 ||
              formik.values.nightBasicAmount === formik.values.dayBasicAmount
            ) {
              formik.setFieldValue("nightBasicAmount", event.target.value);
            }
          }}
        />
        <TextField
          variant="outlined"
          label="Night Basic Charge"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">&#8377;</InputAdornment>
            ),
          }}
          sx={{ width: "50%" }}
          type="number"
          error={
            formik.touched.nightBasicAmount &&
            Boolean(formik.errors.nightBasicAmount)
          }
          helperText={
            formik.touched.nightBasicAmount
              ? formik.errors.nightBasicAmount
              : ""
          }
          {...formik.getFieldProps("nightBasicAmount")}
        />
      </Box>
    </Box>
  );
};

export default FixedPlanHourInputs;
