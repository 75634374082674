import { convertTimeTo24Hour } from "./utilities/dateTimeConversion";
import { dayNightCalculation } from "./dayNightCalculation";
import { daysCalculation } from "./daysCalculation";
import { hoursCalculation } from "./hoursCalculation";
import { weekCalculation } from "./weekCalculation";
import { ScheduleTypes } from "./utilities/constants";
import { AmountCalculationModel, PricingPlan, planDetailsModel } from "./Model/amountCalculationModels";

export class AmountCalculation {

    fixedPlanCalculation(planDetails: planDetailsModel): AmountCalculationModel {
        const {
            inTime,
            outTime,
            dayParkingFrom,
            dayParkingTo,
            nightParkingFrom,
            nightParkingTo,
            scheduleType,
            basicAmount,
            actualAmount,
            dayBasicAmount,
            dayActualAmount,
            nightBasicAmount,
            nightActualAmount,
            dayMinimumHour,
            dayMinimumMinutes,
            nightMinimumHour,
            nightMinimumMinutes,
            dailyMinimumHour,
            weeklyMinimumDay,
            dayChargeType
        } = planDetails
        let scheduleTypeInNum = scheduleType.slice(1);
        let pricingPlan: PricingPlan = {
            day: {
                startHour: convertTimeTo24Hour(dayParkingFrom).hours,
                endHour: convertTimeTo24Hour(dayParkingTo).hours,
                startMinutes: convertTimeTo24Hour(dayParkingFrom).minutes,
                endMinutes: convertTimeTo24Hour(dayParkingTo).minutes
            },
            night: {
                startHour: convertTimeTo24Hour(nightParkingFrom).hours,
                endHour: convertTimeTo24Hour(nightParkingTo).hours,
                startMinutes: convertTimeTo24Hour(nightParkingFrom).minutes,
                endMinutes: convertTimeTo24Hour(nightParkingTo).minutes
            }
        }
        if (scheduleType === ScheduleTypes.dayNight) {
            // Type - Day Night
            const { daySessions, nightSessions, dayMinimumDuration, nightMinimumDuration } = dayNightCalculation(new Date(inTime), outTime, pricingPlan, dayMinimumHour, nightMinimumHour);
            let result = {
                daySessions,
                nightSessions,
                dayMinimumHour: dayMinimumDuration,
                nightMinimumHour: nightMinimumDuration,
                totalAmount: (daySessions * dayActualAmount) + (nightSessions * nightActualAmount) + (dayMinimumDuration * (dayMinimumDuration !== 0 ? dayBasicAmount : dayActualAmount)) + (nightMinimumDuration * (nightMinimumDuration !== 0 ? nightBasicAmount : nightActualAmount))
            }
            return result;
        }
        if (scheduleType.includes(ScheduleTypes.day)) {
            // Type - Day
            const { totalDays, minimumHour } = daysCalculation(inTime, outTime, parseInt(scheduleTypeInNum, 10), dailyMinimumHour);
            // console.log('totalDays::', totalDays, 'minimumHour::', minimumHour);
            // console.log('Amount::', (totalDays * (actualAmount !== null ? actualAmount : 0)) + (minimumHour * (basicAmount !== null ? basicAmount : 0)));
            let result = {
                totalDays,
                minimumHour,
                totalAmount: (totalDays * (actualAmount !== null ? actualAmount : 0)) + (minimumHour * (basicAmount !== null ? basicAmount : 0))
            }
            return result;
        }
        if (scheduleType.includes(ScheduleTypes.week)) {
            // Type - Week
            const { totalWeeks, minimumTime } = weekCalculation(inTime, outTime, parseInt(scheduleTypeInNum, 10), weeklyMinimumDay);
            // console.log('totalWeeks::', totalWeeks, 'minimumHour::', minimumTime);
            // console.log('Amount::', (totalWeeks * (actualAmount !== null ? actualAmount : 0)) + (minimumTime * (basicAmount !== null ? basicAmount : 0)));
            let result = {
                totalWeeks,
                minimumDays: minimumTime,
                totalAmount: (totalWeeks * (actualAmount !== null && actualAmount !== 0 ? actualAmount : 0)) + (minimumTime * (basicAmount !== null && basicAmount !== 0 ? basicAmount : 0))
            }
            return result;
        }
        if (scheduleType.includes(ScheduleTypes.hour)) {
            // Type - Hour
            const { dayHours, nightHours, dayMinimumDuration, nightMinimumDuration } = hoursCalculation(new Date(inTime), outTime, parseInt(scheduleTypeInNum, 10), pricingPlan, dayMinimumMinutes, nightMinimumMinutes);

            // console.log((dayHours * dayActualAmount) + (nightHours * nightActualAmount)
            //     + (dayMinimumDuration * dayBasicAmount) + (nightMinimumDuration * nightBasicAmount));
            let result = {
                dayHours,
                nightHours,
                minimumDayMinutes: dayMinimumDuration,
                minimumNightMinutes: nightMinimumDuration,
                totalAmount: ((dayHours * dayActualAmount) + (nightHours * nightActualAmount)
                    + (dayMinimumDuration * (dayBasicAmount !== 0 ? dayBasicAmount : dayActualAmount)) + (nightMinimumDuration * (nightBasicAmount !== 0 ? nightBasicAmount : nightActualAmount)))
            }
            return result;
        } else {
            return {
                totalAmount: 0
            };
        }
    }
}