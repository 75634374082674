import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import './TokenContent.css';
import { useAppSelector } from '../../Redux/Store';
import { useReactToPrint } from 'react-to-print';
import { inTokenPropsModel } from '../../Model/TokenContentModel';

const InTokenContent = ({ printRef, printData, isPrint, setStartPrint }: inTokenPropsModel) => {

    const { receiptFormat } = useAppSelector((state) => state.profile);
    const receiptData = receiptFormat.find(item => item.typeNumber === 1);

    const date = new Date(printData.time);
    const fullDate = dayjs(date).format("DD-MM-YYYY");
    const time = dayjs(date).format("hh:mm A");

    useEffect(() => {
        if (Object.keys(printData).length !== 0 && isPrint) {
            setStartPrint(false);
            onHandlePrint();
        }
    }, [printData.tokenId])

    const onHandlePrint = useReactToPrint({
        content: () => printRef!.current,
    });

    return (
        <div className="print-wrapper txt-center" ref={printRef} >
            <div className="border-bottom">
                <div className="mv-5">
                    {printData.title ? printData.title : receiptData?.title}
                </div>
            </div>
            {receiptData?.subtitle || printData.subTitle && <div className="border-bottom">
                <div className="mv-5">
                    {printData.subTitle ? printData.subTitle : receiptData?.subtitle}
                </div>
            </div>}
            <div className="border-bottom">
                <div className="d-flex mv-5">
                    <div className='width-40'>
                        {fullDate}
                    </div>
                    <div className='width-40'>
                        TOKEN IN
                    </div>
                    <div className='width-40'>
                        {time}
                    </div>
                </div>
            </div>
            <div className="border-bottom">
                <div className='mv-5'>
                    <div className="vehicle-no">
                        {printData.regNo}
                    </div>
                    <div >
                        {printData.token}
                    </div>
                </div>
            </div>
            <div className="mv-5">
                {printData.footer ? printData.footer : receiptData?.footer}
            </div>
            <div className="mv-5">
                Powered by AutoHiveSquare
            </div>
        </div>
    );
}

export default InTokenContent;