import { combineReducers } from "@reduxjs/toolkit";
import { listReducer } from "./Slices/ListSlice";
import { loginReducer } from "./Slices/LoginSlice";
import { ExitReducer } from "./Slices/ExitSlice";
import { createTokenReducer } from "./Slices/AddTokenSlice";
import { profileReducer } from "./Slices/ProfileSlice";
import { dashBoardReducer } from "./Slices/DashboardSlice";
import { reportReducer } from "./Slices/ReportSlice";
import { settingsReducer } from "./Slices/SettingsSlice";
import { planReducer } from "./Slices/PlanSlice";
import { blockReducer } from "./Slices/Block";
import { scheduleReducer } from "./Slices/ScheduleSlice";
import { parkingLotReducer } from "./Slices/ParkingLotSlice";

export default combineReducers({
  listReducer: listReducer,
  loginReducer: loginReducer,
  addToken: createTokenReducer,
  exitReducer: ExitReducer,
  profile: profileReducer,
  dashboard: dashBoardReducer,
  reports: reportReducer,
  settings: settingsReducer,
  plan: planReducer,
  block: blockReducer,
  schedule: scheduleReducer,
  parkinglot:parkingLotReducer,
});
