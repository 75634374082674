import { PricingPlan } from "./Model/amountCalculationModels";
import { convertMinimumDurationToMinutes, getDayTimeDifferenceInMilliSeconds, getDifferenceInMinutes, getMinutesFromTimeString, getNightTimeDifferenceInMilliSeconds } from "./utilities/dateTimeConversion";

export const hoursCalculation = (entryTime: Date, exitTime: Date, hourDifference: number, pricingPlan: PricingPlan, dayMinimumMinutes: string, nightMinimumMinutes: string) => {
    let dayHours = 0;
    let nightHours = 0;
    let currentTime = entryTime;
    let formattedDayMinimumMinutes = convertMinimumDurationToMinutes(dayMinimumMinutes);
    let formattedNightMinimumMinutes = convertMinimumDurationToMinutes(nightMinimumMinutes);
    let dayMinimumDuration = 0;
    let nightMinimumDuration = 0;

    while (currentTime < exitTime) {
        let differenceInMinutes = getDifferenceInMinutes(currentTime, exitTime);
        let currentHour = currentTime.getHours();
        let currentMinutes = currentTime.getMinutes();
        let currentHourAndMinutes = getMinutesFromTimeString(currentHour, currentMinutes);
        let planStartHoursAndMinutes = getMinutesFromTimeString(pricingPlan.day.startHour, pricingPlan.day.startMinutes);
        let planEndHoursAndMinutes = getMinutesFromTimeString(pricingPlan.day.endHour, pricingPlan.day.endMinutes);
        let isDayTime = currentHourAndMinutes >= planStartHoursAndMinutes && currentHourAndMinutes < planEndHoursAndMinutes;
        if (differenceInMinutes >= (isDayTime ? formattedDayMinimumMinutes : formattedNightMinimumMinutes)) {
            if (isDayTime) {
                dayHours++;
            } else {
                nightHours++;
            }
        } else {
            if (isDayTime) {
                dayMinimumDuration++;
            } else {
                nightMinimumDuration++;
            }
        }

        // Increment current time by hour difference for the next iteration
        currentTime = new Date(currentTime.getTime() + (hourDifference * 60 * 60 * 1000));
    }
    // console.log('dayHours::', dayHours, 'nightHours::', nightHours, 'dayMinimumDuration::', dayMinimumDuration, 'nightMinimumDuration::', nightMinimumDuration);
    return { dayHours, nightHours, dayMinimumDuration, nightMinimumDuration };
}