import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaderToken } from "../utils/header";
import { ApiResponse } from "../utils/ApiResponse";

const url = process.env.REACT_APP_HIVERAPIURL;

export const GetProfileDetails = createAsyncThunk<ApiResponse>(
  "Get/ProfileDetails",
  async () => {
    try {
      let response = await axios.get(
        `${url}api/user/profile`,
        await getHeaderToken()
      );
      return {
        status: true,
        data: response.data.data,
        resultCode: response.status,
        message: response.data.message,
      };
    } catch (error: any) {
      return {
        status: false,
        data: null,
        resultCode: error.response.status,
        message: error.response.data,
      };
    }
  }
);
