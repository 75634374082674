import React, { useEffect, useState } from 'react';
import { Box, Card, Grid } from '@mui/material';
import './Settings.css';
import theme from '../../Theme/Theme';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import NewLabel from '../../components/NewLabel/NewLabel';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { GetAccountDetails } from '../../Redux/Thunks/SettingsThunk';
import Loading from '../../components/Loading/Loading';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
    children: JSX.Element | JSX.Element[];
}

const Settings = ({ children }: Props) => {
    const { user, parkingLot } = useAppSelector((state: any) => state.profile);
    const { Account } = useAppSelector((state: any) => state.settings);


    const [activeOption, setActiveOption] = useState<any>('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const pages = [
        { name: 'Account', key: '/settings/account' },
        { name: 'Add User', key: '/settings/adduser' },
        { name: 'Token ', key: '/settings/token' },
        { name: 'Receipt', key: '/settings/receipt' },
    ]

    useEffect(() => {
        if (user && Object.keys(user).length > 0 && parkingLot && Object.keys(parkingLot).length > 0) {
            let AccountParams = {
                "UserName": user?.userName,
                "ParkingLotId": parkingLot?.parkingLotId
            }
            dispatch(GetAccountDetails(AccountParams));
        }
    }, [user]);

    useEffect(() => {
        setActiveOption(location.pathname);
    }, [location.pathname])

    const settingsNavigate = (key: string) => {
        navigate(key);
    }

    return (
        <Box margin={1.5}>
            <Grid container>
                <Grid xs={12} md={2}>
                    <Box>
                        <Grid container>
                            <Grid paddingLeft={{ xs: '2%' }} md={12} xs={12}>
                                <Card className='profile-wrapper' elevation={2}>
                                    <div className='profile-picture'>
                                        <PersonOutlineTwoToneIcon style={{ height: '55px', width: '55px' }} />
                                    </div>
                                    <div>
                                        <NewLabel Label='' Value={Account ? Account.customerDetails ? Account.customerDetails.userName : '' : ''} />
                                    </div>
                                </Card>
                            </Grid>
                            <Grid md={12} xs={12} sx={{
                                display: 'flex',
                                flexDirection: { xs: 'row', md: 'column' }
                            }}>
                                {pages.map((page) => (
                                    <div
                                        style={{
                                            color: activeOption == page.key ? theme.palette.primary.main : '',
                                            fontWeight: activeOption == page.key ? '800' : ''
                                        }}
                                        className='settings-options'
                                        onClick={() => settingsNavigate(page.key)}>
                                        {page.name}
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid md={10} xs={12} >
                    {children}
                </Grid>
            </Grid >
        </Box>
    )
};

export default Settings;
