export interface ParkinglotSettingsModal {
    id: number,
    parkingLotId: string,
    settingGroupKey: string,
    settingKey: string,
    settingValue: number,
    lastUpdatedBy: string,
    lastUpdatedOn: Date
}

export const constSettingValues = {
    settings_key_Token_in_payment: "token_in_payment",
    settings_key_Token_grace_interval: "grace_interval",
    settings_key_Token_grace_active: "grace_active",
    settings_key_Helmet_hour: "helmet_hours",
    settings_key_Helmet_amount: "helmet_amount",
    settings_key_Token_grace_both_in_same_button: "grace_both_in_same_button",
    settings_Key_Token_out_payment: "token_out_payment",
    settings_Key_Token_in_otp: "token_otp",
    settings_key_Token_in_receipt: "token_in_receipt",
    settings_Key_Token_out_receipt: "token_out_receipt",
    settings_Key_Token_in_dummy_mobile: "token_in_allow_dummy_mobile",
    settings_Key_Token_in_dummy_register_number: "token_in_allow_dummy_register_number"
}