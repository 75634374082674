import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikProps } from "formik";
import { ScheduleFormikInputs } from "./CreateSchedule";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAppSelector } from "../../../Redux/Store";
import { useEffect, useState } from "react";
import { PlanDetailsModel } from "../../../Model/PlanModel";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface Props {
  formik: FormikProps<ScheduleFormikInputs>;
}

interface ScheduleType {
  value: string;
  label: string;
}

const CreateScheduleForm: React.FC<Props> = (props) => {
  const { formik } = props;
  const { otherPlanDetails: plans } = useAppSelector((state) => state.plan);
  const theme = useTheme();
  const navigate = useNavigate();

  const [activePlan, setActivePlan] = useState<PlanDetailsModel | undefined>(
    undefined
  );

  let scheduleTypes: ScheduleType[] = [
    { value: "normal", label: "Normal" },
    { value: "special", label: "Special" },
    { value: "forced", label: "Forced" },
  ];

  useEffect(() => {
    if (plans.length > 0) {
      const value = plans.find(
        (each) => each.plan.planId === formik.values.planId
      );
      setActivePlan(value);
    }
  }, [formik.values.planId]);

  const getEffectiveDateValue = () => {
    if (formik.values.scheduleType === "normal") {
      return formik.values.normalEffectiveDateValue;
    } else if (formik.values.scheduleType === "special") {
      return formik.values.specialEffectiveDateValue;
    } else {
      return formik.values.forcedEffectiveDateValue;
    }
  };

  const getEffectiveDateDisabled = () => {
    if (formik.values.scheduleType === "normal") {
      return formik.values.normalEffectiveDateDisabled;
    } else if (formik.values.scheduleType === "special") {
      return formik.values.specialEffectiveDateDisabled;
    } else {
      return formik.values.forcedEffectiveDateDisabled;
    }
  };

  const getExpiryDateValue = () => {
    if (formik.values.scheduleType === "normal") {
      return formik.values.normalExpiryDateValue;
    } else if (formik.values.scheduleType === "special") {
      return formik.values.specialExpiryDateValue;
    } else {
      return formik.values.forcedExpiryDateValue;
    }
  };

  const getExpiryDateDisabled = () => {
    if (formik.values.scheduleType === "normal") {
      return formik.values.normalExpiryDateDisabled;
    } else if (formik.values.scheduleType === "special") {
      return formik.values.specialExpiryDateDisabled;
    } else {
      return formik.values.forcedExpiryDateDisabled;
    }
  };

  useEffect(() => {
    formik.setFieldValue("effectiveDate", getEffectiveDateValue());
    formik.setFieldValue("expiryDate", getExpiryDateValue());
  }, [
    formik.values.scheduleType,
    formik.values.normalEffectiveDateValue,
    formik.values.normalExpiryDateValue,
  ]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        margin: "16px 8px 16px 16px",
        padding: "20px",
        borderRadius: "15px",
        background:
          "linear-gradient(276deg, rgba(57, 201, 241,0.2) 0%, rgba(116,179,240,0.2) 4%, rgba(255, 225, 230,0.2) 100%)",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            textAlign: "center",
            alignSelf: "stretch",
            margin: "0 0 16px 0",
          }}
        >
          Assign Schedule
        </Typography>
        {/* <TextField
          label="Schedule Name"
          placeholder="Ex: Jan-March Schedule"
          variant="outlined"
          error={
            formik.touched.scheduleName && Boolean(formik.errors.scheduleName)
          }
          helperText={
            formik.touched.scheduleName ? formik.errors.scheduleName : ""
          }
          {...formik.getFieldProps("scheduleName")}
          sx={{ minWidth: "70%" }}
        /> */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ margin: "10px 0" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "600" }}>
            Schedule Type :
          </Typography>
          {scheduleTypes.map((eachType) => {
            let disabled = false;
            if (
              formik.values.isSpecialScheduleTypeDisabled &&
              eachType.value === "special"
            ) {
              disabled = true;
            }
            return (
              <Chip
                key={eachType.label}
                label={eachType.label}
                clickable
                color={
                  formik.values.scheduleType === eachType.value
                    ? "primary"
                    : "default"
                }
                onClick={() =>
                  formik.values.scheduleType === eachType.value
                    ? formik.setFieldValue("scheduleType", "")
                    : formik.setFieldValue("scheduleType", eachType.value)
                }
                style={{
                  padding: "0 10px",
                  margin: "4px",
                }}
                disabled={disabled}
              />
            );
          })}
          {formik.errors.scheduleType && (
            <FormHelperText
              sx={{ color: theme.palette.error.main, margin: "0 0 0 15px" }}
            >
              {formik.errors.scheduleType}
            </FormHelperText>
          )}
        </Box>
        {/* <Typography variant="body1" sx={{ fontWeight: "600" }}>
          Plan Name : {"  "}
          {formik.values.planName === "" ? "NA" : formik.values.planName}
        </Typography> */}
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={plans}
          getOptionKey={(plan) => plan.plan.planId}
          getOptionLabel={(plan) => plan.plan.planName}
          value={activePlan}
          onChange={(event, value) => {
            value
              ? formik.setFieldValue("planId", value.plan.planId)
              : formik.setFieldValue("planId", "");
          }}
          fullWidth
          // inputValue={activePlan ? activePlan.plan.planName : ""}
          onInputChange={(event, value, reason) => {}}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Plan Name"
              placeholder="Ex: April Plan"
            />
          )}
        /> */}
        <DatePicker
          label="Effective Date"
          value={formik.values.effectiveDate}
          onChange={(newDate) => formik.setFieldValue("effectiveDate", newDate)}
          sx={{ minWidth: "70%", margin: "10px 0" }}
          format="DD MMMM YYYY, dddd"
          formatDensity="spacious"
          disabled={getEffectiveDateDisabled()}
          minDate={
            formik.values.scheduleType === "special"
              ? dayjs(formik.values.specialMinimumEffectiveDate)
              : formik.values.scheduleType === "normal"
                ? dayjs(new Date(new Date().setHours(0, 0, 0, 0))).add(1, "day")
                : undefined
          }
          maxDate={
            formik.values.scheduleType === "special"
              ? dayjs(formik.values.specialMaximumExpiryDate)
              : undefined
          }
        />
        <DatePicker
          label="Expiry Date"
          value={formik.values.expiryDate}
          onChange={(newDate) => formik.setFieldValue("expiryDate", newDate)}
          sx={{ minWidth: "70%", margin: "10px 0" }}
          format="DD MMMM YYYY, dddd"
          formatDensity="spacious"
          disabled={getExpiryDateDisabled()}
          minDate={
            formik.values.scheduleType === "special"
              ? dayjs(formik.values.specialMinimumEffectiveDate)
              : formik.values.scheduleType === "normal"
                ? dayjs(new Date(new Date().setHours(0, 0, 0, 0))).add(1, "day")
                : undefined
          }
          maxDate={
            formik.values.scheduleType === "special"
              ? dayjs(formik.values.specialMaximumExpiryDate)
              : formik.values.effectiveDate.add(6, "months")
          }
        />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          flexGrow={1}
          justifyContent="center"
          sx={{ margin: "10px 0" }}
        >
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            color="primary"
            style={{
              margin: "0 20px",
              backgroundColor: `${theme.palette.background.default}`,
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{
              margin: "0 20px",
            }}
          >
            Submit
          </Button>
        </Box>
      </LocalizationProvider>
    </Card>
  );
};

export default CreateScheduleForm;
