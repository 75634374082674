import React, { Dispatch, SetStateAction } from "react";
import { Autocomplete, Grid, IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { nanoid } from "@reduxjs/toolkit";
import { SearchValueModel } from "../../Model/SearchBarModel";

interface props {
  handleSearch: (data: any) => void;
  searchData: any[];
  setSearchExit: Dispatch<SetStateAction<boolean>>;
  searchValue: SearchValueModel;
  setSearchValue: Dispatch<SetStateAction<SearchValueModel>>;
}

const SearchBar = (props: props) => {
  const {
    handleSearch,
    searchData,
    setSearchExit,
    searchValue,
    setSearchValue,
  } = props;

  let options = searchData
    .filter(
      (data) =>
        data.tokenNumber ||
        data.registerNumber ||
        data.customerMobile ||
        data.vehicleNumber
    )
    .flatMap((data) => {
      const values = [];
      if (data.tokenNumber) {
        values.push({
          key: nanoid(),
          title: "tokenNumber",
          value: data.tokenDisplayNumber,
          tokenNo: data.tokenNumber,
        });
      }
      if (data.registerNumber) {
        values.push({
          key: nanoid(),
          title: "registerNumber",
          value: data.registerNumber,
          tokenNo: data.tokenNumber,
        });
      }
      if (data.vehicleNumber) {
        values.push({
          key: nanoid(),
          title: "registerNumber",
          value: data.vehicleNumber,
          parkingLotId: data.parkingLotId,
          type: data.type,
          id: data.id,
        });
      }
      // if (data.customerMobile) {
      //     values.push({ key: `${data.tokenNumber + data.customerMobile}`, title: 'customerMobile', value: data.customerMobile, tokenNo: data.tokenNumber });
      // }
      return values;
    })
    .sort((a, b) => -b.title.localeCompare(a.title));

  // options = options.filter((item, index, arr) =>
  //     arr.findIndex((t) => t.tokenNo === item.tokenNo) === index);
  return (
    <Autocomplete
      fullWidth
      options={options}
      groupBy={(option) => option.title}
      getOptionLabel={(option) => option.value}
      onInputChange={(event, value, reason) => {
        if (reason === "clear") {
          setSearchExit(false);
          setSearchValue({
            key: "",
            title: "",
            value: "",
            tokenNo: "",
          });
        } else if (reason === "input") {
          setSearchValue((prev: any) => ({ ...prev, value }));
        }
      }}
      value={searchValue}
      popupIcon={null}
      onChange={(event: any, value: any) => {
        if (value) {
          handleSearch(value);
          setSearchValue(value);
        }
      }}
      renderInput={(params: any) => (
        <Grid container>
          <IconButton sx={{ padding: "0px 20px" }} aria-label="menu">
            <SearchIcon />
          </IconButton>
          <TextField {...params} sx={{ flex: 1 }} label="Search" autoFocus />
        </Grid>
      )}
    />
  );
};

export default SearchBar;
