import { TextField } from '@mui/material'
import React from 'react';

interface TextBoxProps {
    formik: any;
    label: string;
    fieldpropsName: string;
    error: boolean;
    helpertext: string;
    value?: string;
    type?: 'text' | 'password' | 'number' | 'email';
    upperCase?: boolean;
}

function TextBox(props: TextBoxProps) {
    const { formik, label, fieldpropsName, error, helpertext, type, value, upperCase } = props;
    return (
        <div>
            <TextField
                value={value}
                label={label}
                variant="outlined"
                type={type}
                fullWidth
                error={error}
                helperText={
                    error ? helpertext : ''
                }
                {...formik.getFieldProps(fieldpropsName)}
                onChange={(e) => {
                    if (upperCase) {
                        const uppercaseValue = e.target.value.toUpperCase();
                        formik.setFieldValue(fieldpropsName, uppercaseValue);
                    }
                    else {
                        formik.setFieldValue(fieldpropsName, e.target.value);
                    }
                }}
            />
        </div>
    )
}

export default TextBox