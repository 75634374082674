import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loading from "../../../components/Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../../Redux/Store";
import {
  DeleteVehicleBlockOrTheft,
  GetByIdBlockOrTheft,
  ListBlockOrTheft,
} from "../../../Redux/Thunks/Block";
import DataTable from "../../../components/DataTable";
import { ColDef } from "ag-grid-community";
import { ToastMessageState } from "../../../components/ToastMessage/ToastMessage";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate } from "react-router-dom";
import theme from "../../../Theme/Theme";
import { TransitionProps } from "@mui/material/transitions";
import { SearchValueModel } from "../../../Model/SearchBarModel";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BlockAndTheftList() {
  const [isSearchExit, setSearchExit] = useState<boolean>(false);
  const [details, setDetails] = useState<any>([]);
  const [autosearchData, setAutosearchData] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);

  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });
  const [deleteData, setDeleteData] = useState<any>();
  const [currentTab, setCurrentTab] = useState("block");
  const [searchValue, setSearchValue] = useState<SearchValueModel>({
    key: "",
    title: "",
    value: "",
    tokenNo: "",
  });

  const { parkingLot } = useAppSelector((state: any) => state.profile);
  const { blockList, block }: any = useAppSelector((state: any) => state.block);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSearchExit) {
      setDetails([{ ...block }]);
    } else {
      setDetails(blockList);
    }
  }, [isSearchExit]);

  useEffect(() => {
    if (blockList.length > 0) {
      setDetails(blockList);
    } else {
      setDetails([]);
    }
    let searchData = blockList
      .map((block: any) => {
        return { ...block, vehicleNumber: block.registerNumber };
      })
      .filter((data: any) => data.registerNumber)
      .map(({ registerNumber, ...rest }: any) => rest);
    setAutosearchData(searchData);
  }, [blockList.length, currentTab]);

  useEffect(() => {
    if (parkingLot && parkingLot.parkingLotId) {
      let details = {
        type: 1,
        parkingLotId: parkingLot.parkingLotId,
      };
      dispatch(ListBlockOrTheft(details));
    }
  }, [parkingLot?.parkingLotId]);

  const toggleTab = async () => {
    if (parkingLot && parkingLot.parkingLotId) {
      let details = {
        type: currentTab === "block" ? 2 : 1,
        parkingLotId: parkingLot.parkingLotId,
      };
      await dispatch(ListBlockOrTheft(details));
    }
    setCurrentTab(currentTab === "block" ? "theft" : "block");
  };

  const handleDialogAgree = async () => {
    handleDelete(deleteData.id, deleteData.type, deleteData.parkingLotId);
  };

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const handleSearch = (data: any) => {
    let params = {
      id: data.id,
      type: data.type,
      parkingLotId: data.parkingLotId,
    };
    dispatch(GetByIdBlockOrTheft(params)).then((data) => {
      if (data.meta.requestStatus !== "fulfilled") {
        updateToastMessage(true, "Connection Refused", "error");
        return;
      }
      if (data.payload.status == 204) {
        updateToastMessage(true, "No data found", "info");
        return;
      }
      if (data.payload.status) {
        setSearchExit(true);
      } else {
        updateToastMessage(true, data.payload.message, "error");
      }
    });
  };
  const onEdit = (data: any) => {
    if (data.type == 1)
      navigate(
        "/blocked/block?id=" +
        data.id +
        "&type=" +
        data.type +
        "&parkingLotId=" +
        data.parkingLotId
      );
    if (data.type == 2)
      navigate(
        "/blocked/theft?id=" +
        data.id +
        "&type=" +
        data.type +
        "&parkingLotId=" +
        data.parkingLotId
      );
  };

  const handleDelete = async (
    id: string,
    type: number,
    parkingLotId: string
  ) => {
    let params = {
      id,
      type,
      parkingLotId,
    };
    const res = await dispatch(DeleteVehicleBlockOrTheft(params));
    setOpenModal(false);
    if (res.payload.status) {
      await dispatch(ListBlockOrTheft(params));
    }
  };

  const handelOpenModel = (id: string, type: number, parkingLotId: string) => {
    setOpenModal(true);
    setDeleteData({
      id,
      type,
      parkingLotId,
    });
  };

  const ActionRenderer = (props: any) => {
    return (
      <div>
        <Box aria-label="Actions">
          <Tooltip title="Edit" placement="bottom-start">
            <IconButton onClick={() => onEdit(props.data)}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom-start">
            <IconButton
              onClick={() =>
                handelOpenModel(
                  props.data.id,
                  props.data.type,
                  props.data.parkingLotId
                )
              }
            >
              <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    );
  };

  const theftColumnDefs: ColDef[] = [
    { field: "registerNumber", headerName: "Register Number" },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer },
    { field: "description", headerName: `Description`, minWidth: 170 },
    { field: "stationMobile", headerName: "Station Mobile" },
    { field: "stationName", headerName: "Station Name" },
    { field: "parkingLotName", headerName: "Lot Name", minWidth: 90 },
    { field: "isParked", headerName: "Vehicle Parked", minWidth: 90 },
  ];

  const blockColumnDefs: ColDef[] = [
    { field: "registerNumber", headerName: "Register Number", minWidth: 132 },
    { field: "actions", headerName: "Actions", cellRenderer: ActionRenderer, minWidth: 108 },
    { field: "mobileNo", headerName: "Mobile Number", minWidth: 154 },
    { field: "description", headerName: `Description`, minWidth: 170 },
    // { field: "stationMobile", headerName: "Station Mobile" },
    // { field: "stationName", headerName: "Station Name" },
    { field: "parkingLotName", headerName: "Lot Name", minWidth: 150 },
    { field: "isParked", headerName: "Vehicle Parked", minWidth: 90 },
  ];

  const { isLoading } = useAppSelector((state) => state.block);
  return (
    <div style={{ height: "80vh", margin: "10px" }}>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure want to delete
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDialogAgree}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid
          xs={12}
          md={4}
          justifyContent={{ xs: 'center', md: 'start' }}
          display="flex"
          alignItems="center"
        >
          <Typography
            style={{ height: "40px", width: "104px" }}
            color={theme.palette.text.primary}
            variant="h4"
          >
            {currentTab === "block" ? "Block" : "Theft"}
          </Typography>
          <Box onClick={() => toggleTab()}>
            <Fab color="primary">
              <SwapHorizIcon fontSize="medium" />
            </Fab>
          </Box>
          <Typography
            style={{ marginLeft: "20px", height: "40px" }}
            color={theme.palette.text.secondary}
            variant="h4"
          >
            {currentTab === "block" ? "Theft" : "Block"}
          </Typography>
        </Grid>
        <Grid xs={9} md={4}>
          <SearchBar
            searchData={autosearchData}
            handleSearch={handleSearch}
            setSearchExit={setSearchExit}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Grid>
        <Grid md={3} />
        <Grid xs={2} md={1}>
          <Box
            onClick={() =>
              navigate(currentTab === "block" ? "/blocked/block" : "/blocked/theft")
            }
          >
            <Fab color="primary">
              <AddIcon fontSize="medium" />
            </Fab>
          </Box>
        </Grid>
        <Loading openModal={isLoading} />
      </Grid>
      <DataTable
        rows={details}
        columnDefs={currentTab === "block" ? blockColumnDefs : theftColumnDefs}
      />
      {/* <Box
        // sx={{ position: "fixed", top: "10.5%", right: "3%" }}
        onClick={() =>
          navigate(currentTab === "block" ? "/blocked/block" : "/blocked/theft")
        }
      >
        <Fab color="primary">
          <AddIcon fontSize="medium" />
        </Fab>
      </Box> */}
    </div>
  );
}

export default BlockAndTheftList;
