import React from "react";

const Permission = () => {
    return (
        <div>
            Permission
        </div>
    )
};

export default Permission;
