import { AlertColor, Box, Button, Grid, Slide, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import blockAnimation from '../../../assets/block3.json';
import * as yup from "yup";
import { BlockInitialValues, BlockParams } from '../../../Model/BlockModal';
import { useFormik } from 'formik';
import TextBox from '../../../components/TextBox/TextBox';
import Lottie from 'react-lottie';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store';
import { AddBlockAndTheft, GetByIdBlockOrTheft, UpdateBlockAndTheft } from '../../../Redux/Thunks/Block';
import { ToastMessage, ToastMessageState } from '../../../components/ToastMessage/ToastMessage';
import { useSearchParams } from 'react-router-dom';
import { ClearBlock } from '../../../Redux/Slices/Block';

function Block() {
    const { parkingLot } = useAppSelector((state) => state.profile);
    const { block }: any = useAppSelector((state: any) => state.block);

    const [checked, setChecked] = useState(false);
    const [toastMessage, setToastMessage] = useState<ToastMessageState>({
        showMessage: false,
        message: "",
        alertType: "success",
    });

    const containerRef = useRef<HTMLElement>(null);

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    let id = searchParams.get('id')
    let type = searchParams.get('type')
    let parkingLotId = searchParams.get('parkingLotId')

    const handleChange = () => {
        setChecked(true);
    };
    useEffect(() => {
        setChecked(true);

        return () => {
            setChecked(false);
        };
    }, []);

    useEffect(() => {
        if (parkingLotId != null) {
            let params = {
                id: id,
                type: type,
                parkingLotId: parkingLotId
            }
            dispatch(GetByIdBlockOrTheft(params))
        } else {
            dispatch(ClearBlock());
        }
    }, [parkingLotId])

    useEffect(() => {
        if (Object.keys(block).length > 0) {
            formikBlock.setFieldValue("vehicleNo", block.registerNumber);
            formikBlock.setFieldValue("mobile", block.mobileNo);
            formikBlock.setFieldValue("description", block.description);
        } else {
            formikBlock.setFieldValue("vehicleNo", '');
            formikBlock.setFieldValue("mobile", '');
            formikBlock.setFieldValue("description", '');
        }
    }, [block]);

    const defaultOptions = {
        loop: true,
        // autoplay: true,
        animationData: blockAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const initialValues: BlockInitialValues = {
        vehicleNo: '',
        mobile: '',
        description: '',
    }

    const updateToastMessage = (
        showMessage: boolean,
        message: string,
        alertType: AlertColor
    ) => {
        setToastMessage({ showMessage, message, alertType });
    };

    const formikBlock = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object({
            vehicleNo: yup.string()
                .matches(
                    /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
                    "Invalid Vehicle Number - XX00XX0000"
                )
                .transform((value: string) => (value ? value.toUpperCase() : value))
                .required("Required"),
            mobile: yup.string()
                .matches(/^\d{10}$/, "Mobile number must be 10 digits"),
        }),
        onSubmit: async (values: any, { resetForm }: any) => {
            let params: BlockParams = {
                id: id && Object.keys(block).length > 0 ? block.id : null,
                registerNumber: values.vehicleNo.toUpperCase(),
                mobileNo: values.mobile,
                description: values.description,
                parkingLotId: parkingLot ? parkingLot.parkingLotId : null,
                type: 1
            };
            if (id != null) {
                dispatch(UpdateBlockAndTheft(params)).then((data: any) => {

                    if (data.meta.requestStatus !== "fulfilled") {
                        updateToastMessage(true, "Connection Refused", "info");
                        return;
                    }
                    if (data.payload.data.status == 204) {
                        updateToastMessage(true, "No data found", "info");
                        return;
                    }
                    if (data.payload.status) {
                        updateToastMessage(true, data.payload.data.message, 'success');
                        resetForm()
                        id = null;
                        type = null;
                        parkingLotId = null;
                    } else {
                        updateToastMessage(true, data.payload.message, "error");
                    }
                });
                return;
            }
            dispatch(AddBlockAndTheft(params)).then((data: any) => {
                if (data.meta.requestStatus !== "fulfilled") {
                    updateToastMessage(true, "Connection Refused", "info");
                    return;
                }
                if (data.payload.data.status == 204) {
                    updateToastMessage(true, "No data found", "info");
                    return;
                }
                if (data.payload.data.status) {
                    updateToastMessage(true, data.payload.data.message, 'success');
                    resetForm()
                } else {
                    updateToastMessage(true, data.payload.message, "error");
                }
            })
        }
    })

    const vehicleNo = (
        <Box sx={{ width: '100%' }}>
            <TextBox
                type='text'
                error={Boolean(formikBlock.touched.vehicleNo) && Boolean(formikBlock.errors.vehicleNo)}
                formik={formikBlock}
                label='Vehicle Number'
                helpertext={formikBlock.touched.vehicleNo ? String(formikBlock.errors.vehicleNo) : ""}
                fieldpropsName={'vehicleNo'}
                upperCase={true}
            />
        </Box>
    );

    const mobile = (
        <Box sx={{ width: '100%' }}>
            <TextBox
                type='text'
                error={Boolean(formikBlock.touched.mobile) && Boolean(formikBlock.errors.mobile)}
                formik={formikBlock}
                label='Mobile Number'
                helpertext={formikBlock.touched.mobile ? String(formikBlock.errors.mobile) : ""}
                fieldpropsName={'mobile'}
            />
        </Box>
    );

    const description = (
        <Box sx={{ width: '100%' }}>
            <TextBox
                type='text'
                error={Boolean(formikBlock.touched.description) && Boolean(formikBlock.errors.description)}
                formik={formikBlock}
                label='Description'
                helpertext={formikBlock.touched.description ? String(formikBlock.errors.description) : ""}
                fieldpropsName={'description'}
            />
        </Box>
    );
    const buttons = (
        <Box sx={{ width: '100%' }}>
            <Grid container>
                <Grid xs={4} />
                <Grid item md={3} xs={3} >
                    <Button sx={{
                        height: '40px',
                        width: '100%',
                        marginLeft: '15%',
                        marginTop: '8%'
                    }}
                        variant='contained'
                        onClick={() => formikBlock.handleSubmit()}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
    const heading = (
        <Box sx={{ width: '100%' }}>
            <Typography variant='h4' >{id ? 'Update ' : 'Add to '}Block</Typography>
        </Box>
    );

    const wholeJsx = (
        <Grid container gap={2} alignItems='center'>
            <Grid item md={0.4} />
            <Grid item xs={0} md={3} display={{ xs: "none", sm: "none", md: "flex" }} >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <Lottie
                        style={{
                            height: "110%",
                            width: "110%",
                            margin: "0 auto",
                        }}
                        options={defaultOptions}
                    />
                </Box>
            </Grid>
            <Grid item md={0.4} />
            <Grid item xs={11.6} md={7}>
                <Box sx={{ p: 2, height: '100%', alignItems: 'center' }} ref={containerRef}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ p: 2, height: 60, overflow: 'hidden' }} ref={containerRef}>
                                <Slide in={checked} container={containerRef.current} direction='up' timeout={900}>
                                    {heading}
                                </Slide>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <Slide in={checked} container={containerRef.current} direction='left' timeout={700} > */}
                            {vehicleNo}
                            {/* </Slide> */}
                            {/* <Slide in={checked} container={containerRef.current} direction='right' timeout={1250}> */}
                            {mobile}
                            {/* </Slide>
                                <Slide in={checked} container={containerRef.current} direction='left' timeout={1750} > */}
                            {description}
                            {/* </Slide>
                                <Slide in={checked} container={containerRef.current} direction='right' timeout={2100} > */}
                            {buttons}
                            {/* </Slide> */}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <ToastMessage
                showToastMessage={toastMessage.showMessage}
                setToastMessage={setToastMessage}
                message={toastMessage.message}
                alertType={toastMessage.alertType}
            // placement={{ vertical: "top", horizontal: "left" }}
            />
        </Grid>
    )

    return (
        <Slide in={checked} container={containerRef.current} direction='right' timeout={500}>
            {wholeJsx}
        </Slide>
    )
}

export default Block
