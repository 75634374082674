import { AlertColor, Box, Grid } from "@mui/material";
import CurrentPlan from "./CurrentPlan";
import OtherPlans from "./OtherPlans";
import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../Redux/Store";
import { GetPlansDetails } from "../../../Redux/Thunks/PlanThunk";
import {
  ToastMessage,
  ToastMessageState,
} from "../../../components/ToastMessage/ToastMessage";
import Loading from "../../../components/Loading/Loading";

const Plan = () => {
  const { isLoading, currentPlanDetails, upcomingPlanDetails } = useAppSelector(
    (state) => state.plan
  );
  const dispatch = useAppDispatch();

  const [toastMessage, setToastMessage] = useState<ToastMessageState>({
    showMessage: false,
    message: "",
    alertType: "success",
  });

  const updateToastMessage = (
    showMessage: boolean,
    message: string,
    alertType: AlertColor
  ) => {
    setToastMessage({ showMessage, message, alertType });
  };

  const getPlansDetails = async () => {
    const response = await dispatch(GetPlansDetails());
    if (!response.payload) {
      updateToastMessage(true, "Connection Refused", "error");
    }
  };

  useEffect(() => {
    getPlansDetails();
  }, []);

  return (
    <Box display="flex" flexDirection="column" sx={{ minHeight: "90vh" }}>
      <Grid container spacing={2} flexGrow={1} padding={2}>
        <Grid
          item
          xs={12}
          md={5}
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          <CurrentPlan currentPlanDetails={currentPlanDetails} />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          <OtherPlans upcomingPlanDetails={upcomingPlanDetails} />
        </Grid>
      </Grid>
      <Loading openModal={isLoading} />
      <ToastMessage
        showToastMessage={toastMessage.showMessage}
        setToastMessage={setToastMessage}
        message={toastMessage.message}
        alertType={toastMessage.alertType}
      />
    </Box>
  );
};

export default Plan;
