import { createSlice } from "@reduxjs/toolkit";
import {
  AddSchedule,
  GetScheduleInitialDetails,
} from "../Thunks/ScheduleThunk";
import { ScheduleInitialModel } from "../../Model/ScheduleModel";

interface InitialState {
  isLoading: boolean;
  createScheduleDetails: ScheduleInitialModel;
}

let initialState: InitialState = {
  isLoading: false,
  createScheduleDetails: {
    unassignedPlans: [],
    normalEffectiveDate: null,
    specialMinimumEffectiveDate: null,
    specialMaximumExpiryDate: null,
    forcedEffectiveDate: null,
    forcedExpiryDate: null,
  },
};

const scheduleSlice = createSlice({
  name: "Schedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AddSchedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AddSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(AddSchedule.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(GetScheduleInitialDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetScheduleInitialDetails.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.createScheduleDetails.unassignedPlans =
            action.payload.data === null
              ? []
              : action.payload.data.unassignedPlans;
          state.createScheduleDetails.normalEffectiveDate =
            action.payload.data.normalEffectiveDate;
          state.createScheduleDetails.specialMinimumEffectiveDate =
            action.payload.data.specialMinimumEffectiveDate;
          state.createScheduleDetails.specialMaximumExpiryDate =
            action.payload.data.specialMaximumExpiryDate;
          state.createScheduleDetails.forcedEffectiveDate =
            action.payload.data.forcedEffectiveDate;
          state.createScheduleDetails.forcedExpiryDate =
            action.payload.data.forcedExpiryDate;
        }
        state.isLoading = false;
      })
      .addCase(GetScheduleInitialDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const scheduleReducer = scheduleSlice.reducer;
